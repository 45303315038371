<template lang="pug">
  .share-gauge
    | You keep
    b.ml-1.has-text-success {{teacherShare}}%
    .is-flex
      span.teacher-share(:style="'width: '+teacherShare+'%'")
      b-tooltip.application-share(
       :style="'width: '+platformShare+'%'"
       :label="platformShare+'% for Teachee'", type='is-primary', :animated='true', position='is-bottom')
      b-tooltip.transaction-fee(label="3% in average for transaction fees", type='is-info', :animated='true', position='is-bottom')
</template>

<script>

export default {
  name: "ShareGauge",
  props: [
    'teacherShare',
    'platformShare',
    'price',
  ],
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss">
  @import 'colors';

  .share-gauge .is-flex {
    min-height: 9px;
    border-radius: 5px;
  }
  .teacher-share {
    background-color: $green;
  }
  .application-share {
    background-color: $primary;
  }
  .transaction-fee {
    background-color: $blue;
    width: 3%
  }
</style>
