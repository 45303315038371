import {Lesson, VisitedLesson, Activity} from './models/resources'


export default {

  editWord(state, {sentence, word}) {
    sentence.words[word.idx] = Object.assign({}, word)
    if (sentence.ipa) {
      sentence.ipa = sentence.ipa.replace(word.tts, word.content)
    }
    sentence.ipa_locked = true;
    Vue.set(
        state.lesson.sentences,
        sentence.index,
        Object.assign({}, sentence, {version: Date.now(), lastChange: sentence}))
    const sent = state.lesson.sentences.find(el => el.id == sentence.id)
    Vue.set(sent.words, word.idx, word)
  },

  SOMEONE_JOINED(state, data) {
    console.log("Mutation SOMEONE_JOINED", data)
  }
}
