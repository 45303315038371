<template lang="pug">
  .app-container.page--front.text-main.page--pronunciation
    front-menu
    main.app-content.is-wide

      pronunciation-try-section( v-if="browser.name === 'chrome' || browser.name === 'safari'" )

    footer-section
</template>

<script>
import footerSection from "@/components/sections/FooterSection";
import askToSubscribeOnLeave
  from '@/components/behaviors/AskToSubscribeOnLeave';
import CtaSection from "@/components/sections/Cta";
import {noLayoutAndStorePartnerInfo, ssrFinishedRendering} from "@/utils";
import PronunciationTrySection from "../sections/PronuncationTry";

const { detect } = require('detect-browser');
  const browser = detect();

export default {
    name: 'PronunciationPage',
    mixins: [
      askToSubscribeOnLeave,
    ],
    components: {
      PronunciationTrySection,
      CtaSection,
      footerSection
    },
    data() {
      return {
        browser,
      }
    },
    mounted() {
      noLayoutAndStorePartnerInfo(this.$store, this.$route, this.$cookie)
      ssrFinishedRendering();
    },
  }
</script>


<style lang="scss">
  @import 'colors';

  .page--pronunciation {
    .app-header .navbar {
      background-color: #FFF;
    }
    .app-content {
      background-color: $primaryDarker;
    }
    #pronunciation_try {
      margin-top: 5rem;
    }
  }

</style>
