<template lang="pug">
  .card.has-shadow-soft-dark
    header.card-header.has-text-right-mobile
      p.card-header-title.is-hidden-mobile
        slot(name="title")
      slot(name="headerLeft")
      b-tooltip(
        :label='(status) ? "Live connection active: Modifications will be seen live to everyone following this lesson" : "OFF: Live change has an issue. Please leave a feedback if it persists"'
        type='is-light'
        animated
        multilined
        position='is-top')
        a.card-header-icon(href="#" aria-label="more options" @click="status ? disconnect() : connect()")
          span(:class="{'is-green':status, 'is-red': !status}")
            | •
        //span.icon
          i.fas.fa-angle-down(aria-hidden="true")
      slot(name="header")
    slot(name="content")
    footer.card-footer.is-flex
      slot(name="footer")
      <!--a.card-footer-item(href="#") Save-->


</template>

<script>
export default {
  name: "Board",
  props: ["status"],
  data() {
    return {
    };
  },
  mounted() {},
  methods: {
    // disconnect() {
    //   this.$sails.socket._raw.disconnect();
    // },
    // connect() {
    //   this.$sails.socket._raw.connect();
    // }

    disconnect() {
      this.$socket.disconnect();
    },
    connect() {
      this.$socket.connect();
    }
  }
};
</script>

<style lang="scss">
@import "colors";

.is-green {
  color: $green;
}
.is-red {
  color: $red;
}
</style>
