
<template lang="pug">
  span.ipa(@click="speak(tts, lang, $event)" v-ga="'listenToWord'")
    | {{ content }}


</template>

<script>
  import Audio from '@/services/Audio.js.coffee'
  import {mapGetters} from 'vuex'

  export default {
    name: 'ipa',
    props: [
      'content',
      'user',
      'tts',
      'lang'
    ],
    computed: mapGetters(['voices']),
    data () {
      return {
        audio: null
      }
    },
    mounted () {
      this.audio = Audio.init()
    },
    methods: {
      speak: function(text, lang, event) {
        if (!this.user.id || this.user.preferences.tts_on_click_on_ipa) {
          this.audio.speak(text,  event, lang || 'en', this.voices[lang])
          this.$mixpanel.track("Sentence - Listen to word");
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'colors';

  span:not(.disabled) {
    padding: 0 4px;
    cursor: pointer;
    transform: scale(1);
    display: inline-block;
    transition: scale .2s ease-in-out, background-color .2s ease-in-out;
  }
  span.disabled {
    padding: 0 4px;
    display: inline-block;
    font-size: 0.8rem;
  }

  span:first-child {
    margin-left: -4px;
  }

  span:not(.disabled):hover {
    border-radius: 2px;
    background-color: $yellow;
    color: $darkGray;
    transform: scale(1.1);
  }
  span.pronounced:not(.disabled):hover {
    background-color: #f3f3f3;
  }
</style>
