`import {Category} from '../models/resources'`

actions =
  getCategories: (context) ->
    Category.query().then (response) ->
      categories = response.body
      if categories.length
        categories.unshift
          name: 'uncategorized'
      context.commit('categories', categories)

  addCategory: (context, category) ->
    new Promise((resolve, reject) ->
      context.commit('addCategory', category)
      resolve()
    )

  editCategory: (context, category) ->
    new Promise (resolve, reject) ->
      context.commit('editCategory', category)
      resolve()

  deleteCategory: (context, category) ->
    Category.delete({id: category.id}).then (res) ->
      context.commit('deleteCategory', category)

  saveCategory:  (context, category) ->
    rs = Category

    new Promise((resolve, reject) ->
      if category.id
        rs.update({id: category.id}, {category: category}).then(
          (res) ->
            category.units      = res.body.units
            category.units_icon = res.body.units_icon
            context.commit('editCategory', category)
            resolve(res)
        , (res) ->
          reject(res)
        )
      else
        rs.save(category: category).then(
          (res) ->
            context.commit('addCategory', res.body)
            resolve(res)
        , (res) ->
          reject(res)
        )
    )


`export default actions`
