`import {Charge} from '../models/resources'`

actions =

  charge: (context, charge) ->
    new Promise((resolve, reject) ->
      Charge.save(charge).then(
        (res) ->
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )

  getCharges: (context) ->
    new Promise((resolve, reject) ->
      Charge.query().then (response) ->
        context.commit('charges', response.body.charges)
        resolve()
    )

`export default actions`
