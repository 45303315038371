<template>
  <div class="app-container page--auth page--sign-in text-main">
    <front-menu/>
    <hero-section title="Sign in"
                  :hide-cta="true"/>

    <section class="my-auto">
    <div class="container is-max-desktop mb-5 has-bg">
      <div class="columns is-centered">
          <div class="column is-one-third">
            <!--<h3 class="title has-text-grey">Sign in</h3>-->
            <div class="box">
              <form>
                <div
                  v-if="error"
                  class="message is-danger">
                  <div
                    class="message-body">
                    <span v-html="error"></span>
                    <router-link
                      v-if="error && error.indexOf('Invalid username and password') !== -1"
                      :to="{name: 'SignUp'}">Sign up</router-link>
                  </div>
                </div>

                <div
                  v-google-signin-button="googleSignInParams.client_id"
                  class="is-fullwidth is-clickable hover-brighten-95"
                  @click="googleIsLoading = true">
                  <img src="@/assets/btn_google_signin_light_normal_web@2x.png" width="191" />
                </div>
                <p class="has-text-centered my-2">
                  or
                </p>


                <b-field>
                  <b-input
                    id="email"
                    v-model="form.email"
                    type="text"
                    required
                    autofocus
                    size="is-medium"
                    placeholder="Email"
                  />
                </b-field>

                <b-field>
                  <b-input
                    id="password"
                    v-model="form.password"
                    type="password"
                    required
                    password-reveal
                    size="is-medium"
                    placeholder="Password"
                  />
                </b-field>

                <b-field class="has-text-left">
                  <b-checkbox v-model="form.checked">
                    Remember me
                  </b-checkbox>
                  <!--<a style="float: right" href="/">login?</a>-->
                </b-field>


                <button
                  id="btn_sign_in"
                  :class="{'is-loading': isLoading, 'is-disabled': isLoading}"
                  class="button is-block is-primary ml-auto"
                  @click="onSubmit($event)">
                  Sign in
                </button>
              </form>

            </div>
            <p class="has-text-white is-flex has-justify-around mb-4">
              <router-link :to="{name: 'SignUp'}">Sign up</router-link>
              <router-link :to="{name: 'LostPasswordPage'}">Forgot Password</router-link>
              <a href="mailto:guillaume@teachee.io">Help</a>
            </p>
          </div>
      </div>
    </div>
    </section>
    <footer-section class="mt-auto" />
  </div>

</template>


<script>

  import GoogleSignInButton from 'vue-google-signin-button-directive'
  import HeroSection from "@/components/sections/Hero";
  import FooterSection from "@/components/sections/FooterSection";

export default {
  name: "SignIn",
  components: {FooterSection, HeroSection},
  directives: {
    GoogleSignInButton
  },
  data() {
    return {
      gdprConcented: false,
      error: null,
      googleIsLoading: false,
      redirect: false,
      form: {
        email: "",
        username: "",
        password: "",
        confirm_password: "",
        checked: false
      },
      isLoading: false,
      googleSignInParams: {
        client_id: "476667578043-0tm7eoufgmi6m961qb2m77hhelj5ksl5.apps.googleusercontent.com",
        response_type: "permission",
      }
    };
  },
  created() {
    if (!this.$auth.check()) {
      this.$store.dispatch("setLayout", "no-layout");
      const path = this.$localStorage.get("redirectAfterLogin")
      const query = this.$localStorage.get("redirectQueryAfterLogin")

      if (path && path !== 'null') {
        this.redirect = {
          path,
          query: JSON.parse(query)
        }
      }
    } else {
      this.$router.push({name: 'Home'});
    }
  },

  methods: {
    onSubmit(evt) {
      this.isLoading = true;
      evt.preventDefault();
      this.$auth
        .login({
          body: this.form,
          redirect: this.redirect || {name: 'Home'},
          rememberMe: this.form.checked
        })
        .then(
          (res) => {
            this.$store.dispatch("setLayout", "logged-in-layout");
            this.$store.dispatch("setUser", res.body.data);
            this.$ga.event("conversion", "sign_in");
          },
          (res) => {
            this.error = res.data;
            this.isLoading = false;
          }
        );
    },
    onClick(e) {
      e.preventDefault();
      this.$router.push("/");
    },
    OnGoogleAuthSuccess(access_token) {
      this.$store.dispatch("setUserFromGoogle", {
        access_token
      }).then((res) => {
        this.$auth.user(res.user)
        this.$store.dispatch("setUser", res.user);
        this.$auth.token(null, res.token)
        this.$cookie.set('rememberMe', true, 365)
        this.$ga.event("conversion", "sign_in");
        this.$router.push({name: 'Home'})
      });
    },
    OnGoogleAuthFail(error) {
      this.googleIsLoading = false;
    }
  }
};
</script>

<style lang="scss">

body .page--sign-in {
  background: linear-gradient(180deg, #FFF 52%, #8464FF 52%);
}
</style>
