`import {Pack} from '../models/resources'`

actions =
  getPacks: (context) ->
    Pack.query().then (response) ->
      context.commit('packs', response.body.packs.concat(response.body.packsBought))

  findPack: (context, pack) ->
    new Promise((resolve, reject) ->
      Pack.get({id: pack.id}).then (response) ->
        context.commit('pack', response.body)
        resolve(response.body)
      , (err) ->
        reject(err)
    )

  addPack: (context, pack) ->
    new Promise((resolve, reject) ->
      Pack.save(pack: pack).then(
        (res) ->
          context.commit('addPack', Object.assign(pack, {id:  parseInt(res.body.id)}))
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )

  editPack: (context, pack) ->
    new Promise (resolve, reject) ->
      context.commit('editPack', pack)
      resolve()

  deletePack: (context, pack) ->
    if pack.id
      Pack.delete({id: pack.id}).then (res) ->
        context.commit('deletePack', pack)
    else
      context.commit('deletePack', pack)

  savePack: (context, pack) ->
    rs = Pack

    return new Promise((resolve, reject) ->
      if pack.id
        context.commit('editPack', pack)
        rs.update({id: pack.id}, {pack: pack}).then(
          (res) ->
            resolve(res.body)
        , (res) ->
          reject(res)
        )
      else
#        context.commit('editPack', pack)
        rs.save(pack: pack).then(
          (res) ->
            resolve(res.body)
        , (res) ->
          reject(res.body)
        )
    )


`export default actions`
