// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// EXTENSIONS
String.prototype.hashCode = function() {
  var hash = 0;
  if (this.length == 0) {
    return hash;
  }
  for (var i = 0; i < this.length; i++) {
    var char = this.charCodeAt(i);
    hash = ((hash<<5)-hash)+char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

import Vue from "vue";
// import VueI18n from "vue-i18n";

import VueSocketIO from "vue-socket.io";
import 'vue-event-calendar/dist/style.css' //^1.1.10, CSS has been extracted as one file, so you can easily update it.
import App from "./App";
import Buefy from "buefy";
import Rollbar from 'vue-rollbar';
import Trend from 'vuetrend';
import VueAnalytics from "vue-analytics";
import VueAnimate from 'vue-animate-scroll'
import VueClipboard from 'vue-clipboard2'
import VueCookie from "vue-cookie";
import VueCurrencyFilter from 'vue-currency-filter'
import VueLocalStorage from "vue-localstorage";
import VueMoment from "vue-moment";
import VueResource from "vue-resource";
// import VueScheduler from "v-calendar-scheduler";
import VuejsDialog from "vuejs-dialog";
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import moment from "moment-timezone";
import noop from "lodash/noop";
import router from "./router";
import vueEventCalendar from 'vue-event-calendar'
import { store } from "./store/store";
import {farewellDirectivePlugin} from 'vue-farewell';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

import BasicMenu from "./components/BasicMenu";
import FrontMenu from "./components/FrontMenu";
import Board from "./components/Board";

const {NODE_ENV} = process.env
console.log({NODE_ENV});

import VueFacebookPixel from 'vue-analytics-facebook-pixel';
Vue.use(VueFacebookPixel);
if (
    NODE_ENV === "production"
) {
  Vue.analytics.fbq.init('601778481077138')
  Vue.analytics.fbq.event('PageView')
}

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
Vue.component('lottie', LottieAnimation)

import VueScrollReveal from 'vue-scroll-reveal';
Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
  delay: 150,
  duration: 1000,
  distance: '60px',
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  // easing: 'cubic-bezier(0.2, 0.8, 0.2, 1)',
  origin: 'bottom',
  interval: 150
});

// import axios from 'axios'
// import VueAxios from 'vue-axios'
// Vue.use(VueAxios, axios)



const isStaging = window.location.href.includes("staging")

var mixpanel;
if (
  NODE_ENV === "production"
) {
  mixpanel = require('mixpanel-browser');
  mixpanel.init('9fcccd984ead87cf8d5b9b6fc4109eb1');
} else {
  mixpanel = {
    alias: noop,
    identify: noop,
    track: noop,
    people: {
      set: noop,
      increment: noop
    }
  }
}

Vue.prototype.$mixpanel = mixpanel;
Vue.prototype.$qs = document.querySelector.bind(document);
Vue.config.performance = true

const socketOptions = {
  // debug: true,
  connection: 'http://localhost:1338',
  vuex: {
    store,
    actionPrefix: '',
    mutationPrefix: 'SOCKET_'
  },
  options: {
    transports: ["websocket"],
    withCredentials: true
  }
}

if (NODE_ENV !== "production" && !isStaging) {
  Vue.use(new VueSocketIO({
    ...socketOptions,
    debug: true,
    connection: 'http://localhost:1338'
  }))

} else if (isStaging) {
  Vue.use(new VueSocketIO({
    ...socketOptions,
    connection: 'https://staging-ws.teachee.io',
    options: {
      transports: ["websocket"],
      withCredentials: true,
      secure: true,
      reconnect: true,
      reconnectionDelay: 500,
    }
  }))
} else {
  Vue.use(new VueSocketIO({
    ...socketOptions,
    connection: 'https://ws.teachee.io',
    options: {
      transports: ["websocket"],
      withCredentials: true,
      secure: true,
      reconnect: true,
      reconnectionDelay: 500,
    }
  }))
}

if (NODE_ENV === "production" || isStaging) {
  Vue.use(Rollbar, {
    accessToken: '9d9f5584d1424dbb92e4bd8d2fc65b9f',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: NODE_ENV
    }
  });
}

Vue.use(Trend);
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(VueToast, { position: 'top' });

Vue.use(vueEventCalendar, {
  locale: 'en',
  color: '#FF5B62',
  weekStartOn: 1
})

// https://github.com/mike-prince/vue-animate-scroll
Vue.use(VueAnimate);

Vue.use(farewellDirectivePlugin);

Vue.use(VueCurrencyFilter,
  {
    symbol : '€',
    thousandsSeparator: ' ',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: false
  })

Vue.use(VueCookie);

// Vue.use(VueScheduler, {
//   timeRange: [6, 22],
//   availableViews: ["month", "week", "day"],
//   initialDate: new Date(),
//   initialView: "week"
// });
Vue.use(VueResource);

Vue.router = router;

import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import bearer from "@websanova/vue-auth/dist/drivers/auth/bearer.esm.js"
import resource from "@websanova/vue-auth/dist/drivers/http/vue-resource.1.x.esm.js"
import vueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js"
import i18n from './i18n'
Vue.use(VueAuth, {
  plugins: {
    http: Vue.http,
    router: Vue.router,
  },
  drivers: {
    auth: bearer,
    http: resource,
    router: vueRouter,
  },
  refreshData: {url: 'auth/refresh', method: 'GET', enabled: false, interval: 0},
  tokenExpired: function() {return false}
});

// SET UP TRACKING

if (NODE_ENV !== 'development') {
  Vue.use(VueAnalytics, {
    id: "UA-120602036-1",
    beforeFirstHit() {
      Vue.$ga.set("anonymizeIp", true);
    },
    autoTracking: {
      exception: true
    },
    batch: {
      enabled: true,
      amount: 5,
      delay: 2000
    },
    commands: {
      // conversionSignUp() {
      //   this.$ga.event("conversion", "sign_up");
      // },
      // conversionBuyCredits() {
      //   this.$ga.event("conversion", "payment", "credits");
      // },
      // conversionSubscribeToPaidPlan() {
      //   this.$ga.event("conversion", "payment", "subscription");
      // },
      // conversionFromCreditToSubscription() {
      //   this.$ga.event("conversion", "payment", "credit_to_subscription");
      // },
      conversionWatchDemo() {
        this.$ga.event("conversion", "watch", "demo");
      },
      trustpilot() {
        this.$ga.event("conversion", "interest", "trustpilot");
      },
      listenToTeacher() {
        this.$ga.event("sentences", "listen", "teacher", 1);
      },
      listenToStudent() {
        this.$ga.event("sentences", "listen", "student", 1);
      },
      listenToWord() {
        this.$ga.event("sentences", "listen", "word", 1);
      },
      listenToSentence() {
        this.$ga.event("sentences", "listen", "sentence", 1);
      },
      editLiteral() {
        this.$ga.event("sentences", "edit_literal_translation", "input", 1);
      },
      recordAudio() {
        this.$ga.event("sentences", "record_audio", "button", 1);
      },
      addSentenceBigButton() {
        this.$ga.event("sentences", "add", "big_button", 1);
      },
      addExistingSentenceBigButton() {
        this.$ga.event("sentences", "add_existing", "big_button", 1);
      },
      exportToAnki() {
        this.$ga.event("lessons", "export_to_anki", "footer_button", 1);
      },
      exportToAudio() {
        this.$ga.event("lessons", "export_to_audio", "footer_button", 1);
      },
      print() {
        this.$ga.event("lessons", "print", "footer_button", 1);
      },
      deleteSentence() {
        this.$ga.event("sentences", "delete", "button", 1);
      },
      addAfterSentence() {
        this.$ga.event("sentences", "add_after", "button", 1);
      },
      addComment() {
        this.$ga.event("sentences", "add_comment", "textarea", 1);
      },
      downloadAnki() {
        this.$ga.event("lessons", "download_anki", "modal", 1);
      }
    },
    debug: {
      sendHitTask: NODE_ENV === "production"
    },
    router
  });
} else {
  Vue.use(VueAnalytics)
}

Vue.use(require("vue-shortkey"), { prevent: ["input", "textarea"] });
Vue.use(VueMoment, {
  moment
});
Vue.use(Buefy);
Vue.use(VuejsDialog, {
  html: true,
  okText: "Continue",
  cancelText: "Cancel"
});
// Vue.use(VueI18n({locale: "en"}));
// Vue.use(VueI18n);
Vue.use(VueLocalStorage);
Vue.config.productionTip = false;

Vue.component("basic-menu", BasicMenu);
Vue.component("front-menu", FrontMenu);
Vue.component("board", Board);

window.Vue = Vue;

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
// Authorization

Vue.http.interceptors.push(function(request, next) {
  next(function(res) {
    // Unauthorized Access

    // if (res.url.match(/v2\/students$/)) {
    //   return
    // }

    if ( res.status === 404 ) {
      store.commit('eraseLastLessons')
    }

    console.log({request, url: request.url})

    if ( res.status === 403 ) {
      // TOKEN IS EXPIRED
      if (request.url === "auth/refresh" || request.url.includes("anki")) {
        Vue.auth.logout({
          redirect: { name: "SignIn" }
        });
      }
    }

    // 403 HANDLING
    // if ( res.status === 403 && (res.bodyText && !res.bodyText.includes("More credits are needed"))) {
    //   Vue.auth.logout({
    //     redirect: { name: "SignIn" }
    //   });
    // }

    // Vue.auth.logout({
    //   redirect: { name: "RefreshCache" }
    // });

    // if (
    //   res.status === 401 &&
    //   ["UnauthorizedAccess", "InvalidToken"].indexOf(res.data.code) > -1
    // ) {
    //   Vue.auth.logout({
    //     redirect: { name: "SignIn" }
    //   });
    // }

    // System Error
    // else if (res.status === 500) {
    //   Vue.router.push({ name: "Error500" });
    // }
  });
});

/* eslint-disable no-new */
new Vue({
  el: "#app",

  // i18n,
  router,

  store,
  components: { App },
  i18n,
  template: "<App/>"
});
