<template lang="pug">
  footer.app-footer.section.is-small
    .container
      .columns.is-centered
        .column.is-9.has-text-left
          .columns.has-space-between
            .column
              .menu
                p.has-text-grey Pages
                ul.menu-list
                  li
                    router-link(:to="{name: 'Pricing'}") Pricing
                  li
                    router-link(:to="{name: 'SignIn'}") Sign in
                  li
                    router-link(:to="{name: 'SignUp'}") Sign up
                  li
                    router-link(:to="{name: 'PrivacyPage'}") Privacy

            .column.flex-grow
              .menu.has-text-right
                p.has-text-grey.has-text-right Connect with us
                ul.menu-list.has-justify-end
                  li.has-text-right
                    a(href='https://www.facebook.com/teacheeio', target='_blank')
                      | Facebook
                  li.has-text-right
                    a(href='https://twitter.com/teacheeio', target='_blank')
                      | Twitter
                  li.has-text-right
                    a.pr-0(href='https://www.youtube.com/channel/UClhHU4vcY7zhnGgrUkFj_tw', target='_blank')
                      | Youtube
      img(
        height="1"
        width="1"
        style="display:none"
        src="https://www.facebook.com/tr?id=293952861544315&ev=PageView&noscript=1")

</template>

<script>
  export default {
    name: "FooterSection"
  }
</script>

