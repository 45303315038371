<template lang="pug">
  #modal_paste.modal.is-active
    .modal-background
    .modal-card

      section.modal-card-body
        b-table(
          :data="sentencesTable"
          :columns="columns"
        )

      footer.modal-card-foot
        button.button.is-primary.is-fullwidth(
          :class='{"is-loading": loading}'
          :disabled="loading"
          @click="applyPaste"
        ) Add those {{ sentencesTable.length }} sentence{{ sentencesTable.length > 1 ? 's' : '' }}

    button.modal-close.is-large(aria-label="close" @click="$emit('close')")
</template>

<script>
  import {Sentence} from '@/store/models/resources';

  export default {
    name: "PasteModal",
    props: ["content", "lessonUuid", "initialIndex"],
    data() {
      return {
        loading: false,
        columns: [
          {
            field: 'content',
            label: 'Content',
          },
          {
            field: 'literal_translation',
            label: 'Literal Translation',
          },
          {
            field: 'translation',
            label: 'Translation',
          },
          {
            field: 'comment',
            label: 'Comment',
          },
        ]
      }
    },
    computed: {
      sentencesTable() {
        const arr = []

        this.content.split("\n").forEach((line, idx) => {
          if (idx !== 0 ) {
            var content, literal_translation, translation, comment;
            var words = line.split("\t");

            if (words.length >= 4) {
              [content, literal_translation, translation, comment] = words;
            } else {
              [content, translation, comment] = words;
            }
            if (content && content.length) {
            arr.push({
              content, literal_translation, translation, comment
            });
            }
          }
          //
          // if (
          //     some([content, literal, translation], text => text && text.length)
          // ) {
          //   if (!idx) {
          //     this.sentence.content = this.trim(content);
          //     this.sentence.literal_translation = this.trim(literal);
          //     this.sentence.translation = this.trim(translation);
          //     // this.$store.commit('addSentence', Object.assign({index: this.index}, this.sentence))
          //   } else {
          //     index += 1;
          //     const newSentence = {
          //       uuid: this.uuid,
          //       index: index,
          //       content: this.trim(content),
          //       lesson_id: this.lessonUuid,
          //       literal_translation: this.trim(literal),
          //       translation: this.trim(translation)
          //     };
          //
          //     this.$store.commit("addSentence", newSentence);
          //     this.$store.dispatch("saveSentence", newSentence);
          //     ctrl.$emit("propagate-change", {
          //       sentence: newSentence,
          //       action: "addSentence"
          //     });
          //   }
          // }
        });

        return arr;
      }
    },
    mounted() {

    },
    methods: {
      applyPaste() {
        this.loading = true
        Sentence.paste({
          sentences: this.sentencesTable,
          lessonUuid: this.lessonUuid,
          index: this.initialIndex
        }).then(() => {
          this.$emit('paste-success');
          this.$socket.emit('LESSON_ASK_REFRESH', {
            room: `LESSON_${this.lessonUuid}`,
            uuid: "0" // Will refresh for everyone
          });
          this.$emit('close');
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style lang="scss">
#modal_paste {
  .modal-background {
    background-color: rgba(10, 10, 10, 0.005);
  }
  .b-table .table-wrapper {
    max-height: 60vh;
    overflow: auto

  }
  .modal-card {
    min-width: 70vw;
    max-width: 90%;
    box-shadow: 0 20px 40px rgba(51, 51, 51, 0.15);
  }
  .modal-card-head {
    border-bottom: 0 none;
  }
  .modal-card-body {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .modal-card,
  .modal-card-body {
    overflow: visible;
  }
}
</style>
