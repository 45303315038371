<template lang="pug">
  section#our_community.section.section--our-community.is-small
    .container

      .head.mb-6.mt-5.has-text-centered
        p.text-main.is-size-6.mb-0.opacity-8 BE PART OF TEACHEE
        h2.size-h2.mb-6 Our community

      .body.mt-6.pt-6

        .columns.mb-6
          .column.px-0(v-for="info in infos")
            h3.has-text-white.is-size-2 {{ info.value | format_number }}
            p.text-primary.text-title.is-size-5
              | {{ labels[info.label] }}

        router-link.button.is-primary.mt-6(
          to="/sign_up"
        ) Join for free

</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "OurCommunitySection",
  computed: {
    ...mapGetters(["apiUrl"]),
  },
  data() {
    return {
      labels: {
        teachers: "teachers",
        students: "students",
        lessons: "lessons created",
        sentences: "sentences learned"
      },
      infos: [
        {
          value: 636,
          label: "teachers"
        },
        {
          value: 1086,
          label: "students"
        },
        {
          value: 10975,
          label: "lessons created"
        },
        {
          value: 264973,
          label: "sentences learned"
        }
      ]
    }
  },
  created() {
    this.$http.get(this.apiUrl + "/stats/global_info").then(res => {
      this.infos = res.body.data
    })
  },
  filters: {
    format_number: function (value){
      return parseInt(value).toLocaleString()
    }
  }
}
</script>

<style lang="scss">
@import "colors";

.section--our-community {
  padding-bottom: 5rem;
  background-color: $primaryDarker;
  color: $primary;
  .picture {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
  h3, p {
    width: 70%;
    margin: auto;
  }
  h3 {
    line-height: 1.3
  }

  .wiggles {
    animation-delay: 4s;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
  }
}
</style>

