<template lang="pug">
  b-button.ui--back-button(
    @click="$router.go(-1)"
    )
    b-icon.pt-2(icon="keyboard-arrow-left")
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'ButtonBack',
    components: {
    },
    data () {
      return {

      }
    },
  }
</script>

<style lang="scss">
  .ui--back-button {
    min-width: 98px;
  }
</style>
