`import {Sentence} from '../models/resources'`
`import { nodeApi } from "@/config";`

actions =
  getSentences: (context, params) ->
    Sentence.query(language_to: params.to, language_from: params.from).then (response) ->
      context.commit('sentencesOptions', response.body.sentences)

  addSentence: (context, sentence) ->
#    if sentence.content && sentence.content.length
    new Promise((resolve, reject) ->
      Sentence.save(sentence: sentence).then(
        (res) ->
          context.commit('addSentence', Object.assign(sentence, {id:  parseInt(res.body.id)}))
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )
#    else
#      new Promise((resolve, reject) ->
#        context.commit('addSentence', sentence)
#        resolve()
#      )

  addPictureToSentence: (context, sentenceAndFile) ->
    formData = new FormData();
    formData.append('file', sentenceAndFile.file);

    new Promise((resolve, reject) ->
      Vue.http.put(nodeApi+"/sentences/"+sentenceAndFile.sentence.id+"/picture", formData).then((res) ->
        context.commit('editSentence', Object.assign({}, res.body, {lesson_id: sentenceAndFile.sentence.lesson_id}))
        resolve(res.body)
      , (err) ->
        reject()
      )
    )

  removePictureFromSentence: (context, sentenceAndUuid) ->
    new Promise((resolve, reject) ->
      Vue.http.delete(nodeApi+"/sentences/"+sentenceAndUuid.sentence.id+"/picture?uuid="+sentenceAndUuid.uuid).then((res) ->
        context.commit('editSentence', Object.assign({}, res.body.sentence))
        resolve(res.body)
      , (err) ->
        reject()
      )
    )

  translateSentence: (context, obj) ->
    # obj = {sentence, lesson}

    new Promise (resolve, reject) ->
      Sentence.translate({id: obj.sentence.id}, {sentence: obj.sentence, language_to: obj.lesson.language_to, language_from: obj.lesson.language_from}).then(
        (res) ->
          context.commit('editSentenceAndForceRefresh', res.body.sentence)
          resolve(res.body.sentence)
      )

  editSentence: (context, sentence) ->
    new Promise (resolve, reject) ->
      context.commit('editSentence', sentence)
      resolve()

  deleteSentence: (context, sentence) ->
    if sentence.id
      Sentence.delete({id: sentence.id}).then (res) ->
        context.commit('deleteSentence', sentence)
    else
      context.commit('deleteSentence', sentence)

  cloneSentence:  (context, sentence) ->
    Sentence.clone({id: sentence.id}).then (res) ->

  saveStandaloneSentence:  (context, sentence) ->
    new Promise((resolve, reject) ->
      Sentence.update({id: sentence.id}, {sentence: sentence}).then(
        (res) ->
          resolve(res.body)
      , (res) ->
        reject(res)
      )
    )

  saveSentence:  (context, sentence) ->
    rs = Sentence

    new Promise((resolve, reject) ->
      if sentence.id
        context.commit('editSentence', sentence)
        rs.update({id: sentence.id}, {sentence: sentence}).then(
          (res) ->
            if res.body.sentence
              context.commit('editSentenceWords', res.body.sentence)
            resolve(res.body)
        , (res) ->
          reject(res)
        )
      else
#        context.commit('editSentence', sentence)
        rs.save(sentence: sentence).then(
          (res) ->
            context.commit('editSentence', Object.assign(sentence, {id:  parseInt(res.body.id)}))
            resolve(res.body)
        , (res) ->
          reject(res.body)
        )
    )


`export default actions`
