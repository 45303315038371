<template lang="pug">
  .box.has-shadow.pb-4.h-100.box--activity(v-if="activities.length")
    h3.title.has-text-left.mb-3
      | Activity Feed

    .has-position-relative
      .has-loader.is-primary.is-outlined.is-loading.has-margin-auto.primary-loader(v-if="querying")
      .timeline.has-text-left
        .timeline-item.is-light(v-for="activity in sortedActivities")
          .timeline-content.has-text-grey(v-if="activity")
            p.is-size-7.is-capitalized-first-letter {{$moment(activity.created_at).fromNow()}}
            p(v-if="activity.user_id == user.id")
              | You created&nbsp;
              router-link.has-text-weight-bold.has-text-grey-darker(:to="links[activity.id]") {{activity.title}}
            p(v-else)
              router-link.has-text-weight-bold.has-text-grey(:to="links[activity.id]") {{activity.title}}
              | &nbsp was created for you

          .timeline-marker.is-grey-light(v-if="activity")

</template>

<script>
  import {mapGetters} from 'vuex'
  import userPicture from "@/components/UserPictureUI";
  import sortBy from 'lodash/sortBy';

  export default {
    name: "Activity",
    components: {
      userPicture,
    },
    computed: {
      ...mapGetters(['user', 'activities']),
      links() {
        const links = {};
        this.activities.forEach(lesson => {
          if (lesson.has_package || lesson.has_readonly) {
            links[lesson.id] = {name: 'ReadOnlyPackageLesson', params: {lesson_uuid: lesson.uuid}}
          } else {
            links[lesson.id] = {name: 'LiveLesson', params: {id: lesson.uuid}};
          }
        })
        return links
      },
      sortedActivities() {
        return sortBy(this.activities, 'created_at').reverse()
      }
    },
    data() {
      return {
        querying: true
      };
    },
    mounted() {},
    created() {
      if (this.user && this.user.id) {
        this.$store.dispatch('getActivities').then(res => {
          this.querying = false
        });
      } else {
        this.querying = false
      }
    },
    methods: {

    }
  };
</script>

<style lang="scss" scoped>

  .timeline {
    /*height: 355px;*/
    max-height: 355px;
    /*overflow: auto;*/
  }
  .timeline-content {
    margin-top: -11px;
    line-height: 17px;
  }
  body .timeline-marker {
    background-color: #FFF !important;
  }

  .box--activity {
    overflow: hidden;
  }

  .has-loader {
    opacity: 0.8 !important;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    background-color: rgba(255, 255, 255, 0.5);
    display: block;
    z-index: 2;
  }
</style>
