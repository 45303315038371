`import {User} from '../models/resources';`
`import keyBy from "lodash/keyBy";`

actions =
  setUser: (context, user) ->
    context.commit('user', user)

  getStorageStats: (context, user) ->
    new Promise((resolve, reject) ->
      User.storageStats({id: user.id}).then((res) ->
        context.commit('storageStats', res.body.data)
        resolve()
      )
    )

  queryUsers:  (context, user) ->
    new Promise((resolve, reject) ->
      User.interactedWith().then((res) ->
        context.commit('usersById', keyBy(res.body, 'id'))
        resolve(res.body)
      , (err) ->
        reject(err)
      )
    )

  setUserFromGoogle:  (context, user) ->
    new Promise((resolve, reject) ->
      User.authGoogle(user).then((res) ->
        resolve(res.body)
      , (err) ->
        reject(err)
      )
    )

  saveUser:  (context, user) ->
    rs = User

    new Promise((resolve, reject) ->
      if user.id
        rs.update({id: user.id}, {user: user}).then(
          (res) ->
            context.commit('user', res.body.user)
            resolve(res.body)
        , (res) ->
          reject(res)
        )
#        context.commit('editLesson', user)
      else
        rs.save(user: user).then(
          (res) ->
#            context.commit('editLesson', Object.assign({id: parseInt(res.body.id), uuid: res.body.uuid}, user))
            resolve(res.body)
        , (res) ->
          reject(res.body)
        )
    )

  deleteUser: (context, user) ->
    User.delete({id: user.id})

`export default actions`
