<template lang="pug">
  section.section.section--pricing-faq.has-background-dark
    .container.is-max-desktop
      .head
        h2.size-h1.has-text-primary.has-text-weight-bold.mb-6 Pricing FAQ

      .body.mt-6.has-text-centered(

      )

        b-collapse(
          v-for="(collapse, index) of Object.values($t('pricing_faq'))"
          v-scroll-reveal.reset="{ delay: 200*index }"
          :key='index'
          :open='isOpen == index'
          @open='isOpen = index'
          )
          template(#trigger='props')
            .card-header(role='button')
              p.card-header-title.text-title.has-text-white.is-size-5.has-text-left
                | {{ collapse.question }}
              a.card-header-icon
                b-icon(:icon="props.open ? 'arrow-drop-down' : 'arrow-left'")
          .card-content.has-text-left.has-text-white
            .content.is-size-6(v-html="collapse.answer")
</template>

<script>
export default {
  name: "PricingFaqSection",
  props: [
    'tagline',
    'linkText',
    'linkHref'
  ],
  data() {
    return {
      isOpen: 0
    }
  },
}
</script>

<style lang="scss">
.section--pricing-faq {
  .card-header-title {
    font-weight: 400;
  }
}
</style>

<i18n lang="yaml">
en:
  pricing_faq:
    - question: When do I get charged?
      answer: At the moment you buy credit or subscribe to a plan.
    - question: Can I cancel at any time?
      answer: Yes. If you cancel a monthly subscription, your pro status stays for the duration of the paid period, then your account is switched to free.
    - question: Are payments safe?
      answer: Yes, they are handled by a trusted third party (“Paddle” -- <a href='https://paddle.com/' target='_blank'>https://paddle.com/</a>)
    - question: What can I do with 1 credit?
      answer: 1 credit gives you 1 lesson supporting media. For one lesson, you can create up to 50 sentences, record 50 audio & attach 50 pictures (on average, our teachers fill 27 sentences per lesson).
    - question: Am I limited to 50 sentences per lesson when I subscribe to a paid plan
      answer: No, you won't have any limit. You'll be able to create as many lessons as you wish with as many sentences with media (audio & picture) as you need.
</i18n>
