
<template lang="pug">
  .ui--play-button.is-relative
    button.play-button.button.is-small.has-justified-start.is-flex(
      :class="{empty: !labelBody.length}"
      v-ga="blob ? 'listenToStudent' : 'listenToTeacher'"
      v-shortkey="[('f'+(shortcut+1))]"
      @shortkey="play"
      @click.exact="play"
      @click.shift="playSlowly"
      @click.ctrl="openInNewTab"
      @click.meta="openInNewTab")
      b-icon(:icon="icon || 'play-arrow'" size="is-small")
      span.p-0(v-if="!$slots.default" :title="label") {{ labelBody }}
      slot
    button.delete-audio-button.button.is-small.is-secondary.has-justified-start.is-flex(
      v-if="canDelete && audio && audio.id"
      @click.stop="onDeleteClick"
    )
      b-icon(icon="delete")

</template>

<script>
  import {mapGetters} from 'vuex';
  export default {
    name: 'audio-player',
    props: [
      'canDelete',
      'audio',
      'label',
      'blob',
      'shortcut',
      'noEvent',
      'icon'
    ],
    computed: {
      ...mapGetters(['apiUrl', 'lesson', 'uuid']),
      labelBody() {
        if (this.label === '' || !this.label) {
          return '';
        } else {
          if (!this.label || this.label === 'Play') {
            return 'Play'
          } else {
            return this.label.substring(0, 3) + (this.label.length > 3 ? '.' : '')
          }
        }
      }
    },
    data () {
      return {
        delay: 400,
        clicks: 0,
        timer: null
      }
    },
    mounted() {
    },
    methods: {
      openInNewTab() {
        if (!this.blob) {
          // console.log('OPENING')
          window.open(this.apiUrl + '/' + this.audio.path, '_blank');
        }
      },
      play(custom) {
        var audio = new Audio(this.blob || this.apiUrl + '/' + this.audio.path)

        // console.log(typeof(custom))
        if (typeof(custom)=='function') {
          custom(audio)
        }

        if (audio && audio.paused === false) {
          audio.pause();
        } else {
          audio.play();
        }
        if (!this.noEvent) {
          this.$mixpanel.track("Sentence - Play audio");
        }
      },
      playSlowly() {
        this.play((audio)=>{
          audio.playbackRate = 0.7
        })
      },

      onDeleteClick() {
        this.clicks++;
        if (this.clicks === 1) {
          this.timer = setTimeout( () => {
            this.explainRemove()
            this.clicks = 0;
          }, this.delay);
        } else {
          clearTimeout(this.timer);
          this.remove();
          this.clicks = 0;
        }
      },

      explainRemove() {
        this.$toast.info("Double-click to remove the audio recording.")
      },

      remove() {
        console.log({id: this.audio.id, uuid: this.lesson.uuid, model_id: this.audio.model_id});

        this.$store.dispatch("removeAudio", {id: this.audio.id, uuid: this.lesson.uuid, model_id: this.audio.model_id}).then(() => {
          this.$socket.emit('LESSON_ASK_REFRESH', {
            room: `LESSON_${this.lesson.uuid}`,
            uuid: this.uuid
          });
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'colors';

  .play-button {
    transition: all .3s ease;
    width: 79px;
    &.empty {
      width: 30px;
    }
  }

  .delete-audio-button {
    display: none !important;
    border-radius: 50%;
    position: absolute;
    right: -0.6rem;
    bottom: -0.6rem;
    transform: scale(0.75);
    transition: transform .3s cubic-bezier(.2, .8, .2, .8);
    color: #8e8e8e;
    background-color: #ededed;
    z-index: 100;
  }
  .ui--play-button:hover {
    .delete-audio-button {
      display: block !important;
      &:hover {
        transform: scale(0.95);
        //&:after {
        //  position: absolute;
        //  content: "Double-click";
        //  left: 110%;
        //  top: 40%;
        //  z-index:100;
        //}
      }
    }
  }

  //*//

  .is-student .play-button {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    width: 79px;
    /*-webkit-transform: scale(0.7);*/
    /*transform: scale(0.7);*/
    z-index: 52;
    border: 2px solid #FFF;
  }
  //*/

  @media (max-width: 768px) {


    .is-teacher .play-button {
      position: relative;
      top: initial;
      left: initial;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      width: 79px;
      display: inline-block;
      text-align: left;
    }
  }
  @media (max-width: 425px) {
    .play-button {
      position: relative;
      top: initial;
      left: initial;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      width: 79px;
      z-index: 43;
    }
    .is-student .play-button {
      /*top: 78px;*/
    }
  }
</style>
