
<template lang="pug">
  .card.mr-4.is-hidden-mobile(:class="{ active }")
    header.card-header
      p.card-header-title(@click.prevent="active = !active")
        | Feedback
      a.card-header-icon.has-text-dark(href="#" aria-label="more options" @click.prevent="active = !active")
        b-icon(:icon="active ? 'close' : 'edit'")
    .card-content
      .content.has-text-left
        | We love feedback! If you have any feature request, any question, don't hesitate.
        |  We're looking for as many contributions as possible :)
        b-field.mt-3(label="Subject")
          b-select(placeholder="Select an option" v-model="feedback.subject")
            option(v-for="option in options" :value="option.id" :key="option.id")
              | {{ option.name }}

        b-field(label="Message")
          b-input(maxlength="500" type="textarea" v-model="feedback.message")

      button.button.is-primary.is-fullwidth(
        href="#"
        @click.prevent="sendFeedback"
        :disabled="!feedback.message"
        :class="{'is-disabled': !feedback.message, 'is-loading': loading}")
        b-icon.has-text-white(v-if="success" icon="check")
        span.is-flex(v-else="")
          b-icon.mr-2(icon="send")
          span Send

</template>

<script>
  import { Feedback } from "@/store/models/resources";

  export default {
    name: 'FeedbackBox',
    data () {
      return {
        loading: false,
        success: false,
        active: false,
        feedback: {
          subject: 'bug',
          message: null
        },
        options: [
          {id: 'bug', name: 'Bug'},
          {id: 'feature', name: 'Feature request'},
          // {id: 'positive', name: 'Positive feedback'},
          // {id: 'negative', name: 'Negative feedback'},
          {id: 'other', name: 'Other'},
        ]
      }
    },
    mounted() {
    },
    methods: {
      sendFeedback() {
        this.loading = true
        Feedback.save({ feedback: {
          subject: this.feedback.subject,
          message: this.feedback.message+"\n\n"+`${screen.width}x${screen.height}`
          }}).then( (res) => {
            this.$toast.open({
              duration: 5000,
              message: 'Thank you for your feedback!',
              type: "success"
            });
            this.success = true;
            this.loading = false;
            setTimeout(() => {
              this.reset();
            }, 2500);
          }
          , (_res) => {
            this.$toast.open({
              duration: 12000,
              message: 'Something went wrong. Please try later or send an email at guillaume@teachee.io if it\'s urgent',
              type: "error"
            });
          })
      },
      reset() {
        this.feedback = {
          subject: 'bug',
          message: null
        }
        this.loading = false
        this.success = false;
        this.active = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'colors';

  .card {
    background: rgb(255,255,255); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(232,232,232,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(232,232,232,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(232,232,232,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e8e8e8',GradientType=0 ); /* IE6-9 */

    z-index: 10;
    width: 25vw;
    min-width: 390px;
    position: fixed;
    right: 0;
    bottom: 0;
    height: 50px;
    overflow: hidden;
    transition: height .3s ease;
    &.active {
      height: 505px;
    }
  }
</style>
