import { Line } from 'vue-chartjs';
import _keys from "lodash/keys";
import _uniq from "lodash/uniq";
import moment from 'moment';

export default {
  extends: Line,
  props: ['user', 'frequency'],
  computed: {

  },
  data() {
    return {
      options: {}
    }
  },
  mounted () {
    const { data } = this.user.counter;
    // const year = new Date().getFullYear();
    // const year = 2018
    const colors = [
      'rgb(255, 91, 98)',
      // 'rgb(4, 137, 0)',
      'rgb(249, 202, 36)',
      'rgb(28, 124, 156)',
    ];
    const colorsTrans = [
      'rgb(255, 91, 98, 0.2)',
      // 'rgb(4, 137, 0, 0.2)',
      'rgb(249, 202, 36, 0.2)',
      'rgb(28, 124, 156, 0.2)',
    ];

    // const flags = {
    //   'en-GB': '<i class="flag flag-en-GB"></i>',
    //   'en-US': '<i class="flag flag-en-US"></i>',
    //   'hu-HU': '<i class="flag flag-hu-HU"></i>',
    // }


    const xTitle = {
      week: 'Week',
      month: 'Month',
      day: 'Day',
    }

    const prefixes = {
      week: 'W',
      month: '',
      day: '',
    }

    const chartData = {
      labels: [],
      datasets: []
    }
    let keys = []
    let years = []
    for (let language of _keys(data)) {
      years = _uniq(years.concat(_keys(data[language]))).sort();
      for (let year of years) {
        if (data[language][year]) {
          keys = keys.concat(_keys(data[language][year][this.frequency]));
        }
      }
    }

    // keys = _.uniq(keys).sort((a, b) => a - b);
    keys = _uniq(keys);
    if (this.frequency !== 'day') {
      chartData.labels = keys.map(key => `${prefixes[this.frequency]}${key}`);
    } else {
      keys = keys.sort()
      chartData.labels = keys.slice(-10).map((key) => new moment(key).format("MM.DD"))
    }
    for (let language of _keys(data)) {
      var cumulative = 0;
      var dataVals = keys.map(entry => {
        for (let year of years) {
          if (data[language][year] && data[language][year][this.frequency] && data[language][year][this.frequency][entry]) {
            cumulative += data[language][year][this.frequency][entry].wc;
            return cumulative;
          } else {
            return cumulative;
          }
        }
      })
      if (this.frequency === 'day') {
        dataVals = dataVals.splice(-10)
      }
      chartData.datasets.push({
        // label: flags[language],
        label: language.substring(0,2).toUpperCase(),
        backgroundColor: colorsTrans.shift(),
        borderColor: colors.shift(),
        data: dataVals
      })
    }
    this.options = {
      scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Words',
          }
        }],
        // xAxes: [{
        //   scaleLabel: {
        //     display: true,
        //     labelString: xTitle[this.frequency]
        //   }
        // }]
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => `${tooltipItem.yLabel} words`,
            // " #{ctrl.series[tooltipItem.datasetIndex]}: #{tooltipItem.yLabel} #{ctrl.unit}"
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    }

    this.renderChart(chartData, this.options)
  }
}
