`import {Subscription} from '../models/resources'`

actions =

  requestSubscribe: (context, plan) ->
    new Promise((resolve, reject) ->
      Subscription.query(plan).then(
        (res) ->
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )

  subscribe: (context, plan) ->
    new Promise((resolve, reject) ->
      Subscription.save(plan).then(
        (res) ->
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )

  unsubscribe: (context, plan) ->
    new Promise((resolve, reject) ->
      Subscription.delete({id: 0}).then(
        (res) ->
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )


`export default actions`
