<template lang="pug">
  .box.pb-5.modal-charge
    user-picture(
      v-if="teacher"
      :user="teacher"
      )
    figure.avatar(v-else)
      img(
        src="@/assets/logo_teachertool_v2_square.png"
        width="128"
        height="128"
      )
    h2.title.has-text-left Payment
    .stripe-payment
      .message.is-danger(v-if="error")
        .message-body
          | {{ error }}

      table.table.is-fullwidth
        thead
          tr
            th.pl-0 {{ productTh || 'Product' }}
            th Price
        tbody
          tr
            td.pl-0
              | {{ product.invoiceName || product.name }}
              p.is-size-7(v-if="product.invoiceDescription" v-html="product.invoiceDescription")
            td.has-text-right {{ Math.round(priceWithoutVat*100)/100 }}€
          tr(v-if="hasVat")
            td.has-text-right VAT
            td.has-text-right {{ Math.round(vat*100)/100 }}€
          tr
            td.has-text-right TOTAL
            td.has-text-right {{ product.price}}€

      b-field.has-text-left(label="Country" v-if="!countryWasSet")
        country-select(v-model="country" v-if="!country.code")
        span.input(v-else)
          | {{ country.name }}
          button.button.is-rounded.is-light.pull-right.btn-edit-country.ml-auto(@click="country.code = null")
            b-icon(
              icon="close"
              custom-size="mdi-16px"
            )

      b-field.has-text-left(label="Credit card" :class="{'is-disabled': !country.code}")
        #card_element

      button.button.is-primary.is-fullwidth(
        :class="{'is-loading is-disabled': isPaying}"
        :disabled="!complete"
        @click="pay")
          | Pay

</template>

<script>
import userPicture from "@/components/UserPictureUI";
import { stripeKey } from "@/config";
import countrySelect from "@/components/CountrySelect";
import VATRates from 'vatrates';
import map from "lodash/map";
import find from "lodash/find";
import { mapGetters } from "vuex";
import countries from '@/support/countries';
const vatRates = new VATRates();

export default {
  name: "ChargeModal",
  components: {
    countrySelect,
    userPicture
  },

  props: [
    'teacher',
    'kind',
    'product',
    'productUid',
    'productTh',
    'bookings',
    'discount'
  ],

  computed: {
    ...mapGetters(["user"]),
    hasVat() {
      if (!this.teacher.charges_vat) {
        return false
      }

      return this.country.code && vatRates.isVATCountry(this.country.code)
    },
    vat() {
      if (this.hasVat) {
        const vat = vatRates.getStandardRate(this.country.code)
        return this.product.price - this.product.price/((100+vat)/100)
      } else {
        return 0
      }
    },
    priceWithoutVat() {
      return this.product.price - this.vat
    }
  },

  data() {
    return {
      stripe: {},
      card: null,
      countryWasSet: false,
      vatRates: vatRates,
      country: {
        code: null,
        name: null
      },
      isPaying: null,
      error: null,
      stripeKey: stripeKey,
      complete: false,
    };
    // see https://stripe.com/docs/stripe.js#element-options for details
  },

  created() {
    const country = find(countries, {name: this.user.country})
    if (country) {
      this.countryWasSet = true;
      this.country = country
    }

    try {
      // console.log(stripeKey)
      this.stripe = Stripe(stripeKey)
    } catch (err) {
    }

    setTimeout( () => {
      var elements = this.stripe.elements();
      const style = {
        base: {
          color: "#32325d",
          lineHeight: "18px",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };
      this.card = elements.create("card", { hidePostalCode: true, style: style });
      // this.card.hidePostalCode = true;
      this.card.mount("#card_element");

      this.card.addEventListener("change", event => {
        if (event.error) {
          // this.error = event.error;
          this.$toast.open({
            duration: 2000,
            message: event.error.message,
            type: "error"
          });
        } else {
          this.error = null;
        }
        this.complete = event.complete;
        // console.log(event);
      });
    }, 700)
  },
  methods: {

    pay() {
      // SAVE COUNTRY
      if (!this.countryWasSet) {
        this.$store.dispatch("saveUser", Object.assign(this.user, {country: this.country.name})).then(data => {
          this.$store.dispatch("setUser", data.user);
        })
      }

      this.isPaying = true;
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      this.stripe.createToken(this.card).then(data => {
        if (data.error) {
          this.error = data.error;
          this.isPaying = false;
          this.$toast.open({
            duration: 5000,
            message: this.error,
            type: "error"
          });
        } else {
          // console.log(data.token);
          if (this.kind === 'booking') {
            this.stripeTokenBookingsHandler(data.token);
          } else {
            this.stripeTokenHandler(data.token);
          }
        }
      });
    },

    stripeTokenHandler(token) {
      const { product, kind, teacher } = this;

      this.$store
        .dispatch("charge", {
          amount: product.price,
          kind,
          product,
          teacher_id: teacher.id,
          stripe_token: token.id
        })
        .then(
          data => {
            this.$store.dispatch("getPurchases");
            this.$store.dispatch("findPack", { id: product.id })
            this.$toast.open({
              duration: 5000,
              message: `Perfect! You can now access "${product.name}"`,
              type: "success"
            });
            this.$emit('close');
            // this.bookingSuccess()
          },
          err => {
            this.isPaying = false;
            this.$toast.open({
              duration: 5000,
              message: err.message,
              type: "error"
            });
          }
        );
    },

    stripeTokenBookingsHandler(token) {
      this.$store
        .dispatch("charge", {
          amount: this.bookings.length * this.teacher.rate * ((100 - (this.discount || {percent:0}).percent) / 100),
          kind: 'booking',
          teacher_id: this.teacher.id,
          event_ids: map(this.bookings, "id"),
          stripe_token: token.id
        })
        .then(
          data => {
            this.isPaying = false;
            this.$toast.open({
              duration: 5000,
              message: `Perfect! Everything is confirmed`,
              type: "success"
            });
            this.$emit('close');
            this.$router.push({ name: "Bookings" });
          },
          err => {
            this.isPaying = false;
            this.$toast.open({
              duration: 5000,
              message: err.message,
              type: "error"
            });
          }
        );
    },
  }
};
</script>

<style lang="scss">
@import "colors";

.modal-charge .modal-content {
  max-width: 400px;
  overflow: visible;
}
.modal-charge {
  width: 400px;
  .avatar {
    margin: -50px auto 0px;
    padding-bottom: 20px;
    text-align: center;
    &.letter-avatar {
      display: none !important;
    }
  }

  .avatar img {
    padding: 5px;
    background: #fff;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }
}


.btn-edit-country {
  padding: 0 !important;
  width: 24px;
  display: inline-flex;
  /* text-align: center; */
  border-radius: 50%;
  height: 25px;
}

.stripe-card {
  width: 300px;
  border: 1px solid grey;
}

.stripe-card.complete {
  border-color: green;
}

.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
