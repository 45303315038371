const items = [
  'Record audio',
  'Attach pictures',
  'Live edit',
  'Auto translate',
  'Export to flashcards',
  'Export all audio files',
  'Speech to text',
  'Voice recognition',
  //'Booking system',
  //'Payment system',
]

module.exports = [
  {
    public: true,
    classes: '',
    kind: 'credit',
    name: 'Pay as you go',
    uid: 'credits',
    price: 0,
    prices: {
      // '10': 7,
      '50': 27,
      '120': 47
    },
    uids: {
      // '10': 'credit_7',
      '50': 'credit_27',
      '120': 'credit_47'
    },
    duration: '-',
    fee: 0,
    includes: [
      'Speech to text',
      'Record audio',
      'Attach pictures',
      'Live edit',
      'Auto translate',
      'Export to flashcards',
      'Export all audio files',
      'Voice recognition',
    ]
  },
  {
    public: true,
    kind: 'plan',
    name: 'Basic',
    invoiceDescription: 'Monthly subscription.<br>Cancelable at any moment',
    classes: 'is-warning',
    uid: 'basic_monthly',
    frequency: 'monthly',
    product_id: '891524',
    price: 14.90,
    cents: 90,
    fee: 0,
    includes: [
      '50 lessons',
      'Speech to text',
      'Record audio',
      'Attach pictures',
      'Live edit',
      'Auto translate',
      'Export to flashcards',
      'Export all audio files',
      'Voice recognition',
      'Computer-generated audio',
      'Auto translate in bulk',
      'Low priority support',
    ]
  },
  {
    public: true,
    kind: 'plan',
    name: 'Pro',
    invoiceDescription: 'Monthly subscription.<br>Cancelable at any moment',
    classes: 'is-primary',
    uid: 'premium_monthly',
    frequency: 'monthly',
    product_id: '891522',
    price: 19.90,
    cents: 90,
    fee: 0,
    includes: [
      '<b>Unlimited lessons</b>',
      '<b>High quality speech to text</b> (via OpenAI)',
      'Record audio',
      'Attach pictures',
      'Live edit',
      'Auto translate',
      'Export to flashcards',
      'Export all audio files',
      'Voice recognition',
      'Computer-generated audio',
      'Auto translate in bulk',
      'Support - 48 hours',
    ]
  },
  {
    public: true,
    kind: 'plan',
    name: 'Pro Yearly',
    discountText: '2 months off',
    invoiceDescription: 'Yearly subscription.<br>2 MONTHS OFF',
    classes: 'is-secondary',
    uid: 'premium_yearly',
    frequency: 'yearly',
    product_id: '891523',
    price: 199.0,
    normalPrice: 238.80,
    cents: 0,
    fee: 0,
    includes: [
      '<b>Unlimited lessons</b>',
      '<b>High quality speech to text</b> (via OpenAI)',
      'Record audio',
      'Attach pictures',
      'Live edit',
      'Auto translate',
      'Export to flashcards',
      'Export all audio files',
      'Voice recognition',
      'Computer-generated audio',
      'Auto translate in bulk',
      'Priority Support - 24 hours',
      //'Booking system',
      //'Payment system',
    ],
  },
  {
    classes: 'is-success',
    kind: 'credit',
    name: 'Pay as you go',
    invoiceDescription: 'Gives 50 lessons with media',
    invoiceName: '50 Credits',
    uid: 'credit_27',
    sku: 'sku_credit_27',
    product_id: '563547',
    credits: 50,
    price: 27,
    duration: '-',
    fee: 0,
    includes: items
  },
  {
    classes: 'is-success',
    kind: 'credit',
    name: 'Pay as you go',
    invoiceDescription: 'Gives 120 lessons with media',
    invoiceName: '120 Credits',
    credits: 120,
    uid: 'credit_47',
    sku: 'sku_credit_47',
    product_id: '563552',
    price: 47,
    duration: '-',
    fee: 0,
    includes: items
  }
]


