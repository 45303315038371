<template lang="pug">
  #students_page.container.page--students(
    v-shortkey="['arrowright']"
    @shortkey="nextPage()"
  )
    .box.has-shadow(
      v-shortkey="['arrowleft']"
      @shortkey="previousPage()"
    )
      .columns
        .column
          h3.title.has-text-left
            | People
      // EXPLANATIONS
      article.message.is-light(v-if="students.length === 0")
        .message-body
          | Once you share your first lesson, your students will appear on this page.

      b-table.w-100.with-search(
        v-else
        narrowed
        hoverable
        sort-icon="arrow-drop-up"
        default-sort="last_update"
        default-sort-direction="desc"
        :paginated="students.length > 10"
        :per-page="10"
        :pagination-simple="true"
        :loading="loading"
        :data="students"
        )
        b-table-column(v-slot="props"
          field="first_name"
          label="Name"
          sortable
          searchable
          width="320"
          )
          router-link.is-smooth.has-text-grey-dark.has-text-weight-bold.is-student-link(:to="`/students/${props.row.id}`")
            student(:student="props.row" :index="props.index")
        b-table-column(v-slot="props" label="Lessons" width="150")
          span.has-text-grey
            | {{ lessonsByStudent[props.row.id].length }} lessons
        b-table-column(v-slot="props" field="last_update" label="Last lesson at" width="150" sortable)
          | {{ props.row.last_update | moment("DD.MM") }}
        b-table-column(v-slot="props" label="" width="360")
          .has-text-right
            router-link.button.is-info.is-small.mr-1(:to="`/students/${props.row.id}`")
              b-icon(icon="remove-red-eye" size="is-small")
              span.pl-0 All lessons
            router-link.button.is-info.is-outlined.is-small.mr-1(:to="{name: 'allSentencesForStudent', params: {student_id: props.row.id}}")
              b-icon(icon="view-headline" size="is-small")
              span.pl-0 All sentences
            router-link.button.is-info.is-small.is-outlined.button-last-lessons(:to="{name: 'allForStudent', params: {student_id: props.row.id}}")
              b-icon(icon="view-headline" size="is-small")
              span.pl-0 Last {{ lessonsByStudent[props.row.id].length > 3 ? '3' : lessonsByStudent[props.row.id].length }} lesson{{ lessonsByStudent[props.row.id].length > 1 ? 's' : '' }}
</template>


<script>
  import LessonsList from '@/components/LessonsList'
  import StudentMiniCardUI from '@/components/StudentMiniCardUI'
  import {mapGetters} from 'vuex'
  import sortBy from 'lodash/sortBy'

  export default {
    name: 'students',
    components: {
      lessonsList: LessonsList,
      student: StudentMiniCardUI
    },
    data() {
      return {
        activeId: null,
        loading: false
      }
    },
    computed: {
      ...mapGetters(['lessons', 'students', 'apiUrl', 'user']),
      lessonsByStudent() {
        var lessons = {}
        let i = 0;
        const iMax = this.students.length;
        for(; i < iMax; i++) {
          const student = this.students[i]
          if (!student.lesson_ids)
            continue
          lessons[student.id] = sortBy(this.lessons.filter(function(lesson) {
            return (student.lesson_ids.indexOf(lesson.id+'') !== -1) || lesson.student_id === student.id
          }), ['created_at']).reverse()
          // if (lessons[student.id][0]) {
          //   student.last_update = lessons[student.id][0].created_at
          // }
        }
        return lessons;
      }
    },
    created() {
      if (!this.students.length) {
        this.loading = true
      }

      this.$store.dispatch('getStudents').then(res => {
        this.loading = false;
      });
      this.$store.dispatch('getLessons')
      this.$store.dispatch('setLayout', 'logged-in-layout')
    },
    mounted() {
      setTimeout(() => {
        this.$qs('#students_page table input').focus()
      }, 200)
    },
    methods: {
      previousPage() {
        this.$qs('#students_page .pagination-previous').click()
      },
      nextPage() {
        this.$qs('#students_page .pagination-next').click()
      },

      addLesson(lang) {
        this.$store.dispatch('addLesson', {language_to: lang || 'en-US'}).then(() => {
          setTimeout(function () {
            document.querySelector('.lesson__title input').focus()
          }, 400)
        })
      },

      studentLessons(student) {
        return this.lessons.filter((lesson) => (student.lesson_ids.indexOf(lesson.id+'') !== -1) || lesson.student_id === student.id)
      }
    }
  }
</script>


<style lang="scss">
  @import 'colors';

  $figureSize: 36px;
  $imgSize: 32px;

  .page--students {
    .is-student-link:hover {
      color: $primary !important;
    }

    .tabs a {
      font-weight: 600;
    }

    td {
      vertical-align: middle;
    }

    tr:nth-child(2) {
      th { height: 0px; padding: 0; border-width: 1px}
    }

    .button-last-lessons {
      min-width: 116px;
    }
  }


</style>
