import { Doughnut } from 'vue-chartjs';
import _keys from "lodash/keys";
import _uniq from "lodash/uniq";
import _sum from "lodash/sum";
import 'chartjs-plugin-labels';

export default {
  extends: Doughnut,
  props: ['user'],
  computed: {

  },
  data() {
    return {
      frequency: 'month',
      options: {}
    }
  },
  mounted () {
    const { data } = this.user.counter;
    const year = new Date().getFullYear();
    const colors = [
      'rgb(255, 91, 98)',
      // 'rgb(4, 137, 0)',
      'rgb(249, 202, 36)',
      'rgb(28, 124, 156)',
    ];
    const colorsTrans = [
      'rgb(255, 91, 98, 0.2)',
      // 'rgb(4, 137, 0, 0.2)',
      'rgb(249, 202, 36, 0.2)',
      'rgb(28, 124, 156, 0.2)',
    ];

    const chartData = {
      labels: [],
      datasets: [
        {
          backgroundColor: colorsTrans,
          borderColor: colors,
          data: []
        }
      ]
    }

    for (let language of _keys(data)) {
      let years = _keys(data[language]).sort();
      chartData.labels.push(language.substring(0,2).toUpperCase());
      let keys = []
      for (let year of years) {
        if (data[language][year]) {
          keys = keys.concat(_keys(data[language][year][this.frequency]));
        }
      }
      keys = _uniq(keys);
      chartData.datasets[0].data.push(_sum(keys.map(entry => {
        let num = 0;
        for (let year of years) {
          if (data[language][year] && data[language][year][this.frequency][entry]) {
            num += data[language][year][this.frequency][entry].wc;
          }
        }
        return num;
      })))
    }
    this.options = {
      responsive: false,
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      plugins: {
        labels: {
          // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
          render: 'value',
          // fontColor: '#fff',
        }
      }
    }

    this.renderChart(chartData, this.options)
  }
}
