<template lang="pug">
  .app-container.page-for-teachers.text-main(v-farewell="onLeave")
    front-menu
    main.app-content

      //- Hero
      hero-section(
        topic="FOR TEACHERS"
        title="Remote conversational<br>training with a hint of magic"
        scroll-anchor="how_it_works_teacher"
      )

      how-it-works-teacher-section.has-background-dark(
        topic="USE TEACHEE AS A TEACHER"
      )

      features-section(
        topic="TEACHEE HELPS YOU TEACH LANGUAGES"
        content="teacher"
        mode="dark"
      )

      cta-section(
        tagline="Teach pronunciation<br>like magic"
        link-text="Create your first lesson"
        link-href="/sign_up"
      )

    footer-section
</template>

<script>
  import footerSection from "@/components/sections/FooterSection";
  import askToSubscribeOnLeave from '@/components/behaviors/AskToSubscribeOnLeave';
  import HowItWorksTeacherSection
    from "@/components/sections/HowItWorksTeacher";
  import CtaSection from "@/components/sections/Cta";
  import {
    noLayoutAndStorePartnerInfo,
    ssrFinishedRendering
  } from "@/utils";
  import FeaturesSection from "@/components/sections/Features";
  import HeroSection from "@/components/sections/Hero";

  export default {
    name: 'ForTeachersPage',
    mixins: [
      askToSubscribeOnLeave,
    ],
    components: {
      HeroSection,
      FeaturesSection,
      CtaSection,
      HowItWorksTeacherSection,
      footerSection
    },
    mounted() {
      noLayoutAndStorePartnerInfo(this.$store, this.$route, this.$cookie);
      ssrFinishedRendering();

      if (this.$analytics && this.$analytics.fbq && this.$analytics.fbq.event) {
        this.$analytics.fbq.event("ViewContent", {
          content_name: 'Teachers Page'
        })
      }
    },
  }
</script>


<style lang="scss">
@import 'colors';

.page-for-teachers {
  .hero-body {
    background: url("~@/assets/v3/bottomCTA-bg.svg") no-repeat;
    background-size: 50%;
    background-position: center 51%;
    @media screen and (max-width: 767px) {
      background-size: 100%;
    }
  }
  .section--how-it-works-teacher {
    p,
    h3,
    h2 {
      color: $primary !important;
    }
    .tab-selector {
      span {
        color: $primary;
        &.selected {
          background-color: $primary;
          color: #FFF
        }
      }
    }
  }
}
</style>
