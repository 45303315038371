<template lang="pug">
  .is-loading.is-fullheight.has-loader.primary-loader.app-container.w-100.is-size-1
</template>


<script>
  export default {
    name: 'RefreshCache',
    data() {
      return {
        activeId: null
      }
    },
    computed: {
    },
    created() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++){
        var spcook =  cookies[i].split("=");
        document.cookie = spcook[0] + "=;expires=Thu, 21 Sep 1979 00:00:01 UTC;";
      }
      window.localStorage.clear()
      setTimeout(() => {
        this.$router.push({name: 'SignIn'})
      }, 2000)
    },
    methods: {
    }
  }
</script>


<style lang="scss" scoped>
</style>
