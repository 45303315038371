<template lang="pug">
  .modal.is-active.modal-add-existing-sentence
    .modal-background
    .modal-card

      header.modal-card-head.has-flex-center-items.valign
        h2.title.is-size-5.mb-0.mr-5
          | Add a sentence you used before

      section.modal-card-body
         search-sentence(v-model="sentence_id")

      footer.modal-card-foot
        button.button.is-primary.is-fullwidth(
        :class="{'is-loading': loading}"
        :disabled="loading || !sentence_id"
        @click="apply"
        ) Add this sentence

    button.modal-close.is-large(aria-label="close" @click="$emit('close')")


</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import SearchSentence from "./SearchSentence";
  import { Sentence } from "@/store/models/resources";

  export default {
    name: 'AddExistingSentenceModal',
    components: {SearchSentence},
    props: [
      'lesson_uuid',
      'languageTo',
      'languageFrom',
      'index'
    ],
    data() {
      return {
        loading: false,
        sentence_id: null,
      };
    },
    computed: mapGetters(['uuid']),
    mounted() {
      setTimeout(() => {
        document.getElementById('search_sentence_btn').focus()
      }, 400)
    },
    created() {
      // this.getSentences({to: this.languageTo, from: this.languageFrom})
    },
    methods: {
      ...mapActions(['getSentences']),
      apply() {
        this.loading = true;
        Sentence.clone({
          id: this.sentence_id,
          lesson_uuid: this.lesson_uuid,
          index: this.index
        }).then(res => {

          this.$socket.emit('LESSON_ASK_REFRESH', {
            room: `LESSON_${this.lesson_uuid}`,
            uuid: this.uuid
            // uuid: '0'
          });
          this.$store.commit("addSentence", res.body);

          this.$mixpanel.track("Lesson - Add existing sentence");

          // this.$socket.emit(
          //   "SENTENCE_EDITED",
          //   {
          //     room: `LESSON_${this.lesson_uuid}`,
          //     uuid: this.uuid,
          //     action: 'addSentence',
          //     sentence: Object.assign({}, {lesson_uuid: this.lesson_uuid}, res.body)
          //   }
          // );
          // this.$store.commit("addSentence", res.body);

          this.$emit('close');
        }, err => {
          this.$toast.open({
            duration: 3000,
            message: "Something went wrong, please try again",
            type: "error"
          });

        })
      }
    }
  };
</script>

<style lang="scss">
  @import "colors";

  .modal-add-existing-sentence {
    /*justify-content: flex-start;*/
    /*padding-top: 2rem;*/
  }
  .modal-add-existing-sentence,
  .modal-add-existing-sentence .modal-card {
    overflow: visible;
    z-index: 10;
  }
  .modal-add-existing-sentence .modal-card {
    width: 430px;
  }
</style>
