`import {FreeLessonToken} from '../models/resources'`

actions =

  useFreeLessonToken: (context, booking) ->
    new Promise((resolve, reject) ->
      FreeLessonToken.save({id: booking.id}, booking).then(
        (res) ->
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )


`export default actions`
