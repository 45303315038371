module.exports =

  sentences:   (state) -> state.sentences
  sentencesOptions:   (state) -> state.sentencesOptions
  packs:       (state) -> state.packs
  purchases:   (state) -> state.purchases
#  sentencesForLesson:  (state, lesson) -> state.sentences.filter((sent) -> sent.lessonId == lesson._id)
  categories:  (state) -> state.categories
  storageStats:  (state) -> state.storageStats
  students:    (state) -> state.students
  student:     (state) -> state.student
  lessons:     (state) -> state.lessons
  allLessons:     (state) -> state.allLessons
  visitedLessons: (state) -> state.visitedLessons
  activities:  (state) -> state.activities
  lastLessons: (state) -> state.lastLessons
  lesson:      (state) -> state.lesson
  pack:        (state) -> state.pack
  user:        (state) -> state.user
  showShortIpaVersion: (state) -> state.showShortIpaVersion
  isGuest:     (state) -> !state.user.id
  layout:      (state) -> state.layout
  layoutBackground:      (state) -> state.layoutBackground
  defaultHours:      (state) -> state.defaultHours
  uuid:        (state) -> state.uuid
  apiUrl:      (state) -> state.apiUrl
  lastAudio:   (state) -> state.lastAudio
  isConnected: (state) -> state.isConnected
  isEditingSentenceContent: (state) -> state.isEditingSentenceContent
  voices:      (state) -> state.voices
  lastActivity: (state) -> state.lastActivity
  usersById:    (state) -> state.usersById
  compactMode:    (state) -> state.compactMode
  navCompactMode:    (state) -> state.navCompactMode
  voiceGender:    (state) -> state.voiceGender
  usedLanguages: (state) -> state.usedLanguages
#  lessonById: (state, id) -> state.lessons[id]


