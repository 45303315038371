<template lang="pug">
  .columns
    .column.is-4
      .box.student-is-booking.has-shadow
        figure.avatar
          img(:src="picture" width="98" height="98")

        h2 {{ name }}
        h3.is-size-7 {{ location }}
        //-h4.is-size-6(v-if="teacher.currency")
          | {{ (currencies[teacher.currency] || {}).l || '' }}{{ teacher.rate }}{{ (currencies[teacher.currency] || {}).r || '' }}/h
        .pt-3.has-text-left(v-if="teacher.description_html && teacher.description_html != 'null'")
          .content(v-html="teacher.description_html")
    .column.is-8
      .box.student-is-booking.has-shadow
        .tabs
          ul
            //-li(:class="{'is-active': (activeId === 'bookings') || !activeId}")
              a(@click="activeId = 'bookings'") Bookings
            li(:class="{'is-active': (activeId === 'lessons') || !activeId}")
              a(@click="activeId = 'lessons'") Lessons
            li(:class="{'is-active': (activeId === 'public_lessons')}")
              a(@click="activeId = 'public_lessons'") Public lessons
            li(:class="{'is-active': (activeId === 'packs')}" v-if="teacher.role === 'proplus'")
              a(@click="activeId = 'packs'") Packages

        //-section.section.has-text-left.pt-0( v-if="(activeId ==='bookings') || (!activeId)" )
          article.message.is-danger(v-if="teacher.id && !teacher.stripe_connect_uid && !teacher.paypal_email")
            .message-body
              | The teacher doesn't handle payments yet. Hence no booking can be proceed.
              br
              | Ask him/her to go on his/her profile page and click on <b>Stripe Connect</b> or enter his/her <b>Paypal email</b>
          transition(name="fade")
            vue-scheduler(
              v-if="teacher.id && (teacher.stripe_connect_uid || teacher.paypal_email)"
              :events='events'
              initial-view="month"
              @time-clicked="timeClicked"
              @event-clicked="book"
              @event-created="eventCreated"
              @week-changed="weekChanged"
              :disable-dialog="true"
            )

        //-section.section.has-text-left.pt0( v-show="(activeId === 'bookings' || !activeId) && teacher.id && (teacher.stripe_connect_uid || teacher.paypal_email)" )
          .columns
            .column
              h2.title {{ freeTokenCount ? 'Credits' : 'Payment' }}
              hr.spacer.is-1
              .field.has-text-left
                label.label Bookings
                div(v-for="event in pendingBookings")
                  p.button.is-info.booking.has-text-left
                    b {{ event.startTime }}
                    | &nbsp;- {{ event.endTime }}
                    |  on {{ event.date | moment("dddd, MMMM Do") }}
              .message.is-warning(v-if="!freeTokenCount")
                .message-body
                  h3.title.is-size-6 Acknowledgement
                  hr.spacer.is-1
                  p
                    | Teachee is not responsible for the successful completion of the lesson (ex: delayed teacher, absent teacher).
                    | For any reclamation or refund, please contact your teacher directly.
                    br
                    br
                    .field
                      b-checkbox(
                        v-model="acknowledgementRead"
                        :value="true"
                        type="is-warning")
                        | Ok, I understand
                        span.has-text-danger(v-if="complete && !acknowledgementRead")
                          |  • Please read and check to continue
              .message.is-danger(v-if="!freeTokenCount && pendingBookings.length && nextDiscount && (nextDiscount.quantity - pendingBookings.length <= 3)")
                .message-body
                  h3.title.is-size-6 Discounts
                  hr.spacer.is-1
                  p Add {{ nextDiscount.quantity - pendingBookings.length }} booking to unlock {{ nextDiscount.percent }}% OFF
              .field.has-text-left(v-show="!freeTokenCount")
                label.label Confirm
                .control
                  button.button.is-primary(
                    v-if="teacher.stripe_connect_uid"
                    :disabled="!acknowledgementRead"
                    @click="pay")
                    | Pay {{ (currencies[teacher.currency] || {}).l || '' }}{{ price.toFixed(2) }}{{ (currencies[teacher.currency] || {}).r || '' }}
                    |  to {{ teacher.first_name }}
                    |  to confirm bookings
                    span(v-if="currentDiscount")
                      | &nbsp;({{ currentDiscount.percent }}% OFF)

                  div.w-100(v-else :class="{'is-disabled is-unclickable': !acknowledgementRead}")
                    div.mb-2
                      | Pay {{ (currencies[teacher.currency] || {}).l || '' }}{{ price.toFixed(2) }}{{ (currencies[teacher.currency] || {}).r || '' }}
                      |  to {{ teacher.first_name }}
                      |  to confirm bookings
                      span(v-if="currentDiscount")
                        | &nbsp;({{ currentDiscount.percent }}% OFF)
                    #paypal-button
              .field(v-show="freeTokenCount")
                label.label Confirmation
                p You have {{ freeTokenCount }} credit{{ freeTokenCount > 1 ? 's' : '' }} left
                button.button.is-primary(
                  :class="{'is-loading is-disabled': isPaying}"
                  :disabled="!pendingBookings.length"
                  @click="useCredits")
                  | Use {{ pendingBookings.length }} credit{{ pendingBookings.length > 1 ? 's' : '' }} to confirm bookings with {{ teacher.first_name }}

        section.section.has-text-center.pt0(
          v-if="activeId ==='lessons' || !activeId"
        )
          lessons-list(
            :lessons="lessonsWithCurrentTeacher"
            :key="visitedLessons.length"
            group-by="language_to"
            :add-options="{student_id: user.id}")

        section.section.has-text-center.pt0(
          v-if="activeId ==='public_lessons'"
        )
          lessons-list(
            :lessons="publicLessons"
            :key="publicLessons.length"
            :read-only="true"
            empty-message="No public lesson yet"
            group-by="language_to")

        section.section.has-text-center.pt0(
          v-if="activeId ==='packs'"
        )
          article.message.is-grey.has-text-left(v-if="teacher.id && !teacher.packs.length")
            .message-body
              | No packages yet
          .columns.is-multiline
            .column.is-one-quarter(
              v-for="(pack, i) in teacher.packs"
              :key="pack.id")
              router-link.button.is-block.w-100.pack--link(:to="{name: 'ShowPackPage', params: {id: pack.id}}")
                | {{ pack.name }}
                br
                b
                  | {{ (currencies[user.currency] || {}).l || '' }}{{ pack.price }}{{ (currencies[user.currency] || {}).r || '' }}
</template>


<script>
  import filter from "lodash/filter";
  import each from "lodash/each";
  import sortBy from "lodash/sortBy";
  import findIndex from "lodash/findIndex";
  import find from "lodash/find";
  import map from "lodash/map";
  import sum from "lodash/sum";
  import lessonsList from "@/components/LessonsList";
  import {nodeApi, stripeKey} from "@/config";
  import {mapGetters} from "vuex";
  import {User, Event, FreeLessonToken, Lesson} from "@/store/models/resources";
  import chargeModal from "@/components/ChargeModal";

  export default {
    name: "TeacherPage",
    components: {
      lessonsList
    },

    data() {
      return {
        freeTokenCount: 0,
        activeId: null,
        error: null,
        isLoading: false,
        acknowledgementRead: false,
        isSuccess: false,
        showTooltip: false,
        teacher: {},
        // BOOKING
        events: [],
        currencies: {
          EUR: {r: "€"},
          USD: {r: "$"},
          GBP: {l: "£"}
        },
        bookedIds: {},
        publicLessons: [],
        // PAYMENT
        complete: false,
        card: null,
        isPaying: false,
        paymentReady: false,
        stripeKey: stripeKey,
        paypalClientId: (process.env.NODE_ENV == 'production') ? 'AUe6T1D4JEshhL0tP62Bl2s7OkLIlXx_3s7_kGf8U5Ga_0yQZR36571iLzOdxQ6GCF5vAHZJ3xLU6MIB' : 'AVXYxWqw-rmmuJBevw1FSUgf3o-_wh4LQNK2FuuFQ5OEZYecon1fMWFiuUclLIQ_WAsbTjpvw1YATqED'
      };
    },
    computed: {
      ...mapGetters(["apiUrl", "visitedLessons", "user"]),
      pendingBookings() {
        return filter(this.events, ev => ev.state === "pending");
      },
      name() {
        return (
          this.teacher.name ||
          `${this.teacher.first_name || ""} ${this.teacher.last_name || ""}`
        );
      },
      price() {
        return Math.round(sum(map(this.pendingBookings, 'duration')) * this.teacher.rate * ((100 - (this.currentDiscount || {percent: 0}).percent) / 100) * 100, 2) / 100;
      },
      location() {
        if (
          this.teacher.city &&
          this.teacher.city.length &&
          this.teacher.country &&
          this.teacher.country.length
        ) {
          return `${this.teacher.city}, ${this.teacher.country}`;
        }
      },
      picture() {
        if (
          this.teacher.picture &&
          (this.teacher.picture.substring(0, 4) === "data" ||
            this.teacher.picture.substring(0, 4) === "http")
        )
          return this.teacher.picture;
        else if (this.teacher.picture)
          return `${this.apiUrl}/${this.teacher.picture}`;
        else return "/logo_teachertool_v2_square.png";
      },
      lessonsWithCurrentTeacher() {
        if (!this.teacher.id) {
          return [];
        }
        return filter(
          this.visitedLessons,
          lesson => lesson.user_id == this.teacher.id
        );
      },
      sortedDiscounts() {
        if (!this.teacher.discounts || !this.teacher.discounts.length) {
          return []
        }
        return sortBy(this.teacher.discounts, ['quantity'])
      },
      nextDiscount() {
        return find(this.sortedDiscounts, (dis) => dis.quantity > this.pendingBookings.length)
      },
      currentDiscount() {
        return find(this.sortedDiscounts.reverse(), (dis) => dis.quantity <= this.pendingBookings.length)
      },
      remainingToUnlockDiscount() {
        return this.nextDiscount ? this.nextDiscount.quantity - this.pendingBookings.length : 0
      },
    },
    created() {
      if (this.$route.query.tab) {
        this.activeId = this.$route.query.tab
      }
      
      this.$store.dispatch("getVisitedLessons");

      //this.$store.commit("layoutBackground", "");
      // this.$store.dispatch("getLessons");

      Lesson.publicForTeacher({id: this.$route.params.id}).then(res => {
        this.publicLessons = res.body.lessons
      });

      FreeLessonToken.query({id: this.$route.params.id}).then(res => {
        this.freeTokenCount = res.body.free_lesson_tokens_count;
      });

      User.findTeacher({id: this.$route.params.id}).then(res => {
        this.teacher = res.body;

        Event.teacherEvents({id: this.$route.params.id, state: "free"}).then(
          response => {
            each(
              sortBy(response.body.events, ev =>
                parseInt(ev.start_time.replace(":", ""))
              ),
              ev => {
                this.events.push(Event.transformFromApi(ev, this.user.time_zone));
              }
            );
            if (this.pendingBookings.length) {
              // this.setupPaymentOnce();
              this.loadScript("https://js.stripe.com/v3/")
              if (this.teacher.paypal_email) {
                this.loadScript(`https://www.paypal.com/sdk/js?client-id=${this.paypalClientId}&currency=${this.teacher.currency}`, this.loadPaypalButton)
              }
            }
          },
          err => {
            // console.log(err);
            this.$toast.open({
              duration: 3000,
              message: "Error when getting teacher's availabilities",
              type: "error"
            });
          }
        );
      });
    },

    methods: {

      loadPaypalButton() {

        if (!document.getElementById('paypal-button').children.length) {

          paypal.Buttons({
            env: (process.env.NODE_ENV == 'production') ? 'production' : 'sandbox',
            commit: true,

            // Set up a payment
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: `${this.price.toFixed(2)}`,
                    currency_code: this.teacher.currency
                  },
                  payee: {
                    email_address: this.teacher.paypal_email
                  },
                  description: map(this.pendingBookings, 'id').join('-')
                }]
              });
            },
            // Execute the payment
            onApprove: (data, actions) => {
              // Capture the funds from the transaction
              return actions.order.capture().then((details) => {
                // Show a success message to your buyer

                this.$store.dispatch("chargePaypal", {
                  order_id: data.orderID,
                  amount: this.price,
                  teacher_id: this.teacher.id,
                  event_ids: map(this.pendingBookings, 'id')
                }).then(() => {
                  this.$toast.open({
                    duration: 5000,
                    message: 'Payment proceeded succesfully',
                    type: "success"
                  });

                  this.$router.push({name: 'Bookings'})
                })

              });
            }
          }).render('#paypal-button');
        }
      },

      loadScript(url, callback) {
        if (!document.querySelector(`script[src="${url}"]`)) {
          const script = document.createElement("script")
          script.type = "text/javascript";

          if (script.readyState) {  //IE
            script.onreadystatechange = function () {
              if (script.readyState == "loaded" ||
                script.readyState == "complete") {
                script.onreadystatechange = null;
                if (callback) callback();
              }
            };
          } else {  //Others
            script.onload = function () {
              if (callback) callback();
            };
          }

          script.src = url;
          document.getElementsByTagName("head")[0].appendChild(script);
        }
      },
      book(event) {
        const idx = findIndex(this.events, ev => ev.id === event.id);

        if (event.state === "free") {
          // BOOK
          this.$set(this.events[idx], "state", "pending");
          Event.book({id: event.id}, {}).then(
            response => {
              Object.assign(event, response.body.event);
            },
            err => {
              this.$set(this.events[idx], "state", "canceled");
              this.$toast.open({
                duration: 2000,
                message: err.message,
                type: "error"
              });
            }
          );
        } else if (event.state === "pending") {
          // UNBOOK
          this.$set(this.events[idx], "state", "free");
          Event.unbook({id: event.id}, {}).then(response => {
            Object.assign(event, response.body.event);
          });
        }
        // this.setupPaymentOnce();
        this.loadScript("https://js.stripe.com/v3/")

        if (this.teacher.paypal_email) {
          const paypalUrl = `https://www.paypal.com/sdk/js?client-id=${this.paypalClientId}&currency=${this.teacher.currency}`

          if (!document.querySelector(`script[src="${paypalUrl}"]`)) {
            this.loadScript(paypalUrl, this.loadPaypalButton)
          } else {
            this.loadPaypalButton()
          }
        }
      },
      useCredits() {
        this.isPaying = true;
        this.$store
          .dispatch("useFreeLessonToken", {
            id: this.teacher.id,
            free_lesson_token: {
              event_ids: map(this.pendingBookings, "id")
            }
          })
          .then(data => {
            this.bookingSuccess()
          }, err => {
            this.$toast.open({
              duration: 5000,
              message: err,
              type: "error"
            });
          });
      },
      pay() {
        const desc = []
        each(this.pendingBookings, (event) => {
          desc.push(`${event.startTime} - ${event.endTime} on ${this.$moment(event.date).format("dddd, MMMM Do")}`)
        });

        this.$buefy.modal.open({
          parent: this,
          width: 400,
          props: {
            teacher: this.teacher,
            kind: 'booking',
            product: {
              price: this.price,
              invoiceName: `Lessons with ${this.teacher.first_name}`,
              invoiceDescription: desc.join('<br>')
            },
            bookings: this.pendingBookings,
            discount: this.currentDiscount
          },
          component: chargeModal,
          hasModalCard: false
        })
      },

      bookingSuccess() {
        each(this.pendingBookings, event => {
          this.$set(
            this.events[findIndex(this.events, ev => ev.id == event.id)],
            "state",
            "booked"
          );
        });
        this.isPaying = false;
        this.$toast.open({
          duration: 5000,
          message: `Perfect! Everything is confirmed`,
          type: "success"
        });
        this.$router.push({name: "Bookings"});

      },

      // BOOKING

      timeClicked() {
      },
      eventClicked() {
      },
      eventCreated() {
      },
      weekChanged() {
      }
    }
  };
</script>


<style lang="scss">
  .student-is-booking {
    #paypal-button {
      max-width: 300px;
    }

    .v-cal-content.v-cal-content--month .v-cal-days .v-cal-day {
      min-height: 70px;
    }

    h4.subtitle {
      display: none;
    }

    #btn_add_lesson {
      display: none !important;
    }

    .avatar {
      padding-bottom: 10px;
    }

    .pack--link {
      height:      4rem;
      line-height: 1.4rem;
      padding-top: 0.75rem !important;
    }

    .lesson .avatar {
      margin-top:     0;
      padding-bottom: 0;
    }

    .v-cal-header__title-bar {
      width:  300px;
      margin: -30px auto 0px;
    }

    .content h1 {
      font-size: 1.5em;
    }

    .content h2 {
      font-size: 1.2em;
    }

    .content h3 {
      font-size: 1em;
    }

    .content h2:not(:first-child) {
      margin-top: 0.8em;
    }

    input {
      font-weight: 300;
    }

    p.booking {
      min-width:       302px;
      margin-bottom:   4px;
      justify-content: flex-start;
    }

    a {
      text-decoration: none
    }

    .title {
      margin-bottom: 0;
    }

    .StripeElement {
      background-color:   white;
      height:             40px;
      padding:            10px 12px;
      border-radius:      4px;
      border:             1px solid #dbdbdb;
      box-shadow:         inset 0 1px 2px rgba(10, 10, 10, 0.1);
      -webkit-transition: box-shadow 150ms ease;
      transition:         box-shadow 150ms ease;
    }

    .StripeElement--focus {
      box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
      border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
      background-color: #fefde5 !important;
    }
  }
</style>
