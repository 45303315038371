<template>
  <transition name="fade" mode="out-in">
    <section class="hero is-success is-fullheight">
      <div class="hero-body">
        <div class="container">
          <router-view/>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  name: 'AuthLayout',
};
</script>
