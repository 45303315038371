<template lang="pug">
  nav.navbar.is-transparent
    .navbar-brand.w-100
      router-link.navbar-item(:to="{name: (user && user.id) ? 'Home' : 'SignUp'}")
        img(
          src="@/assets/v3/logo.svg"
          alt="Teachee"
        )

      .navbar-end.ml-auto.mr-3
        .navbar-item
          .field.is-grouped.is-grouped-centered(
            v-if="user.id"
          )


            p.control.is-hidden-mobile
              search
            p.control(v-if="user.id && (user.role === 'free' || !user.role)")
              router-link.button(:to="{name: 'SubscribePage'}" :class="{'is-warning': !user.credits}")
                | Go Pro
            p.control.is-hidden-mobile
              b-dropdown(position="is-bottom-left" paddingless)
                a#create_lesson_navbar.button(slot="trigger" :class="{'is-primary': user.is_teacher, 'is-light': !user.is_teacher}")
                  span Create a lesson
                  b-icon(icon="arrow-drop-down")
                b-dropdown-item(custom paddingless)
                  .modal-card(style="width:300px;")
                    section.modal-card-body
                      b-field(label="Title")
                        b-input(
                          placeholder="Ex: Lesson with Anna"
                          required=""
                          v-model="lesson.title"
                          @keyup.native.enter="createLesson"
                          )
                      .columns
                        .column(v-if="students.length")
                          b-field.ml-0.mt-4(label="Student")
                            search-student.is-fullwidth(
                              v-model="lesson.student_id"
                              )
                        .column.is-narrow
                          b-field.ml-0.mt-4(label="Language")
                            language-selector.is-fullwidth(
                            @select="setLessonLanguageTo"
                            :autoselect="true"
                            :languages-on-top="user.teaches_languages"
                            :language="lesson.language_to"
                            )
                    footer.modal-card-foot
                      button.button.is-primary.is-fullwidth(
                        :class="{'is-loading': isCreating}"
                        :disabled="isCreating"
                        @click="createLesson"
                      )
                        | Create

            //-p.control.only-on-mac
              router-link.button(:to="{name: 'ToolsPage'}")
                b-icon(icon="code")
            p.control
              router-link.button(
                :to="{name: 'MyAccount'}"
                )
                b-icon(icon="account-circle")
                span Profile

            p.control
              b-tooltip.is-hidden-mobile(
                label="Logout"
                type='is-light'
                :animated='true'
                position='is-bottom'
                )
                button#btn_sign_out.button(@click="logout")
                  b-icon(icon="exit-to-app")
                  <!--span Download-->

          .field.is-grouped.is-grouped-centered(
            v-else
          )
            router-link.button.is-secondary.is-outlined.mr-sm-2(
              :to="{name: 'SignIn'}"
            ) Log in
            router-link.button.is-warning( :to="{name: 'SignUp'}" )
              b-icon(icon="account-circle")
              span Create my free account

</template>

<script>
  import languageSelector from "@/components/LanguageSelector";
  // import studentSelector from "@/components/StudentSelector";
  import SearchEverywhere from '@/components/SearchEverywhere'
  import searchStudent from '@/components/SearchStudent'
  import {mapGetters} from 'vuex'

  export default {
    name: 'BasicMenu',
    components: {
      search: SearchEverywhere,
      languageSelector,
      searchStudent
    },
    data () {
      return {
        isCreating: false,
        lesson: {
          title: '',
          language_to: 'en-US'
        },
      }
    },
    computed: mapGetters(['lastLessons', 'user', 'students', 'lessons', 'isGuest']),
    mounted () {
      setTimeout(() => {
        if (!this.isGuest) {
          this.$store.dispatch('getStudents')
        }
      }, 1000)
    },
    methods: {
      setLessonLanguageTo(newValue) {
        this.lesson.language_to = newValue;
      },
      createLesson() {
        this.isCreating = true;
        this.$store.dispatch('saveLesson', Object.assign(this.lesson, {
          visitors: [],
          user_id: this.user.id,
          user: this.user
        })).then((le) => {
          // console.log('uuid is ', le.uuid)
          this.$router.push(`/lessons/${le.uuid}`)
          if (this.lesson.student_id) {
            this.$socket.emit('LESSON_CREATED', {student_id: this.lesson.student_id})
          }
          this.isCreating = false
          document.getElementById('create_lesson_navbar').click();
        }, (err) => {
          this.isCreating = false
        })
      },
      logout() {
        this.$store.dispatch("setUser", {});
        this.$auth.logout({
          redirect: {name: 'SignIn'}
        });
      }
    }
  }
</script>

<style lang="scss">
  .navbar-item {
    img {
      height: 37px;
      width: auto;
    }

    #voice_selector_dropdown {
      padding-left: 0;
      height: 34px;
    }
    .dropdown-content {
      text-align: left;
      padding-bottom: 0;
    }
    .modal-card,
    .modal-card-body {
      overflow: visible;
    }
    .field.is-grouped-centered {
      align-items: center;
    }
  }

  .navbar-brand picture.logo {
    height: 40px
  }

  @media (max-width: 768px) and (orientation: portrait) {

  }

</style>
