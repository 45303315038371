<template lang="pug">
  b-dropdown.is-small-dropdown
    button.button.is-white.has-text-centered.is-small(slot="trigger")
      i.mdi.mdi-record-voice-over
      span.voice
        | {{ voices[language] }}
    b-dropdown-item.has-text-left(
      v-for="(voice, i) in filteredVoices"
      :key="i"
      @click="select(voice)")
      i.flag(:class="'flag-'+language")
      span.voice__name
        |  {{ voice.name }}
</template>

<script>
  import Audio from '@/services/Audio.js.coffee'
  import {mapGetters} from 'vuex'

  export default {
    name: 'voice-selector',
    props: [
      'language'
    ],
    data() {
      return {
        voiceOptions: [],
        synth: window.speechSynthesis,
      }
    },
    computed: {
      ...mapGetters(['voices']),
      filteredVoices() {
        return this.voiceOptions.filter(voice => voice.lang.includes(this.language))
      }
    },
    created() {
      this.voiceOptions = this.synth.getVoices()

      this.synth.onvoiceschanged = () => {
        this.voiceOptions = this.synth.getVoices()
      }
    },
    methods: {
      select(voice) {
        this.$store.commit('editVoice', {
          name: voice.name,
          lang: voice.lang
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  span.voice {
    margin-left: 6px;
    &:empty {
      display: none;
    }
  }

  i.mdi-record-voice-over {
    font-size: 20px;
  }

</style>
