<template lang="pug">
  .box.page-home.has-shadow(v-if="visitedLessons.length")
    section.pt-0
      .columns
        .column.is-wide
          h3.title.has-text-left
            | Lessons created for me
        .column.text-align-right.is-narrow
          button#btn_refresh.button.is-outlined.is-small(@click="refresh" v-shortkey="['r']" @shortkey="refresh()")
            b-icon(icon="sync" custom-size="mdi-18px" :custom-class="(isRefreshing) ? 'fa-spin' : ''")
            span
              u
                | R
              | efresh
      .section.pt-0
        lessons-list(:lessons="visitedLessons" group-by="language_to" read-only="true")
</template>


<script>
import { mapGetters } from "vuex";
import LessonsList from "@/components/LessonsListV2";
import throttle from "lodash/throttle";

export default {
  name: "VisitedLessons",
  components: {
    lessonsList: LessonsList
  },
  data() {
    return {
      isRefreshing: false,
      user: {
        id: null
      }
    };
  },
  computed: mapGetters(["visitedLessons"]),
  created() {
    this.$store.dispatch("getVisitedLessons");
  },
  methods: {
    refresh: throttle(function() {
      this.isRefreshing = true
      this.$store.dispatch("getVisitedLessons").then(() => {
        this.isRefreshing = false
      });
    }, 500)
  }
};
</script>


<style lang="scss" scoped>
</style>
