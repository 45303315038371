<template lang="pug">
  section#testimonials.section.section--testimonials.has-background-primary.px-0
    .head
      p.has-text-dark.text-main.is-size-6.mb-0.opacity-8 SEE WHAT PEOPLE SAY
      h2.size-h2.has-text-dark.mb-6 Why people like us

    .body

      .custom-carousel.pt-6
        .testimonials(
          :style="`transform: translateX(-${currentSlide*40+currentSlide}vw)`"
        )
          .testimonial.column.py-6.px-4.is-clickable(
            v-for="(testimonial, idx) in carousels" :class="[(idx == 1) ? '' : '']"
            @click="currentSlide = testimonial.slide"
            )
            p.stars.has-text-primary.mb-3
              b-icon(icon="star")
              b-icon(icon="star")
              b-icon(icon="star")
              b-icon(icon="star")
              b-icon(:icon="testimonial.score > 4.5 ? 'star' : 'star-border'")
            h3.size-subtitle.has-text-primary
              span.comment(v-if="testimonial.comment.length <= 90") {{testimonial.comment}}
              span.comment(v-else :title="testimonial.comment") {{testimonial.comment.substring(0, 90)}}...
            p.has-text-dark.mt-3 {{ testimonial.author }}

      .custom-carousel-indicator(v-if="!isMobile")
        .indicator-item.is-clickable.mx-1(
          v-for="nb in slidesCtrl"
          @click="currentSlide = nb"
          :class="{active: currentSlide == nb}"
          )
          .indicator-style

      .container
        .has-text-right.mt-2
          a.mt-4.is-inline-block(
          href="https://www.trustpilot.com/review/teachee.io?languages=all"
          target="_blank"
          v-ga="'trustpilot'"
          )
            img(
              src="@/assets/trustpilot-1.svg"
              alt="TrustPilot"
              width="130"
            )
</template>

<script>

export default {
  name: "TestimonialsSection",
  data() {
    return {
      currentSlide: 0,
      slidesCtrl: [0, 1, 2, 3, 4, 5],
      isMobile: window.innerWidth < 700,
      carousel: 0,
      carousels: [
          {
            comment: "Think so far I like the way the phrases are written in snippets and that I can listen to them recorded by Tom. It seems way more engaging than just typing phrases in a google doc or Skype message.",
            author: "Jerry S., learning French",
            score: 5,
            slide: 5
          },
          {
            comment: "I'm an avid language learner. Big discovery for me. I like the export to Anki feature, how you can work together with your teacher, easy adding of audios, etc.",
            author: "Thomas D., teaching French",
            score: 4,
            slide: 0
          },
          {
            comment: "The functionality is incredible, it makes creating flashcards from lessons so easy",
            author: "Kat L., studying French",
            score: 4,
            slide: 1
          },
          {
            comment: "Teachee speeds up my learning progress substantially",
            author: "Egons L., learning French",
            score: 5,
            slide: 2
          },
          {
            comment: "In terms for langauge learning, the teachee software makes it extremely simple and efficient to get authentic accents/languages into Anki for study.",
            author: "Sharp M., learning French",
            score: 4.5,
            slide: 3
          },
          {
            comment: "Teachee significantly improves my learning speed.",
            author: "Boris, learning French",
            score: 4,
            slide: 4
          },
          {
            comment: "Think so far I like the way the phrases are written in snippets and that I can listen to them recorded by Tom. It seems way more engaging than just typing phrases in a google doc or Skype message.",
            author: "Jerry S., learning French",
            score: 5,
            slide: 5
          },
          {
            comment: "I'm an avid language learner. Big discovery for me. I like the export to Anki feature, how you can work together with your teacher, easy adding of audios, etc.",
            author: "Thomas D., teaching French",
            score: 4,
            slide: 0
          },
      ]
    }
  },
  created() {
    // this.isMobile = window.innerWidth < 700;
  }
}
</script>

<style lang="scss">
@import "colors";

.section--testimonials {
  .testimonial {
    background-color: #FFF;
    border: 3px solid $primaryDarker;
    .icon {
      .mdi {
        font-size: 30px;
      }
      &:not(:last-child) {
        margin-right: 3px;
      }
    }
  }

  .carousel-items {
    overflow: visible;
  }
  .carousel-arrow {
    display: none;
  }

  .columns {
    height: 275px;
    width: 140%;
    margin-left: -20%;
  }

  .custom-carousel {
    overflow: hidden;
    width: 100%;

    .testimonials {
      transition: transform .2s ease-in-out;
      width: 6000px;
      text-align: left;
      @media (max-width: 960px) {
        transform: none !important;
      }
      .testimonial {
        display: inline-block;
        vertical-align: middle;
        width: 40vw;
        margin-right: 1vw;
        @media (max-width: 960px) {
          display: block;
          margin: 0 2vw 1rem 2vw !important;
          width: 96vw;
          span.comment {
            min-height: 70px;
          }
        }
        &:nth-child(1) {
          margin-left: -10vw;
        }
      }
    }
  }

  span.comment {
    line-height: 1.2;
    min-height: 92px;
    display: inline-block;
  }

  .fade-leave-active {
    opacity: 1 !important;
    transition-duration: 0s;
    transition-timing-function: ease-in-out;
    transition-delay: 0.01s;
  }

  .fade-enter-active {
    opacity: 1 !important;
    transition-delay: 0.01s;
    transition-duration: 0s;
    transition-timing-function: ease-in-out;
  }

  .tooltip-content {
    font-weight: 400;
    font-family: 'Lexend', sans-serif;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  @media screen and (max-width: 960px) {
    .columns {
      width: 100%;
      margin-left: 0;
      display: block;
      height: auto;
      .testimonial:not(:last-child) {
        margin-bottom: .75rem;
      }
      .testimonial.mx-4 {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  .custom-carousel-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    .indicator-item {
      .indicator-style {
        background: $primary;
        border: 3px solid $primaryDarker;
        border-radius: 50%;
        width: 14px;
        height: 14px
      }
      &.active {
        .indicator-style {
          background: $primaryDarker;
          border-color: $primaryDarker;
        }
      }
    }
  }
}
</style>
