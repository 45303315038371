import uuid from 'uuid-random'
import find from "lodash/find";
import pick from "lodash/pick";
import uniqBy from "lodash/uniqBy";
import values from "lodash/values";

extractUuid = (sentence) ->
  teacherSessionUuid = sentence.uuid
  delete sentence.uuid
  teacherSessionUuid

fieldsWatched = ['picture', 'content', 'translation', 'literal_translation']

mutations =

  SET_COMPACT_MODE: (state, compactMode) ->
    state.compactMode = compactMode

  SET_NAV_COMPACT_MODE: (state, compactMode) ->
    state.navCompactMode = compactMode

  updateActivity: (state) ->
    state.lastActivity = new Date()

  purchases: (state, purchases) ->
    state.purchases = purchases

  purchase: (state, purchase) ->
    state.purchase = purchase

  pack: (state, pack) ->
    for lesson in pack.lessons
      podcast = find(lesson.attachments, (att) -> att.kind == 'podcast')
      if (podcast)
        lesson.podcast_path = podcast.path

    state.pack = pack
    unless pack.user.currency
      Vue.set(state.pack.user, 'currency', 'EUR')

  packs: (state, packs) ->
    state.packs = packs

  addPack: (state, pack) ->
    state.packs.push pack

  editPack: (state, pack) ->
    categ = state.packs.find((el) -> el.id-0 == pack.id-0)
    Vue.util.extend(categ, pack)

  deletePack: (state, pack) ->
    index = state.packs.findIndex((el) -> el.id-0 == pack.id-0)
    state.packs.splice index, 1

  # CATEGORIES

  categories: (state, categories) ->
    state.categories = categories

  addCategory: (state, category) ->
    state.categories.push category

  editCategory: (state, category) ->
    categ = state.categories.find((el) -> el.id-0 == category.id-0)
    Vue.util.extend(categ, category)

  deleteCategory: (state, category) ->
    index = state.categories.findIndex((el) -> el.id-0 == category.id-0)
    for freq in ['daily', 'weekly', 'monthly']
      for habit in state.habits[freq]
        if habit.status == category.name
          habit.status = 'uncategorized'
    state.categories.splice index, 1

  sentences: (state, sentences) -> state.lesson.sentences = sentences
  sentencesOptions: (state, sentences) -> state.sentencesOptions = sentences
  addSentence: (state, sentence) ->
    if !sentence.tags
      sentence.tags = []

    if sentence.addAfter
      state.lesson.sentences.splice(sentence.index, 0, sentence)
    else
      if state.lesson.sentences
        state.lesson.sentences.push sentence
      else
        Vue.set(state.lesson, 'sentences', [sentence])

  editSentence: (state, sentence) ->
    sent = state.lesson.sentences.find((el) -> el.id-0 == sentence.id-0)
    if !sent
      sent = state.lesson.sentences[sentence.index]
    if sent
      audio = sent.audio || sentence.audio
#      sent.lastChange = sentence
      Vue.set(state.lesson.sentences, sentence.index, Object.assign({}, sent, sentence, {audio: audio}))
    else
      state.lesson.sentences.push sentence

  editSentenceWords: (state, sentence) ->
    sent = state.lesson.sentences.find((el) -> el.id-0 == sentence.id-0)
    sent ||= state.lesson.sentences[sentence.index]
    if sent
      Vue.set(state.lesson.sentences[sentence.index], 'words', sentence.words)

  editSentenceAndForceRefresh: (state, sentence) ->
#    console.log({SentenceInEditSentenceAndForceRefresh: sentence})
    sent = state.lesson.sentences.find((el) -> el.id == sentence.id)
    idx = state.lesson.sentences.findIndex((el) -> el.id == sentence.id)
    if (idx == -1)
      idx = sentence.index
    sent = sent || state.lesson.sentences[sentence.index]
    if sent
      updated = Object.assign({}, sent, sentence)
#      console.log({updated: updated})
      Vue.set(state.lesson.sentences, idx, Object.assign(updated, {lastChange: updated}))
      state.lesson.sentences[idx].version = values(pick(sentence, fieldsWatched)).join().hashCode()
    else
      state.lesson.sentences.push sentence

  deleteSentence: (state, sentence) ->
    index = sentence.index || state.lesson.sentences.findIndex((el) -> el.id-0 == sentence.id-0)
    state.lesson.sentences.splice index, 1



# STUDENTS

  students: (state, students) ->
    state.students = students
    state.students.sort((studentA, studentB) -> (studentA.first_name+'').localeCompare(studentB.first_name+''))

  student: (state, student) ->
    state.student = Object.assign({}, student)

  # LESSONS

  lesson: (state, lesson) ->
    state.lesson = Object.assign({}, lesson)
    Vue.set(state.lesson, 'file', null)
    if !lesson.sentences || !lesson.sentences.length
      Vue.set(state.lesson, 'sentences', [])
#    else
#      for sentence, i in lesson.sentences
#        console.log({sentence: sentence})
#        Vue.set(state.lesson.sentences[i], Object.assign({}, sentence, {version: Date.now(), lastChange: sentence}))
#    else
#      state.lesson.sentences = state.lesson.sentences.sort((sentA, sentB) -> sentA.index - sentB.index)

  allLessons: (state, lessons) ->
    state.allLessons = lessons

  usedLanguages: (state, lessons) ->
    languages = {}
    for less in lessons
      languages[less.language_to] = true

    state.usedLanguages = Object.keys(languages)


  lessons: (state, lessons) ->
    state.lessons = lessons
    state.lessons.forEach((less) ->
      unless less.sentences
        less.sentences = []
    )
    state.lessons.sort((lessonA, lessonB) -> lessonB.id - lessonA.id)

  activities: (state, activities) -> state.activities = activities

  visitedLessons: (state, lessons) -> state.visitedLessons = lessons

  addLessonToLastLessons: (state, lesson) ->
    state.lastLessons.unshift(pick(lesson, 'uuid', 'title', 'icon', 'id'))
    state.lastLessons = uniqBy(state.lastLessons, 'id').slice(0, 5)

  addLesson: (state, lesson) ->
    state.lessons.push lesson
    state.lesson = lesson
    Vue.set(state.lesson, 'sentences', [])
#    console.log('LESSON USED TO BE', state.lesson)

  editLesson: (state, lesson) ->
#    console.log('WE WANT TO PUT', lesson)
    state.lesson = Object.assign({}, state.lesson, lesson)
#    Vue.set(state.lesson, 'sentences', lesson.sentences)
#    console.log('LESSON IS NOW', state.lesson)
    if lesson.id
      index = state.lessons.findIndex((el) -> el.id-0 == lesson.id-0)
      index ||= state.lessons.findIndex((el) -> !el.id)
      if index
        Vue.set(state.lessons, index, state.lesson)


  eraseLastLessons: (state) ->
    state.lastLessons = []

  deleteLesson: (state, lesson) ->
    index = state.lessons.findIndex((el) -> el.id-0 == lesson.id-0)
    state.lessons.splice index, 1

    state.lastLessons = state.lastLessons.filter((less) -> less.id != lesson.id)
    state.lesson = {}

  SOCKET_CONNECTED: (state) ->
    state.isConnected = true

  SOCKET_DISCONNECT: (state) ->
    state.isConnected = false

  SOCKET_SENTENCE_DELETED: (state, sentence) ->
    teacherSessionUuid = extractUuid(sentence)

    if teacherSessionUuid != state.uuid && sentence.lesson_uuid == state.lesson.uuid
      index = state.lesson.sentences.findIndex((el) -> el.id-0 == sentence.id-0)
      if index != 0 && !index
        index = sentence.index
      state.lesson.sentences.splice index, 1

  SOCKET_SENTENCE_ADDED: (state, sentence) ->
    teacherSessionUuid = extractUuid(sentence)

    if teacherSessionUuid != state.uuid && sentence.lesson_uuid == state.lesson.uuid
      state.lesson.sentences.push sentence

  SOCKET_SENTENCE_EDITED: (state, sentence) ->
    teacherSessionUuid = extractUuid(sentence)

    unless state.lesson.sentences
      state.lesson.sentences = []
    sent = state.lesson.sentences.find((el) -> el.id-0 == sentence.id-0)
    sent ||= state.lesson.sentences[sentence.index]

    # APPLY CHANGES
    if teacherSessionUuid != state.uuid && sentence.lesson_uuid == state.lesson.uuid
      if sent
        if sentence.audio && sentence.audio.length
          sent.audio = sentence.audio
        if typeof(sentence.audio) == "object"
          delete sentence.audio
        sent.lastChange = sentence
        sent.version = Date.now()
        Vue.set(state.lesson.sentences, sentence.index, Object.assign({}, sent, sentence))
      else
        state.lesson.sentences.push sentence
    else if sentence.ipa && sentence.ipa.length
      # APPLY IPA & WORDS
      if sent
        sent.lastChange = {
          ipa: sentence.ipa,
          words: sentence.words
        }
        sent.version = Date.now()

  # LAYOUT

  layout: (state, layout) ->
    state.layout = layout

  layoutBackground: (state, str) ->
    state.layoutBackground = str

  # BOOKING

  defaultHours: (state, defaultHours) ->
    state.defaultHours = defaultHours

  # UUID

  uuid: (state, id) -> state.uuid = state.uuid || id

  # USER

  user: (state, user) ->
    state.user = user

  usersById: (state, users) ->
    state.usersById = users

  storageStats: (state, storageStats) ->
    state.storageStats = storageStats

  SET_SHOW_SHORT_IPA_VERSION: (state, value) ->
    state.showShortIpaVersion = value

# AUDIO

  addAudio: (state, data) ->
    audio = data.audio
    idx = state.lesson.sentences.findIndex((el) -> el.id-0 == audio.model_id-0)
    sent = state.lesson.sentences.find((el) -> el.id-0 == audio.model_id-0)

    state.lastAudio = audio
    Vue.set(state.lastAudio, 'index', idx)
    if !sent.audio
      sent.audio = []

    lastAudioIdx = sent.audio.findIndex((aud) -> aud.user_id == state.user.id)
    if (lastAudioIdx == -1)
      audioArr = sent.audio.concat([audio])
      Vue.set(sent, 'audio', audioArr)
    else
      Vue.set(sent.audio, lastAudioIdx, audio)
      Vue.set(sent.audio[lastAudioIdx], 'path', audio.path)

    Vue.set(state.lesson.sentences, idx, Object.assign({}, sent))

  removeAudio: (state, audio) ->
    idx = state.lesson.sentences.findIndex((el) -> el.id-0 == audio.model_id-0)
    sent = state.lesson.sentences.find((el) -> el.id-0 == audio.model_id-0)

    audioArr = sent.audio.filter((aud) -> aud.id != audio.id)

    Vue.set(sent, 'audio', audioArr)
    Vue.set(state.lesson.sentences, idx, Object.assign({}, sent))

  editVoice: (state, voice) ->
    Vue.set(state.voices, voice.lang, voice.name)

  SET_VOICE_GENDER: (state, gender) ->
    state.voiceGender = gender

`export default mutations`

