<template lang="pug">
  .is-loading.is-fullheight.has-loader.primary-loader.app-container.w-100.is-size-1
</template>

<script>

import {Lesson} from "@/store/models/resources";

export default {
  name: "DuplicateLesson",
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
    this.duplicate();
  },
  methods: {
    onError() {
      this.$toast.error("An error occurred. Please email <a class='has-text-white has-text-underlined' href='mailto:guillaume@teachee.io?subject=Problem%20on%20Teachee&body=Issue%20with:%20" + window.location.href + "'>guillaume@teachee.io</a> if the problem persists.", {duration: 10000});
      this.$router.push({name: 'Home'})
    },

    duplicate() {
      const {id} = this.$route.params

      try {
        Lesson.duplicate({id}, {
          with_media: this.$route.query.with_media,
          sentence_ids: this.$route.query.sentence_ids.split(",")
        }).then((res) => {
          console.log({res})
          this.$router.push({
            name: 'LiveLesson',
            params: {id: res.body.lesson.uuid}
          })
        }).catch(err => {
          throw err;
        })
      } catch (err) {
        this.onError()
      }
    }
  }
};
</script>
<style lang="scss">
@import "colors";
</style>
