
<template lang="pug">
  .nps-box.is-hidden-mobile(:class="{ active, 'is-in-page': inPage, 'card ml-4': !inPage, 'is-hidden': !isShown }")
    header.card-header( v-if="!inPage")
      p.card-header-title(@click.prevent="active = !active")
        | We have 4 questions for you!
      a.card-header-icon.has-text-dark(href="#" aria-label="more options" @click.prevent="active = !active")
        b-icon(:icon="active ? 'close' : 'edit'")
    div(:class="{'card-content': !inPage }")
      div(v-if="!thanks")
        .content.has-text-left
          | Your feedback is precious to us. If you could take 3 min to answer those questions, that would be great :)
          br

          span.label.mt-4.is-size-6 How likely would you be to recommend Teachee to a friend or a fellow teacher?
          b-field.my-4.has-flex-center-items
            span.hint.mr-3.has-text-right.nps-label.is-size-7-desktop Not likely
            b-radio-button(
              v-for='(val, i) in npsOptions'
              :key="i"
              type="is-success"
              :size="!inPage ? 'is-small' : ''"
              v-model="nps.score"
              :native-value="val")
              | {{val}}
            span.hint.ml-3.has-text-left.nps-label.is-size-7-desktop Extremely likely

          b-field.mt-4(label="What is the primary reason for your score?" custom-class="is-size-6")
            b-input(type="textarea" v-model="nps.answers.why")

          .columns
            .column
              b-field(label="What annoys you in the product?" custom-class="is-size-6")
                b-input(type="textarea" v-model="nps.answers.hate")

            .column
              b-field(label="Any feature you'd like to see in it?" custom-class="is-size-6")
                b-input(type="textarea" v-model="nps.answers.feature")

          b-field(label="Comment" custom-class="is-size-7")
            b-input(type="textarea" v-model="nps.answers.comment")

        button.button.is-primary.is-fullwidth.mt-5(
          href="#"
          @click.prevent="sendNps"
          :disabled="!nps.score"
          :class="{'is-disabled': !nps.score, 'is-loading': loading, 'is-success': success}")
          b-icon(v-if="success" icon="check")
          span.is-flex(v-else="")
            b-icon.mr-2(icon="send")
            span Send

      div.thanks-message(v-if="thanks")
        .content.has-text-left.is-size-5-fullhd.is-size-6(v-if="nps.score >= 8")
          blockquote.mb-3
            | {{ nps.answers.why }}

          div.w-100.has-text-centered.mb-4
            img.m-auto(
              src="@/assets/extra/fireworks.png"
              width="128"
              height="128"
            )
          | Great! We're happy to see that you like Teachee :).
          br
          br
          | If that's not too much too ask, <b>could you please leave us a nice word on
          |  our <a target="_blank" href="https://fr.trustpilot.com/evaluate/teachee.io">Trust Pilot page</a>?</b> (<a target="_blank" href="https://fr.trustpilot.com/evaluate/teachee.io">https://fr.trustpilot.com/evaluate/teachee.io</a>)
          br
          br
          | Ratings help us tremendously. They help us bring more users and the more users, the more budget we have to make the app better.
          br
          br
          | Thanks!

        .content.has-text-left.is-size-5(v-if="nps.score >= 6 && nps.score < 8")
          div.w-100.has-text-centered.mb-3.has-text-success.is-flex.has-flex-center-items
            b-icon.has-text-success(icon="check-circle" size="is-large")
            span
              | Thank you for you answer!

        .content.has-text-left.is-size-5(v-if="nps.score < 6")
          div.w-100.has-text-centered.mb-3.has-text-info.is-flex.has-flex-start-items
            b-icon.has-text-info(icon="check-circle" size="is-large")
            span.pt-2.has-text-left
              | Thank you for you answer. We'll continue our best to deliver better quality and hopefully please you at some point.

</template>

<script>
  import { Nps } from "@/store/models/resources";

  export default {
    name: 'NpsBox',
    props: [
      'inPage'
    ],
    computed: {
      isShown() {
        // console.log({route: this.$route})
        if ((this.$route.path == '/nps' || this.$route.path == '/') && !this.inPage) {
          return false;
        } else {
          return true;
        }
      }
    },
    data () {
      return {
        loading: false,
        success: false,
        active: false,
        thanks: false,
        npsOptions: [0,1,2,3,4,5,6,7,8,9,10],
        nps: {
          score: null,
          answers: {
            why: null,
            hate: null,
            feature: null,
            comment: null,
          }
        },
      }
    },
    mounted() {
      // console.log({route: this.$route})
      if (this.$route.query && this.$route.query.score) {
        this.nps.score = parseInt(this.$route.query.score);
        Nps.save({ nps: Object.assign({silent: true}, this.nps)});
      }
    },
    methods: {
      sendNps() {
        this.loading = true
        Nps.save({ nps: this.nps}).then( (res) => {
            this.success = true;
            this.loading = false;
            setTimeout(() => {
              this.thanks = true
            }, 2000);
          }
          , (_res) => {
            this.$toast.open({
              duration: 12000,
              message: 'Something went wrong. Please try later or send an email at guillaume@teachee.io',
              type: "error"
            });
          })
      },
    }
  }
</script>

<style lang="scss">
  .nps-box .textarea {
    height: 90px;
    min-height: 90px;
    font-size: 1rem;
  }
  body .nps-box {
    z-index: 100;
  }

  @media (max-height: 801px) {
    body .nps-box {
      .textarea {
      height: 80px !important;
      min-height: 80px !important;
      }
      .mt-4 {
        margin-top: 0.8rem !important;
      }
      &.active {
        height: 750px !important;
      }
      .b-radio.radio {
        font-size: 0.75rem;
      }
      span.hint {
        font-size: 0.7rem;
        &.ml-3 {
          margin-left: 0.5rem !important;
        }
        &.mr-3 {
          margin-right: 0.5rem !important;
        }
      }
    }
  }

  @media (max-height: 668px) {
    body .nps-box {
      transform: scale(0.8);
      transform-origin: bottom;
      .active {
        height: 770px !important;
      }
    }
  }
</style>
