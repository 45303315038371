<template>
  <div class="app-container">
    <front-menu/>
    <main class="app-content">
      <section class="hero is-success is-fullheight">
        <div class="hero-body">
          <!--<transition-->
            <!--name="fade"-->
            <!--mode="out-in">-->
            <router-view :key="$route.fullPath"/>
          <!--</transition>-->
        </div>
      </section>
    </main>
  </div>
</template>

<script>
  export default {
    name: "FrontLayout"
  };
</script>

<style>
  .fade-leave-active {
    transition-duration: 0.1s;
  }

  .fade-enter-active {
    transition-duration: 0.1s;
  }
</style>
