<template lang="pug">
  .container
    .box.has-shadow
      .content.has-text-left
        .section
          h2 Mac OS Service
          p
            | If you use a Mac, you can download all the audio from a lesson by clicking the "Download all audios" button.
            |  From there you unzip the archive and right click on the folder. In the right-click menu you will have a new item: <b>TT - Generate Podcast</b>
          p
            | A podcast will be generated for you if you click on it.

        .section
          h3 How to install the menu item
          p
            | 1. Launch the Terminal:
            ul
              li
                | click the Spotlight icon
                b-icon(icon="search")
                |  (at the top right corner of the screen)
              li enter "Terminal"
              li press ENTER
            | 2. Paste and run the installation script:
            ul.mb-2
              li
                | Copy/paste in the Terminal
                article.message.is-info.my-2
                  .message-body
                    | curl -fsS https://teachee.io/tools/install_generate_podcast.sh | bash
              li press ENTER
            | 3. Now you have the new item in the right-click menu!

        .section
          h3 How to use
          ol
            li
              | At the end of the lesson, click the button:
              br
              img.mt-2(src="@/assets/explanations/001_mac_service.png" width="283")
            li.mt-4
              | Unzip the archive:
              br
              img.mt-2(src="@/assets/explanations/002_mac_service.png" width="255")
            li.mt-4
              | Right-click on the folder to generate the podcast:
              br
              img.mt-2(src="@/assets/explanations/003_mac_service.png" width="385")
            li.mt-4
              | You have your podcast :)
              br
              img.mt-2(src="@/assets/explanations/004_mac_service.png" width="233")


</template>

<script>

export default {
  name: "ToolsPage",
  components: {
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {}
};
</script>


<style lang="scss" scoped>

</style>
