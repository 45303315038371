<template lang="pug">
  .container.admin-all-sentences
    .box.has-shadow
      .columns
        .column
          h3.title.has-text-left.is-flex
            span Sentences
            .ml-auto.buttons.has-addons
              button.button.is-small(@click="language = lg" v-for="lg in languages")
                i.flag(:class="'flag-'+(lg)")

      b-field.mb-4
        b-input(
          placeholder='Search...'
          v-model="search"
          type='search'
          icon='search')

      b-table.w-100(
        :striped="true"
        default-sort="created_at"
        default-sort-direction="desc"
        :paginated="sentences.length > 200"
        :per-page="200"
        :pagination-simple="true"
        :loading="loading"
        :data="filteredSentences"
        :narrowed="true"
      )
        b-table-column(v-slot="props" field="content" label="Content" sortable)
          .is-flex
            audio-player.is-small.is-dark.is-inline-block.valign.mr-1.is-rounded(
              :class="{'is-primary': checkedRows.indexOf(props.row) !== -1}"
              v-if="props.row.audio && props.row.audio.path"
              :audio="props.row.audio")
            p.m-0.is-inline-block.valign.has-flex-grow-1
              b
                | {{ props.row.content }}
              em.is-size-6.is-pulled-right
                | {{ props.row.translation }}
        b-table-column(v-slot="props" field="score" label="Difficulty" sortable numeric)
          | {{ props.row.score }}
        b-table-column(v-slot="props" field="lesson.user_id" label="User ID" sortable numeric)
          | {{ props.row.lesson.user_id }}
        b-table-column(v-slot="props" label="Context" width="130")
          router-link.is-size-7(:to="{name: 'LiveLesson', params: {id: lessons[props.row.lesson_id].uuid}}")
            | {{ lessons[props.row.lesson_id].title }}
        b-table-column(v-slot="props" field="created_at" label="Created" width="130" sortable)
          | {{ props.row.created_at | moment("from", "now") }}
</template>


<script>
  import {mapGetters} from 'vuex'
  import { Sentence } from "@/store/models/resources";
  import _keyBy from 'lodash/keyBy'
  import _uniq from 'lodash/uniq'
  import AudioPlayer from "./AudioPlayer";

  export default {
    name: 'AdminAllSentencesPage',
    components: {
      AudioPlayer
    },
    data() {
      return {
        activeId: null,
        loading: true,
        sentences: [],
        search: '',
        language: '',
        languages: [],
        lessons: {},
        checkedRows: []
      }
    },
    computed: {
      ...mapGetters(['user']),
      prefilteredSentences() {
        return this.sentences.filter((sent) => (this.lessons[sent.lesson_id] || {}).language_to == this.language)
      },
      filteredSentences() {
        if (!this.search.length) {
          return this.prefilteredSentences
        } else {
          return this.prefilteredSentences.filter((sent) => sent.content.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
        }
      }
    },
    created() {

      Sentence.allForAdmin().then(res => {
        this.sentences = res.body.sentences;
        for (let sentence of this.sentences) {
          if (sentence.reviewed_at) {
            this.checkedRows.push(sentence)
          }
        }
        this.lessons = _keyBy(res.body.lessons, 'id')
        this.languages = _uniq(res.body.lessons.map(less => less.language_to))
        this.language = this.languages[0]
        this.loading = false
      }, err => {
        this.$toast.open({
          duration: 5000,
          message: err.body.message,
          type: "error"
        });
        this.loading = false
        setTimeout(() => {
          this.$router.push({ name: "Home" });
        }, 5000)
      });

      this.$mixpanel.track("PageView - All sentences");
    },
    methods: {

    }
  }
</script>


<style lang="scss">
  @import 'colors';

  .admin-all-sentences {
    i[class*="flag"] {
      width: 20px;
      height: 20px;
    }

    .box {
      min-height: 300px;
    }

    .play-button {
      width: 2rem !important;
      transform: scale(.7);
      transition: none !important;
      .icon {
        margin-left: -7px;
      }
      .icon+span {
        display: none;
      }
    }

    b.reviewed {
      color: $primary;
      text-decoration: line-through;
    }
    b.padding {
      padding-left: 2.25rem;
    }

    th.checkbox-cell {
      visibility: hidden;
    }

  }


</style>
