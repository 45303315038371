`import {Lesson, VisitedLesson, Activity} from '../models/resources'`
`import { nodeApi } from "@/config";`

actions =
  setLesson: (context, lesson) ->
    context.commit('lesson', lesson)

  batchDestroyLessons: (context, lessonUuids) ->
    new Promise((resolve, reject) ->
      Lesson.batchDestroy({}, {uuids: lessonUuids}).then (response) ->
        resolve()
    )

  allLessons: (context, lesson) ->
    new Promise((resolve, reject) ->
      Lesson.all().then (response) ->
        context.commit('allLessons', response.body)
        context.commit('usedLanguages', response.body)
        resolve(response.body)
      , (err) ->
        reject(err)
    )

  findLesson: (context, lesson) ->
    new Promise((resolve, reject) ->
      Lesson.get({id: lesson.id}).then (response) ->
        if context.state.lesson.uuid != lesson.id
          context.commit('lesson', Object.assign({}, response.body, {sentences: []}))
        else
          context.commit('lesson', Object.assign({}, response.body))

        if response.body.sentences
          for sentence in response.body.sentences
            context.commit('editSentenceAndForceRefresh', sentence)
        resolve(response.body)
      , (err) ->
        reject(err)
    )

  queuePodcast: (context, lesson) ->
    new Promise((resolve, reject) ->
      Lesson.queuePodcast({id: lesson.uuid}, {}).then (response) ->
        resolve()
      , (err) ->
        reject()
    )

  updateSentencesOrder: (context, params) ->
    new Promise((resolve, reject) ->
      Lesson.updateSentencesOrder({id: params.id}, params).then (response) ->
        resolve()
      , (err) ->
        reject()
    )

  unlockLesson: (context, lesson)->
    new Promise((resolve, reject) ->
      Lesson.unlock(lesson, {}).then (response) ->
        context.commit('editLesson', {paid: true})
        resolve(response.body)
      , (err) ->
        reject(err)
    )

  lastLesson: (context) ->
    new Promise((resolve, reject) ->
      Lesson.last().then (response) ->
        resolve(response.body)
      , (err) ->
        reject(err)
    )

  getActivities: (context) ->
    new Promise((resolve, reject) ->
      Activity.query().then (response) ->
        context.commit('activities', response.body.activities)
        resolve()
    )


  getLessons: (context, user) ->
    Lesson.query().then (response) ->
      context.commit('lessons', response.body.lessons)

  getVisitedLessons: (context) ->
    new Promise((resolve, reject) ->
      VisitedLesson.query().then (response) ->
        context.commit('visitedLessons', response.body.lessons)
        resolve(response.body)
      , (err) ->
        reject(err)
    )

  addLesson: (context, lesson) ->
    new Promise((resolve, reject) ->
      context.commit('addLesson', lesson)
      resolve()
    )

  editLesson: (context, lesson) ->
    new Promise (resolve, reject) ->
      context.commit('editLesson', lesson)
      resolve()

  deleteLesson: (context, lesson) ->
    new Promise (resolve, reject) ->
      Lesson.delete({id: lesson.uuid}).then (res) ->
        context.commit('deleteLesson', lesson)
        resolve()

  addPictureToLesson:  (context, lessonAndFile) ->
    formData = new FormData();
    formData.append('file', lessonAndFile.file);

    new Promise((resolve, reject) ->
      Vue.http.put(nodeApi+"/lessons/"+lessonAndFile.lesson.id+"?uuid="+lessonAndFile.lesson.uuid, formData).then((res) ->
        context.commit('editLesson', Object.assign({}, lessonAndFile.lesson, res.body))
        resolve(res.body)
      , (err) ->
        reject()
      )
    )

#  saveLesson: (context, lesson) ->
#    new Promise((resolve, reject) ->
#      console.log({lesson})
#      console.log(!!lesson.id)
#      if lesson.id
#        console.log("HEY")
#        console.log("calling", nodeApi+"/lessons/"+lesson.id)
#
#        Vue.http.put(nodeApi+"/lessons/"+lesson.id, {lesson: lesson}).then(
#          (res) ->
#            #context.commit('editLesson', lesson)
#            resolve(res.body)
#        , reject
#        )
##        upd = Lesson.update({id: lesson.id}, {lesson: lesson})
##        console.log("HO")
##        console.log(upd)
##        Lesson.update({id: lesson.id}, {lesson: lesson}).then(
##          (res) ->
##            resolve(res.body)
##        , reject
##        )
#      else
#        Lesson.save(lesson: lesson).then(
#          (res) ->
#            context.commit('editLesson', Object.assign({}, lesson, res.body))
#            resolve(res.body)
#        , (res) ->
#          reject(res.body)
#        )
#    )

`export default actions`
