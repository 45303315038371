<template lang="pug">
  .box.has-shadow.box--contacts(
    v-shortkey="['arrowright']"
    @shortkey="nextPage()"
    )
    h3.title.has-text-left.mb-3.is-flex
      | Contacts

    // EXPLANATIONS
    article.message.is-light(v-if="usersInteractedWith.length === 0")
      .message-body
        | Once you share your first lesson, people will appear on this page.

    div(
        v-else
        v-shortkey="['arrowleft']"
        @shortkey="previousPage()"
      )
      b-table.w-100.with-search(
        v-if="usersInteractedWith.length !== 0"
        default-sort="last_update"
        default-sort-direction="desc"
        :paginated="usersInteractedWith.length > 4"
        :per-page="4"
        :pagination-simple="true"
        :loading="loading"
        :data="usersInteractedWith"
        )
        //- First name
        b-table-column(v-slot="props" field="first_name" label="Name" sortable searchable)
          router-link.is-smooth.has-text-grey-dark.has-text-weight-bold.is-student-link(
            v-if="props.row.is_teacher"
            :to="`/teachers/${props.row.id}?tab=lessons`"
          )
            teacher(:teacher="props.row" :index="props.index")
          router-link.is-smooth.has-text-grey-dark.has-text-weight-bold.is-student-link(
            v-else
            :to="`/students/${props.row.id}`"
          )
            student(:student="props.row" :index="props.index")
        //- Last lesson on
        b-table-column(v-slot="props" field="last_update" label="" width="150" sortable)
          | {{ props.row.last_update | moment("DD.MM") }}
        //- Actions
        b-table-column(v-slot="props" label="" width="150")
          router-link.button.is-info.is-outlined.is-small(v-if="!props.row.is_teacher" :to="{name: 'allForStudent', params: {student_id: props.row.id}}")
            b-icon(icon="remove-red-eye" size="is-small")
            span See last {{ lessonsByStudent[props.row.id].length > 3 ? '3' : lessonsByStudent[props.row.id].length }} lesson{{ lessonsByStudent[props.row.id].length > 1 ? 's' : '' }}
  </template>


<script>
  import LessonsList from '@/components/LessonsList'
  import StudentMiniCardUI from '@/components/StudentMiniCardUI'
  import TeacherMiniCardUI from '@/components/TeacherMiniCardUI'
  import {mapGetters} from 'vuex'
  import sortBy from 'lodash/sortBy'

  export default {
    name: 'PeopleBox',
    components: {
      lessonsList: LessonsList,
      student: StudentMiniCardUI,
      teacher: TeacherMiniCardUI
    },
    data() {
      return {
        activeId: null,
        loading: false
      }
    },
    computed: {
      ...mapGetters(['lessons', 'usersById', 'apiUrl', 'user']),
      usersInteractedWith() {
        return Object.values(this.usersById);
      },
      lessonsByStudent() {
        var lessons = {}
        let i = 0;
        const iMax = this.usersInteractedWith.length;
        for(; i < iMax; i++) {
          const student = this.usersInteractedWith[i]
          if (!student.lesson_ids)
            continue
          lessons[student.id] = sortBy(this.lessons.filter(function(lesson) {
            return (student.lesson_ids.indexOf(lesson.id+'') !== -1) || lesson.student_id === student.id
          }), ['created_at']).reverse()
          // if (lessons[student.id][0]) {
          //   student.last_update = lessons[student.id][0].created_at
          // }
        }
        return lessons;
      }
    },
    created() {
      if (!this.usersInteractedWith.length) {
        this.loading = true
      }
      this.$store.dispatch('queryUsers').then(res => {
        this.loading = false;
      });
    },
    methods: {
      previousPage() {
        document.querySelector('.box--contacts .pagination-previous').click()
      },
      nextPage() {
        document.querySelector('.box--contacts .pagination-next').click()
      },
      addLesson(lang) {
        this.$store.dispatch('addLesson', {language_to: lang || 'en-US'}).then(() => {
          setTimeout(function () {
            document.querySelector('.lesson__title input').focus()
          }, 400)
        })
      },

      studentLessons(student) {
        return this.lessons.filter((lesson) => (student.lesson_ids.indexOf(lesson.id+'') !== -1) || lesson.student_id === student.id)
      }
    }
  }
</script>


<style lang="scss">
  @import 'colors';

  $figureSize: 36px;
  $imgSize: 32px;


  .box--contacts {
    .is-student-link:hover {
      color: $primary !important;
    }

    .b-table.with-search table th:first-child .control {
      top: 13px;
      input {
        min-width: 155px;
      }
    }


    .tabs a {
      font-weight: 600;
    }

    .table td, .table th {
      padding: 0.9em 0.75em;
      vertical-align: middle;
    }

  }

</style>
