<template lang="pug">
  .container.new-smart-lesson-page
    .box.has-text-left

      h2.title.is-flex Lesson Builder

      article.message.is-light
        .message-body
          ol
            li Click on a flag to load your sentences for this language.
            li Pick the sentence you want to add to the new public lesson
            li Save it

      <!--div.has-loader.is-primary.is-outlined.is-loading.has-margin-auto.primary-loader(v-if="querying")-->
      .columns(
        v-shortkey="['arrowright']"
        @shortkey="nextPage()"
      )
        #pick_sentences.column.is-8(
          v-shortkey="['arrowleft']"
          @shortkey="previousPage()"
        )
          h3.title.is-size-4.is-flex
            span(
              :class="{'is-disabled': !sentences.length}"
            ) Sentences
            .ml-auto.buttons.has-addons
              button.button.is-small(@click="queryForLanguage(language)" v-for="language in languages")
                i.flag(:class="'flag-'+(language)")

          b-table.has-nice-th.search-near-th(
            :class="{'has-search': sentences.length, 'is-disabled': !sentences.length}"
            backend-pagination
            backend-sorting

            @page-change="onPageChange"
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
            checkable
            striped
            :loading="querying"
            :paginated="true"
            :per-page="50"
            :narrowed="false"
            :columns="columns"
            :checked-rows.sync="checkedRows"
            :data="sentences"
            :total="total"
            )
            template(slot-scope="props" slot="header")
              b-tooltip(:active="!!props.column.meta" :label="props.column.meta" dashed)
                | {{ props.column.label }}

        .column.is-4.has-shadow(
          v-if="sentences.length"
          )

          h3.title.is-size-4 New public lesson
          <!--.content-->
            <!--ul.ul-->
              <!--li(v-for="sentence in checkedRows" :key="sentence.id")-->
                <!--| {{ sentence.content }}-->
              <!---->
          b-table.has-nice-th(
              striped
              :loading="querying"
              :paginated="true"
              :per-page="50"
              :pagination-simple="true"
              :narrowed="false"
              :columns="columnsLight"
              :empty="!checkedRows.length"
              :data="checkedRows"
              )
            template(slot="footer")
              .has-text-right
                | {{checkedRows.length}} sentence{{checkedRows.length > 1 ? 's' : ''}}


          b-field.mt-2
            b-input.is-wide(
            v-model="title"
            placeholder="Title"
            @keyup.enter.native="createLesson"
            )
            p.control
              button.button.is-primary(
                :class="{'is-loading': creating}"
                :disabled="!checkedRows.length || !title.length || creating"
                @click="createLesson"
                ) Save




</template>


<script>
import { mapGetters } from "vuex";
import includes from "lodash/includes";
import { User, Sentence, Lesson } from "@/store/models/resources";
import userCard from "@/components/UserCardUI";
import userPicture from "@/components/UserPictureUI";
import uniqBy from "lodash/uniqBy";

export default {
  name: "NewSmartLessonPage",
  components: {
    userCard,
    userPicture
  },
  data() {
    return {
      creating: false,
      querying: false,
      checkedRows: [],
      columns: [
        {
          field: 'content',
          label: 'Sentence',
          sortable: true,
          searchable: true
        },
        {
          field: 'translation',
          label: 'Translation',
          sortable: true,
        },
        {
          field: 'score',
          label: 'Score',
          meta: 'Difficulty score',
          numeric: true,
          // sortable: true,
        },
      ],
      columnsLight: [
        {
          field: 'content',
          label: 'Sentence',
          sortable: true,
        },
      ],
      sentences: [],
      picked: {},
      title: '',
      language: '',
      sortField: 'score',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      total: 0,
      page: 1
    };
  },
  computed: {
    ...mapGetters(['lessons', 'user']),
    languages() {
      return uniqBy(this.lessons, 'language_to').map(less => less.language_to)
    }
  },
  mounted() {
    // Sentences.analysis({ language }).then(response => {
    //   console.log(response.body)
    //   this.sentences = response.body.sentences;
    //   this.querying = false;
    // })
  },
  created() {
    this.$store.dispatch("getLessons", this.user);
  },
  methods: {
    onPageChange(page) {
      this.page = page
      this.queryForLanguage(this.language)
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.queryForLanguage(this.language)
    },
    previousPage() {
      document.querySelector('#pick_sentences .pagination-previous').click()
    },
    nextPage() {
      document.querySelector('#pick_sentences .pagination-next').click()
    },
    queryForLanguage(language) {
      this.querying = true;
      // this.checkedRows = [];
      this.language = language;
      Sentence.analysis({
        language,
        sortBy: `${this.sortField}.${this.sortOrder}`,
        page: this.page
      }).then(response => {
        // console.log(response.body)
        this.sentences = response.body.sentences;
        this.total = response.body.count;

        if (this.page === 1) {
          for (const idx in this.sentences) {
            // console.log(idx);
            if (idx == 25) {
              break;
            }
            this.checkedRows.push(this.sentences[idx]);
            // this.$set(this.picked, sentence.id, false);
          }
          }
        this.querying = false;
      })
    },
    createLesson() {
      if (this.checkedRows.length) {
        this.creating = true;
        const { title, language } = this;
        Lesson.createPublic({title, language, sentence_ids: this.checkedRows.map(sentence => sentence.id)}).then(res => {
          this.creating = false;
          this.$store.commit('lesson', {});
          this.$router.push(`/lessons/${res.body.lesson.uuid}`);
        })
      }
    }
  }
};
</script>


<style lang="scss">
@import "colors";

.new-smart-lesson-page {
  i[class*="flag"] {
    width: 20px;
    height: 20px;
  }

  .b-table.search-near-th {
    table {
      position: relative;
      tr:nth-child(2) {
        th { height: 0px; padding: 0; border-width: 1px}
      }
      th:nth-child(2) .control {
        position: absolute;
        left: 142px;
        top: 7px;
        input {
          padding-left: 1.3rem;
          min-width: 200px;
          height: 23px;
          font-size: 10px;
        }
        &:before {
          position: absolute;
          top: 7px;
          left: 7px;
          z-index: 2;
          content: "search";
          font-family: "Material Icons";
          font-weight: normal;
          font-style: normal;
          font-size: 12px;
          display: inline-block;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
        }
      }
    }
    &:not(.has-search) th:nth-child(2) .control {
      display: none !important
    }
  }
}
</style>
