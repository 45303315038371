
<template lang="pug">
    b-dropdown.is-small-dropdown.ui--language-selector
      button#voice_selector_dropdown.button.is-white.card-header-icon.has-text-centered.p-0(slot="trigger")
        i.flag(:class="'flag-'+(language || 'en-US')" v-if="language || !showChoose")
        span.button.is-small.is-uppercase.is-size-7.has-text-weight-bold.has-text-grey(v-else)
          | Choose
      b-dropdown-item.has-flex-start-items(v-for="(lang, i) in preselected", :key="lang", @click="select(lang)", :id="'lang_'+lang")
        i.flag(:class="'flag-'+lang")
        span {{ LG_TO_TXT[lang] }}
      hr(v-if="preselected.length")
      b-dropdown-item.has-flex-start-items(v-for="(lang, i) in options", :key="lang", @click="select(lang)", :id="'lang_'+lang")
        i.flag(:class="'flag-'+lang")
        span {{ LG_TO_TXT[lang] }}
</template>

<script>
import {AVAILABLE_LANGUAGES, LG_TO_TXT} from "@/constants";
console.log({ AVAILABLE_LANGUAGES })



export default {
  name: "language-selector",
  props: [
    "language",
    "showChoose",
    "autoselect",
    "languagesOnTop"
  ],
  computed: {
    preselected() {
      if (!this.languagesOnTop || !this.languagesOnTop.length) {
        return []
      }

      return this.languagesOnTop
    },
    options() {
      if (!this.languagesOnTop || !this.languagesOnTop.length) {
        return this.availableLanguages
      }

      return this.availableLanguages.filter(lang => !this.languagesOnTop.includes(lang))
    }
  },
  data() {
    return {
      availableLanguages: AVAILABLE_LANGUAGES,
      LG_TO_TXT
    };
  },
  created() {
    if (this.autoselect && this.languagesOnTop && this.languagesOnTop.length) {
      this.$emit("select", this.languagesOnTop[0]);
    }
  },
  methods: {
    select(lang) {
      this.$emit("select", lang);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "colors";
.ui--language-selector span {
  font-variant: small-caps;
}
</style>
