<template lang="pug">
  #lessons_page.container.page--lessons.is-relative(
    v-shortkey="['arrowright']"
    @shortkey="nextPage()"
  )
    .box.has-shadow(
      v-shortkey="['arrowleft']"
      @shortkey="previousPage()"
    )
      .columns
        .column
          h3.title.has-text-left
            | Lessons

      // EXPLANATIONS
      article.message.is-light(v-if="allLessons.length === 0")
        .message-body
          | Once you share your first lesson, your students will appear on this page.
      b-table.w-100.with-search(
        v-else
        checkable
        :checked-rows.sync="checkedRows"
        narrowed
        hoverable
        sort-icon="arrow-drop-up"
        default-sort="updated_at"
        default-sort-direction="desc"
        :paginated="allLessons.length > 50"
        :per-page="10"
        :pagination-simple="true"
        :loading="loading"
        :data="lessons"
        @mouseenter="() => {}"
      )
        //- Title
        b-table-column(
          v-slot="props"
          field="title"
          label="Title"
          width="320"
          sortable
          searchable
          align="left"
        )
          router-link.is-smooth.has-text-grey-dark.has-text-weight-bold.is-block.is-lesson-link(
            :to="{name: 'LiveLesson', params:{ id: props.row.uuid } }")
            i.flag.mr-2(:class="'flag-'+props.row.language_to")
            | {{ props.row.title }}

        //- Sentences
        b-table-column(v-slot="props" field="sentences_nb" label="Sentences" sortable numeric)
          span.has-text-grey
            | {{ props.row.sentences_nb }} sentence{{ (props.row.sentences_nb > 1) ? 's' : '' }}

        //- Owner
        b-table-column(v-slot="props" field="user_id" label="Owner" sortable)
          span(v-if="user.id ==  props.row.user_id")
            | Me
          .has-size-30px(v-else-if="usersById[props.row.user_id]")
            user-picture-ui(:user="usersById[props.row.user_id]")
          //-user-picture-ui(v-if="user.id ==  props.row.user_id" :user="user")

        //- Visitors
        b-table-column(v-slot="props" field="visitors" label="Shared with" sortable)
          span(v-for="userId in props.row.visitors" :key="userId")
            span.has-size-30px(v-if="usersById[userId]")
              user-picture-ui.mr-1.is-inline-block(:user="usersById[userId]")

        //- Updated at
        b-table-column(v-slot="props" field="updated_at" label="Updated at" sortable width="130")
          | {{ props.row.updated_at | moment("DD.MM.YYYY") }}

        //- Lesson actions
        template(
          #footer
          v-if="checkedRows.length"
          )
          .is-flex.mt-2
            button.button.is-danger.is-outlined.ml-auto.mr-2(
              @click="removeLessons"
              v-confirm="'Are you sure you want to <b>delete the lessons</b>?'"
            ) Delete
            button.button.is-primary(@click="exportToAnkiModal = !exportToAnkiModal") Export to Anki

    b-modal#export_to_anki_modal(:active.sync="exportToAnkiModal" :width="400")
      batch-anki-modal-body(
        :lessonUuids="checkedRows.map(row => row.uuid)"
        @close="exportToAnkiModal = false"
      )
</template>


<script>
  import {mapGetters, mapActions} from "vuex";
  import UserPictureUi from "../UserPictureUI";
  import debounce from "lodash/debounce";
  import BatchAnkiModalBody from "../BatchAnkiModalBody";

  export default {
    name:       "LessonsPage",
    components: {BatchAnkiModalBody, UserPictureUi},
    data() {
      return {
        loading: false,
        exportToAnkiModal: false,
        checkedRows: []
      };
    },
    computed:   {
      ...mapGetters(["allLessons", "user", "usersById"]),
      lessons() {
        const {lang} = this.$route.params
        if (lang) {
          return this.allLessons.filter(less => less.language_to.includes(lang))
        } else {
          return this.allLessons
        }
      }
    },
    created() {
      // created_at: "2020-05-30T04:43:14.463Z"
      // first_name: null
      // id: "58"
      // picture: null
      // title: "Je teste"
      // updated_at: "2020-05-30T04:43:14.463Z"
      // user_id: "1"
      // uuid: "01cf8..."
      this.$store.dispatch('queryUsers')
      this.$store.dispatch('allLessons')
    },
    mounted() {
      this.$nextTick(() => {
        this.$qs('#lessons_page table input[type=text]').focus()
      })
      // setTimeout(() => {
      // }, 200)
      // setTimeout(() => {
      //   this.$qs('#lessons_page table input').focus()
      // }, 200)
    },
    methods: {
      previousPage() {
        this.$qs('#lessons_page .pagination-previous').click()
      },
      nextPage() {
        this.$qs('#lessons_page .pagination-next').click()
      },

      preload: debounce(function(lesson) {
        this.$store.dispatch("findLesson", { id: lesson.uuid })
      }, 200),

      removeLessons() {
        this.$store.dispatch('batchDestroyLessons', this.checkedRows.map(row => row.uuid)).then(() => {
          this.$toast.success("Lessons deleted successfully.");
          this.checkedRows = [];
          this.$store.dispatch('allLessons')
        })
      }
    }
  };
</script>


<style lang="scss">
  @import 'colors';

  .page--lessons {
    tr:nth-child(2) {
      th { height: 0px; padding: 0; border-width: 1px}
    }

    table {
      .is-lesson-link {
        text-align: left;
        &:hover {
          color: $primary !important;
        }
      }
    }

    [data-label="Title"] {
      td { padding: 0; }
      a { padding: 0.25em 0.5em ;
      }
    }
    i.flag {
      transform: scale(0.8);
    }
    .avatar {
      display: inline-block;
      vertical-align: middle;
    }
  }
</style>
