<template lang="pug">
  header.app-header.front-menu
    nav.navbar.pt-2
      .container
        .navbar-brand
          .navbar-item
            router-link.brand(:to="{name: 'FrontPage'}")
               img(
                 src="@/assets/v3/logo.svg"
                 alt="Teachee"
               )
          .navbar-burger.burger(
            data-target='mainNav'
            @click="showNavBar =  !showNavBar"
            :class="{'is-active': showNavBar}")
            span
            span
            span
        #mainNav.navbar-menu(:class="{'is-active': showNavBar}")
          .navbar-end
            .navbar-item
              router-link.px-2(
              :to="{name: 'ForTeachersPage'}"
              ) For teachers
            .navbar-item
              router-link.px-2(
              :to="{name: 'ForStudentsPage'}"
              ) For students
            .navbar-item
              router-link.px-2(
                :to="{name: 'PressKitPage'}"
              ) Press kit
            .navbar-item
              router-link.px-2(
              :to="{name: 'Pricing'}"
              ) Pricing
            .navbar-item
              a.px-2(
                href="https://blog.teachee.io"
              ) Blog
            .navbar-item
              .buttons
                router-link.button.is-dark.mr-sm-2(
                :to="{name: (user.id ? 'Home' : 'SignIn')}"
                ) Log in
                router-link.button.is-primary(
                :to="{name: 'SignUp'}"
                ) Sign up
            <!--a.navbar-item(href='/blog', target='_blank') Blog-->
            //.navbar-item
              a.app-store-badge(href='#', target='_blank')
                img(role='presentation', src='')
</template>

<script>
  export default {
    name: 'FrontMenu',
    data () {
      return {
        showNavBar: false,
        user: {}
      }
    },
    computed: {
    },
    mounted () {
      if (this.$auth.check()) {
        this.user = this.$auth.user()
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss">
  @import 'colors';

  .front-menu {
    color: #FFF;
    .navbar-item {
      img {
        height: 50px;
      }
    }
  }
  .page--front  .front-menu .navbar {
    background-color: transparent;
  }
  .front-menu .navbar {
    background-color: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    a:not(.button) {
      color: $primary;
    }
  }

  .brand{
    display:-ms-flexbox;
    display:flex;
    -ms-flex-align:center;
    align-items:center;
    line-height:1
  }
  .brand .brand-icon{
    margin-right:0.5rem;
    transition-duration:172ms;
    will-change:width, height, margin-right
  }
  .brand .brand-icon img {
    display:block;
    max-width:100%;
    max-height:100%;
    background-color: #FFF;
    width: 160px;
  }

  @media screen and (max-width: 1023px) {
    .front-menu {
      .navbar-item {
        font-weight: 600;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }

      .buttons {
        border-top: 1px solid #E6E6E6;
        padding: 2.5rem 0 1.5rem;
        justify-content: center;
        .button:not(:last-child):not(.is-fullwidth) {
          margin-right: 2.5rem;
        }
      }
    }
  }

</style>
