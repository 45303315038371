<template lang="pug">
  .container
    .box.has-shadow
      div.has-loader.is-primary.is-outlined.is-loading.has-margin-auto.primary-loader(v-if="querying")
      div(v-else)
        .columns
          .column
            h3.title.has-text-left
              | My packages
          .column.text-align-right.is-narrow
            router-link#link_add_package.button.is-outlined.is-primary(:to="{name: 'NewPackPage'}")
              b-icon(icon="add")
              span Add a package

        .columns.is-multiline
          .column.is-one-quarter.has-position-relative(
            v-for="(pack, i) in packsCreated"
            :key="pack.id")
            router-link.button.is-block.w-100.pack--link(:to="{name: 'ShowPackPage', params: {id: pack.id}}")
              | {{ pack.name }}
              br
              b
                | {{ (currencies[user.currency] || {}).l || '' }}{{ pack.price }}{{ (currencies[user.currency] || {}).r || '' }}
            button.button.pack__delete(
            aria-label="Delete the package"
            @click.stop="removePack(pack)"
            v-confirm="'Are you sure you want to <b>delete the pack</b>?'")
              b-icon(icon="delete" custom-size="mdi-20px")

    .box(v-if="packsBought.length")
        h3.title.has-text-left
          | Packages bought
        .columns.is-multiline
          .column.is-one-quarter(
          v-for="(pack, i) in packsBought"
          :key="pack.id")
            router-link.button.is-block.w-100.pack--link(:to="{name: 'ShowPackPage', params: {id: pack.id}}")
              | {{ pack.name }}
              br
              b
                | {{ (currencies[user.currency] || {}).l || '' }}{{ pack.price }}{{ (currencies[user.currency] || {}).r || '' }}


</template>

<script>
  import { mapGetters } from "vuex";

export default {
  name: "PacksPage",
  computed: {
    ...mapGetters(["packs", "user"]),
    packsCreated() {
      return this.packs.filter((pack) => pack.user_id == this.user.id )
    },
    packsBought() {
      return this.packs.filter((pack) => pack.user_id != this.user.id )
    }
  },
  data() {
    return {
      querying: true,
      currencies: {
        EUR: { r: "€" },
        USD: { r: "$" },
        GBP: { l: "£" }
      },
    };
  },
  created() {
    this.$store.dispatch("getPurchases");

    this.$store.dispatch("getPacks").then(() => {
      this.querying = false
    }, () => {
      this.querying = false
    });
  },
  methods: {
    removePack(pack) {
      this.$store.dispatch("deletePack", pack);
    },
  }
};
</script>


<style lang="scss" scoped>


  .pack--link {
      height: 4rem;
      line-height: 1.4rem;
      padding-top: 0.75rem !important;
  }
  .pack__delete {
    position: absolute;
    top: -5px;
    right: -3px;
    display: none;
    height: 37px;
    min-height: 37px;
    line-height: 19px;
    border-radius: 50%;
    .mdi {
      font-size: 20px;
    }
  }


  .column:hover {
    .pack__delete {
      display: block;
    }
  }

</style>
