<template lang="pug">
  .columns.sentence.ro-sentence(:class="sentenceTagsClasses")
    .column.has-text-left
      p.sentence__index
        | {{ index+1 }}
      //p
        | {{ model.content }}
      p.sentence__content.is-fullwidth
        | {{ sentence.content }}

      p.read_only_sentence__ipa.has-text-left
        ipa(
        v-for="word in (sentence.words)"
        :class="{disabled: !word.tts, miss: word.miss, pronounced: (word.tts && pronouncedWords[word.tts.toLowerCase()])}"
        :key="word.idx"
        :lang="language"
        :tts="word.tts"
        :title="word.variations ? word.variations.join(' / ') : ''"
        :content="word.content"
        @mouseenter="highlightIndex = word.idx")

      p.mb0.sentence__literal-translation.is-fullwidth
        | {{ sentence.literal_translation }}

      p.sentence__translation.is-fullwidth
        | {{ sentence.translation }}

      p.light-border.sentence__comment.has-text-grey-light
        | {{ sentence.comment }}
        <!-- TODO STRIP HTML TAGS & \n => <br> -->

    .column.is-narrow
      <!--transition(name="bounce")-->
        <!--tts-button.sentence&#45;&#45;listen.mb-1(-->
          <!--:tts="sentence.content"-->
          <!--:lang="language"-->
          <!--v-if="sentence.content && sentence.content.length")-->
      transition(name="bounce")
        div(v-if="model.audio && model.audio.length")
          player.is-outlined.is-primary.mb-1(
            :class=`{
            'is-primary': audio.user_id == lesson.user_id,
            'is-info': audio.user_id != lesson.user_id
            }`
            v-for="audio in model.audio"
            :key="audio.id"
            :label="''"
            :audio="audio"
            )
      //div(:class="{'is-teacher': isTeacher, 'is-student': !isTeacher}")
      //  transition(name="bounce")
      //    recorder.recorder.mb-1(
      //      v-if="model.id"
      //      :label="''"
      //      model-type="Sentence"
      //      :model-id="model.id"
      //      :language="language"
      //      speech-recognition="true"
      //      :text="sentence.content"
      //      :audio="(model.audio || [])[0]")

          //recorder.recorder(v-if="model.id && isTeacher" model-type="Sentence" :model-id="model.id" :text="sentence.content" :audio="model.audio")
          //recorder.recorder(
          //  v-if="!isTeacher"
          //  speech-recognition="true"
          //  :language="language"
          //  @voice-recognition-result="markWordsAsWellPronounced")
          //  template(slot-scope="slotProps")
          //    player.is-outlined.is-info(:blob="slotProps.blob")
      <!--a.download-button.button.is-rounded.is-light(v-if="sentence.audio" :href="apiUrl+sentence.audio.path" target="_blank")-->
        <!--b-icon(icon="download")-->


</template>

<script>
import Ipa from "@/components/Ipa";
import Recorder from "@/components/Recorder";
import AudioPlayer from "@/components/AudioPlayer";
import TtsButton from "@/components/TtsButton";
import { mapGetters } from "vuex";
import uniq from "lodash/uniq";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import {LG_TO_TXT} from "../constants"

export default {
  name: "Sentence",
  components: {
    ipa: Ipa,
    recorder: Recorder,
    player: AudioPlayer,
    ttsButton: TtsButton
  },
  props: [
    "language",
    "model",
    "index",
    "isTeacher",
    "lessonUuid",
    "version",
    "features"
  ],
  data() {
    return {
      lgToTxt: LG_TO_TXT,
      isSaving: false,
      pronouncedWords: {},
      sentence: {
        id: null,
        lesson_id: null,
        content: "",
        translation: "",
        literal_translation: "",
        ipa: "",
        comment: "",
        tags: []
      },
      highlightIndex: null,
      comment: ""
    };
  },
  computed: {
    ...mapGetters(["uuid", "apiUrl", "lesson"]),
    sentenceTagsClasses() {
      return this.sentence.tags.map(tag => `tag-${tag}`).join(" ");
    }
  },
  watch: {
    version(val) {
      if (val >= (this.sentence.version || 0)) {
        this.sentence = Object.assign(this.sentence, this.model.lastChange);
      }
    }
  },
  mounted() {
    this.sentence = Vue.util.extend(
      {
        lesson_id: this.lessonUuid,
        tags: []
      },
      this.model
    );
    this.sentence.index = this.index;
  },
  methods: {
    literalTranslationEdited() {
      if (
        this.sentence.literal_translation &&
        this.sentence.literal_translation.length
      ) {
        this.sentence.literal_translation = this.sentence.literal_translation
          .replace(/ • /g, " ")
          .split(/\s+/)
          .join(" • ");
        this.$ga.commands.editLiteral();
      }
    },
    transformHashtagToTag() {
      if (this.sentence.content && this.sentence.content.length) {
        if (this.sentence.content.match(/#[\w_]+/)) {
          this.sentence.content.match(/#[\w_]+/g).forEach(tag => {
            var newTag;
            switch (tag) {
              case "#m":
              case "#masc":
                newTag = "masculin";
                break;
              case "#f":
              case "#fem":
                newTag = "feminin";
                break;
              case "#p":
              case "#plu":
                newTag = "plural";
                break;
              case "#fo":
              case "#for":
                newTag = "formal";
                break;
              case "#i":
              case "#inf":
                newTag = "informal";
                break;
              case "#s":
              case "#sin":
              case "#sing":
                newTag = "singular";
                break;
              default:
                newTag = tag;
            }
            this.sentence.tags.push(newTag.replace(/#/, ""));
          });
          this.sentence.tags = uniq(this.sentence.tags);
          this.sentence.content = this.sentence.content.replace(
            /\s?#[\w_\s]+/g,
            ""
          );

          return true;
        }
      }
      return false;
    },
    removeTag(tag) {
      this.sentence.tags = filter(this.sentence.tags, t => t !== tag);
    },

    trim(str) {
      if (!str) {
        return null;
      }
      return str.replace(/^[\/\-_\s]+/, "").replace(/[\/\-_\s]+$/, "");
    },

    markWordsAsWellPronounced(words) {
      forEach(words, word => {
        this.$set(this.pronouncedWords, word, true);
      });
    },

    playAudio() {
      var audio = new Audio();
      audio.src = this.dataUrl;
      if (audio.paused === false) {
        audio.pause();
      } else {
        audio.play();
      }
    },
  }
};
</script>

<style lang="scss">
@import "colors";

.ro-sentence {
  transition: opacity .2s ease;
  &:hover {
    &~.ro-sentence {
      opacity: 0.2
    }
    .sentence__index {
      color: $blue;
    }
  }

  .column.is-narrow {
    transform: scale(0.8);
    transform-origin: top;
  }

  .sentence__comment {
    margin-top: 13px;
    &:empty {
      display: none;
    }
  }
}

.sentence {
  position: relative;
}

.sentence__content {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  transition: color 0.5s ease;
}

.tag-informal .sentence__content {
  font-family: "Caveat Brush", cursive;
  font-weight: 400;
  font-size: 24px;
  padding-left: 9px;
  padding-top: 0px;
  line-height: 1;
  height: 2em;
}

span {
  &.masc,
  &.masculin {
    color: #3272dc;
  }
  &.fem,
  &.feminin {
    color: #ff3860;
  }
  &.sing,
  &.singular {
  }
  &.plu,
  &.plural {
    color: #363636;
  }
  &.for,
  &.formal {
    color: #209cee;
  }
  &.inf,
  &.informal {
    color: #01d1b2;
  }
}

.sentence__index {
  transition: color .2s ease;
  position: absolute;
  top: 13px;
  left: -9px;
  font-size: 15px;
  font-weight: 700;
  color: #dedede;
  width: 18px;
  text-align: center;
}

.read_only_sentence__ipa {
  font-size: 17.5px;
  color: #777;
  letter-spacing: 1px;
  margin-bottom: 8px;
  .miss {
    color: #b3b2b2;
  }
  .pronounced {
    -webkit-animation: wellPronounced 0.5s ease;
    -webkit-animation-fill-mode: forwards;
    animation: wellPronounced 0.5s ease;
    animation-fill-mode: forwards;
    & + .pronounced {
      animation-delay: 0.1s;
      & + .pronounced {
        animation-delay: 0.2s;
        & + .pronounced {
          animation-delay: 0.3s;
          & + .pronounced {
            animation-delay: 0.4s;
            & + .pronounced {
              animation-delay: 0.5s;
              & + .pronounced {
                animation-delay: 0.6s;
              }
            }
          }
        }
      }
    }
  }
}

@-webkit-keyframes wellPronounced {
  0% {
    color: #777;
    transform: scale(1);
  }
  25% {
    color: $darkGreen;
    transform: scale(1.2);
  }
  100% {
    color: $green;
    transform: scale(1);
  }
}
@keyframes wellPronounced {
  0% {
    color: #777;
    transform: scale(1);
  }
  25% {
    color: $darkGreen;
    transform: scale(1.2);
  }
  100% {
    color: $green;
    transform: scale(1);
  }
}

.sentence__literal-translation {
  margin-top: -10px;
  input {
    font-size: 0.95rem;
    height: 2em;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.sentence__literal-translation-formatted {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 16px;
  padding-left: 8px;
}

.sentence__literal-translation,
.sentence__translation {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

.sentence__translation {
  color: $blue;
}

.ro-sentence {

  .sentence--delete {
    position: absolute;
    right: -57px;
    top: 15px;
    height: 32px;
    width: 32px;
    i {
      font-size: 1.1rem;
    }
  }
}

.light-border textarea {
  border: 1px solid #f4f4f4;
  box-shadow: none;
  font-size: 0.8rem;
  resize: none;
}

.column {
  position: relative;
}

.tags-indicator {
  position: absolute;
  width: 8px;
  top: 25px;
  left: 3px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  line-height: 12px;
}

.recorder {
  z-index: 42;
}
.download-button {
  position: absolute;
  top: 62px;
  left: -50px;
  transition: all 0.3s ease;
  width: 40px;
  height: 40px;
}

.sentence__tags {
  height: 12px;
  font-size: 12px;
  padding-left: 9px;
  margin-top: 13px;
  margin-bottom: 26px !important;
  &:empty {
    display: none;
  }
}

/*
  .tags {
    .tag {
      text-transform: uppercase;
    }

    &.masc,
    &.masculin {
      .tag {
        background-color: #3272DC;
        color: #FFF;
        &:hover {
          background-color: #1959C3;
          color: #FFF;
        }
      }
    }
    &.fem,
    &.feminin {
      .tag {
        background-color: #FF3860;
        color: #FFF;
        &:hover {
          background-color: #CC052D;
          color: #FFF;
        }
      }
    }
    &.sing,
    &.singular {

    }
    &.plu,
    &.plural {
      .tag {
        background-color: #363636;
        color: #FFF;
        &:hover {
          background-color: #1D1D1D;
          color: #FFF;
        }
      }
    }
    &.for,
    &.formal {
      .tag {
        background-color: #209CEE;
        color: #FFF;
        &:hover {
          background-color: #0069BB;
          color: #FFF;
        }
      }
    }
    &.inf,
    &.informal {
      .tag {
        background-color: #01D1B2;
        color: #FFF;
        &:hover {
          background-color: #00B899;
          color: #FFF;
        }
      }
    }
  }
  */

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0.3;
    transform: scale(0.5);
  }
  20% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .recorder {
    position: relative;
    top: initial;
    left: initial;
    z-index: 42;
    text-align: left;
    margin-bottom: 20px;
  }
}
</style>
