<template lang="pug">
  section.section.section--features(
  :class="[theme.bg]"
  )
    .container
      .head
        p.text-main.is-size-6.mb-0.has-text-centered.mx-auto.opacity-8(
          :class="[theme.text]"
          v-if="topic"
        ) {{ topic }}
        h2.size-h2.mb-6(
          :class="[theme.text]"
        ) Features

      .body.columns.mt-6.has-flex-start-item.is-multiline

        .column.has-text-left.is-3.mt-5.mb-5(
          v-for="(feature, i) in Object.values($t('features.'+content))"
          :key="i"
          v-scroll-reveal.reset="{ delay: 200*i }"
          )
          img(
            v-if="feature.pict.includes('.svg')"
            :src="require(`../../assets/v3/${feature.pict}`)" )
          b-icon.has-text-white(v-else :icon="feature.pict" custom-size="mdi-30px" )
          h3.size-h3.mt-1.mb-2(
            :class="[theme.text]"
          ) {{ feature.title }}
          p(
            :class="[theme.text]"
          ) {{ feature.desc }}

</template>

<script>
const themes = {
  light: {
    bg: 'has-background-dark',
    text: 'has-text-primary'
  },
  dark: {
    bg: 'has-background-primary',
    text: 'has-text-dark'
  }
}

export default {
  name: "FeaturesSection",
  props: ['content', 'mode', 'topic'],
  computed: {
    theme() {
      return themes[this.mode];
    }
  },
  data() {
    return {
      tabsLabels: [1, 2, 3, 4],
      tabSelectedIndex: 1,
    }
  },

}
</script>

<style lang="scss">
@import "colors";

.section--features {
  img {
    width: 30px;
    height: 30px;
  }
  h3,
  p {
    width: 70%
  }
  h3 {
    height: 60px;
    line-height: 1.2;
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      line-height: 1.1;
      height: auto;
    }
  }
}
</style>

<i18n lang="yaml">
en:
  features:
    teacher:
      - title: Audio recording and storage
        desc: Record and store your sentences so your student can listen to them later
        pict: features-audio.svg

      - title: Speech to text
        desc: The sentences recorded appear in a written form right away
        pict: features-speech.svg

      - title: Automatic translation
        desc: The sentences recorded are translated to your target language
        pict: features-translate.svg

      - title: Phonetic translation
        desc: Phonetics are also provided and with the translation to aid the right pronunciation
        pict: features-phonetic.svg

      - title: Export to Anki flashcards
        desc: In-between lesson practice is aided by the simple Anki export
        pict: features-anki.svg

      - title: Assign homework
        desc: Mark material from your lesson and assign it to your student to practice
        pict: features-homework.svg

      - title: Create lessons in advance
        desc: Create a lesson in Teachee and use that as a framework with your student
        pict: features-advance.svg

      - title: Live edit
        desc: While you record or write sentences, your student see all changes happening live
        pict: present-to-all

    student:
      - title: Audio recording and storage
        desc: Record and store your teacher’s pronunciation to use it later for practice
        pict: features-audio.svg

      - title: Speech to text
        desc: The sentences recorded appear in a written form right away
        pict: features-speech.svg

      - title: Automatic translation
        desc: The sentences recorded are translated to your target language
        pict: features-translate.svg

      - title: Phonetic translation
        desc: Phonetics are also provided and with the translation to aid the right pronunciation
        pict: features-phonetic.svg

      - title: Export to Anki flashcards
        desc: Practice in-between lessons by exporting the material into Anki flashcards
        pict: features-anki.svg

      - title: Homework practice
        desc: Record your own pronunciation and get feedback from your teacher
        pict: features-homework.svg

      - title: Create practice material
        desc: Create a lesson for yourself to practice the hardest to learn phrases
        pict: features-advance.svg

      - title: Live edit
        desc: You see all changes to the lesson happening live
        pict: present-to-all
</i18n>
