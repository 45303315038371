<template lang="pug">
  .container.admin-dashboard
    .box.has-text-left(
      v-shortkey="['arrowright']"
      @shortkey="nextPage()"
    )
      .columns(
        v-shortkey="['arrowleft']"
        @shortkey="previousPage()"
        )
        .column.is-three-quarters
          h2.title.is-size-5.mb-3 Lessons created weekly
          trend(
            v-if="data.length"
            :data="data"
            :gradient="['#FF5B62', '#f9ca24', '#FF5B62']"
            :stroke-width="0.7"
            auto-draw
            smooth)
        .column.is-one-quarter
          h2.title.is-size-5.mb-3 Per day of week
          trend(
          v-if="dataDow.length"
          :data="dataDow"
          :gradient="['#FF5B62', '#f9ca24', '#FF5B62']"
          :stroke-width="2"
          auto-draw
          smooth)
      .columns
        .column.is-three-quarters
          h2.title.is-size-5.mb-3 Users created weekly
          trend(
          v-if="userData.length"
          :data="userData"
          :gradient="['#1C7C9C', '#048900', '#1C7C9C']"
          :stroke-width="0.7"
          auto-draw
          smooth)
        .column.is-one-quarter
          h2.title.is-size-5.mb-3 Per day of week
          trend(
          v-if="userDataDow.length"
          :data="userDataDow"
          :gradient="['#1C7C9C', '#048900', '#1C7C9C']"
          :stroke-width="2"
          auto-draw
          smooth)

      .section#users_table
        .has-text-right.mb-2.has-flex-center-items.valign
          h2.title.is-size-5 {{ usersWithConsumption.length }} users
          b-checkbox.ml-auto(v-model="usersWithConsumptionFreeOnly") Free only

        b-table.w-100(
          :paginated="usersWithConsumption.length > 50"
          :per-page="50"
          :data="usersWithConsumption"
          )
          b-table-column(
            v-slot="props" field="first_name" label="First Name" sortable searchable
            )
            | {{props.row.first_name}}
            em.is-block.is-size-7
              | {{props.row.city}}
              |  -
              | {{props.row.country}}
          b-table-column(v-slot="props" field="role" label="Role" width="100" sortable searchable)
            | {{props.row.role}}
            em.is-block.is-size-7
              | {{props.row.role_valid_until | moment("YYYY.MM.DD") }}
          b-table-column(v-slot="props"
            field="created_at"
            label="Created at"
            sortable
            width="120"
            )
            | {{props.row.created_at | moment("from", "now") }}
          b-table-column(v-slot="props" field="credits" label="Credits" width="90" sortable numeric searchable)
            | {{props.row.credits}}
          b-table-column(v-slot="props" field="credit_removals_count" label="Used Credits" sortable numeric)
            | {{props.row.credit_removals_count}}
          b-table-column(v-slot="props" field="sign_in_count" label="Unique days connected" sortable numeric)
            | {{props.row.sign_in_count}}
          b-table-column(v-slot="props"
            label="Consumption"
            width="300")
            storage-progress-bar-generic(
              :user="props.row"
              :storage-stats="stats[props.row.id]"
            )

      .columns
        .column
          h2.title.is-size-5.is-flex
            | Dormant Users ({{dormantUsers.length}}) 
            span.is-inline-flex.has-flex-center-items.is-size-6.ml-auto.has-text-warning
              | {{activeTeachers.length}}
              b-icon(icon="face")
          p.subtitle.has-text-grey.is-size-6.mb-3 Last sign in more than a month ago
          .notification.is-warning.py-2.px-3(v-for="user in dormantUsers")
            user-card(:user="user" size="is-small")
            .is-flex.has-justify-end.has-flex-wrap.students-wrapper
              .student.ml-1.has-size-30px.mb-1(v-for="studentId in user.student_ids")
                user-picture(:user="usersById[studentId]" :title="(usersById[studentId] || {}).first_name")

        .column
          h2.title.is-size-5.is-flex
            | Users ({{remainingUsers.length}})
            span.is-inline-flex.has-flex-center-items.is-size-6.ml-auto.has-text-grey
              | {{remainingTeachers.length}}
              b-icon(icon="face")
          p.subtitle.has-text-grey.is-size-6.mb-3 Last sign in within last 30 days
          .notification.is-light.py-2.px-3(v-for="user in remainingUsers")
            user-card(:user="user" size="is-small")
            .is-flex.has-justify-end.has-flex-wrap.students-wrapper
              .student.ml-1.has-size-30px.mb-1(v-for="studentId in user.student_ids")
                user-picture(:user="usersById[studentId]" :title="(usersById[studentId] || {}).first_name")

        .column
          h2.title.is-size-5.is-flex
            | Active Users ({{activeUsers.length}})
            span.is-inline-flex.has-flex-center-items.is-size-6.ml-auto.has-text-info
              | {{activeTeachers.length}}
              b-icon(icon="face")
          p.subtitle.has-text-grey.is-size-6.mb-3 More than 5 connections
          .notification.is-info.py-2.px-3(v-for="user in activeUsers")
            user-card(:user="user" size="is-small")
            .is-flex.has-justify-end.has-flex-wrap.students-wrapper
              .student.ml-1.has-size-30px.mb-1(v-for="studentId in user.student_ids")
                user-picture(:user="usersById[studentId]" :title="(usersById[studentId] || {}).first_name")

        .column
          h2.title.is-size-5.is-flex
            | Partners ({{collaborators.length}})
            span.is-inline-flex.has-flex-center-items.is-size-6.ml-auto.has-text-success
              | {{collaboratorsTeachers.length}}
              b-icon(icon="face")
          p.subtitle.has-text-grey.is-size-6.mb-3 Made Teachee sales
          .notification.is-success.py-2.px-3(v-for="user in collaborators")
            user-card(:user="user")



</template>


<script>
import { mapGetters } from "vuex";
import includes from "lodash/includes";
import { User, Lesson } from "@/store/models/resources";
import userCard from "@/components/UserCardUI";
import userPicture from "@/components/UserPictureUI";
import keyBy from "lodash/keyBy";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import difference from "lodash/difference";
import StorageProgressBarGeneric from "./ui/StorageProgressBarGeneric";

export default {
  name: "UsersPage",
  components: {
    StorageProgressBarGeneric,
    userCard,
    userPicture
  },
  data() {
    return {
      users: [],
      data: [],
      dataDow: [],
      userData: [],
      userDataDow: [],
      stats: {},
      usersWithConsumptionFreeOnly: true
    };
  },
  computed: {
    // ...mapGetters(["lessons", "student"]),
    usersById() {
      if (!this.users || !this.users.length) {
        return {};
      }
      return keyBy(this.users, 'id');
    },
    sellers() {
      return groupBy(this.users, 'seller_id');
    },
    activeUsers() {
      return sortBy(this.users.filter(user => user.sign_in_count > 5 && user.credit_removals_count > 3), (user) => user.sign_in_count).reverse()
    },
    activeTeachers() {
      return this.activeUsers.filter(user => user.student_ids.length)
    },
    collaborators() {
      return this.users.filter(user => this.sellers[user.id])
    },
    collaboratorsTeachers() {
      return this.collaborators.filter(user => user.student_ids.length)
    },
    dormantUsers() {
      const aMonthAgo = this.$moment().subtract(1, 'month');
      return difference(this.users, this.activeUsers.concat(this.collaborators)).filter(user => this.$moment(user.last_sign_in) < aMonthAgo)
    },
    dormantTeachers() {
      return this.dormantUsers.filter(user => user.student_ids.length)
    },
    remainingUsers() {
      return sortBy(difference(this.users, this.activeUsers.concat(this.collaborators).concat(this.dormantUsers)), (user) => user.last_sign_in).reverse()
    },
    remainingTeachers() {
      return this.remainingUsers.filter(user => user.student_ids.length)
    },
    usersWithConsumption() {
      return this.users.filter(user => (!this.usersWithConsumptionFreeOnly || user.role === 'free') && this.stats[user.id])
    }
  },
  created() {
    User.query().then(response => {
      // console.log(response)
      this.users = response.body.users;
    })
    User.storageStatsPerUser().then(response => {
      this.stats = response.body.data;
    })
    Lesson.countChart().then(response => {
      this.data = response.body.data;
    })
    Lesson.countChartDow().then(response => {
      this.dataDow = response.body.data;
      this.dataDow = this.dataDow.concat(response.body.data);
    })
    User.countChart().then(response => {
      this.userData = response.body.data;
    })
    User.countChartDow().then(response => {
      this.userDataDow = response.body.data;
      this.userDataDow = this.userDataDow.concat(response.body.data);
    })
  },
  methods: {
    previousPage() {
      document.querySelector('#users_table .pagination-previous').click()
    },
    nextPage() {
      document.querySelector('#users_table .pagination-next').click()
    },
  }
};
</script>


<style lang="scss">
@import "colors";

.admin-dashboard {
  svg {
    width: 100%;
    height: 80%;
  }

  .students-wrapper {
    margin-right: 12px;
    .student+.student+.student+.student+.student+.student+.student {
      display: none
    }
    &:hover {
      .student {
        display: flex !important;
        /*margin-right: 0 !important;*/
        &:empty {
          display: none !important;
        }
      }
    }
    .student {
      margin-right: -14px;
      /*transition: margin-right .2s ease-in-out;*/
      z-index: 10;
      &:last-child {
        margin-right: 0;
      }
      &:empty {
        display: none;
      }
    }
  }
}
</style>
