`import {Audio} from '../models/resources'`
`import { nodeApi } from "@/config";`

actions =
  addAudio: (context, audio) ->
    context.commit('addAudio', audio)

  removeAudio: (context, payload) ->
    Audio.delete({id: payload.id, model_id: payload.model_id, uuid: payload.uuid}).then (res) ->
      context.commit('removeAudio', res.body.audio)

`export default actions`
