<template lang="pug">
  .box.has-shadow
    h3.title.has-text-left.mb-3.is-flex
      span
        | Payments
      div.ml-auto.buttons.has-addons(
      v-if="payments.length"
      )
        button.button.is-small(@click="currency = 'usd'" :class="{'is-info': currency == 'usd'}") USD
        button.button.is-small(@click="currency = 'eur'" :class="{'is-info': currency == 'eur'}") EUR
        button.button.is-small(@click="currency = 'gbp'" :class="{'is-info': currency == 'gbp'}") GBP



    .has-position-relative.w-100
      .has-loader.is-primary.is-outlined.is-loading.has-margin-auto.primary-loader(v-if="querying")

      article.message.is-light.w-100(v-if="!payments.length && !querying")
        .message-body
          | You didn't receive any payment yet

      payments-chart.chart(
      v-if="payments.length"
      :user="user"
      :height="185"
      :width="300"
      :payments="payments"
      :currency="currency"
      :key="currency"
      )


</template>

<script>
  import {mapGetters} from 'vuex'
  import userPicture from "@/components/UserPictureUI";
  import { Payment } from "@/store/models/resources";
  import paymentsChart from "@/charts/PaymentsChart";

  export default {
    name: "PaymentssBox",
    components: {
      userPicture,
      paymentsChart,
    },
    computed: {
      ...mapGetters(['user']),
    },
    data() {
      return {
        querying: true,
        currency: 'eur',
        payments: [],
      };
    },
    mounted() {
      this.currency = (this.user.currency || '').toLowerCase();
    },
    created() {
      Payment.query().then(res => {
        this.payments = res.body.data
        this.querying = false
      });
    },
    methods: {

    }
  };
</script>

<style lang="scss" scoped>

  .has-loader {
    height: 40px;
    margin-bottom: 30px;
    opacity: 0.8 !important;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 2rem;
    background-color: rgba(255, 255, 255, 0.5);
    display: block;
    z-index: 2;
  }

</style>
