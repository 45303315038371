
<template lang="pug">
  b-tooltip(
  :label="tooltip === false ? '' : (tooltip || 'Listen to computer-generated audio')"
  type='is-light'
  :animated='true'
  position='is-left')
    button.button.is-small.has-text-gray(
      tabindex="-1"
      :disabled="disabled"
      @click="speak(tts, lang, $event)"
      v-ga="'listenToSentence'"
    )
      b-icon(:icon="icon || 'record-voice-over'" size="is-small")

</template>

<script>
  import Audio from '@/services/Audio.js.coffee'
  import {mapGetters} from 'vuex'

  export default {
    name: 'tts-button',
    props: [
      'tts',
      'disabled',
      'lang',
      'icon',
      'tooltip',
    ],
    computed: mapGetters(['voices']),
    data () {
      return {
        audio: null
      }
    },
    mounted () {
      this.audio = Audio.init()
    },
    methods: {
      speak: function(text, lang, event) {
        this.audio.speak(text,  event, lang || 'en', this.voices[lang])
        this.$mixpanel.track("Sentence - Listen to whole sentence");
      }
    }
  }
</script>

<style lang="scss" scoped>
  /*@import 'colors';*/
  .button {
  }
</style>
