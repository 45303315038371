import Vue from "vue";
import VueResource from "vue-resource";
import { nodeApi } from "../../config";
// import moment from 'moment';
import moment from "moment-timezone";
import padStart from "lodash/padStart";

Vue.use(VueResource);

const Event = Vue.resource(
  `${nodeApi}/events{/id}`,
  {},
  {
    bookingsAsStudent: { method: "GET", url: `${nodeApi}{/id}/bookings/as_student{/limit}` },
    bookingsAsTeacher: { method: "GET", url: `${nodeApi}{/id}/bookings/as_teacher{/limit}` },
    bookings: { method: "GET", url: `${nodeApi}{/id}/bookings` },
    teacherEvents: { method: "GET", url: `${nodeApi}{/id}/events` },
    batchUpdate: { method: "POST", url: `${nodeApi}/events/batch_update` },
    book: { method: "POST", url: `${nodeApi}/events{/id}/book` },
    unbook: { method: "POST", url: `${nodeApi}/events{/id}/unbook` }
  }
);
Event.transformForCal = (event, timeZone) => {
  /*
  *
  * HANDLE TIME ZONES
  *
  * */

  const date = moment
    .tz(`${event.start_date} ${event.start_time}`, event.time_zone)
    .tz(timeZone || event.time_zone);
  const dateEnd = moment
    .tz(`${event.start_date} ${event.end_time}`, event.time_zone)
    .tz(timeZone || event.time_zone);

  return {
    id: event.id,
    date: date.format("YYYY/MM/DD"),
    title: `<b>${date.format("HH:mm")}</b> - ${dateEnd.format("HH:mm")} • ${
      event.name
    }`,
    start_time: date.format("HH:mm"),
    end_time: dateEnd.format("HH:mm"),
    desc: event.name || "",
    lesson_date: date.format("MM.DD"),
    student_id: event.student_id,
    teacher_id: event.teacher_id,
    duration: event.duration,
    lesson: event.lesson,
    student: event.student,
    teacher: event.teacher
  };
};
Event.transformFromApi = (event, timeZone) => {
  /*
  *
  * HANDLE TIME ZONES
  *
  * */

  const date = moment
    .tz(`${event.start_date} ${event.start_time}`, event.time_zone)
  date.tz(timeZone || event.time_zone);
  const dateEnd = moment
    .tz(`${event.start_date} ${event.end_time}`, event.time_zone)
  dateEnd.tz(timeZone || event.time_zone);

  return {
    id: event.id,
    name: event.name,
    date: date._d,
    dateStr: event.start_date,
    startTime: date.format("HH:mm"),
    endTime: dateEnd.format("HH:mm"),
    comment: event.comment,
    time_zone: event.time_zone,
    teacher_id: event.teacher_id,
    duration: event.duration,
    state: event.state
  };
};
Event.transformToApi = event => {
  return {
    id: event.id,
    name: event.name,
    start_date: event.dateStr,
    start_time: padStart(event.startTime, 5, '0'),
    end_time: event.endTime,
    duration: event.duration,
    comment: event.comment,
    time_zone: event.time_zone
  };
};

export default Event;
