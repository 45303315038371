<template lang="pug">
  .container
    .column.is-4.is-offset-4
      .box
        figure.avatar
          picture
            source(srcset="@/assets/logo_teachertool_v2_square.webp" type="image/webp")
            source(srcset="@/assets/logo_teachertool_v2_square.png" type="image/png")
            img(alt='Teachee', role='presentation', src='@/assets/logo_teachertool_v2_square.png' width="128" height="128")
        form
          .message.is-danger(v-if="error")
            .message-body
              span(v-html="error")
          b-field
            b-input#password(
              v-model="form.newPassword"
              type="password"
              required
              password-reveal
              size="is-medium"
              placeholder="New password")
          button#btn_sign_in.button.is-block.is-primary.is-medium.is-fullwidth.is-flex(
            :class="{'is-loading': isLoading, 'is-success': success, 'is-disabled': isLoading || !validEmail}"
            @click.prevent="validEmail ? onSubmit($event) : ''")
            b-icon(icon="check" v-if="success")
            b-icon(icon="save" v-else)
            span(v-if="!success")
              | Reset my password
</template>


<script>
import { User } from "@/store/models/resources";

export default {
  name: "ResetPasswordPage",
  data() {
    return {
      error: null,
      success: false,
      redirect: null,
      form: {
        newPassword: ""
      },
      isLoading: false
    };
  },
  computed: {
    validEmail() {
      return this.form.newPassword && this.form.newPassword.length > 6;
    }
  },
  created() {
    this.$store.dispatch("setLayout", "front-layout");
  },
  methods: {
    onSubmit(evt) {
      this.isLoading = true;

      User.resetPassword({
        user: this.form,
        token: this.$route.params.token
      }).then(
        response => {
          this.$store.dispatch("setUser", response.body.user);
          this.$auth.token(null, response.body.token)
          //this.$auth.watch.authenticated = true
          //this.$auth.watch.loaded = true
          this.$auth.user(response.body.user);
          this.$toast.open({
            duration: 3000,
            message: 'Password successfully updated',
            type: "success"
          });
          this.$router.push({name: 'Home'})
        },
        err => {
          // console.log(err);
          this.$toast.open({
            duration: 4000,
            message: err.body.message,
            type: "error"
          });
          this.isLoading = false;
        }
      );
    }
  }
};
</script>


<style scoped>
.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}

.avatar img {
  padding: 1rem;
  background: #fff;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

input {
  font-weight: 300;
}

p {
  font-weight: 700;
}

p.subtitle {
  padding-top: 1rem;
}

a {
  text-decoration: none;
}

.container {
  width: 400px;
  margin-top: 100px;
}
</style>
