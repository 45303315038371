
<template lang="pug">
  .ui--recorder(
    :class="{empty: !label.length}"
  )
    button.button.has-justified-start.button-recorder.is-small(
      v-if="browser.name === 'safari' || browser.name === 'chrome' || browser.name === 'firefox'"
      v-shortkey.push="['alt', ('f'+(shortcut+1))]"
      @shortkey="toggleRecording"
      @mousedown.prevent.stop="startRecording"
      @mouseup.prevent.stop="endRecording"
      @mouseout.prevent.stop="endRecording"
      @touchstart.prevent.stop="startRecording"
      @touchend.prevent.stop="endRecording"
      :disabled="isLocked"
      :class=`{
        'is-primary': !dataUrl.length && !audio,
        [success]: dataUrl.length || audio
        }`
      v-ga.mousedown="'recordAudio'"
      )
      b-icon(:icon="isLocked ? 'lock' : 'mic'" size="is-small")
      span.p-0 {{ label }}
    | &nbsp;
    b-tooltip(
      v-if="browser.name !== 'safari' && browser.name !== 'chrome' && browser.name !== 'firefox'"
      label="To record yourself, please use Chrome, Safari or Firefox"
      type='is-light'
      :animated='true'
      position='is-top')
      button.button.is-light.is-disabled.is-small.has-justified-start
        b-icon(icon="mic" size="is-small")
        span.p-0 {{ label }}
    transition(name="bounce")
      slot(v-bind:blob="dataUrl" v-if="dataUrl.length")
    <!--button.button.is-round.is-light(@click="togglePlay")-->
      <!--b-icon(icon="play")-->

</template>

<script>
  const { detect } = require('detect-browser');
  const browser = detect();
  import VoiceRecognition from '@/services/VoiceRecognition.js.coffee'
  import { nodeApi } from '@/config'
  export default {
    name: 'Recorder',
    props: [
      'text', // FOR FILENAME
      'label',
      'user',
      'audio',
      'modelType',
      'modelId',
      'shortcut',
      'language',
      'speechRecognition',
      'successClass'
    ],
    data () {
      return {
        browser,
        audioRecorder: null,
        recordingData: [],
        dataUrl: '',
        voiceRecognition: {}
      }
    },
    watch: {
      dataUrl() {
        if (!this.user || !this.user.id || this.user.preferences.repeat_after_recording) {
          this.togglePlay()
        }

        // if (this.modelType && this.modelId) {
        //   this.submitRecording()
        // }
      }
    },
    computed: {
      success() {
        return this.successClass ? this.successClass : 'is-white'
      },
      isLocked() {
        let isLocked = true;
        const {paid} = this.$store.getters.lesson
        if (this.$store.getters.user) {
          isLocked = this.$store.getters.user.is_locked
        }
        if (paid) {
          isLocked = false
        }
        return isLocked;
      }
    },
    mounted() {
      if (this.speechRecognition) {
        this.voiceRecognition = VoiceRecognition.init(this.language)
      }
    },
    methods: {
      toggleRecording() {
        // if (!this.hasShortkey) {
        //   return
        // }

        if (this.audioRecorder && this.audioRecorder.state === "recording") {
          this.endRecording()
        } else {
          this.startRecording()
        }
      },
      startRecording() {

        const mimeType = this.browser.name === "safari" ? "audio/mp4" : 'audio/webm;codecs="opus"';

        if (this.speechRecognition && this.user.role === 'free') {
        // if (this.speechRecognition) {
          this.voiceRecognition.listen(this.language, (words) => {
            console.log({words})
            this.$emit('voice-recognition-result', words)
          })
        }
        this.recordingData = []
        this.dataUrl = ''
        navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        }).then( (stream) => {
            // this.stream = stream;
            try {
              // if (MediaRecorder.isTypeSupported("audio/webm;codecs=opus")) {
              //   console.log("audio/webm;codecs=opus supported")
              // }

              this.audioRecorder = new MediaRecorder(stream, {
                // mimeType: 'audio/webm;codecs=opus'
                // audioBitsPerSecond: 96000
              });
              this.audioRecorder.ondataavailable = (event) => {
                this.recordingData.push(event.data);
              }
              this.audioRecorder.onstop = (event) => {
                const blob = new Blob(this.recordingData, {type: mimeType});
                this.dataUrl = window.URL.createObjectURL(blob);
                if (this.modelType && this.modelId) {
                  this.submitRecording()
                }
              }
              this.audioRecorder.start();
            } catch (e) {
              console.log({e})
              // Vue.rollbar.debug(e)
              if (Vue.rollbar) {
                Vue.rollbar.debug(error);
              }
              this.$toast.open({
                duration: 5000,
                message: 'Something went wrong while recording. Our team received a notification about the problem. Reloading using cache refresh..',
                type: "error"
              });
              // setTimeout(() => {
              //   window.location.reload(true)
              // }, 5000)
            }
          }
          // console.log('Media recorder started');
        ).catch( errorFromGetUserMedia => {
          console.log({errorFromGetUserMedia});
          // console.error(error);
          // if (Vue.rollbar) {
          //   Vue.rollbar.debug(error);
          // }
          const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

          this.$toast.open({
            duration: isChrome ? 30000 : 7000,
            message: isChrome ? `Something went wrong while recording.<br>Check the instructions in this link to solve the problem: <a href="https://blog.teachee.io/help-i-cant-record-audio-on-teachee/" target="_blank">https://blog.teachee.io/help-i-cant-record-audio-on-teachee/</a>` : 'An error occurred, probably due to Firefox missing language modules. Please use Chrome instead',
            type: "warning"
          });
        });
      },
      endRecording() {
        if (this.audioRecorder && this.audioRecorder.state === "recording") {
          this.audioRecorder.stop();
        }
        if (this.speechRecognition) {
          this.voiceRecognition.stop()
        }
      },
      removeRecording() {
      },
      togglePlay() {
        if (this.dataUrl) {
          var audio = new Audio();
          audio.src = this.dataUrl
          if (audio.paused === false) {
            audio.pause();
          } else {
            audio.play();
          }
        }
        // var audioElement = this.$refs.audio
        // if (audioElement.paused === false) {
        //   audioElement.pause();
        // } else {
        //   audioElement.play();
        // }
      },
      submitRecording() {
        var blob = new Blob(this.recordingData , { type: 'audio/webm;codecs="opus"'});
        var formData = new FormData();
        formData.append('recording', blob);
        formData.append('lesson_uuid', this.$route.params.id || this.$router.params.lesson_uuid);
        formData.append(this.modelType.toLowerCase(), this.modelId);
        formData.append('filename', (this.text || 'file').toLowerCase().replace(/[\s?!'"]+/g, '_'));
        this.$http.post(`${nodeApi}/${this.modelType.toLowerCase()}s/${this.modelId}/audio`, formData).then( (res) => {

          console.log({res})
          var msg = {}
          msg[this.modelType.toLowerCase()] = {id: this.modelId}
          msg.audio = res.data
          if (res.data.transcription) {
            this.$emit('voice-recognition-result', res.data.transcription.split(/[ \?!,\.]/))
          }

          this.$store.dispatch('addAudio', msg)
          this.$emit('audio-created')
        }, err => {
          console.log({ err })

          this.dataUrl = '';
          this.$toast.open({
            duration: 5000,
            message: err.body.message,
            type: "error"
          });

          if (err.user) {
            this.$store.dispatch('setUser', Object.assign({}, this.$store.getters.user, err.user))
          }
        })
        this.$mixpanel.track("Sentence - Record audio");
      }
    },
  }
</script>

<style lang="scss">
  @import 'colors';

  .ui--recorder {

  .button {
    transition: all .3s ease;
    width: 79px;
    position: relative;
    /*width: 40px;*/
    /*height: 40px;*/
  }
  .empty {
    .button {
      width: 30px
    }
  }
  .button-recorder:active {
    -webkit-animation: bgflicking 3s infinite;
    background-color: $info !important;
  }
  }

  @keyframes bgflicking {
    0% {
      transform: scale(1);
      background-color: $info !important;
    }
    25% {
      transform: scale(1.05);
      background-color: darken($info, 10) !important;
    }
    50% {
      transform: scale(.95);
      background-color: $info !important;
    }
    75% {
      transform: scale(1.05);
      background-color: darken($info, 20) !important;
    }
    100% {
      transform: scale(1);
      background-color: $info !important;
    }
  }
</style>
