<template lang="pug">
  div.search-sentence
    div.button.is-fullwidth.has-flex-start-items(
      v-if="selection"
      @click="selection = null"
    )
      b-icon(icon="short-text")
      span
        | {{selection.content}}
    div#search_sentence(
      v-else
    )
      i.fa.fa-spinner.fa-spin(v-if="loading")
      template(v-else="")
        i.fa.fa-search(v-show="isEmpty")
        i.fa.fa-times(v-show="isDirty" @click="reset")
      // the input field
      b-field.mb-0
        b-input#search_sentence_btn(
        icon="search"
        type="search"
        placeholder="Content"
        autocomplete="off"
        v-model="query"
        @keydown.native.prevent.down="down"
        @keydown.native.prevent.up="up"
        @keydown.native.enter="hit"
        @keydown.native.esc="reset"
        @input="update")
      <!--@blur="reset"-->
      // the list
      ul.box(v-show="hasItems")
        // for vue@1.0 use: ($item, item)
        li.mt-2.is-clickable(v-for="(option, $item) in items" :class="activeClass($item)" @mousedown="hit" @mousemove="setActive($item)")
          | {{option.content}}
          em.is-block.is-size-7
            | {{option.translation}}
      ul.box(v-show="query && query.length && !hasItems")
        li(@mousedown="reset") No results


</template>

<script>
import VueTypeahead from "vue-typeahead";
import { mapGetters } from "vuex";
import each from "lodash/each";
import filter from "lodash/filter";
import includes from "lodash/includes";
import sortBy from "lodash/sortBy";
import toLower from "lodash/toLower";
import uniqBy from "lodash/uniqBy";

export default {
  name: "SearchSentence",
  props: ['value'],
  components: {
  },
  extends: VueTypeahead,
  data() {
    return {
      query: null,
      src: "...",
      limit: 15,
      minChars: 2,
      selectFirst: true,
      selection: null
    };
  },
  computed: {
    ...mapGetters(["sentencesOptions"]),
    options() {
      var opts = [];

      each(uniqBy(this.sentencesOptions, 'content'), function(sentence) {
        opts.push({
          id: parseInt(sentence.id),
          content: toLower(sentence.content),
          translation: sentence.translation,
        });
      });
      return sortBy(opts, (opt) => (opt.content || "").length);
      // this.sentencesOptions;
    }
  },
  methods: {
    onHit(selection) {
      if (selection && selection.id) {
        this.selection = selection;
        this.$emit('input', selection.id);
        this.reset();
      }
    },
    fetch() {
      return new Promise((resolve, reject) => {
        const query = toLower(this.query);
        resolve({
          data: filter(this.options, opt =>
            includes(opt.content || "", query)
          )
        });
        reject();
      });
    },
  }
};
</script>

<style lang="scss">
@import "colors";

.search-sentence {
  .button.is-fullwidth {
    overflow: hidden;
    justify-content: flex-start;
  }
}
#search_sentence {
  ul.box {
    position: absolute;
    max-width: 390px;
    min-width: 390px;
    z-index: 10;
    max-height: 300px;
    overflow: auto;
    li.active {
      color: $primary;
    }
  }
}
</style>
