`import {Correction} from '../models/resources'`
`import { nodeApi } from "@/config";`

actions =
  getCorrections: (context) ->
    Correction.query().then (response) ->
      context.commit('corrections', response.body.corrections)

  addCorrection: (context, correction) ->
#    if correction.content && correction.content.length
    new Promise((resolve, reject) ->
      Correction.save(correction: correction).then(
        (res) ->
          context.commit('addCorrection', Object.assign(correction, {id:  parseInt(res.body.id)}))
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )

  editCorrection: (context, correction) ->
    new Promise (resolve, reject) ->
      context.commit('editCorrection', correction)
      resolve()

  deleteCorrection: (context, correction) ->
    if correction.id
      Correction.delete({id: correction.id}).then (res) ->
        context.commit('deleteCorrection', correction)
    else
      context.commit('deleteCorrection', correction)

  saveCorrection:  (context, correction) ->
    rs = Correction

    new Promise((resolve, reject) ->
      if correction.id
#        context.commit('editCorrection', correction)
        rs.update({id: correction.id}, {correction: correction}).then(
          (res) ->
            resolve(res.body)
        , (res) ->
          reject(res)
        )
      else
#        context.commit('editCorrection', correction)
        rs.save(correction: correction).then(
          (res) ->
#            context.commit('editCorrection', Object.assign(correction, {id:  parseInt(res.body.id)}))
            resolve(res.body)
        , (res) ->
          reject(res.body)
        )
    )


`export default actions`
