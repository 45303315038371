<template lang="pug">
  div
    div(v-for="(lessonsByTeacher, lang, index) in groupedLessons" :class="'section-'+lang")
      <!--hr.separator(:class="'idx-'+index" v-if="differentKindNumber != 1")-->
      <!--span.on-separator(v-if="differentKindNumber != 1")-->
        <!--i.flag(:class="'flag-'+lang")-->


      div(v-for="(less, teacherName, index) in lessonsByTeacher" :class="'section-'+lang")

        h4.has-text-left.subtitle.mt-5(v-if="less[0].user.id !== user.id")
          .columns
            .column
              router-link.is-black(:to="{name: 'TeacherPage', params: {id: less[0].user.id}}")
                teacher(:teacher="less[0].user" :index="index")
            .column.is-narrow
              router-link.button.is-info.is-outlined.button-see-last(:to="{name: 'allForTeacher', params: {teacher_id: less[0].user.id}}")
                b-icon(icon="remove-red-eye" size="is-small")
                span See last {{ less.length > 3 ? '3' : less.length }} lessons

        .columns.is-multiline
          b-table.w-100(
            :paginated="less.length > 5"
            :per-page="5"
            :pagination-simple="true"
            v-if="!ifIsEmpty || (!lesson[ifIsEmpty] || !lesson[ifIsEmpty].length)"

            :data="less"
            )
            b-table-column(v-slot="props" field="language_to" label="" width="40")
              i.flag(:class="'flag-'+props.row.language_to")
            b-table-column(v-slot="props" field="title" label="Title" sortable)
              router-link.is-block.is-smooth(:to="links[props.row.id]" @mouseenter.native="preload(props.row)")
                | {{ props.row.title }}
            b-table-column(v-slot="props" field="created_at" label="Created at" width="150" sortable)
              | {{ props.row.created_at | moment("DD.MM") }}
            <!--b-table-column.has-text-right(label="Actions" width="150")-->
              <!--button.button(-->
                <!--v-if="!readOnly && lesson.uuid"-->
                <!--aria-label="Delete the lesson"-->
                <!--@click.stop="removeLesson(lesson)"-->
                <!--v-confirm="'Are you sure you want to <b>delete the lesson</b>?'")-->
                <!--b-icon(icon="delete" custom-size="mdi-20px")-->

    div(v-if="!differentKindNumber")
      .columns.is-multiline
        .column.is-one-quarter(v-if="!readOnly")
          button#btn_add_lesson.button.is-primary.is-outlined.is-fullwidth.match-lesson(@click="addLesson('en-US')")
            b-icon(icon="add")
            span Add
        .column.is-12(v-else)
          article.message.is-grey.has-text-left
            .message-body
              | {{ emptyMessage ? emptyMessage : "You haven't seen any lesson yet" }}
</template>

<script>
import {mapGetters} from 'vuex';
import Lesson from "@/components/Lesson";
import TeacherMiniCardUI from '@/components/TeacherMiniCardUI'
import groupBy from "lodash/groupBy";
import each from "lodash/each";
import uniq from "lodash/uniq";
import map from "lodash/map";
import debounce from "lodash/debounce";

export default {
  name: "LessonsList",
  components: {
    lesson: Lesson,
    teacher: TeacherMiniCardUI
  },
  props: ["lessons", "groupBy", "readOnly", "addOptions", "ifIsEmpty", "emptyMessage"],
  data() {
    return {
      preloadIsActive: true,
    };
  },
  computed: {
    ...mapGetters(['user']),
    groupedLessons() {
      const sorted = groupBy(this.lessons, this.groupBy || "language_to");
      // console.log(sorted)
      each(sorted, (lessons, lang) => {
        sorted[lang] = groupBy(lessons, (lesson) => `${lesson.user.id}|${lesson.user.first_name}`);
      })
      // console.log(sorted)
      return sorted
    },
    differentKindNumber() {
      return uniq(map(this.lessons, "language_to")).length;
    },
    links() {
      const links = {};
      this.lessons.forEach(lesson => {
        if (lesson) {
          if (lesson.has_package || lesson.has_readonly) {
            links[lesson.id] = {name: 'ReadOnlyPackageLesson', params: {lesson_uuid: lesson.uuid}}
          } else {
            links[lesson.id] = {name: 'LiveLesson', params: {id: lesson.uuid}};
          }
        }
      })
      return links
    }
  },
  methods: {
    addLesson(lang) {
      this.preloadIsActive = false;
      var opts = { language_to: lang || "en-US", visitors: [], user: this.user, user_id: this.user.id };
      if (this.addOptions) {
        Object.assign(opts, this.addOptions);
      }
      this.$store.dispatch("addLesson", opts).then(() => {
        setTimeout(function() {
          document.querySelector(".lesson__title input").focus();
        }, 400);
      });
      this.$mixpanel.track("Lesson - Add");
    },
    removeLesson(lesson) {
      this.$store.dispatch("deleteLesson", lesson);
    },
    preload: debounce(function(lesson) {
      this.$store.dispatch("findLesson", { id: lesson.uuid })
      // if (lesson.id && this.preloadIsActive) {
      //   console.log("PRELOADING");
      //   this.$store.dispatch("setLesson", lesson);
      // }
    }, 300)
  }
};
</script>

<style lang="scss" scoped>
/*@import 'colors';*/

.match-lesson {
  min-height: 54px;
}

.column {
  position: relative;
}


td[data-label="Title"] {
  min-width: 290px;
}

.lesson__delete {
  position: absolute;
  top: -5px;
  right: -3px;
  display: none;
  height: 37px;
  min-height: 37px;
  line-height: 19px;
  border-radius: 50%;
  .mdi {
    font-size: 20px;
  }
}

.column.is-lesson:hover {
  .lesson__delete {
    display: block;
  }
}
</style>
