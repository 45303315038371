
<template lang="pug">
  .ui--pronunciation-recorder(
  )
    button.button.has-justified-start.button-recorder.is-small(
      v-if="browser.name === 'chrome'"
      @mousedown.prevent="startRecording"
      @mouseup.prevent.stop="endRecording"
      @mouseout.prevent.stop="endRecording"
      @touchstart.prevent="startRecording"
      @touchend.prevent="endRecording"
      :class=`{
        'is-primary': !dataUrl.length,
        'has-loader is-loading': loading,
        [success]: dataUrl.length
        }`
      )
      b-icon(icon="mic" size="is-small")
      span.p-0 Record
    | &nbsp;
    b-tooltip(
      v-else
      label="To record yourself, please use Chrome"
      type='is-light'
      :animated='true'
      position='is-top')
      button.button.is-light.is-disabled.is-small.has-justified-start
        b-icon(icon="mic" size="is-small")
        span.p-0 Record
    //transition(name="bounce")
    //  slot(:blob="dataUrl" v-if="dataUrl.length && words.length")

    .pronunciation-assessment-results-wrapper.is-flex.is-justify-content-center
      table.is-size-4.pronunciation-assessment-results.mt-4.mb-6(v-if="words.length" )
        tbody
          tr
            td.word.pr-2(
              v-for="(word, idx) in words"
              :key="idx"
            )
              span.fullword.is-clickable(
                :data-db-ipa="language === 'en-US' ? (ipa[idx] || {}).content : ''"
                @click="speak(word.Word, language, $event)"
                :class=`{
                  'pronunciation-assessment-color-1': word.PronunciationAssessment.AccuracyScore >= 95,
                  'pronunciation-assessment-color-2': word.PronunciationAssessment.AccuracyScore < 95 && word.PronunciationAssessment.AccuracyScore >= 90,
                  'pronunciation-assessment-color-3': word.PronunciationAssessment.AccuracyScore < 90 && word.PronunciationAssessment.AccuracyScore >= 75,
                  'pronunciation-assessment-color-4': word.PronunciationAssessment.AccuracyScore < 75 && word.PronunciationAssessment.AccuracyScore >= 50,
                  'pronunciation-assessment-color-5': word.PronunciationAssessment.AccuracyScore < 50
                }`
              )
                | {{ word.Word }}
            td
              .is-flex.valign
                transition(name="bounce")
                  .button-sentence--listen(v-if='sentenceStr && words.length' )
                    tts-button.sentence--listen.is-flex(
                      :tts="sentenceStr"
                      :tooltip="false"
                      icon="hearing"
                      :lang="language"
                    )

                transition(name="bounce")
                  audio-player.is-inverted.is-primary.ml-1(
                    v-if="dataUrl"
                    icon="record-voice-over"
                    :can-delete="false"
                    :blob="dataUrl"
                    label=""
                  )

          tr( v-if="hasPhonemes" )
            td.word.phoneme.pr-2(
              v-for="(word, idx) in words"
              :key="idx"
              )

              div.is-inline(
                v-for="(phoneme, i) in word.Phonemes" :key="i"
              )

                b-dropdown(
                  :triggers="['hover']"
                  v-if="phoneme.PronunciationAssessment.AccuracyScore < 90 && phoneme.PronunciationAssessment.NBestPhonemes[0] && phoneme.PronunciationAssessment.NBestPhonemes[0].Phoneme != phoneme.Phoneme"
                  position="is-bottom-right"
                  paddingless
                )
                  span(
                    slot="trigger"
                    :class=`{
                      'pronunciation-assessment-color-1': phoneme.PronunciationAssessment.AccuracyScore >= 95,
                      'pronunciation-assessment-color-2': phoneme.PronunciationAssessment.AccuracyScore < 95 && phoneme.PronunciationAssessment.AccuracyScore >= 90,
                      'pronunciation-assessment-color-3': phoneme.PronunciationAssessment.AccuracyScore < 90 && phoneme.PronunciationAssessment.AccuracyScore >= 75,
                      'pronunciation-assessment-color-4': phoneme.PronunciationAssessment.AccuracyScore < 75 && phoneme.PronunciationAssessment.AccuracyScore >= 50,
                      'pronunciation-assessment-color-5': phoneme.PronunciationAssessment.AccuracyScore < 50
                    }`
                  )
                    | {{ phoneme.Phoneme }}

                  b-dropdown-item(
                    custom
                  )
                    .modal-card.is-flex.is-flex-direction-column
                      p.mb-0.has-text-grey-light You said
                      .is-flex.mx-auto.valign
                        b.is-size-4.pronunciation-assessment-color-5 {{ phoneme.PronunciationAssessment.NBestPhonemes[0].Phoneme }}
                        span.px-2.pt-1 like in
                        span.is-size-4.is-clickable.pronunciation-assessment-color-5.pronunciation-hint(
                          v-html="(hints[language] || {})[phoneme.PronunciationAssessment.NBestPhonemes[0].Phoneme]"
                          @click="speak($event.target.innerText, language, $event)"
                        )
                      p.mb-0.mt-4.has-text-grey-light instead of

                      .is-flex.mx-auto.valign
                        b.is-size-4.pronunciation-assessment-color-1 {{ phoneme.Phoneme }}
                        span.px-2.pt-1 like in
                        span.is-clickable.pronunciation-assessment-color-1.is-size-4.pronunciation-hint(,
                          @click="speak($event.target.innerText, language, $event)"
                          v-html="(hints[language] || {})[phoneme.Phoneme]"
                        )

                span(
                  v-else
                  :class=`{
                      'pronunciation-assessment-color-1': phoneme.PronunciationAssessment.AccuracyScore >= 95,
                      'pronunciation-assessment-color-2': phoneme.PronunciationAssessment.AccuracyScore < 95 && phoneme.PronunciationAssessment.AccuracyScore >= 90,
                      'pronunciation-assessment-color-3': phoneme.PronunciationAssessment.AccuracyScore < 90 && phoneme.PronunciationAssessment.AccuracyScore >= 75,
                      'pronunciation-assessment-color-4': phoneme.PronunciationAssessment.AccuracyScore < 75 && phoneme.PronunciationAssessment.AccuracyScore >= 50,
                      'pronunciation-assessment-color-5': phoneme.PronunciationAssessment.AccuracyScore < 50
                    }`
                )
                  | {{ phoneme.Phoneme }}

              //-b-tooltip(
                v-for="(phoneme, i) in word.Phonemes" :key="i"
                type="is-dark"
                position="is-bottom")
                //-template(v-slot:content)
                  span(v-if="phoneme.PronunciationAssessment.AccuracyScore < 90 && phoneme.PronunciationAssessment.NBestPhonemes[0] && phoneme.PronunciationAssessment.NBestPhonemes[0].Phoneme != phoneme.Phoneme")

            td

          tr(v-else)
            td.word.phoneme.pr-2(
              v-for="(word, idx) in words"
              :key="idx"
            )
              span(
                v-for="(phoneme, i) in word.Phonemes" :key="i"
                :class=`{
                  'pronunciation-assessment-color-1': phoneme.PronunciationAssessment.AccuracyScore >= 95,
                  'pronunciation-assessment-color-2': phoneme.PronunciationAssessment.AccuracyScore < 95 && phoneme.PronunciationAssessment.AccuracyScore >= 90,
                  'pronunciation-assessment-color-3': phoneme.PronunciationAssessment.AccuracyScore < 90 && phoneme.PronunciationAssessment.AccuracyScore >= 75,
                  'pronunciation-assessment-color-4': phoneme.PronunciationAssessment.AccuracyScore < 75 && phoneme.PronunciationAssessment.AccuracyScore >= 50,
                  'pronunciation-assessment-color-5': phoneme.PronunciationAssessment.AccuracyScore < 50
                }`
              )
                | {{ ((ipa[idx] || {}).content || "") | splitByPhoneme(i) }}
                span(v-if="i == (word.Phonemes.length - 1)")
                  | {{ ((ipa[idx] || {}).content || "") | remainingSplitByPhoneme(i) }}

    transition(name="bounce")
      b-tooltip.mt-4.mx-auto.score(
        v-if="pronunciationScore || pronunciationScore === 0"
        :label=`scoreLabel`
        type="is-dark"
        position="is-bottom"
        :class=`{
                'bigger-number': pronunciationScore < 100,
                'pronunciation-assessment-color-1': pronunciationScore >= 95,
                'pronunciation-assessment-color-2': pronunciationScore < 95 && pronunciationScore >= 90,
                'pronunciation-assessment-color-3': pronunciationScore < 90 && pronunciationScore >= 75,
                'pronunciation-assessment-color-4': pronunciationScore < 75 && pronunciationScore >= 50,
                'pronunciation-assessment-color-5': pronunciationScore < 50
              }`
        )
        | {{ pronunciationScore }}
</template>

<script>
import {mapGetters} from "vuex";

const mimeType = 'audio/webm;codecs="opus"';

import Audio from '@/services/Audio.js.coffee'

const { detect } = require('detect-browser');
import every from 'lodash/every';
const browser = detect();
import { nodeApi } from '@/config';
import GraphemeSplitter from 'grapheme-splitter';
import Sentence from "./Sentence";
import TtsButton from "./TtsButton";
import AudioPlayer from "./AudioPlayer";
import {IPA_TO_WORD_EN_GB, IPA_TO_WORD_EN_US} from "../constants";
const splitter = new GraphemeSplitter();


export default {
  name: 'PronunciationRecorder',
  components: {AudioPlayer, TtsButton, Sentence},
  props: [
    'label',
    'sentenceStr',
    'language',
    'successClass'
  ],
  data() {
    return {
      browser,
      audioRecorder: null,
      recordingData: [],
      dataUrl: '',
      voiceRecognition: {},
      words: [],
      loading: false,
      accuracyScore: null,
      pronunciationScore: null,
      audio: null,
      ipa: [],
      hints: {
        'en-US': IPA_TO_WORD_EN_US,
        'en-GB': IPA_TO_WORD_EN_GB
      }
    }
  },
  watch: {
    // dataUrl() {
    //   this.submitRecording()
    // }
  },
  filters: {
    splitByPhoneme: function(value, idx) {
      const newVal = (value || "").replace(/\./g, '')

      return splitter.splitGraphemes(newVal)[idx];
    },
    remainingSplitByPhoneme: function(value, idx) {
      const newVal = (value || "").replace(/\./g, '')

      console.log({ remainingSplitByPhoneme: true, newVal, slice: splitter.splitGraphemes(newVal).slice(idx+1) })

      return splitter.splitGraphemes(newVal).slice(idx+1).join('');
    }
  },
  computed: {

    scoreLabel() {
      let str = "";
      str += `Pronunciation: ${this.pronunciationScore}\n\n`
      str += `Fluency: ${this.fluencyScore}\n`
      str += `Accuracy: ${this.accuracyScore}`
      return str;
    },
    success() {
      return this.successClass ? this.successClass : 'is-white'
    },
    hasPhonemes() {
      return every(this.words.flatMap(word => word.Phonemes), (phoneme) => phoneme.Phoneme !== "");
    },
    ...mapGetters(['voices'])
  },
  mounted () {
    this.audio = Audio.init()
  },
    methods: {
      speak: function(text, lang, event) {
        this.audio.speak(text,  event, lang || 'en', this.voices[lang])
      },
      toggleRecording() {
        if (this.audioRecorder && this.audioRecorder.state === "recording") {
          this.endRecording()
        } else {
          this.startRecording()
        }
      },
      startRecording() {
        // const mimeType = this.browser.name === "safari" ? "audio/mp4" : 'audio/webm;codecs="opus"';
        this.recordingData = []
        this.dataUrl = ''
        navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        }).then( (stream) => {
            try {
              this.audioRecorder = new MediaRecorder(stream, {mimeType });
              this.audioRecorder.ondataavailable = (event) => {this.recordingData.push(event.data);}
              this.audioRecorder.onstop = () => {
                const blob = new Blob(this.recordingData, {type: mimeType});
                this.dataUrl = window.URL.createObjectURL(blob);
                this.submitRecording()
              }
              this.audioRecorder.start();
            } catch (e) {
              console.log({e})
            }
          }
          // console.log('Media recorder started');
        ).catch( error => {
          console.log({error});
        });
      },
      endRecording() {
        if (this.audioRecorder && this.audioRecorder.state === "recording") {
          this.audioRecorder.stop();
        }
      },
      submitRecording() {
        this.loading = true;
        var blob = new Blob(this.recordingData , { type: mimeType });
        var formData = new FormData();
        formData.append('recording', blob);
        formData.append('sentence', this.sentenceStr);
        formData.append('language', this.language);

        let url = `${nodeApi}/audios/assess_pronunciation`;
        if (window.location.href.includes("no_ipa")) {
          url += "?phonetic=SAPI"
        }

        this.$http.post(url, formData).then( (res) => {
          console.log({ res })
          const {words, scores, wordLevelResult} = res.body;
          console.log({words, scores, wordLevelResult});
          const {FluencyScore, PronScore, AccuracyScore} = scores;
          // setTimeout(() => {
          // this.pronunciationScore = Math.floor(PronScore)
          // }, 800)
          this.pronunciationScore = Math.floor(PronScore)
          this.fluencyScore = Math.floor(FluencyScore)
          this.accuracyScore = Math.floor(AccuracyScore)

          this.words = wordLevelResult;
          this.$emit('input', wordLevelResult);
          this.ipa = words;
          this.loading = false;
        }, err => {
          this.loading = false;
          this.$toast.open({
            duration: 5000,
            message: err.body.message,
            type: "error"
          });
        })
        this.$mixpanel.track("Sentence - Pronunciation Assessment");
      }
    },
  }
</script>

<style lang="scss">
@import 'colors';

.pronunciation-assessment-results {
  color: #d8d7d7;
  margin-right: -96px;
  position: relative;
  font-weight: 700;

  .fullword {
    position: relative;
    &:after {
      pointer-events: none;
      position: absolute;
      top: 4.25rem;
      width: 200%;
      text-align: center;
      font-size: .8em;
      color:rgba(182,182,182,.57);
      content: attr(data-db-ipa);
      left: -50%;
    }
  }
  td {
    vertical-align: middle;
    .dropdown-menu {
      margin-left: -50%;
    }
  }
  .pronunciation-hint {
    font-weight: 400;
    &:after {
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 16px;
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      content:"\e91f";
      margin-left: 4px;
    }
    b {
      pointer-events: none;
    }
  }
  .button-sentence--listen {
    height: 30px;
  }
  [class^="pronunciation-assessment-color"] {
    padding: 0 0.1rem;
    //&:hover {
    //  border-bottom: 1px solid #dedede;
    //}
  }

//.word:not(:last-child):after {
//  content: "•";
//  color: #e0d8ff;
//  padding-left: 8px;
//  opacity: 0.5;
//}
}

.ui--pronunciation-recorder {
position: relative;

.score {
transition: all .3s cubic-bezier(0.2, 0.8, 0.2, 1);
border: 8px solid transparent;
padding: 5px;
border-radius: 50%;
line-height: 60px;
font-size: 2rem;
font-weight: 900;
width: 85px;
height: 85px;
box-shadow: 1px 1px 2px 1px rgba(124,118,118,.54);
position: absolute;
right: 4rem;
bottom: -4rem;
background-color: #FFF;
&.bigger-number {
font-size: 2.5rem;
}
}
td {
text-align: center;
padding: 0 1rem;
}
.phoneme {
font-size: 0.9em;
}

.tooltip-content {
line-height: 22px;
text-align: left;
white-space: pre;
}

}
.pronunciation-assessment-results-wrapper {
position: relative;

.button-sentence--listen {

}
}

.pronunciation-assessment-color-1 { color: $resultScore5; border-color: $resultScore5 !important}
.pronunciation-assessment-color-2 { color: $resultScore4; border-color: $resultScore4 !important}
.pronunciation-assessment-color-3 { color: $resultScore3; border-color: $resultScore3 !important}
.pronunciation-assessment-color-4 { color: $resultScore2; border-color: $resultScore2 !important}
.pronunciation-assessment-color-5 { color: $resultScore1; border-color: $resultScore1 !important}

.button-recorder:active {
-webkit-animation: bgflicking 3s infinite;
background-color: $info !important;
}

@keyframes bgflicking {
0% {
transform: scale(1);
background-color: $info !important;
}
25% {
transform: scale(1.05);
background-color: darken($info, 10) !important;
}
50% {
transform: scale(.95);
background-color: $info !important;
}
75% {
transform: scale(1.05);
background-color: darken($info, 20) !important;
}
100% {
transform: scale(1);
background-color: $info !important;
}
}
</style>
