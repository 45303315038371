<template lang="pug">
  .container
    .box.has-shadow
      .columns
        .column
          h4.title.has-text-grey.has-text-left
            .columns
              .column
                student(:student="student" index="1" size="is-large")
              .column.is-narrow
                router-link.button.is-outlined.is-small.mr-1(:to="{name: 'allSentencesForStudent', params: {student_id: student.id}}")
                  b-icon(icon="view-headline" size="is-small")
                  span See all sentences
                router-link.button.is-outlined.is-small(:to="{name: 'allForStudent', params: {student_id: student.id}}")
                  b-icon(icon="remove-red-eye" size="is-small")
                  span See last {{ lessonsForStudent.length > 3 ? '3' : lessonsForStudent.length }} lessons
      p.subtitle.has-text-grey

      section
        lessons-list(
          size="is-3"
          :lessons="lessonsForStudent"
          :key="lessons.length"
          group-by="language_to"
          :add-options="{student_id: studentId}")

</template>


<script>
import LessonsList from "@/components/LessonsList";
import StudentMiniCardUI from "@/components/StudentMiniCardUI";
import { mapGetters } from "vuex";
import includes from "lodash/includes";
import filter from "lodash/filter";

export default {
  name: "StudentPage",
  components: {
    lessonsList: LessonsList,
    student: StudentMiniCardUI
  },
  data() {
    return {
      studentId: null
    };
  },
  computed: {
    ...mapGetters(["lessons", "student"]),
    lessonsForStudent() {
      if (!this.student || !this.student.lesson_ids) {
        return [];
      }
      return filter(
        this.lessons,
        lesson =>
          includes(this.student.lesson_ids, lesson.id + "") ||
          lesson.student_id && lesson.student_id === this.student.id
      );
    }
  },
  created() {
    this.studentId = this.$route.params.id;
    this.$store.dispatch("findStudent", { id: this.$route.params.id });
    this.$store.dispatch("getLessons");
  }
};
</script>


<style lang="scss" scoped>
@import "colors";

$figureSize: 36px;
$imgSize: 32px;

.tabs a {
  font-weight: 600;
}
</style>
