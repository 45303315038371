<template lang="pug">
  div#search_everywhere(
  v-shortkey="['shift','/']"
  @shortkey="search()"
  )
    i.fa.fa-spinner.fa-spin(v-if="loading")
    template(v-else="")
      i.fa.fa-search(v-show="isEmpty")
      i.fa.fa-times(v-show="isDirty" @click="reset")
    // the input field
    b-field.mb-0
      b-input#search_everywhere_btn(
      icon="search"
      type="search"
      placeholder="Search"
      autocomplete="off"
      v-model="query"
      @keydown.native.prevent.down="down"
      @keydown.native.prevent.up="up"
      @keydown.native.enter="hit"
      @keydown.native.esc="reset"
      @input="update")
    <!--@blur="reset"-->
    // the list
    ul.box(v-show="hasItems")
      // for vue@1.0 use: ($item, item)
      li(v-for="(option, $item) in items" :class="activeClass($item)" @mousedown="hit" @mousemove="setActive($item)")
        b-icon(:icon="option.icon" v-if="option.type === 'le' && option.icon" size="is-medium")
        span(v-if="option.type === 'le'")
          | {{ option.title }}

        // STUDENT
        student(:student="option" v-if="option.type !== 'le'")
    ul.box(v-show="query && query.length && !hasItems")
      li(@mousedown="reset") No results


</template>

<script>
import VueTypeahead from "vue-typeahead";
import StudentMiniCardUI from "@/components/StudentMiniCardUI";
import { mapGetters } from "vuex";
import each from "lodash/each";
import uniqBy from "lodash/uniqBy";
import toLower from "lodash/toLower";
import filter from "lodash/filter";
import includes from "lodash/includes";


export default {
  name: "SearchEverywhere",
  components: {
    student: StudentMiniCardUI
  },
  extends: VueTypeahead,
  data() {
    return {
      query: null,
      src: "...",
      limit: 10,
      minChars: 2,
      selectFirst: true
    };
  },
  computed: {
    ...mapGetters(["lessons", "visitedLessons", "students", "usersById"]),
    usersInteractedWith() {
      return Object.values(this.usersById);
    },
    options() {
      var opts = [];
      each(this.usersInteractedWith, function(studentOrTeacher) {
        opts.push({
          type: "st",
          title: studentOrTeacher.first_name,
          picture: studentOrTeacher.picture,
          first_name: studentOrTeacher.first_name,
          link: studentOrTeacher.is_teacher ? `/teachers/${studentOrTeacher.id}` : `/students/${studentOrTeacher.id}`
        });
      });
      each(uniqBy(this.lessons.concat(this.visitedLessons), 'id'), function(lesson) {
        opts.push({
          type: "le",
          title: lesson.title,
          icon: lesson.icon,
          link: `/lessons/${lesson.uuid}`
        });
      });

      return opts;
    }
  },
  methods: {
    redirectWhenSelect(selection) {
      if (selection) {
        this.$router.push(selection.link);
      }
    },
    onHit(selection) {
      if (selection && selection.link) {
        this.$router.push(selection.link);
        this.reset();
      }
    },
    fetch() {
      return new Promise((resolve, reject) => {
        const query = toLower(this.query);
        resolve({
          data: filter(this.options, opt =>
            includes(toLower(opt.title || ""), query)
          )
        });
        reject();
      });
    },
    search() {
      document.getElementById("search_everywhere_btn").focus();
    }
  }
};
</script>

<style lang="scss">
@import "colors";

#search_everywhere {
  position: relative;
  #search_everywhere_btn {
    width: 280px;
  }
  img {
    max-height: initial;
  }
  ul {
    padding: 0;
    width: 280px;
    position: absolute;
    top: 40px;
    z-index: 60;
    li {
      text-align: left;
      padding: 10px 10px;
      &.active,
      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
    li span {
      vertical-align: middle;
    }
  }
}
@media (max-width: 768px) {
  #search_everywhere #search_everywhere_btn {
    width: 100%;
  }
}
</style>
