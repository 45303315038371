import Vue from "vue";
import VueResource from "vue-resource";
Vue.use(VueResource);

import { nodeApi, railsApi } from "../../config";
import EventRs from './event'

if (process.env.NODE_ENV !== "production") {
  Vue.http.options.root = nodeApi;
} else {
  Vue.http.options.root = nodeApi;
}

export const Correction = Vue.resource(
  `${nodeApi}/corrections{/id}`,
  {},
  {
    use: { method: "POST", url: `${nodeApi}/corrections{/id}/apply`},
    skip: { method: "POST", url: `${nodeApi}/corrections{/id}/skip`},
  }
  );
export const Activity = Vue.resource(`${nodeApi}/activities{/id}`);
export const FreeLessonToken = Vue.resource(`${nodeApi}/teachers{/id}/free_lesson_tokens`);
export const Audio = Vue.resource(
    `${nodeApi}/sentences{/model_id}/audio{/id}`,
    {},
    {
      add: { method: "POST", url: `${nodeApi}/sentences{/id}/audio`},
    }
);

export const Sentence = Vue.resource(
  `${nodeApi}/sentences{/id}`,
  {},
  {
    translate: { method: "POST", url: `${nodeApi}/sentences{/id}/translate`},
    demoTranslate: { method: "POST", url: `${nodeApi}/sentences/translate`},
    analysis: { method: "GET", url: `${nodeApi}/sentences{/language}/analysis`},
    clone: { method: "POST", url: `${nodeApi}/sentences/clone`},
    replace: { method: "PUT", url: `${nodeApi}/sentences{/id}/replace`},
    paste: {method: "POST", url: `${nodeApi}/sentences/paste`},
    allForStudent: { method: "GET", url: `${nodeApi}/students{/id}/sentences`},
    allForAdmin: { method: "GET", url: `${nodeApi}/sentences/admin`},
  }
);
export const Lesson = Vue.resource(
  `${nodeApi}/lessons{/id}`,
  {},
  {
    // savePicture: { method: "PUT", url: `${nodeApi}/lessons{/id}`, headers: { 'Content-Type': 'multipart/form-data' }},
    batchDestroy: { method: "DELETE", url: `${nodeApi}/lessons`},
    exportAll: { method: "GET", url: `${nodeApi}/lessons/export_all.csv`},
    removePicture: { method: "PUT", url: `${nodeApi}/lessons{/id}/remove_picture`},
    allForTeacher: { method: "GET", url: `${nodeApi}/teachers{/id}/lessons`},
    publicForTeacher: { method: "GET", url: `${nodeApi}/teachers{/id}/shared_publicly`},
    allForStudent: { method: "GET", url: `${nodeApi}/students{/id}/lessons`},
    sharedPublicly: { method: "GET", url: `${nodeApi}/lessons/shared_publicly`},
    countChart: { method: "GET", url: `${nodeApi}/lessons/count_chart`},
    last: { method: "GET", url: `${nodeApi}/lessons/last`},
    queuePodcast: { method: "POST", url: `${nodeApi}/lessons{/id}/queue_podcast`},
    updateSentencesOrder: { method: "POST", url: `${nodeApi}/lessons{/id}/update_sentences_order`},
    copy: { method: "POST", url: `${nodeApi}/lessons{/id}/copy`},
    duplicate: { method: "POST", url: `${nodeApi}/lessons{/id}/duplicate`},
    createPublic: { method: "POST", url: `${nodeApi}/lessons/create_public`},
    countChartDow: { method: "GET", url: `${nodeApi}/lessons/count_chart_dow`},
    generateTtsAudio: { method: "GET", url: `${nodeApi}/lessons{/id}/generate_tts_audio`},
    translateAll: { method: "POST", url: `${nodeApi}/lessons{/id}/sentences/translate_all`},
    unlock: { method: "POST", url: `${nodeApi}/lessons{/id}/unlock`},
    all: { method: "GET", url: `${nodeApi}/lessons/all`},
  }
);
export const Payment = Vue.resource(`${nodeApi}/payments{/id}`);
export const Purchase = Vue.resource(`${nodeApi}/purchases{/id}`);
export const Pack = Vue.resource(`${nodeApi}/packs{/id}`);
export const Coupon = Vue.resource(`${nodeApi}/coupons{/id}`);
export const VisitedLesson = Vue.resource(`${nodeApi}/visited_lessons{/id}`, {}, {
  index: { method: "GET", url: `${nodeApi}/lessons{/id}/visited_lessons` },
});
export const User = Vue.resource(
  `${nodeApi}/users{/id}`,
  {},
  {
    resetPasswordEmail: { method: "POST", url: `${nodeApi}/auth/reset_password_email` },
    resetPassword: { method: "POST", url: `${nodeApi}/auth/reset_password` },
    stripeConnect: { method: "POST", url: `${nodeApi}/auth/stripe_connect` },
    mollieConnect: { method: "POST", url: `${nodeApi}/auth/mollie_connect` },
    authGoogle: { method: "POST", url: `${nodeApi}/auth/google` },
    findTeacher: { method: "GET", url: `${nodeApi}/teachers{/id}` },
    countChart: { method: "GET", url: `${nodeApi}/users/count_chart`},
    countChartDow: { method: "GET", url: `${nodeApi}/users/count_chart_dow`},
    storageStats: { method: "GET", url: `${nodeApi}/users{/id}/storage_stats`},
    storageStatsPerUser: { method: "GET", url: `${nodeApi}/users/storage_stats`},
    interactedWith: { method: "GET", url: `${nodeApi}/users/interacted_with`},
  }
);
export const Student = Vue.resource(`${nodeApi}/students{/id}`);
export const Category = Vue.resource(`${nodeApi}/categories{/id}`);
export const Subscription = Vue.resource(`${nodeApi}/subscriptions{/id}`);
export const Charge = Vue.resource(`${nodeApi}/charges{/id}`);
export const Paypal = Vue.resource(`${nodeApi}/paypals{/id}`);
export const Order = Vue.resource(`${nodeApi}/orders{/id}`);
export const Credit = Vue.resource(`${nodeApi}/credits{/id}`,
  {},
  {
    claim: {method: "POST", url: `${nodeApi}/credits/claim`},
  });
export const Feedback = Vue.resource(`${nodeApi}/feedbacks{/id}`);
export const Nps = Vue.resource(`${nodeApi}/net_promoter_scores{/id}`);
export const Event = EventRs;
export const MailingSubscription = Vue.resource(`${nodeApi}/mailing_subscriptions{/id}`);
// export const LifeExperience = Vue.resource('/life_experiences{/id}')
