
export const isInViewport = (elm) => {
  const rect = elm.getBoundingClientRect();
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

export const noLayoutAndStorePartnerInfo = (store, route, cookie) => {
  store.dispatch('setLayout', 'no-layout')
  if (route.query && route.query.p_aid && !cookie.get('p_aid')) {
    cookie.set('pid', route.query.p_aid, 365);
  }
}

export const ssrFinishedRendering = () => {
  setTimeout(function() {
    document.dispatchEvent(new Event('x-app-rendered'));
  }, 2000)
}

export function preloadImage(imagePath) {
  const img = new Image();
  img.src = imagePath;
}
