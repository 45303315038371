import { Line } from 'vue-chartjs';
import _max from "lodash/max";
import _groupby from "lodash/groupBy";

export default {
  extends: Line,
  props: ['user', 'payments', 'currency'],
  computed: {

  },
  data() {
    return {
      currencies: {
        eur: { r: "€" },
        usd: { r: "$" },
        gbp: { l: "£" }
      },
      options: {}
    }
  },
  mounted () {
    const data = _groupby(this.payments, 'currency');

    // const year = new Date().getFullYear();
    // const year = 2018
    const colors = [
      'rgb(255, 91, 98)',
      // 'rgb(4, 137, 0)',
      'rgb(249, 202, 36)',
      'rgb(28, 124, 156)',
    ];
    const colorsTrans = [
      'rgb(255, 91, 98, 0.2)',
      // 'rgb(4, 137, 0, 0.2)',
      'rgb(249, 202, 36, 0.2)',
      'rgb(28, 124, 156, 0.2)',
    ];

    // const flags = {
    //   'en-GB': '<i class="flag flag-en-GB"></i>',
    //   'en-US': '<i class="flag flag-en-US"></i>',
    //   'hu-HU': '<i class="flag flag-hu-HU"></i>',
    // }

    const xTitle = {
      week: 'Week',
      month: 'Month',
      day: 'Day',
    }

    const chartData = {
      labels: [],
      datasets: []
    }

    let values = [];
    const {currencies, currency} = this;

    // for (const currency in data) {
    //   console.log({currency})
    if (data[currency]) {
      values = data[currency].map(item => item.value);

      chartData.labels = data[currency].map(it => `W${it.week_nb}`);
      chartData.datasets.push({
        // label: flags[language],
        label: this.currency.toUpperCase(),
        backgroundColor: colorsTrans.shift(),
        borderColor: colors.shift(),
        data: values
      })
    }


    this.options = {
      type: 'bar',
      scales: {
        yAxes: [{
          // scaleLabel: {
          //   display: true,
          //   labelString: 'Amount',
          // },
          gridLines: {
            display: false
          },
          ticks: {
            max: _max(values) + 20,
            beginAtZero: true,
            callback: function(value, index, values) {
              return ''
            }
          },
        }],
        // xAxes: [{
        //   scaleLabel: {
        //     display: true,
        //     labelString: xTitle[this.frequency]
        //   }
        // }]
        xAxes: [{
          gridLines: {
            display: false
          },
        }],
      },
      tooltips: {
        enabled: false
      },
      animation: {
        duration: 0,
        onComplete: function () {
          var chartInstance = this.chart,
            ctx = chartInstance.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "rgba(0, 0, 0, 1)";
          ctx.textBaseline = 'bottom';

          this.data.datasets.forEach(function (dataset, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach(function (bar, index) {
              var data = dataset.data[index];
              ctx.fillText(`${(currencies[currency] || {}).l || ''}${data}${(currencies[currency] || {}).r || ''}`, bar._model.x, bar._model.y - 5);
            });
          });
        }
      },
      legend: {
        display: false
      },
      plugins: [],
      responsive: true,
      maintainAspectRatio: false,
    }

    this.renderChart(chartData, this.options)
  }
}
