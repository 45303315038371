<template lang="pug">
  .container
    .box.page-shared-lessons.has-shadow
      div.has-loader.is-primary.is-outlined.is-loading.has-margin-auto.primary-loader(v-if="querying")
      div(v-else)
        .columns
          .column
            h3.title.has-text-left
              | Lessons shared by the community
        section.section.my-lessons.pt-2
          lessons-list(
            empty-message="Nobody shared a lesson publicly yet"
            size="is-3"
            :lessons="lessons"
            :read-only="true"
            group-by="language_to")


</template>

<script>
  import {mapGetters} from "vuex";
  import {Lesson} from "@/store/models/resources";
  import lessonsList from "@/components/LessonsList";

  export default {
    name: "SharedLessonsPage",
    computed: {
      ...mapGetters(["user"]),
    },
    components: {
      lessonsList,
    },
    data() {
      return {
        querying: true,
        lessons: []
      };
    },
    created() {
      Lesson.sharedPublicly().then((res) => {
        this.lessons = res.body.lessons
        this.querying = false
      }, err => {
        this.querying = false
      })
    },
    methods: {
      removePack(pack) {
        this.$store.dispatch("deletePack", pack);
      },
    }
  };
</script>


<style lang="scss">
  @import "colors";

  .page-shared-lessons {
    .teacher-mini-card {
      color: $darkGray;
      font-weight: bold;
    }
    .button-see-last {
      display: none !important;
    }
  }

</style>
