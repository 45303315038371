<template lang="pug">
  .tt-calendar
    article.message.is-light(v-if="!allEvents.length && !value")
      .message-body
        | You don't have any booking yet

    vue-event-calendar.has-text-left(
      :events="allEvents"
    )
      template(slot-scope="props")
        div.wrapper(
        v-for="(event, index) in props.showEvents"
        :class="{'is-about-a-teacher': event.teacher, 'is-about-a-student': event.student, 'is-today': today == event.date}"
        class="event-item")
          .columns
            .column
              h3.title.has-text-weight-normal
                b {{ event.start_time }}
                |  - {{ event.end_time }} •&nbsp;
                span.lesson-with
                  | Lesson with&nbsp;
                router-link(:to="{name: 'StudentPage', params: {id: event.student_id}}" v-if="event.iAmTeacher")
                  b {{ (event.student || {}).first_name }}
                router-link(:to="{name: 'TeacherPage', params: {id: event.teacher_id}}" v-else)
                  b {{ (event.teacher || {}).first_name }}
            .column.has-text-right.is-narrow.has-flex-center-items
              button.button.is-small(
                :class="{'is-white is-outlined': today == event.date}"
                @click="createLesson(event)"
                v-if="event.iAmTeacher && !event.lesson")
                b-icon(icon="add")
                span.pl-0 lesson
              router-link.button.is-small(
                v-if="event.lesson"
                :class="{'is-white is-outlined': today == event.date}"
                :to="{name: 'LiveLesson', params:{ id: event.lesson.uuid } }")
                b-icon(icon="remove-red-eye")
                span.p-0 lesson

</template>


<script>
import { mapGetters } from "vuex";
import { Event } from "@/store/models/resources";
// import timeZoneSelect from "@/components/TimeZoneSelect";
import each from "lodash/each";
import sortBy from "lodash/sortBy";

export default {
  name: "Calendar",
  props: ['limit', 'value'],
  components: {
  },
  data() {
    return {
      loading: false,
      bookings: [],
      events: [],
      today: null,
      lastDate: "2200-01-01",
    };
  },
  computed: {
    ...mapGetters(["user"]),
    allEvents() {
      return sortBy(this.events.concat(this.bookings), ev =>
        parseInt(ev.start_time.replace(":", ""))
      );
    }
  },
  mounted() {
    this.today = this.$moment().format("Y/MM/DD");

    const ctrl = this
    Event.bookingsAsTeacher({ state: "booked", limit: this.limit }).then(
      response => {
        each(response.body.events, ev => {
          const event = Event.transformForCal(ev);
          event.iAmTeacher = true;
          this.events.push(event);
        });
        if (this.events.length) {
          setTimeout(() => {
            if (ctrl.events[0].date < this.lastDate) {
              this.lastDate = ctrl.events[0].date
              ctrl.$EventCalendar.toDate(ctrl.events[0].date)
            }
          }, 0)
        }
        this.$emit('input', false)
      }
    );

    Event.bookingsAsStudent({ limit: this.limit }).then(response => {
      each(response.body.events, ev => {
        const event = Event.transformForCal(ev);
        event.iAmTeacher = false;
        this.bookings.push(event);
      });
      if (this.bookings.length) {
        setTimeout(() => {
          if (ctrl.bookings[0].date < this.lastDate) {
            this.lastDate = ctrl.bookings[0].date
            ctrl.$EventCalendar.toDate(ctrl.bookings[0].date)
          }
        }, 0)
      }
      this.$emit('input', false)
    });
  },
  methods: {
    createLesson(event) {
      this.$store
        .dispatch("saveLesson", {
          title: `Lesson with ${event.student.first_name} ${event.lesson_date}`,
          language_to: "en-US",
          student_id: event.student_id,
          event_id: event.id
        })
        .then((lesson) => {
          this.$socket.emit('LESSON_CREATED', {student_id: event.student_id})
          this.$router.push(`/lessons/${lesson.uuid}`)
        });
    }
  }
};
</script>


<style lang="scss">
  @import "colors";

  .v-cal-header__title-bar {
    width: 300px;
    margin: -30px auto 0px;
  }
  .is-about-a-student,
  .is-about-a-teacher {
    a {
      color: inherit;
    }
  }

  .tt-calendar.is-in-box {
    .lesson-with {
      display: none;
    }
    .__vev_calendar-wrapper .cal-wrapper {
      padding: 0 1rem 0 0;
      height: calc(100% - 41px);
    }
    .__vev_calendar-wrapper .cal-wrapper .cal-body .weeks .item {
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: 700;
      color: $midGray;
    }
    .__vev_calendar-wrapper .cal-wrapper .cal-header .title {
      margin-bottom: 0;
    }
    .__vev_calendar-wrapper .cal-wrapper .cal-header {
      padding-bottom: 0;
    }
    .__vev_calendar-wrapper .events-wrapper .event-item .title {
      overflow: hidden;
    }
    .__vev_calendar-wrapper .events-wrapper .event-item.is-today {
      background-color: $primary !important;
      color: $white;
      .title {
        color: $white;
      }
    }
    .__vev_calendar-wrapper .events-wrapper {
      background-color: $sweetGray !important;
      color: $darkGray;
      padding: 10px 7px;
    }
    .__vev_calendar-wrapper .events-wrapper .event-item .title {
      border-color: transparent;
    }
  }
</style>
