<template lang="pug">
  .app-container.page--front.page--for-teachers(v-farewell="onLeave")
    front-menu
    main.app-content
      section.hero.is-bold.is-large
        .hero-body.has-affiliate-bg.replace-top
          .container
            .columns.is-vcentered
              .column.is-6.mr-auto
                .section-header.has-text-left
                  h1.title.is-spaced.mb-2.is-size-4
                    | Become a Teachee Affiliate
                  h2.subtitle.is-size-5.text-regular
                    | Earn a <b>40% recurring commission</b> as a Teachee affiliate, and continue earning as long as your referrals remain Teachee customers.
                  div.mb-2
                    a.button.is-info.is-medium.mr-2.is-fullwidth-mobile.mb-2-mobile(
                      href="https://teachee.tolt.io/"
                      target="_blank"
                      ) Join the Affiliate Program

            //-.scroll-wrapper
              button#scroll_down.scroll_down(type="button" @click="scrollOneScreenHeight()")

      //-section.section.has-bg-light.pb-5
        .container
          h2.title.mt-1.pb-3 About the Teachee Affiliate Program

          .column.is-8.mt-1.mx-auto.has-text-left.is-size-5

            p.my-3 Join the Teachee Affiliate Partner Program and become a pivotal part of our global mission.
            p.my-3 Our web application  now offers you a rewarding opportunity. Earn a steady 40% recurring commission on all sales you generate, including renewals and credit purchases, as long as your referrals remain active customers.

      section.section.hero.is-primary
        .container
          h2.title.mt-1.pb-3 FAQ

          .column.is-8.mt-1.mx-auto.has-text-left.is-size-5

            h3.mt-0.mb-2.has-text-weight-bold.text-system.bold How long is your cookie life?
            p Our 365-day cookie life ensures you're credited for sales made within 365 days of your referral's link click.

            h3.mt-6.mb-2.has-text-weight-bold.text-system.bold How do you track conversions?
            p Conversions are tracked through your affiliate link provided by our affiliate tracking software, Tolt.

            h3.mt-6.mb-2.has-text-weight-bold.text-system.bold How do I receive my commission?
            p You'll be payed out by our partner Tolt via PayPal or Wise. You can choose your preferred payment method in your Tolt account.

            h3.mt-6.mb-2.has-text-weight-bold.text-system.bold What is the commission payout? How much can I earn?
            p
              | We currently offer a lifetime 40% recurring commission for the life of the customer.
              br
              | Let's see 2 examples:
              br
              p.is-size-6.mt-3
                u
                  | For 100 sales of <b>199€ memberships</b>, you'll get around 5950€ of commission.
              ul.is-size-6.ml-2
                li 1) A customer from UK pays 199€ for a 2-year access.
                li 2) Our payment processing gateway takes 10.45€: -10.45€
                li 3) UK VAT (20%): -39.80€
                li 4) We then share the remaining 148.75€ in a 60/40 split. You get <b>59.50€</b>.
                li 5) From then on, you'll get 59.50€ every time the customer renews their membership.

              p.is-size-6.mt-3
                u
                  | For 1000 sales of <b>14.90€ memberships</b>, you'll get around 4270€/monthly of recurring commission.
              ul.is-size-6.ml-2
                li 1) A customer from UK pays 14.90€ for a monthly subscription.
                li 2) Our payment processing gateway takes 1.25€: -1.25€
                li 3) UK VAT (20%): -2.98€
                li 4) We then share the remaining 10.68€ in a 60/40 split. You get <b>4.27€</b>.
                li 5) From then on, you'll get 4.27€ every time the customer renews their membership.

      section.section.is-light.mb-5.mt-5
        .container
          h2.title.mt-1.pb-3 Would Teachee be a match for your audience?

          .column.is-8.mt-1.mx-auto.has-text-left.is-size-5

            p.my-3
              | Teachee offers several benefits for its users:


            ul.is-size-6
              li
                p
                  b Time-Saving for Teachers
                | Teachee significantly reduces lesson preparation time, enabling teachers to quickly create effective and engaging materials.

              li.mt-3
                p
                  b Unique Competitive Edge
                | The platform stands out with features like audio recordings, flashcards, and a voice recognition system, offering a unique learning experience.

              li.mt-3
                p
                  b Enhanced Student Progress
                | With Teachee, teachers can create lesson content on-the-fly, facilitating faster and more efficient student learning.

              li.mt-3
                p
                  b Flexibility with Video Chat Software
                | The platform's independence from specific video chat software offers flexibility and convenience in teaching setups.

              li.mt-3
                p
                  b Anki Integration
                |  The ability to export to Anki enhances the overall learning experience, allowing for better retention and practice.

            //-p.my-3
              | Teachee represents a leap forward in language learning and teaching. By combining efficiency, innovation and design, Teachee is transforming the way languages are taught and learned. It's not just an app, it's a revolution in educational technology, making language learning accessible, effective, and enjoyable.


    footer-section
    img(
      height="1"
      width="1"
      style="display:none"
      src="https://www.facebook.com/tr?id=293952861544315&ev=PageView&noscript=1")

</template>

<script>
  import footerSection from "@/components/sections/FooterSection";
  import askToSubscribeOnLeave from '@/components/behaviors/AskToSubscribeOnLeave';

  export default {
    name: 'ForTeachersPage',
    mixins: [askToSubscribeOnLeave],
    components: {
      footerSection
    },
    data() {
      return {
        currentServiceTab: 0,
        howItWorksDetails: false,
      }
    },
    created() {
      this.$store.dispatch('setLayout', 'no-layout')
      // console.log({routeMounted: this.$route});
      // PARTNER COOKIE
      if (this.$route.query && this.$route.query.p_aid && !this.$cookie.get('p_aid')) {
        this.$cookie.set('pid', this.$route.query.p_aid, 365);
      }
    },

    mounted() {
      setTimeout(function() {
        document.dispatchEvent(new Event('x-app-rendered'));
      }, 2000);
    },

    methods: {
      openImageModal(src) {
          this.$buefy.modal.open(
            `<p class="image">
                        <img src="${src}">
                    </p>`
          )
      },
      scrollOneScreenHeight() {
        window.scrollTo({
          'behavior': 'smooth',
          'left': 0,
          'top': document.documentElement.clientHeight-72
        });
      },
    }
  }
</script>


<style lang="scss">
  @import 'colors';


  .page--for-teachers {
    background: url('~@/assets/homepage/headerbg.svg') #FFF;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: right top;
    .is-size-3 {
      font-weight: 800;
    }
    .title {
      font-weight: 700;
    }
    .has-affiliate-bg {
      background: url('~@/assets/affiliateprogrampage/affiliate.svg') transparent;
      background-size: 40%;
      background-repeat: no-repeat;
      background-position: 90% center;
    }
  }

  .mt-15 {
    margin-top: 15rem !important;
  }
  .mb-15 {
    margin-bottom: 15rem !important;
  }

  @media (max-width: 768px) {
    .page--for-teachers {
      .replace-top {
        padding-top: 0rem !important;
      }
      .has-front-bg {
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 100% bottom;
      }
    }
    .mt-15 {
      margin-top: 3rem !important;
    }
    .mb-15 {
      margin-bottom: 3rem !important;
    }
  }



</style>
