module.exports = [
  {
    kind: 'coupon_pass',
    name: 'Premium',
    invoiceDescription: 'One year pass.<br><b>Special price for coupon: __COUPON__</b>',
    classes: 'is-danger',
    uid: 'premium_coupon_pass_partner',
    frequency: 'yearly',
    price: 97.00,
    normalPrice: 249.00,
    cents: 0,
    fee: 0.02,
    includes: [
      'Live edit',
      'Record audio & attach pictures (<b>up to 2GB/month</b>)',
      'Export to Anki & audio',
      'Voice recognition',
      '<b>Booking system</b>',
      '<b>Payment system</b>'+
      '<br>2% Platform fee<br>+<br>Europe: 1.4% + €0.25 Stripe fee<br>US: 2.9%  + 30¢ Stripe fee',
      '<b>Sell packages</b>'+
      '<br>Keep 90% of the sales for yourself',
    ]
  },
  {
    kind: 'coupon_pass',
    name: 'Premium',
    invoiceDescription: 'One year pass (40% OFF).<br><b>Special price for coupon: __COUPON__</b>',
    classes: 'is-danger',
    uid: 'premium_coupon_pass',
    frequency: 'yearly',
    price: 149.00,
    normalPrice: 249.00,
    cents: 0,
    fee: 0.02,
    includes: [
      'Live edit',
      'Record audio & attach pictures (<b>up to 2GB/month</b>)',
      'Export to Anki & audio',
      'Voice recognition',
      '<b>Booking system</b>',
      '<b>Payment system</b>'+
      '<br>2% Platform fee<br>+<br>Europe: 1.4% + €0.25 Stripe fee<br>US: 2.9%  + 30¢ Stripe fee',
      '<b>Sell packages</b>'+
      '<br>Keep 90% of the sales for yourself',
    ]
  },
]


