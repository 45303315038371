<template lang="pug">
  div
    h3 Use <b>{{ lgToTxt[lesson.language_to] }}</b> on the <b>back</b> of the card (recommended)
    button#export_to_anki_default.button.is-primary(
      :title="!user.id ? 'You need an account to receive the deck by email' : ''"
      @click="downloadAnki('back', '/lessons/'+lesson.uuid+'/anki')"
      :class="{'is-loading': loading.back, 'is-disabled': loading.back || !user.id}"
      v-ga="'exportToAnki'")
      b-icon(icon="save")
      span Download my Anki deck
    p.mt20
      | or

    h3 Use <b>{{ lgToTxt[lesson.language_to] }}</b> on the <b>front</b> of the card
    button#export_to_anki_alternate.button.is-primary.is-outlined(
      :title="!user.id ? 'You need an account to receive the deck by email' : ''"
      @click="downloadAnki('front', '/lessons/'+lesson.uuid+'/anki?alternate=1')"
      :class="{'is-loading': loading.front, 'is-disabled': loading.front || !user.id}"
      v-ga="'exportToAnki'")
      b-icon(icon="save")
      span Download my Anki deck
    p.mt20
      | or

    h3 Train your comprehension
    button#export_to_anki_separeted_fields.button.is-primary.is-outlined(
      :title="!user.id ? 'You need an account to receive the deck by email' : ''"
      @click="downloadAnki('custom', '/lessons/'+lesson.uuid+'/anki/separated_fields')"
      :class="{'is-loading': loading.custom, 'is-disabled': loading.custom || !user.id}"
      v-ga="'exportToAnki'")
      b-icon(icon="save")
      span Download my Anki deck

    br
    br
    hr

    h3 Don't have Anki yet?
    a#download_anki.button.is-info(
      href="https://apps.ankiweb.net/#download"
      target="_blank"
      v-ga="'downloadAnki'")
      <!--b-icon(icon="save")-->
      span Download the free Anki desktop app


</template>

<script>
import { mapGetters } from "vuex";
import {LG_TO_TXT} from "../constants"

const keyToWord = {
  back: "Back",
  front: "Front",
  custom: "Custom"
}

export default {
  name: "AnkiModalBody",
  components: {
  },
  props: [
    'lesson',
  ],
  computed: {
    ...mapGetters(["apiUrl", "user"]),
  },
  data() {
    return {
      isLoading: false,
      loading: {
        back: false,
        front: false,
        custom: false,
      },
      lgToTxt: LG_TO_TXT
    };
  },
  methods: {
    downloadAnki(key, path) {
      this.$set(this.loading, key, true)
      this.$http.get(this.apiUrl + path).then(res => {
        const {email} = this.user;
        let msg = "Export in progress...<br />You will receive your Anki deck in an email within 2 mins at <b>"+email+"</b>."
        if (email.includes("@gmail.com")) {
          msg += "<br><br><a style='color: #FFF;text-decoration: underline;' href='https://mail.google.com/mail/u/0/#inbox' target='_blank'>Open Gmail</a>"
        } else if (email.includes("@live") || email.includes("@hotmail") || email.includes("@yahoo")) {
          const domain = email.split("@")[1];
          msg += "<br><br><a style='color: #FFF;text-decoration: underline;' href='https://"+domain+"' target='_blank'>Open Inbox</a>"
        }

        this.$toast.success(msg, { duration: 10000})
        this.$emit('close');
      }).catch(({body}) => {
        this.$toast.error(body, { duration: 10000});
      }).finally(() => {
        this.$set(this.loading, key, false)
      })

      this.$mixpanel.track('Lesson - Download Anki Deck - '+keyToWord[key])
    }
  }
};
</script>

<style lang="scss">
  @import "colors";

  #export_to_anki_modal {
    b {
      font-weight: 400;
    }
  }
</style>
