<template>
  <div>
    <basic-menu/>
    <div class="is-flex content-body">
      <sidebar-menu/>
      <section
        :class="layoutBackground"
        class="section main-section has-light-background is-wide pt-4">
        <div :class="['w-100', {'container': !compactMode, 'px-3': compactMode}]">
          <router-view :key="$route.fullPath"/>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import SidebarMenu from "./ui/SidebarMenu";

  export default {
    name: 'LoggedInLayout',
    components: {SidebarMenu},
    computed: mapGetters(["layoutBackground", "compactMode"])
  };
</script>

<style>
  .fade-leave-active {
    transition-duration: .1s
  }
  .fade-enter-active {
    transition-duration: .1s
  }

  .main-section {
    min-height: calc(100vh - 56px);
    transition: background-color .3s ease;
  }

  @media screen and (max-width: 768px) {
    .content-body {
      flex-direction: column;
    }
  }
</style>
