<template lang="pug">
  .country-select
    i.fa.fa-globe(v-show="isEmpty")
    i.fa.fa-times(v-show="isDirty" @click="reset")
    // the input field
    b-field
      b-input#search_everywhere_btn(
      icon="location-on"
      placeholder="Select your country"
      autocomplete="off"
      v-model="query"
      v-shortkey="['s']"
      @keydown.native.prevent.down="down"
      @keydown.native.prevent.up="up"
      @keydown.native.enter="hit"
      @keydown.native.esc="reset"
      @input="update")
    <!--@blur="reset"-->
    // the list
    ul.box(v-show="hasItems")
      // for vue@1.0 use: ($item, item)
      li(v-for="(option, $item) in items" :class="activeClass($item)" @mousedown="hit" @mousemove="setActive($item)")
        | {{ option.name }}
    ul.box(v-show="query && query.length && !hasItems")
      li(@mousedown="reset") No results


</template>

<script>
  import VueTypeahead from "vue-typeahead";
  import includes from "lodash/includes";
  import filter from "lodash/filter";
  import toLower from "lodash/toLower";
  import countries from '@/support/countries'

  export default {
    name: "CountrySelect",
    extends: VueTypeahead,
    data() {
      return {
        query: null,
        src: "...",
        limit: 10,
        minChars: 2,
        selectFirst: true
      };
    },
    methods: {
      onHit(selection) {
        this.$emit('input', selection)
      },
      fetch() {
        return new Promise((resolve, reject) => {
          const query = toLower(this.query);
          resolve({
            data: filter(countries, opt =>
              includes(toLower(opt.name || ""), query)
            )
          });
          reject();
        });
      },
      search() {
        document.getElementById("search_everywhere_btn").focus();
      }
    }
  };
</script>

<style lang="scss">
  @import "colors";

  .country-select {
    ul {
      padding: 0;
      li {
        padding: 10px 10px;
        &.active,
        &:hover {
          background-color: $primary;
          color: #fff;
        }
      }
      li span {
        vertical-align: middle;
      }
    }
  }
</style>
