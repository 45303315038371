<template lang="pug">
  .app-container.page--front.page--pricing.text-main
    front-menu
    main.app-content

      hero-section.pb-0(
        title="Pricing"
        :hide-cta="true"
      )
      .columns.pricing-table-wrapper.is-centered
        .column.is-11.is-9-widescreen.is-8-fullhd
          pricing-table(
            @plan-chosen="planChosen"
            :offers="offers"
            :can-buy="true"
            highlight="premium_monthly"
            )

      .is-flex.has-text-white.halign.py-5.has-background-primary
        b-icon(icon="info")
        span The "Speech to text" and "Voice recognition" features are supported on Google Chrome only.

      pricing-faq-section

      footer-section

</template>

<script>
  import HeroSection from "@/components/sections/Hero";
  import pricingTable from "@/components/PricingTableV2";
  import footerSection from "@/components/sections/FooterSection";
  import {ssrFinishedRendering, noLayoutAndStorePartnerInfo} from "@/utils";
  import PricingFaqSection from "@/components/sections/PricingFaq";
  import offers from "@/support/newOffers";


  export default {
    name: 'PricingPage',
    components: {
      PricingFaqSection,
      HeroSection,
      pricingTable,
      footerSection
    },
    data() {
      return {
        currentServiceTab: 0,
        howItWorksDetails: false,
        offers
      }
    },

    mounted() {
      noLayoutAndStorePartnerInfo(this.$store, this.$route, this.$cookie)
      ssrFinishedRendering();

      if (this.$analytics && this.$analytics.fbq && this.$analytics.fbq.event) {
        this.$analytics.fbq.event("ViewContent", {
          content_name: 'Pricing Page'
        })
      }
    },

    methods: {
      planChosen(plan){
        this.$router.push({ name: 'SignUp' })

        // switch (plan) {
        //   case 'free':
        //     this.$router.push({ name: 'SignUp' })
        //     break;
        //   case 'basic':
        //     this.$router.push({ name: 'SignUp' })
        //     break;
        //   default:
        //     this.$router.push({ name: 'SignUp' })
        // }
      }
    }
  }
</script>


<style lang="scss">
  @import 'colors';

  .page--pricing {
    .pricing-table-wrapper {
      margin-top: 5rem;
      margin-bottom: 0rem;
      background: $primary;
      .pricing-plan {

        @media (min-width:1260px) {
          margin-top: -10rem;
          &:nth-child(4) {
            margin-top: -12rem;
          }
        }
        &.is-active {
          transform: none;
        }
      }
    }
    .pricing-table .pricing-plan .plan-item {
       background: #FFF;
    }
  }
</style>
