<template lang="pug">
  .live-lesson-page.mb-3(
    @mousemove="updateActivity()"
    :class="{'compact-mode': compactMode}"
    )


    alert.my-3.mx-2(
      v-if="user.role === 'free' && !user.from_partner"
      uid="welcome"
      color='is-info'
    )
      h2.title.mb-1 Welcome to Teachee
      div(v-if="!user.claimed_credits")
        | As a welcome gift, we give you <b>10 credits for free</b> 🎉
        b-tooltip(
          label='A credit gives you one lesson containing up to 50 sentences with media (record audio & add pictures).'
          type="is-light"
          multilined
          :animated='true'
          position='is-top')
          b-icon(icon="info" size="is-small")
        | . Claim your credits anytime by clicking here:
        button.button.is-info.is-inverted.is-outlined.is-small.ml-2(@click="getCredits" :class="{'is-loading': claiming}" :disabled="claiming") Get my 10 free credits
      div(v-else)
        | Well done! Now you can create lesson and record audio.<br>People you invite to the lesson will also be able to add audio and pictures.

    article.has-text-centered.has-flex-center-items.has-justified-center.mb-3(
        v-if="lesson.has_readonly && lesson.user_id == user.id"
      )
      b-icon.mr-1(icon="info")
      span
        | This lesson has custom sharing settings: <b>only you can modify it</b>.


    article.message.is-danger.has-text-left(
      v-if="['firefox'].includes(browser.name)"
    )
      .message-body.has-text-centered.is-flex
        .is-flex.valign.m-auto
          b-icon.mr-4(icon="warning" custom-size="is-size-2")
          div
            | The speech recognition is not supported on {{browser.name | capitalize}}.
            br
            | Please use <a href="https://www.google.com/chrome/">Google Chrome</a> to enjoy all the features.

    .is-flex.has-flex-center-items.is-justify-content-space-between
      button-back.is-small.mb-2.mr-auto(
        v-if="!guest"
      )

      b-tooltip.ml-auto.is-size-7.has-text-grey.d-none-on-print.py-2(
        v-if="!guest"
        :label='forceTeacher ? "The audio you record will be saved to the sentences" : "The audio you record will not be saved to the sentences"'
        type='is-light'
        :animated='true'
        )
        | Edition mode: {{ forceTeacher ? "TEACHER" : "STUDENT"}}

    board(:status="isConnected" v-if="lesson.uuid != requestedUuid")
      template(slot="title")
        div.has-loader.is-primary.is-outlined.is-loading.has-margin-auto.primary-loader.lesson-loader.is-size-2

    transition(name="bounce")
      figure.lesson-picture.mb-1(
        @dragover="$event.preventDefault()"
        @drop="onDrop"
        v-if="picture" @click="togglePictureFixed = !togglePictureFixed"
        :class="{ togglePictureFixed }"
        )
        img(:src="picture")

    board(
      :status="isConnected"
      v-if="lesson.uuid == requestedUuid"
      )
      template(slot="title")
        b-icon(:icon="lesson.icon" v-if="lesson.icon")
        b-icon(icon="public" v-if="!lesson.icon && lesson.is_public" title="Public")
        b-field.lesson__title.is-fullwidth
          b-input.live-edit(
          placeholder="Lesson title"
          v-model="lesson.title"
          @blur="saveField('title')"
          )

      template(slot="headerLeft")

        transition(name="bounce")
          div.is-flex.share-wrapper.d-none-on-print(
          )
            b-tooltip.is-hidden-mobile.has-flex-center-items(
              label='Hide the sentences & listen to the recordings to put your ears to work', type='is-info', :animated='true', position='is-top'
              )
              button#review.button.is-info.is-small.mr-2(
                v-if="!forceTeacher"
                :class="{'is-outlined': languageLearnedShown}"
                @click="languageLearnedShown=!languageLearnedShown"
              )
                b-icon(icon="flip-to-front")
                span Review

            b-tooltip.is-hidden-mobile.has-flex-center-items(
              v-if="user && user.credits > 0 && cannotDownloadAudio"
              label='Use 1 credit so you can record audio & attach pictures'
              type='is-dark'
              :animated='true'
              position='is-top'
            )
              button#unlock_credit.button.is-dark.is-outlined.is-small.mr-2(
                v-if="!lesson.paid"
                :class="{'is-loading': unlockLoading}"
                @click="unlock"
              )
                b-icon(icon="lock-open")
                span Use 1 credit

            button#share.button.is-primary.is-outlined.is-small.mr-2(
              v-if="!lesson.has_readonly && !lesson.shared"
              @click="share"
              )
              b-icon(icon="share")
              span Share

            span.separator
              |  |

        div.is-flex.has-flex-center-items.d-none-on-print(
          v-if="!isGuest"
        )
          language-selector(
            :languages-on-top="user.teaches_languages"
            @select="setLessonLanguageTo" :language="lesson.language_to"
            )
          span.mr-1 ➡︎
          language-selector(@select="setLessonLanguageFrom" :language="lesson.language_from")

        span.separator.d-none-on-print(
          v-if="!isGuest"
        )
          |  |

        b-tooltip.is-hidden-mobile.has-flex-center-items.mr-1(:label='lesson.user.first_name', type='is-light', :animated='true', position='is-top', v-if="!user || (lesson.user && lesson.user.id != user.id)")
          router-link(:to="{name: 'TeacherPage', params: {id: lesson.user_id}}")
            user-picture.has-size-30px(:user="lesson.user")

        b-tooltip.is-hidden-mobile.has-flex-center-items(:label='user.first_name', type='is-light', :animated='true', position='is-top', v-if="user")
          user-picture.has-size-30px(:user="user")

        span.separator.d-none-on-print
          |  |

      template(slot="header")

        b-dropdown(
          position="is-bottom-left"
          paddingless
          )
          a#live_lesson_info.button.is-white.card-header-icon.has-text-grey(slot="trigger" @mouseenter="computeInfo")
            b-icon(icon="info")
          b-dropdown-item(custom paddingless)
            .modal-card(style="width:300px;")
              section.modal-card-body.has-text-left.py-2
                 .info-created-at.is-flex.valign(v-if="lesson.created_at")
                   span.day.is-size-2.has-text-weight-bold {{ lesson.created_at | moment("D") }}
                   span.date.pl-3
                      | {{ lesson.created_at | moment("MMM YYYY h:mm A").toUpperCase() }}<br>
                      span.info-label CREATION DATE
                 hr.mt-1
                 .info-numbers.is-flex
                   .charaters-count-info.w-50
                     p.is-size-4 {{info.charactersCount}}
                     span.info-label CHARACTER{{info.charactersCount >= 1 ? 'S' : ''}}

                   .words-count-info.w-50
                     p.is-size-4 {{info.wordsCount}}
                     span.info-label UNIQUE WORD{{info.wordsCount >= 1 ? 'S' : ''}}

                 .info-numbers.is-flex.mt-3
                   .charaters-count-info.w-50
                     p.is-size-4 {{info.sentencesCount}}
                     span.info-label SENTENCE{{info.sentencesCount >= 1 ? 'S' : ''}}

                   .charaters-count-info.w-50
                     p.is-size-4 {{info.audioCount}}
                     span.info-label RECORDING{{info.audioCount >= 1 ? 'S' : ''}}


        b-dropdown.d-none-on-print(
          position="is-bottom-left"
          paddingless
          v-if="!isGuest"
          )
          a#live_lesson_settings.button.is-white.card-header-icon(
            v-shortkey="['p']"
            @shortkey="openCogMenu"
            ref="cogIconMenuTrigger"
            slot="trigger")
            b-icon(icon="settings")
          b-dropdown-item(custom paddingless)
            .modal-card(style="width:300px;")
              section.modal-card-body.has-text-left.py-2
                h3.title.is-size-5.mb-2 Actions
                // SHARE
                button.button.is-primary.is-outlined.is-fullwidth.is-small.mb-1(
                  @click="share"
                )
                  b-icon(icon="share")
                  span Share

                button.button.is-primary.is-outlined.is-fullwidth.is-small.mb-1(
                  @click="duplicate"
                )
                  b-icon(icon="content-copy")
                  span Duplicate
                .is-flex
                  b-field.file.is-fullwidth.is-flex-grow-1.mb-1
                    b-upload.w-100(
                    :disabled="isLocked"
                    accept="image/*"
                    v-model='lesson.file'
                    @input="updatePicture")
                      a.button.is-primary.is-outlined.is-fullwidth.is-small(
                        :disabled="isLocked"
                        :class="{'primary-loader has-loader is-loading': uploadingPicture}"
                        )
                        b-icon(icon='image')
                        span
                          | {{ !!lesson.picture ? 'Change' : 'Add a' }} picture

                  a.button.is-danger.is-outlined.is-narrow.is-small.ml-1(
                    v-if="lesson.picture"
                    :class="{'primary-loader has-loader is-loading': removingPicture}"
                    @click="removePict"
                    )
                      b-icon(icon="delete")

                div.mt-2(
                  v-if="user && user.role && user.role === 'premium'"
                )
                  h3.title.is-size-6.mb-1  Batch actions
                  // SHARE
                  .is-flex.mb-1.primary-border(
                    v-if="AWS_POLLYABLE.includes(lesson.language_to) || AZURE_TTS.includes(lesson.language_to)"
                  )
                    b-select#voice_gender_select(
                      v-if="AWS_POLLYABLE.includes(lesson.language_to)"
                      size="is-small"
                      icon="voice"
                      v-model="storeVoiceGender"
                    )
                      option.has-text-primary(v-for="option in ['male', 'female']" :value="option" :key="option")
                        | {{ option }}

                    button.button.is-fullwidth.is-small.mb-0.has-text-primary(
                      v-shortkey="['g']"
                      @shortkey="generateTtsAudio"
                      @click="generateTtsAudio"
                      :class="{'primary-loader has-loader is-loading': generatingTts}"
                      :disabled="generatingTts"
                    )
                      b-icon(icon="auto-fix-high")
                      span
                        u
                          | G
                        | enerate Audio

                  button.button.is-primary.is-outlined.is-fullwidth.is-small.mb-0(
                    v-shortkey="['t']"
                    @shortkey="translateAll"
                    @click="translateAll"
                    :class="{'primary-loader has-loader is-loading': translatingAll}"
                    :disabled="translatingAll"
                  )
                    b-icon(icon="auto-fix-high")
                    span
                      u
                        | T
                      | ranslate all

                h3.title.is-size-5.mb-2.mt-3 Languages
                // SETTINGS
                div.is-flex.has-flex-center-items
                  span.mr-3.mw-32px From
                  language-selector(@select="setLessonLanguageTo" :language="lesson.language_to")
                  voice-selector(:language="lesson.language_to")
                div.is-flex.has-flex-center-items
                  span.mr-3.mw-32px To
                  language-selector(@select="setLessonLanguageFrom" :language="lesson.language_from")

                h3.title.is-size-5.mb-2.mt-3 Display
                // DISPLAY
                b-checkbox(
                  v-model="lesson.display_ipa"
                  @input="saveField('display_ipa')"
                  :native-value="true"
                  :type="'is-info'"
                  ) Show phonetic for student

                b-checkbox(
                  v-model="lesson.display_lit"
                  @input="saveField('display_lit')"
                  :native-value="true"
                  :type="'is-info'"
                ) Show literal translation field

                //-b-checkbox( v-model="storeCompactMode" :native-value="true" :type="'is-info'" ) Compact mode

                h3.title.is-size-5.mb-2.mt-3 Edition mode
                .is-clickable(
                  @click="forceTeacher=!forceTeacher"
                )
                  b-tooltip(
                  v-if="!lesson.has_readonly"
                  type='is-light'
                  :animated='true'
                  position='is-top'
                  )
                    | Student&nbsp;&nbsp;
                    b-switch(v-model="forceTeacher")
                    | Teacher
                .field.mt-2
                  b-radio(
                    :disabled="lesson.is_public"
                    v-model="lesson.has_readonly"
                    :native-value="false"
                    @input="saveField('has_readonly')"
                    :type="'is-info'"
                    ) Anybody with link can edit
                .field
                  b-radio(
                    :disabled="lesson.is_public"
                    v-model="lesson.has_readonly"
                    :native-value="true"
                    @input="saveField('has_readonly')"
                    :type="'is-warning'"
                    ) Only you can edit

                .field.field-is-public
                  b-radio(
                  v-model="lesson.is_public"
                  :native-value="false"
                  @input="isPublicChanged"
                  :type="'is-info'"
                  ) Private to you & your student
                .field.field-is-public
                  b-radio(
                  v-model="lesson.is_public"
                  :native-value="true"
                  @input="isPublicChanged"
                  :type="'is-danger'"
                  )
                    | Public to all Teachee users<br>
                    span.has-text-grey.is-size-7
                      | The lesson {{ lesson.is_public ? 'is now' : 'will be' }} accessible on your teacher page and on the community page

                h3.title.is-size-5.mb-2.mt-3 Export
                b-field.is-flex.valign
                  | Use audio of
                  b-select.ml-2(
                    size="is-small"
                    icon="account-circle"
                    @input="saveField('audio_teacher_id')"
                    placeholder="User"
                    v-model="lesson.audio_teacher_id"
                    )
                    option(v-for="option in audioTeacherIdOptions" :value="option.id" :key="option.id")
                      | {{ option.first_name }}

                button.button.is-info.is-inverted.is-fullwidth.is-small.mt-4(
                  v-if="user.id == lesson.user_id"
                  aria-label="Change access"
                  @click="openAccessModal"
                )
                  b-icon(
                  icon="vpn-key"
                  custom-size="mdi-18px")
                  span Edit access

                button.button.is-primary.is-inverted.is-fullwidth.is-small(
                  :class="{'mt-4': user.id != lesson.user_id, 'mt-2': user.id == lesson.user_id}"
                  aria-label="Delete the lesson"
                  @click="remove"
                  v-confirm="'Are you sure you want to <b>delete the lesson</b>?'")
                  b-icon(icon="close" custom-size="mdi-18px")
                  span Delete the lesson

      template(slot="content")
        .card-content(:class="{'has-invisible-inputs': !languageLearnedShown}")
          alert.has-text-left.mb-3(
            color='is-info'
            :uid="'introduction_to_mic_record_ui'"
            v-if="forceTeacher"
            )
            p.has-text-centered.mb-2
              b-icon.mr-1.has-valign-middle(icon="info")
              span Before starting
            p.ml-3.content
              | Make sure you learn to use the <b>recording feature</b> properly:<br>
              ol.mt-0
                li
                  | Let Teachee use your microphone by clicking here:
                  button.button.is-small.has-valign-middle.ml-2(
                    @click="askMicPermission()"
                    :class=`{
                        'is-success': !micIsOff,
                        'is-info is-outlined is-inverted': micIsOff
                    }`
                  )
                    b-icon(:icon="micIsOff ? 'highlight' : 'check'")
                    span.p-0 Enable microphone
                li
                  span.has-valign-middle
                    | Put your cursor on the record button:
                  button.button.is-primary.is-small.has-valign-middle.ml-2
                    b-icon(icon="mic")
                    span.p-0 Record
                li
                  | <u><b>Press</b> and <b>hold</b></u> the left click
                li
                  | <b>Speak</b>
                li
                  | <b>Release</b> the left click when you're done speaking

            |
          slick-list(
            v-model="sentencesList"
            :transitionDuration="250"
            :lockAxis="'y'"
            :useDragHandle="true")
            sentence(
              v-for="(sentence, i) in lesson.sentences"
              :key="sentence.id+'-'+i"
              :model="sentence"
              :display-ipa="lesson.display_ipa"
              :display-lit="lesson.display_lit"
              :features="features"
              :language="lesson.language_to"
              :language-known="lesson.language_from"
              :last-tag="lastTag"
              :is-last="i == (lesson.sentences.length-1)"
              :version="sentence.version"
              :index="i"
              :lesson-uuid="requestedUuid"
              :is-teacher="forceTeacher"
              :is-guest="isGuest"
              @tag="updateLastTag"
              @add-sentence="addSentence"
              @propagate-change="isTyping")

          .suggestions-prompt(v-if="isEditingSentenceContent")

          .columns.printclearboth.mt-4
            .column
              b-tooltip.is-hidden-mobile.has-flex-center-items(
                v-if="!guest"
                multilined
                :label='((guest || user.role == "free") && lesson.sentences.length >= 50) ? "Lessons are limited to 50 sentences. You can upgrade to a Premium account to remove the limit." : ""', type='is-warning', :animated='true', position='is-top'
              )
                button#btn_add_sentence.button.is-primary.is-outlined.is-fullwidth.is-small(
                  @click="addSentence"
                  :disabled="(guest || user.role == 'free') && lesson.sentences.length >= 50"
                  :class="{'has-loader is-loading': isAdding}"
                  v-ga="'addSentenceBigButton'"
                  v-shortkey="['a']"
                  @shortkey="addSentence()")
                  b-icon(icon="add")
                  span
                    u
                      | A
                    | dd
            .column
              b-tooltip.is-hidden-mobile.has-flex-center-items(
                v-if="!guest"
                multilined
                :label='((guest || user.role == "free") && lesson.sentences.length >= 50) ? "Lessons are limited to 50 sentences. You can upgrade to a Premium account to remove the limit." : ""', type='is-warning', :animated='true', position='is-top'
              )
                button#btn_add_sentence.button.is-outlined.is-fullwidth.is-small(
                  @click="addExistingSentence"
                  :disabled="(guest || user.role == 'free') && lesson.sentences.length >= 50"
                  :class="{'has-loader is-loading': isAdding}"
                  v-ga="'addExistingSentenceBigButton'"
                  v-shortkey="['e']"
                  @shortkey="addExistingSentence()")
                  b-icon(icon="add")
                  span
                    | Add
                    |
                    u
                      | E
                    |xisting
            .column
              button#btn_refresh.button.is-outlined.is-fullwidth.is-small(
                v-if="!guest"
                @click="refresh"
                :class="{'is-loading': isRefreshing}"
                v-shortkey="['r']"
                @shortkey="refresh()"
                )
                b-icon(icon="sync")
                span
                  u
                    | R
                  | efresh
            .column
              b-dropdown.w-100(
                position="is-top-right"
                )
                button#btn_export.button.is-lightgrey.is-outlined.is-fullwidth.is-small(
                  slot="trigger"
                  )
                  b-icon(icon="cloud-download")
                  span
                    u
                      | E
                    | xport
                b-dropdown-item.is-flex.has-flex-center-items.valign(
                :custom="true"
                )
                  b-field.is-flex.valign.has-text-dark
                    | Use audio of
                    b-select.ml-2(
                      size="is-small"
                      icon="account-circle"
                      @input="saveField('audio_teacher_id')"
                      placeholder="User"
                      v-model="lesson.audio_teacher_id"
                    )
                      option(v-for="option in audioTeacherIdOptions" :value="option.id" :key="option.id")
                        | {{ option.first_name }}

                b-dropdown-item.is-flex.has-flex-center-items(
                  :has-link="true"
                )
                  a#export_to_anki.has-loader.is-flex.has-flex-center-items(
                    :class="{'is-disabled':guest}"
                    :title="guest ? 'You need to login to export to Anki' : ''"
                    @click.prevent='guest ? () => {} : exportToAnkiModal = true')
                    b-icon(icon="save")
                    span Export to Anki
                b-dropdown-item(
                  :has-link="true"
                )
                  download-excel#export_to_excel.has-loader.is-flex.has-flex-center-items(
                    v-if="lesson.sentences && lesson.sentences.length"
                    :type="isMacOs ? 'csv' : 'xls'"
                    @click="$mixpanel.track('Lesson - Download Excel')"
                    :data= "lesson.sentences"
                    :meta="excelMeta"
                    :fields= "excelFields"
                    :name= "filename+'.xls'")
                    b-icon(icon="view-list")
                    span Export to Excel
                b-dropdown-item#export_to_audio(
                  :has-link="true"
                )
                  a.has-loader.is-flex.has-flex-center-items(
                  :href="cannotDownloadAudio ? '#' : apiUrl+'/lessons/'+lesson.uuid+'/audio'"
                  :target="cannotDownloadAudio ? '_self' : '_blank'"
                  @click="$mixpanel.track('Lesson - Download Podcast')"
                  :class="{'is-disabled':cannotDownloadAudio}"
                  v-ga="'exportToAudio'")
                    b-icon(icon="headset")
                    span Export to audio
                b-dropdown-item(:has-link="true")
                  a#export_to_audio.has-loader.is-flex.has-flex-center-items(
                  :href="cannotDownloadAudio ? '#' : apiUrl+'/lessons/'+lesson.uuid+'/audio_files'"
                  @click="$mixpanel.track('Lesson - Download All Audios')"
                  :target="cannotDownloadAudio ? '_self' : '_blank'" :class="{'is-disabled':cannotDownloadAudio}"
                  v-ga="'exportToAudio'")
                    b-icon(icon="audiotrack")
                    span Download all audios
                b-dropdown-item#print.is-flex.has-flex-center-items(
                  @click="print"
                  v-ga="'print'"
                )
                  b-icon(icon="print")
                  span Print
                b-dropdown-item.is-flex.has-flex-center-items(
                  :has-link="true"
                )
                  a#copy_to_clipboard.has-loader.is-flex.has-flex-center-items(
                    href="#"
                    @click.prevent.stop='copyToClipboard')
                    b-icon(:icon="copiedToClipboard ? 'check' : 'content-copy'")
                    span Copy to clipboard
            .column(
              v-if="!guest && lesson.user && lesson.user.stripe_connect_uid && !forceTeacher"
              )
              router-link.button.is-outlined.is-fullwidth.is-small(:to="{name: 'TeacherPage', params: {id: lesson.user_id}}")
                b-icon(icon="book")
                span Book more lessons
            //-.column(v-if="forceTeacher && user.stripe_connect_uid")
              button.button.is-outlined.is-fullwidth.is-small( @click="closeLesson" :disabled="isClosed" )
                b-icon(:icon="isClosed ?  'check' : 'cloud-done'")
                span Lesson is over

      template(slot="footer")
        <!--a#export_to_anki.has-loader.button.is-info.is-outlined.is-primary(:href="apiUrl+'/lessons/'+lesson.uuid+'/anki'" target="_blank" :class="{'is-loading': isLoading, 'is-disabled': isLoading}"  v-ga="'exportToAnki'")-->

        b-modal#share_modal(:active.sync="shareModal" :width="710")
          .box
            .content.has-text-left
              h3 Copy the link to share your lesson
              h4.mt-4.mb-2 Share with your student
              b-field.is-light.is-fullwidth
                b-input(:value="url" ref="shareUrl" @keyup="url = forceUrl" expanded :class="{'has-text-success': copiedStatus.student}")
                p.control
                  button.button.is-info(
                    v-clipboard:copy="url"
                    v-clipboard:success="onCopyStudent"
                    v-clipboard:error="onCopyError"
                  :class="{'is-success': copiedStatus.student, 'is-info': !copiedStatus.student}"
                  ) {{ copiedStatus.student ? 'Copied' : 'Copy' }}


              h4.mt-4.mb-2(v-if="!lesson.has_readonly") Share with your teacher
              b-field.is-light.is-fullwidth.mb-2(v-if="!lesson.has_readonly")
                b-input(:value="teacherUrl" ref="shareTeacherUrl" @keyup="teacherUrl = forceTeacherUrl" expanded :class="{'has-text-success': copiedStatus.teacher}")
                p.control
                  button.button.is-info(
                    v-clipboard:copy="teacherUrl"
                    v-clipboard:success="onCopyTeacher"
                    v-clipboard:error="onCopyError"
                    :class="{'is-success': copiedStatus.teacher, 'is-info': !copiedStatus.teacher}"
                  ) {{ copiedStatus.teacher ? 'Copied' : 'Copy' }}


        b-modal#export_to_anki_modal(:active.sync="exportToAnkiModal" :width="620")
          .box
            .content.has-text-centered
              anki-modal-body(
                :lesson="lesson"
                @close="exportToAnkiModal = false"
                )

        b-modal#duplicate_modal(:active.sync="duplicateModal" :width="620")
          duplicate-modal-body(
            :key="lesson.sentences.length"
            :lesson="lesson"
            @close="duplicateModal = false"
          )

        b-modal#export_to_anki_modal(:active.sync="lessonOverModal" :width="620")
          .box.lesson-over-modal
            .content.has-text-centered
              h1.has-text-left.is-size-4 🚀 Well done! The lesson is over
              div(v-if="lesson.user && lesson.user.stripe_connect_uid")
                h2.has-text-left.is-size-5 Stay committed, book your teacher right now
                router-link.button.is-primary(:to="{name: 'TeacherPage', params: {id: lesson.user_id}}")
                  b-icon(icon="book")
                  span Book more lessons

              h2.has-text-left.is-size-5 Download your flashcards
              button.button(@click="lessonOverModal=false;exportToAnkiModal=true")
                b-icon(icon="cloud-download")
                span Download flashcards

    <!--hr.spacer.is-1-->
    <!--.box-->
      <!--router-link.button.is-white.is-fullwidth(:to="{name: 'TeacherPage', params: {id: lesson.user_id}}")-->
        <!--| Book more lessons-->


</template>

<script>
// import draggable from 'vuedraggable';
import { SlickList, SlickItem } from 'vue-slicksort';

import downloadExcel from "vue-json-excel";
import alert from "@/components/Alert";
import sentence from "@/components/Sentence";
import ankiModalBody from "@/components/AnkiModalBody";
import userPicture from "@/components/UserPictureUI";
import languageSelector from "@/components/LanguageSelector";
import voiceSelector from "@/components/VoiceSelector";
import addExistingSentenceModal from "@/components/AddExistingSentenceModal";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Lesson} from '@/store/models/resources';
import debounce from "lodash/debounce";
import some from "lodash/some";
import throttle from "lodash/throttle";
import uniq from "lodash/uniq";
import ButtonBack from "./ui/ButtonBack";
import Vue from "vue";
import {LG_TO_TXT} from "../constants"
import editLessonAccessModal from "@/components/EditLessonAccessModal";
import DuplicateModalBody from "@/components/DuplicateModalBody";
const { detect } = require('detect-browser');
const browser = detect();
import {AWS_POLLYABLE, AZURE_TTS} from "@/constants";

export default {
  name: "LiveLesson",
  components: {
    DuplicateModalBody,
    ButtonBack,
    downloadExcel,
    voiceSelector,
    userPicture,
    languageSelector,
    sentence,
    ankiModalBody,
    // draggable,
    SlickList,
    SlickItem,
    alert,
  },
  data() {
    return {
      browser,
      claiming: false,
      isLocked: false,
      isClosed: false,
      copiedToClipboard: false,
      lessonEditWillCrash: false,
      languageLearnedShown: true,
      lessonOverModal: false,
      uploadingPicture: false,
      removingPicture: false,
      togglePictureFixed: false,
      requestedUuid: null,
      shareModal: false,
      duplicateModal: false,
      exportToAnkiModal: false,
      isRefreshing: false,
      isLoadingIpa: false,
      forceTeacher: false,
      forceUrl: "",
      forceTeacherUrl: "",
      copiedStatus: {
        student: false,
        teacher: false
      },
      excelMeta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      excelFields: {
        Content: "content",
        Phonetic: "ipa",
        "Literal translation": {
          field: "literal_translation",
          callback: value => {
            return `${value || ""}`;
          }
        },
        Translation: {
          field: "translation",
          callback: value => {
            return `${value || ""}`;
          }
        }
      },
      features: {
        pinyin: false
      },
      isLoading: false,
      isAdding: false,
      title: "",
      description: "",
      lgToTxt: LG_TO_TXT,
      micIsOff: true,
      lastTag: null,
      info: {
        wordsCount: 0,
        charactersCount: 0,
        sentencesCount: 0,
        audioCount: 0
      },
      unlockLoading: false,
      generatingTts: false,
      translatingAll: false,
      AWS_POLLYABLE,
      AZURE_TTS
    };
  },
  computed: {
    ...mapGetters(["user", "lesson", "uuid", "lastAudio", "isConnected", "apiUrl", "lastActivity", "isEditingSentenceContent", "isGuest", "compactMode", "usersById", "voiceGender"]),

    audioTeacherIdOptions() {
      const users = [this.user]
      for (const userId in this.usersById) {
        if (this.user.id != userId && this.usersById[userId].lesson_ids.includes(this.lesson.id)) {
          users.push(this.usersById[userId])
        }
      }
      return users;
    },

    storeCompactMode: {
      get () {
        return this.compactMode
      },
      set (value) {
        this.SET_COMPACT_MODE(value)
      }
    },

    storeVoiceGender: {
      get () {
        return this.voiceGender || "male"
      },
      set (value) {
        this.SET_VOICE_GENDER(value)
      }
    },

    isMacOs() {
      return /Mac/i.test(navigator.platform)
    },
    guest() {
      return this.isGuest || !this.user;
    },
    sentencesList: {
      get() {
        return this.lesson.sentences
      },
      set(value) {
        this.$store.commit('editLesson', Object.assign({}, this.lesson, {sentences: value}))
        this.dragEnded()
      }
    },
    filename() {
      return (this.lesson.title || "")
        .toLowerCase()
        .replace(/[\s\/\\?!'"\(\)]+/g, "_");
    },
    cannotDownloadAudio() {
      return !some(
        this.lesson.sentences,
        sent => sent.audio && sent.audio[0] && sent.audio[0].path
      );
    },
    picture() {
      if (
        this.lesson.picture && this.lesson.picture.url
      ) {
       return `${this.apiUrl}/${this.lesson.picture.url}`;
      } else {
        return null;
      }
    },
    url() {
      if (this.lesson && this.lesson.has_readonly) {
        return `${window.location.origin}/p/${this.$route.params.id}`;
      } else {
        return window.location.href;
      }
    },
    teacherUrl() {
      return this.forceTeacherUrl
    },
    userName() {
      if (!this.user) {
        return "";
      }

      return this.user.first_name || (this.user.email ? this.user.email.match(/^\w+/)[0] : '')
    }
  },
  methods: {
    ...mapMutations(["SET_COMPACT_MODE", 'SET_VOICE_GENDER']),
    ...mapActions(['getSentences']),
    openCogMenu() {
      console.log(this.$refs.cogIconMenuTrigger)
      this.$refs.cogIconMenuTrigger.click()
    },
    generateTtsAudio() {
      if (!this.generatingTts) {
        const {uuid} = this;

        this.generatingTts = true;
        Lesson.generateTtsAudio({id: this.lesson.uuid, gender: this.voiceGender}).then(() => {
          this.generatingTts = false
          this.$toast.success("Audio generated");
          this.refresh();
          this.$socket.emit('LESSON_ASK_REFRESH', {
            room: `LESSON_${this.lesson.uuid}`,
            uuid
          });
        })
      }
    },
    translateAll() {
      if (!this.translatingAll) {
        const {uuid} = this;

        this.translatingAll = true;
        Lesson.translateAll({id: this.lesson.uuid}, {}).then(() => {
          this.translatingAll = false
          this.$toast.success("Sentences translated");
          this.refresh();
          this.$socket.emit('LESSON_ASK_REFRESH', {
            room: `LESSON_${this.lesson.uuid}`,
            uuid
          });
        })
      }
    },
    copyToClipboard() {
      let text = "Content\tPhonetic\tLiteral translation\tTranslation\tAudio\n"
      const sentences = this.lesson.sentences.filter(sent => sent.content && sent.content.length)

      sentences.forEach((sentence) => {
        const audio = sentence.audio.find(aud => aud.user_id == this.lesson.audio_teacher_id)
        const audioPath = audio ? `${this.apiUrl}/${audio.path}` : ''

        text += `${sentence.content}\t${sentence.ipa || ""}\t${sentence.literal_translation || ""}\t${sentence.translation || ""}\t${audioPath}\n`
      })

      this.$copyText(text);
      this.copiedToClipboard = true;
      let successMsg = "Copied successfully";
      successMsg += "<br><br><a class='is-flex valign' style='color: #FFF;border-bottom: 1px solid #FFF;' href='https://spreadsheet.new' target='_blank'><i class='mdi mdi-open-in-new mdi-16px pr-2'></i>New Google Spreadsheet</a>"


      this.$toast.success(successMsg, { duration: 8000 })

      setTimeout(() => {
        this.copiedToClipboard = false;
      }, 2000)
    },

    getCredits() {
      this.claiming = true
      this.$store
          .dispatch("claimCredits").then(data => {

        console.log({ data })
        this.$store.dispatch("setUser", data.user);
        this.$auth.user(data.user);
        this.claiming = false
        this.isLocked = data.user.is_locked;
        this.$toast.open({
          duration: 5000,
          message: 'You now have 10 credits',
          type: "success"
        });
      })
    },

    handleChangeAudioTeacherId() {
      if (this.lesson.id) {
        Lesson.update({id: this.lesson.id}, {lesson: {
            uuid: this.lesson.uuid,
            audio_teacher_id: this.user.id
          }})
      }
    },

    removePict() {
      const {uuid} = this;

      this.removingPicture = true;
      Lesson.removePicture({id: this.lesson.id}, {lesson: {
          uuid: this.lesson.uuid
        }}).then(() => {
        this.$store.dispatch("editLesson", {
          ...this.lesson,
          picture: null
        })
        this.removingPicture = false;
        this.$socket.emit('LESSON_ASK_REFRESH', {
          room: `LESSON_${this.lesson.uuid}`,
          uuid
        });
      })
    },

    saveField(field) {
      const params = {
        uuid: this.lesson.uuid
      }
      params[field] = this.lesson[field]
      Lesson.update({id: this.lesson.id}, {lesson: params})
    },

    unlock() {
      this.unlockLoading = true;
      const {id} = this.$route.params;
      const {uuid} = this;

      this.$store.dispatch('unlockLesson', { id }).then(() => {
        this.unlockLoading = false
        this.$toast.open({
          duration: 5000,
          message: 'You successfully unlocked the recordings & pictures for this lesson',
          type: "success"
        });
        this.$socket.emit('LESSON_ASK_REFRESH', {
          room: `LESSON_${id}`,
          uuid
        });
      });
    },
    reloadIfn() {
      if (this.lessonEditWillCrash) {
        window.location.reload()
      }
    },
    dragStart() {
    },
    dragEnded() {
      const {id} = this.$route.params;
      const {uuid} = this;
      const ids = this.lesson.sentences.map(sentence => sentence.id)
      this.$store.dispatch('updateSentencesOrder', {
        id,
        ids
      }).then(() => {
        this.lessonEditWillCrash = true

        this.$socket.emit('LESSON_ASK_REFRESH', {
          room: `LESSON_${id}`,
          uuid
        });

      })
    },

    onCopyError() {
      this.$toast.open({
        duration: 5000,
        message: 'Problem while copying',
        type: "error"
      });
    },
    onCopyStudent() {
      this.copiedStatus = Object.assign({}, {
        student: false,
        teacher: false
      });

      this.copiedStatus.student = true
      this.$mixpanel.track("Lesson - Share - Copy student link");
    },
    onCopyTeacher() {
      this.forceTeacher = false;
      this.copiedStatus = Object.assign({}, {
        student: false,
        teacher: false
      });

      this.copiedStatus.teacher = true
      this.$mixpanel.track("Lesson - Share - Copy teacher link");
    },

    computeInfo() {
      const sentences = this.lesson.sentences.filter(sent => sent.content && sent.content.length)
      this.info.wordsCount = uniq(sentences.map(sent => (sent.content || "").toLowerCase()).join(" ").split(/[\s?!\.,]+/)).length;
      this.info.charactersCount = sentences.map(sent => sent.content.length).reduce((num, arr) => num + arr, 0);
      this.info.sentencesCount = sentences.length
      this.info.audioCount = sentences.filter(sent => sent.audio && sent.audio.path).length
      this.$mixpanel.track("Lesson - Check info");
    },
    updateLastTag(tag) {
      this.lastTag = tag
    },
    isPublicChanged() {
      if (this.lesson.is_public) {
        this.lesson.has_readonly = true;
      }
      this.saveField("is_public")
    },
    closeLesson() {
      this.$socket.emit('LESSON_FINISHED', {
        room: `LESSON_${this.$route.params.id}`,
      })
      this.$store.dispatch('queuePodcast', this.lesson).then(() => {
        this.$toast.open({
          duration: 5000,
          message: 'Generating quality audio podcast within the next 15min...',
          type: "success"
        });
      });
      this.isClosed = true;
    },
    // startAutoRefresh() {
    //   this.autoRefresh = setInterval(() => {
    //     if (!this.forceTeacher) {
    //       const now = new Date();
    //       // console.log("this.lastActivity was ", (now - this.lastActivity) / 1000, " seconds ago" );
    //       if (now - this.lastActivity > 3600000) {
    //         this.stopAutoRefresh();
    //       } else if (now - this.lastActivity > 6000) {
    //         this.silentRefresh();
    //       }
    //     }
    //   }, 6000)
    // },
    stopAutoRefresh() {
      if (this.autoRefresh) {
        clearInterval(this.autoRefresh);
      }
    },
    onDrop(ev) {
      ev.stopPropagation();
      ev.preventDefault();
      const {dataTransfer} = ev;
      this.updatePicture(dataTransfer.files)
    },
    updatePicture(pictArr) {
      const { lesson } = this;
      this.uploadingPicture = true;
      this.$store.dispatch("addPictureToLesson", {
        lesson,
        file: pictArr[0] || pictArr }).then(res => {
        this.uploadingPicture = false;
      }, err => {
        this.$toast.open({
          duration: 7000,
          message: 'The file sent couldn\'t be processed. Please make sure you send an image.',
          type: "error"
        });
        this.uploadingPicture = false;
      })

      // Lesson.savePicture({
      //   id,
      // }, {
      //   lesson: {
      //     file: pictArr[0],
      //     uuid,
      //   }}).then((res) => {
      //   console.log(res);
      //   this.refresh();
      // })
    },
    addExistingSentence() {
      this.$buefy.modal.open({
        parent: this,
        // width: ,
        props: {
          lesson_uuid: this.requestedUuid,
          languageTo: this.lesson.language_to,
          languageFrom: this.lesson.language_from,
          index: this.lesson.sentences.length
        },
        component: addExistingSentenceModal,
        hasModalCard: false
      })
    },
    addSentence() {
      if ((!this.user || this.user.role == "free") && this.lesson.sentences.length >= 50) {
        return false;
      }

      this.isAdding = true;
      this.$store.dispatch("addSentence", {
        lesson_id: this.requestedUuid,
        index: this.lesson.sentences.length
      }).then(
        sentence => {
          // console.log("we can autofocus");
          this.isTyping({
            sentence: Object.assign(
              {
                uuid: this.uuid
              },
              sentence
            ),
            action: "addSentence"
          });
          this.isAdding = false;
          setTimeout(() => {
            document
              .querySelectorAll(`.sentence .sentence__content input`)[this.lesson.sentences.length-1]
              .focus();
          }, 400);
        },
        err => {
          this.isAdding = false;
        }
      );
      this.$mixpanel.track("Sentence - Add");
    },
    pingServer() {
      // Send the "pingServer" event to the server.
      this.$socket.emit("PING_SERVER", "PING!");
    },
    openAccessModal() {
      this.$buefy.modal.open({
        parent: this,
        width: 700,
        props: {
          lesson: this.lesson,
        },
        component: editLessonAccessModal,
        hasModalCard: false
      })
    },
    remove() {
      this.$store.dispatch("deleteLesson", this.lesson).then(() => {
        this.$toast.success("Lesson removed successfully");
        this.$router.push({ name: "Home" });
      });
      this.$mixpanel.track("Lesson - Delete");
    },
    print() {
      setTimeout(() => {
        window.print();
        this.$mixpanel.track("Lesson - Print");
      }, 400)
    },
    save() {
      // console.log("EXECUTING save");
      console.log(this.lesson, this.lesson.title && this.lesson.title.length)
      if (this.lesson.title && this.lesson.title.length) {
        // console.log("SAVING YEAH");
        this.$store.dispatch(
          "saveLesson",
            {
              ...this.lesson,
              id: this.lesson.id
            }
        );
      }
    },
    delayedSave: debounce(function() {
      console.log("saving", this)
      this.save();
    }, 1500),
    isTyping(msg) {
      // console.log('we are typing: ', msg.sentence.content)
      this.$socket.emit(
        "SENTENCE_EDITED",
        Vue.util.extend({
          room: `LESSON_${this.$route.params.id}`,
          language_to: this.lesson.language_to,
          language_from: this.lesson.language_from,
          user_id: this.user.id,
        }, msg)
      );
    },
    setLessonLanguageTo(newValue) {
      this.lesson.language_to = newValue;
      this.saveField("language_to");
      this.$mixpanel.track("Lesson - Change language learned");
    },
    setLessonLanguageFrom(newValue) {
      this.lesson.language_from = newValue;
      this.saveField("language_from");
      this.$mixpanel.track("Lesson - Change language known");
    },
    share() {
      this.shareModal = true;
      // setTimeout(() => {
      //   this.$refs.shareUrl.focus();
      //   this.$refs.shareUrl.select();
      // }, 300);
      this.$mixpanel.track("Lesson - Share");
    },
    duplicate() {
      this.duplicateModal = true;
      // setTimeout(() => {
      //   this.$refs.shareUrl.focus();
      //   this.$refs.shareUrl.select();
      // }, 300);
      this.$mixpanel.track("Lesson - Duplicate");
    },
    updateActivity: throttle(function() {
      this.$store.commit('updateActivity')
    }, 500),
    silentRefresh: throttle(function() {
      this.$store.dispatch("findLesson", { id: this.$route.params.id })
    }, 500),
    refresh: throttle(function() {
      if (
        this.$route &&
        this.$route.params &&
        this.$route.params.id &&
        this.$route.params.id != "null" &&
        this.$route.params.id != "undefined"
      ) {
        this.isRefreshing = true;
        this.$store.dispatch("findLesson", { id: this.$route.params.id }).then(
          lesson => {
            this.isRefreshing = false;
          },
          err => {
            this.$toast.open({
              message: `The lesson cannot be found :/`,
              type: "error"
            });
            this.isRefreshing = false;
          }
        );
        this.$mixpanel.track("Lesson - Refresh");
      }
    }, 500),
    lessonFindCallback(lesson) {
      if (this.user.id) {
        this.$auth
            .load()
            .then(() => {
              if (this.$auth.check()) {
                this.$store.dispatch('queryUsers')
                this.getSentences({to: lesson.language_to, from: lesson.language_from})
              }
            });
      }

      if (this.$route.query.t) {
        this.handleChangeAudioTeacherId();
      }


      if (this.user) {
        this.forceTeacher = this.$route.query.t || this.user.id == lesson.user_id;
        if (this.user.force_student) {
          this.forceTeacher = false
        }
      }
      this.$store.commit("addLessonToLastLessons", lesson);
      if (this.lesson && this.lesson.language_to) {
        if (this.lesson.language_to.indexOf('en') !== -1) {
          this.lesson.language_from = this.lesson.language_from || 'fr-FR'
        } else {
          this.lesson.language_from = this.lesson.language_from || 'en-US'
        }
      }
      document.title = `${this.lgToTxt[lesson.language_to] || ""} lesson: ${
        lesson.title || ''
        }`;
      if (lesson.sentences && lesson.sentences.length == 1) {
        document
          .querySelector(".sentence .sentence__content input")
          .focus();
      }
    },
    askMicPermission() {
      // navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.mediaDevices.getUserMedia;
      // console.log(navigator.getUserMedia);
      // console.log({navigator});
      navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      }, (stream) => {
        this.micIsOff = false
      }, (err) => {
        this.micIsOff = true
      })
    },
    joinRoom() {
      this.$socket.emit("ROOM", {
        room: `LESSON_${this.$route.params.id}`,
        uuid: this.uuid,
        name: this.userName
      });
    }
  },
  watch: {
    forceTeacher() {
      // let bg = this.forceTeacher ? "hero is-primary" : "";
      // if (this.lesson.has_readonly) {
      //   bg = "hero is-warning";
      // }
      // //this.$store.commit("layoutBackground", bg);
    },
    lastAudio() {
      // console.log("LAST AUDIO CHANGED");
      if (this.lastAudio && this.lastAudio.id) {
        // console.log(this.lastAudio);
        const msg = {
          sentence: {
            id: this.lastAudio.model,
            uuid: this.uuid,
            index: this.lastAudio.index,
            audio: this.lastAudio
          }
        };
        this.$socket.emit(
          "SENTENCE_EDITED",
          Vue.util.extend({ room: `LESSON_${this.$route.params.id}` }, msg)
        );
      }
    }
  },
  mounted() {
    this.$localStorage.set("redirectAfterLogin", null);
    const {id} = this.$route.params

    // this.url = window.location.href;
    this.forceUrl = window.location.href;
    this.forceTeacherUrl = `${window.location.origin}/lessons/${id}?t=1`

    this.joinRoom()

    if (this.user) { // User can be in guest mode
      this.forceTeacher = this.user.id === this.lesson.user_id;
    } else {
      this.$localStorage.set("redirectAfterLogin", this.$route.path);
      this.$localStorage.set("redirectQueryAfterLogin", JSON.stringify(this.$route.query));
    }
    // this.$store.dispatch('getSentences')
    this.$store.dispatch("setLayout", "logged-in-layout");
    if (this.lesson.uuid === id) {
      this.lessonFindCallback(this.lesson)
    } else {
      this.$store
        .dispatch("findLesson", { id })
        .then(lesson => {
          this.lessonFindCallback(lesson)
        }, err => {
          // console.log(err);
          this.$toast.open({
            duration: 5000,
            message: err.body.message,
            type: "error"
          });
          this.$router.push({ name: "Home" });
        });
    }
  },
  created() {
    this.$store.commit("layoutBackground", "");
    // this.$auth.token(null, "expired")

    this.requestedUuid = this.$route.params.id;
    if (
      !this.$route.params.id ||
      this.$route.params.id == "null" ||
      this.$route.params.id == "undefined"
    ) {
      this.$router.push({ name: "Home" });
      return;
    }

    this.$store.dispatch("setLayout", "logged-in-layout");
    document.title = `${this.lgToTxt[this.lesson.language_to] || ""} lesson: ${
      this.lesson.title || ''
    }`;
    //this.startAutoRefresh()

    this.isLocked = this.$store.getters.user.is_locked;
    if (this.lesson.paid) {
      this.isLocked = false
    }

  },
  beforeDestroy() {
    document.title = `Teachee - Language lessons made easy`;
    this.$socket.emit("LEAVE_ROOM", { room: `LESSON_${this.lesson.uuid}` });
    this.stopAutoRefresh()
  },
  sockets: {
    LESSON_ASK_REFRESH({uuid}) {
      if (uuid != this.uuid) {
        this.refresh()
      }
    },
    LESSON_FINISHED() {
      if (!this.forceTeacher) {
        this.lessonOverModal = true;
      }
    },
    SOMEONE_JOINED(data) {
      if (data.uuid != this.uuid) {
        this.$toast.success(`${data.name} just joined`)
        this.lesson.shared = true
      }
    },

    CONNECTED() {
      this.joinRoom()
    },

    disconnect() {
      this.$socket.emit("LEAVE_ROOM", {
        room: `LESSON_${this.lesson.uuid}`
      });
    },

    error(err) {
      //  ["connect", "error", "disconnect", "reconnect", "reconnect_attempt",
      // "reconnecting", "reconnect_error", "reconnect_failed", "connect_error",
      // "connect_timeout", "connecting", "ping", "pong"]
      // console.log("SOCKET Error \\o/", err);
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    }
  }
};
</script>
<style lang="scss">
@import "colors";

.live-lesson-page {
  .control.has-icons-left .icon,
  .control.has-icons-right .icon {
    color: #363636;
    width: 24px;
    text-align: revert;
    justify-content: flex-end;
    display: inline-flex;
  }

  .columns.printclearboth {
    border-top: 2px solid $sweetGray;
    margin-top: 10px !important;
    padding-top: 10px;
  }

  .card {
    overflow: visible;
  }

  .lesson-loader {
    min-height: 70vh;
  }

  .lesson-picture {
    margin: auto;

    &:hover {
      cursor: pointer;
    }

    img {
      transition: all .2s ease-in-out;
      box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
      border-radius: 7px;
      width: auto;
      max-height: 250px;
    }

    &:hover:not(.togglePictureFixed) img {
      transform: scale(1.01);
      position: relative;
      z-index: 10000;
    }

    &.togglePictureFixed {
      position: fixed;
      right: 23px;
      bottom: 50px;
      z-index: 10;
    }
  }

  .field-is-public label {
    align-items: flex-start;
  }


  #export_to_excel {
    cursor: pointer;
  }

  .mode-switcher {
    line-height: 55px;
    margin: 0 9px;
  }

  span.separator {
    color: $lightGray;
    line-height: 55px;
    margin: 0 15px;
  }

  button#unlock_credit,
  button#review,
  button#share {
    height: 30px !important;
    align-self: center;
  }

  .avatar img {
    width: 30px;
    height: 30px;
    padding: 3px;
  }

  .card-content {
    padding-top: 14px;
    padding-left: 12px;
  }

  .info-created-at {
    .date {
      height: 44px;
      line-height: 20px;
    }
  }

  .info-numbers p {
    font-weight: bold;
    height: 29px;
  }

  .info-label {
    font-weight: bold;
    color: $midGray;
  }

  .card-header a.options,
  .card-header .avatar,
  .button.card-header-icon {
    //min-width: 50px;
  }

  &.compact-mode {
    .sentence--inputs {

      //.sentence__content,
      //.sentence__ipa,
      //.sentence__translation {
      //  width: auto
      //}
      //
      //.sentence__ipa {
      //  position: absolute;
      //  top: 64px;
      //}
      //
      //.sentence__literal-translation {
      //  margin-top: 0;
      //}
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    .main-section {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

@media (max-width: 425px) {
  .live-lesson-page {
    .button.is-info.is-outlined,
    .separator {
      display: none;
    }
  }
}

@media print {
  .live-lesson-page {
    .columns:last-child {
      border-top: 2px solid transparent;
    }
  }
}
</style>
