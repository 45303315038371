

<template lang="pug">
  section#how_it_works_teacher.section.section--how-it-works-teacher
    .container
      .head
        p.has-text-dark.text-main.is-size-6.mb-0.has-text-centered.mx-auto.opacity-8(
          v-if="topic"
        ) {{ topic }}
        h2.size-h2.has-text-dark.mb-6 How it Works

      .body.columns.mt-6.has-flex-start-items.is-multiline
        .column.is-three-fifths.is-12-mobile(
          v-scroll-reveal.reset="{ delay: 200 }"
              )
          .tab.has-text-right-desktop(v-for="i in tabsLabels" :key="i")
            div(v-if="tabSelectedIndex == i")
              img(
                v-if="i == 4"
                src="@/assets/v3/screenshot-step4-teacher.png"
              )
              img(
                v-else
                :src="require(`../../assets/v3/screenshot-step${i}.png`)"
                )

        .column.has-text-left.pl-md-5(
          v-scroll-reveal.reset="{ delay: 400 }"
        )
          .tab-selector(
          )
            span.is-clickable(
              v-for="nb in tabsLabels" :key="nb"
              :class="{selected: tabSelectedIndex === nb}"
              @click="tabSelectedIndex = nb"
              ) {{ nb }}

          .tab(v-for="(tab, i) in Object.values($t('tabs'))" :key="i")
            div(v-if="tabSelectedIndex == i+1")
              h3.has-text-dark.size-h3.my-3(
              ) {{ tab.title }}
              p.has-text-dark(
              ) {{ tab.desc }}
</template>

<script>
import img1 from "@/assets/v3/screenshot-step1.png"
import img2 from "@/assets/v3/screenshot-step2.png"
import img3 from "@/assets/v3/screenshot-step3.png"
import img4 from "@/assets/v3/screenshot-step4-teacher.png"

import {preloadImage} from "@/utils";

export default {
  name: "HowItWorksTeacherSection",
  props: ['topic'],
  created() {
    preloadImage(img1);
    preloadImage(img2);
    preloadImage(img3);
    preloadImage(img4);
  },
  data() {
    return {
      tabsLabels: [1, 2, 3, 4],
      tabSelectedIndex: 1,
    }
  },

}
</script>

<style lang="scss">
@import "colors";

.section--how-it-works-teacher {
  padding-bottom: 5rem;
  @media (min-width: 768px) {
    min-height: 760px;
  }

  img {
    height: auto;
    width: 100%;
    max-width: 600px;
  }

  p,
  h3 {
    line-height: 1.2;
    width: 65%;
  }

  .tab--picture {
    @media (min-width: 768px) {
      min-height: 268px;
    }
  }

  .tab-selector {
    margin-top: 5rem;
    @media (max-width: 767px) {
      margin-top: .5rem;
    }

    span {
      user-select: none;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      color: $primaryDarker;
      font-size: 24px;
      width: 55px;
      height: 55px;
      line-height: 50px;
      font-weight: 600;
      font-family: 'Dela Gothic One', cursive;
      transition: font-size 0.15s cubic-bezier(.2, .8, .2, .8), color 0.15s cubic-bezier(.2, .8, .2, .8), transform 0.15s ease-in-out;

      &.selected {
        background-color: $primaryDarker;
        color: #FFF;
        border-radius: 50%;
        font-size: 40px;
        line-height: 51px;
      }

      &:not(.selected):hover {
        //transform: scale(1.2);
      }

      &:active {
        transform: scale(0.95) !important;
      }
    }

    span + span {
      margin-left: -3px;
    }
  }

  @media screen and (max-width: 767px) {
    padding-left: 0.5rem;
    .column+.column {
      display: flex;
      flex-direction: column;
      align-items: center;
      .tab {
        max-width: 100%;
        margin: auto;
        text-align: center;
      }
      p,
      h3 {
        width: 100%;
      }
      h3 {
        line-height: 1.1;
      }
    }
  }
}
</style>

<i18n lang="yaml">
en:
  tabs:
    - title: Start your video chat with your student as usual
      desc: Teachee runs separately from your video chat software, so you can use any software you wish.
    - title: Create a lesson and share the link with your student
      desc: Don’t worry, it just takes two clicks. You will create the content of the lesson in real time.
    - title: Hit the record button when an important sentence comes up
      desc: Teachee will record and store your pronunciation, provide a phonetic transcription and will automatically translate the sentence into your student’s native language.
    - title: Assign homework practice
      desc: After the lesson is over, your student can export all the sentences as flashcards to practice for the next lesson.
</i18n>