<template>
  <div class="app-container page--auth text-main">
    <front-menu/>
    <hero-section title="Sign up"
                  :hide-cta="true"/>
    <section class="my-auto">
      <div class="container is-max-desktop mb-5 has-bg">
        <div class="columns is-centered">
          <gdpr-consent
              v-if="!gdprConsented"
              v-model="gdprConsented"
              class="column is-half"
          />
          <div
              v-else
              class="column is-one-third"
          >
            <!--<h3 class="title has-text-grey">Sign up</h3>-->
            <div class="box">

              <div
                  v-google-signin-button="googleSignInParams.client_id"
                  class="is-fullwidth is-clickable hover-brighten-95"
                  @click="googleIsLoading = true">
                <img src="@/assets/btn_google_signin_light_normal_web@2x.png" width="191" />
              </div>

              <p class="has-text-centered my-2">
                or
              </p>

              <form>
                <!--<div class="field">-->
                <!--<div class="control">-->
                <!--<input class="input is-medium" type="email" placeholder="Your Email" autofocus="">-->
                <!--</div>-->
                <!--</div>-->
                <div
                    v-if="error"
                    class="message is-danger">
                  <div class="message-body">
                    {{ error }}
                  </div>
                </div>

                <b-field>
                  <b-input
                      id="first_name"
                      v-model="form.first_name"
                      ttpe="text"
                      required
                      autofocus
                      size="is-medium"
                      placeholder="First name"
                  />
                </b-field>

                <b-field>
                  <b-input
                      id="email"
                      v-model="form.email"
                      ttpe="text"
                      required
                      autofocus
                      size="is-medium"
                      placeholder="Email"
                  />
                </b-field>

                <b-field>
                  <b-input
                      id="password"
                      v-model="form.password"
                      type="password"
                      required
                      password-reveal
                      size="is-medium"
                      placeholder="Password"
                  />
                </b-field>

                <b-field>
                  <b-input
                      id="confirm_password"
                      v-model="form.confirm_password"
                      type="password"
                      password-reveal
                      required
                      size="is-medium"
                      placeholder="Confirm password"
                  />
                </b-field>

                <b-field class="has-text-left">
                  <b-checkbox v-model="form.checked">
                    Remember me
                  </b-checkbox>
                  <!--<a style="float: right" href="/">login?</a>-->
                </b-field>


                <button
                    id="btn_sign_up"
                    :class="{'is-loading': isLoading, 'is-disabled': isLoading}"
                    class="button is-block is-primary ml-auto"
                    @click="onSubmit($event)">
                  Sign up
                </button>

              </form>
            </div>
            <p class="has-text-white is-flex has-justify-around mb-4">
              <router-link :to="{name: 'SignIn'}">Sign in</router-link>
              <router-link :to="{name: 'LostPasswordPage'}">Forgot Password
              </router-link>
              <a href="mailto:guillaume@teachee.io">Help</a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <footer-section class="mt-auto" />
  </div>
</template>


<script>
import gdprConsent from "@/components/GdprConsent";
import GoogleSignInButton from 'vue-google-signin-button-directive'
import HeroSection from "@/components/sections/Hero";
import FooterSection from "@/components/sections/FooterSection";


export default {
  name: 'SignUp',
  directives: {
    GoogleSignInButton
  },
  components: {
    FooterSection,
    HeroSection,
    gdprConsent
  },
  data() {
    return {
      gdprConsented: Vue.cookie.get('gdprConsented'),
      error: null,
      form: {
        first_name: '',
        email: '',
        username: '',
        password: '',
        confirm_password: '',
        from_partner: null
      },
      redirect: false,
      isLoading: false,
      googleIsLoading: false,
      googleSignInParams: {
        client_id: '476667578043-0tm7eoufgmi6m961qb2m77hhelj5ksl5.apps.googleusercontent.com',
        response_type: 'permission'
      }
    }
  },
  created() {
    var pid;
    if (pid = this.$cookie.get("pid")) {
      this.form.from_partner = this.$cookie.get("pid")
    }

    this.$store.dispatch('setLayout', 'no-layout')

    var path;
    if (path = this.$localStorage.get('redirectAfterLogin')) {
      if (path != 'null') {
        this.redirect = {
          path,
          query: JSON.parse(this.$localStorage.get("redirectQueryAfterLogin"))
        }
      }
    }
  },

  methods: {
    onSubmit(evt) {
      this.isLoading = true;
      evt.stopPropagation();
      evt.preventDefault()
      this.$auth.register({
        body: this.form,
        autoLogin: true,
        redirect: this.redirect || {name: 'Home'},
        rememberMe: this.form.checked
      }).then((res) => {
        this.$mixpanel.alias(res.data.id);
        this.$store.dispatch("setLayout", "logged-in-layout");
        this.$store.dispatch("setUser", res.body.data);

        try {
          if (this.$analytics && this.$analytics.fbq && this.$analytics.fbq.event) {
            this.$analytics.fbq.event("StartTrial")
          }
          if (window.rdt) {
            window.rdt('track', 'SignUp');
          }
          this.$ga.event("conversion", "sign_up");
        } catch (e) {

        }

        // this.$router.push(this.redirect || {name: 'Home'}).catch()
      }, (res) => {
        if (res.status === 409) {
        }
        this.error = res.data.message;
        this.isLoading = false
      });
    },
    OnGoogleAuthSuccess(access_token) {
      this.$store.dispatch("setUserFromGoogle", {
        access_token,
        pid: this.$localStorage.get("pid"),
      }).then((res) => {
        if (!res.userExisted) {
          this.$mixpanel.alias(res.user.id + '');
        }
        this.$store.dispatch("setUser", res.user);
        this.$auth.user(res.user)
        this.$auth.token(null, res.token)
        this.googleIsLoading = false;
        this.$store.dispatch("setLayout", "logged-in-layout");
        this.$cookie.set('rememberMe', true, 365)

        if (this.$analytics && this.$analytics.fbq && this.$analytics.fbq.event) {
          this.$analytics.fbq.event("StartTrial")
        }
        if (window.rdt) {
          window.rdt('track', 'SignUp');
        }
        this.$ga.event("conversion", "sign_up");

        this.$router.push({name: 'Home'}).catch()
      });
    },
    OnGoogleAuthFail(error) {
      this.googleIsLoading = false;
    }
  }

}
</script>


<style lang="scss">
@import 'colors';

.page--auth {
  display: flex;
  flex-direction: column;

  background: linear-gradient(180deg, #FFF 51%, $primary 51%);

  .columns.is-centered {
    margin-top: -3rem;
  }

  .has-bg {
    background: url("~@/assets/v3/bottomCTA-bg.svg") no-repeat;
    background-size: 50%;
    background-position: center 2%;
    @media screen and (max-width: 767px) {
      background-size: 100%;
    }
  }

  input {
    font-weight: 300;
  }

  p.subtitle {
    padding-top: 1rem;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

}

</style>
