`import {Event} from '../models/resources'`

actions =
  getEvents: (context) ->
    Event.query().then (response) ->
      context.commit('events', response.body.events)

  addEvent: (context, event) ->
    new Promise((resolve, reject) ->
      Event.save(event: event).then(
        (res) ->
          context.commit('addEvent', Object.assign(event, {id:  parseInt(res.body.id)}))
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )

  editEvent: (context, event) ->
    new Promise (resolve, reject) ->
      context.commit('editEvent', event)
      resolve()

  deleteEvent: (context, event) ->
    if event.id
      new Promise((resolve, reject) ->
        Event.delete({id: event.id}).then (res) ->
#          context.commit('deleteEvent', event)
          resolve()
        , (err) ->
          reject()
      )
    else
      new Promise((resolve, reject) ->
        context.commit('deleteEvent', event)
        resolve()
      )

  saveEvents:  (context, events) ->
    transformedEvents = []
    for event in events
      transformedEvents.push(Event.transformToApi(event))

    new Promise((resolve, reject) ->
      Event.batchUpdate({ events: transformedEvents }).then (res) ->
        resolve(res.body)
      , (res) ->
        reject(res.body)
      )


  saveEvent:  (context, event) ->
    rs = Event

    new Promise((resolve, reject) ->
      if event.id
        context.commit('editEvent', event)
        rs.update({id: event.id}, {event: event}).then(
          (res) ->
            resolve(res.body)
        , (res) ->
          reject(res)
        )
      else
#        context.commit('editEvent', event)
        rs.save(event: event).then(
          (res) ->
            context.commit('editEvent', Object.assign(event, {id:  parseInt(res.body.id)}))
            resolve(res.body)
        , (res) ->
          reject(res.body)
        )
    )


`export default actions`
