import find from "lodash/find";

voiceMapping =
  es: "Google español"
  en: "Samantha"
  he: "Carmit"
  ar: "Maged"
  cs: "Zuzana"
  el: "Melina"
  fi: "Satu"
  da: "Sara"
  tr: "Yelda"
  ko: "Yuna"
  bg: "Daria"
  it: "Google italiano"

#   pt: "Google português do Brasil"

Audio =
  name: 'audio'
  voice: null
  voices: []
  lang: 'en-US'
  text: null
  utterance: null

  getVoice: (voice_params) ->
    find(window.speechSynthesis.getVoices(), voice_params)

  getVoices: (languages) ->
    for language in languages
      lang = language || @lang
      voice = null
      if voiceMapping[lang]
        voice = find(window.speechSynthesis.getVoices(), {name: voiceMapping[lang]})
      else
        voice = find(window.speechSynthesis.getVoices(), {lang: "#{language}-#{language.toUpperCase()}"})

      @voices.push voice
    @voices

  loadVoices: ->
    @voices = window.speechSynthesis.getVoices()
#    console.log('@voices')
#    console.log(@voices)
    @voices

  getVoiceName: (language) ->
    voice = @getVoice(language)
    if voice
      voice.name

  speak: (text, event, language, voice_name) ->
    window.speechSynthesis.cancel()
#    @utterance = @utterance || new SpeechSynthesisUtterance()
    @utterance = new SpeechSynthesisUtterance()
    @utterance.text  = text
    @utterance.volume = 0.75;
#    @utterance.volume = 1;
    @utterance.lang  = language || @lang
    @utterance.voice = @voice

#    console.log({voices: window.speechSynthesis.getVoices()})

    if voice_name && (!@utterance.voice || (@voice && @voice.name && @voice.name != voice_name))
      try
        @utterance.voice = find(window.speechSynthesis.getVoices(), {lang: language, name: voice_name})
      catch error
        console.log(error)

    if !@utterance.voice
      try
        @utterance.voice = find(window.speechSynthesis.getVoices(), {lang: "#{@lang}"})
      catch error
        console.log(error)

    if !@utterance.voice
      try
        @utterance.voice = find(window.speechSynthesis.getVoices(), {lang: "#{@lang.substring(0,2)}"})
      catch error
        console.log(error)

    @voice = @utterance.voice

    if event and event.shiftKey
      @utterance.rate = 0.6
    else
      @utterance.rate = 1
    window.speechSynthesis.speak(@utterance)

  init: () ->
    @loadVoices()
    @

`export default Audio`
