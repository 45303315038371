<script>
import {
  isInViewport,
} from "@/utils";

export default {
  name: 'ShowMouseScrollIfNecessary',
  data() {
    return {
      showScrollLink: false
    }
  },
  mounted() {
    setTimeout(() => {
      const dom = document.getElementById(this.scrollAnchor)
      console.log({ dom })
      if (dom && !isInViewport(dom)) {
        this.showScrollLink = true
      }
    }, 2000)
  },
}
</script>
