<template lang="pug">
  div.has-position-relative.search--student
    div(v-if="selection" @click="selection = null")
      student(:student="selection")
    div#search_student(
      v-else
    )
      i.fa.fa-spinner.fa-spin(v-if="loading")
      template(v-else="")
        i.fa.fa-search(v-show="isEmpty")
        i.fa.fa-times(v-show="isDirty" @click="reset")
      // the input field
      b-field.mb-0
        b-input#search_student_btn(
        icon="search"
        type="search"
        :placeholder="placeholder || 'First name'"
        autocomplete="off"
        v-model="query"
        @keydown.native.prevent.down="down"
        @keydown.native.prevent.up="up"
        @keydown.native.enter="hit"
        @keydown.native.esc="reset"
        @input="update")
      <!--@blur="reset"-->
      // the list
      ul.box.has-position-absolute(v-show="hasItems")
        // for vue@1.0 use: ($item, item)
        li.mt-2(v-for="(option, $item) in items" :class="activeClass($item)" @mousedown="hit" @mousemove="setActive($item)")
          // STUDENT
          student(:student="option")
      ul.boxm.has-position-absolute(v-show="query && query.length && !hasItems")
        li(@mousedown="reset") No results


</template>

<script>
import VueTypeahead from "vue-typeahead";
import StudentMiniCardUI from "@/components/StudentMiniCardUI";
import { mapGetters } from "vuex";
import each from "lodash/each";
import toLower from "lodash/toLower";
import filter from "lodash/filter";
import includes from "lodash/includes";

export default {
  name: "SearchStudent",
  props: ['value', 'placeholder'],
  components: {
    student: StudentMiniCardUI
  },
  extends: VueTypeahead,
  data() {
    return {
      query: null,
      src: "...",
      limit: 10,
      minChars: 2,
      selectFirst: true,
      selection: null
    };
  },
  computed: {
    ...mapGetters(["students"]),
    options() {
      var opts = [];
      each(this.students, function(student) {
        opts.push({
          type: "st",
          id: student.id,
          title: student.first_name,
          picture: student.picture,
          first_name: student.first_name,
          last_name: student.last_name,
          city: student.city,
          link: `/students/${student.id}`
        });
      });
      return opts;
    }
  },
  methods: {
    redirectWhenSelect(selection) {
      if (selection) {
        this.$router.push(selection.link);
      }
    },
    onHit(selection) {
      if (selection && selection.link) {
        this.selection = selection;
        this.$emit('input', selection.id);
        this.reset();
      }
    },
    fetch() {
      return new Promise((resolve, reject) => {
        const query = toLower(this.query);
        resolve({
          data: filter(this.options, opt =>
            includes(toLower(opt.title || ""), query)
          )
        });
        reject();
      });
    },
  }
};
</script>

<style lang="scss">
@import "colors";

.search--student {
  .box {
    min-width: 260px;
    z-index: 10;
  }
  li.mt-2 {
    cursor: pointer;
  }
}

#search_everywhere {
  position: relative;
  #search_everywhere_btn {
    width: 280px;
  }
  img {
    max-height: initial;
  }
  ul {
    padding: 0;
    width: 280px;
    position: absolute;
    top: 40px;
    z-index: 60;
    li {
      padding: 10px 10px;
      &.active,
      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
    li span {
      vertical-align: middle;
    }
  }
}
@media (max-width: 768px) {
  #search_everywhere #search_everywhere_btn {
    width: 100%;
  }
}

/*
::-webkit-input-placeholder::first-letter {
border-bottom: 1px solid red;
}
*/
</style>
