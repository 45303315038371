<template lang="pug">
  .app-container.page-press-kit.text-system(v-farewell="onLeave")
    front-menu
    main.app-content

      section.content.pt-8.has-text-left.pb-8

        h1.mt-8.has-text-primary.text-main.has-text-weight-bold Teachee Press Kit
        p Welcome to the Teachee Press Kit! We&apos;re excited to have you help us spread the word about our innovative language learning platform. Below, you&apos;ll find all the information and resources you need to create engaging and informative content about Teachee.

        h2.text-main.has-text-weight-bold.has-text-primary About Teachee:
        p Teachee is a groundbreaking Software-as-a-Service (SaaS) platform designed to help remote language teachers streamline their teaching process and enhance their students&apos; learning experience. By combining voice-to-text lesson creation, automatic translation, phonetic transcription, audio recording, and flashcard integration with spaced-repetition learning, Teachee provides a comprehensive solution for language learners and educators worldwide.

        h2.text-main.has-text-weight-bold.has-text-primary Key Features:
        ul
          li
            | <b>Voice-to-text lesson creation:</b> Teachers can effortlessly create customized learning materials by speaking into the microphone.
          li
            | <b>Automatic translation and phonetic transcription:</b> Teachee automatically translates and transcribes lesson content into phonetic form, ensuring accurate pronunciation.
          li
            | <b>Audio recording:</b> Teachee records the teacher&apos;s voice and generates high-quality audio for students to review and practice.
          li
            | <b>Flashcard integration and spaced-repetition learning:</b> Students can export lesson content into digital flashcards.

        h2.text-main.has-text-weight-bold.has-text-primary Logo:
        img(src="@/assets/teachee-logo.svg" alt="Teachee Logo" width="200")

        h2.text-main.has-text-weight-bold.has-text-primary Demo videos:
        p Here are videos of Teachee in action. Feel free to use those videos in your content, or reach out to us if you&apos;d like to create a custom video for your audience.
        video.mb-2(width="1280" height="720" controls)
          source(src="https://teachee.io/v2/uploads/demo-v4.mp4" type="video/mp4")
        video.mb-6(width="1280" height="720" controls)
          source(src="https://teachee.io/v2/uploads/teachee-features-demo.mp4" type="video/mp4")

        //-div
          h2.text-main.has-text-weight-bold.has-text-primary Social Media:
          p Stay connected with Teachee by following us on our social media channels:
          ul
            li Twitter:
              a(href="#") Link to Teachee Twitter Account
            li Facebook:
              a(href="#") Link to Teachee Facebook Page
            li Instagram:
              a(href="#") Link to Teachee Instagram Account
            li LinkedIn:
              a(href="#") Link to Teachee LinkedIn Page

    footer-section
</template>

<script>
  import footerSection from "@/components/sections/FooterSection";
  import askToSubscribeOnLeave from '@/components/behaviors/AskToSubscribeOnLeave';
  import {
    noLayoutAndStorePartnerInfo,
    ssrFinishedRendering
  } from "@/utils";

  export default {
    name: 'PressKit',
    mixins: [
      askToSubscribeOnLeave,
    ],
    components: {
      footerSection
    },
    mounted() {
      noLayoutAndStorePartnerInfo(this.$store, this.$route, this.$cookie);
      ssrFinishedRendering();

      if (this.$analytics && this.$analytics.fbq && this.$analytics.fbq.event) {
        this.$analytics.fbq.event("ViewContent", {
          content_name: 'Teachers Page'
        })
      }
    },
  }
</script>


<style lang="scss">
@import 'colors';

.page-press-kit {
  section {
    padding-top: 8rem;
    margin: auto;
    max-width: 60rem;
  }
}
</style>
