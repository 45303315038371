`import {Student} from '../models/resources'`

actions =
  getStudents: (context) ->
    new Promise((resolve, reject) ->
      Student.query().then (response) ->
        context.commit('students', response.body)
        resolve()
    )

  findStudent: (context, student) ->
    new Promise((resolve, reject) ->
      Student.get({id: student.id}).then (response) ->
        context.commit('student', Object.assign({}, response.body, {id: student.id}))
        resolve(response.body)
      , (err) ->
        reject(err)
    )


`export default actions`
