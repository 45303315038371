<template lang="pug">
  div.page--temporary-pricing
    div
      hero-section.pb-0(
        title="One-time offer<br>before price change"
        subtitle="the offer ends in"
        :hide-cta="true"
      )
      #countdown
        flip-countdown(deadline="2024-07-31 23:59:59")
      .columns.pricing-table-wrapper.is-centered
        .column.is-3
          .pricing-table
            .pricing-plan(
              :class="[offer.classes]"
            )
              span.ribbon.has-text-weight-bold.pr-2(v-if="offer.discountText")
                | {{  offer.discountText }}
              .plan-header
                h2(
                  v-html="offer.name"
                  :class="{'has-text-primary': offer.uid == 'premium_tmp_once', 'has-text-dark': offer.uid != 'premium_tmp_once'}"
                )
              .plan-price.mb-2
                h3.plan-price-amount.is-size-3(
                  :class="{'has-text-dark': offer.uid != 'premium_tmp_once', 'has-text-primary': offer.uid == 'premium_tmp_once'}"
                )
                  span.plan-price-currency.text-main €
                  | {{  parseInt(offer.price) }}
                  sup(v-if="offer.cents")
                    | .{{ offer.cents }}
                span.has-text-grey(v-if="!offer.duration")
                  | / 24 months
                span.sub-price(v-if="offer.duration && offer.uid != 'free'")
                  | for {{ offer.duration }}
              .plan-items.mx-2.mt-5
                b-tooltip.plan-item(
                  :label='""'
                  v-for="item in offer.includes"
                  :type="offer.classes"
                  :animated='true'
                  multilined
                  position='is-top')
                  b-icon(icon="check" :type="offer.classes")
                  span(v-html="item")
                  b-icon(icon="info" custom-size="mdi-18px" v-if="item.indexOf('&nbsp;') !== -1" :type="offer.classes")
              div.mb-4.mt-auto
                button.button.mt-5(
                  @click="buy"
                  :class="{'is-primary': offer.uid == 'premium_tmp_once', 'is-dark': offer.uid != 'premium_tmp_once'}"
                )
                  | Buy


      .is-flex.halign.py-5.has-text-primary
        b-icon(icon="info")
        span The "Speech to text" and "Voice recognition" features are supported on Google Chrome only.
</template>

<script>
  import FlipCountdown from 'vue2-flip-countdown';
  import HeroSection from "@/components/sections/Hero";
  import pricingTable from "@/components/PricingTableV2";
  import footerSection from "@/components/sections/FooterSection";
  import PricingFaqSection from "@/components/sections/PricingFaq";
  import offers from "@/support/offers";
  import {mapGetters} from "vuex";
  const offer = offers.find(o => o.uid === "premium_tmp_once")
  console.log({offer})

  export default {
    name: 'TemporaryPricingPage',
    components: {
      FlipCountdown,
      PricingFaqSection,
      HeroSection,
      pricingTable,
      footerSection
    },
    computed: mapGetters(['user']),

    data() {
      return {
        currentServiceTab: 0,
        howItWorksDetails: false,
        selected: "",
        offer: offer
      }
    },

    created() {
      this.$store.dispatch("setLayout", "logged-in-layout");
    },

    mounted() {
      // noLayoutAndStorePartnerInfo(this.$store, this.$route, this.$cookie)
      // ssrFinishedRendering();

      if (this.$analytics && this.$analytics.fbq && this.$analytics.fbq.event) {
        this.$analytics.fbq.event("ViewContent", {
          content_name: 'Temporary Pricing Page'
        })
      }
    },

    methods: {
      onPaymentSuccess(data) {
        const toast = this.$toast;
        const analytics = this.$analytics;
        const auth = this.$auth;
        const store = this.$store;
        const ga = this.$ga;
        const user = this.user;
        console.log({ data })

        if (analytics && analytics.fbq && analytics.fbq.event) {
          analytics.fbq.event("Subscribe")
        }
        if (window.rdt) {
          window.rdt('track', 'Purchase');
        }
        toast.success("The payment was successfully processed", { duration: 5000 })
        ga.event("conversion", "payment", "one_time");

        setTimeout(() => {
          auth.fetch().then( (data) => {
            console.log({userFetched: data})
            store.dispatch("setUser", data.body.data);
          })
        }, 2500)
      },

      buy() {
        this.kind = 'plan'
        this.selectedPlan = offer.uid;
        this.$mixpanel.track("Subscribe - chose - " + offer.uid);
        this.selectedPlan = offer.product_id;

        window.Paddle.Checkout.open({
          product: this.selectedPlan,
          email: this.user.email,
          customer_country: this.user.country,
          allowQuantity: false,
          passthrough: `{"user_id": ${this.user.id}}`,
          customData: {
            tolt_referral: window.tolt_referral,
          },
          successCallback: this.onPaymentSuccess
        });
      }
    }
  }
</script>


<style lang="scss">
  @import 'colors';

  @media (min-width:1260px) {
    #countdown {
      margin: -8.5rem 0 2rem;
    }
  }

  div.page--temporary-pricing {
    .hero-body {
      padding-top: 4rem !important;
    }
    .pricing-plan {
      margin-top: 0 !important;
    }
    .plan-item {
      margin-bottom: 0.2em;
    }
    .pricing-table span.ribbon {
      width: 58px;
    }
  }

  #countdown {
    .flip-card__top, .flip-card__bottom, .flip-card__back-bottom, .flip-card__back::before, .flip-card__back::after {
      display: block;
      color: #ecf0f1;
      background: $primaryDarker;
    }
  }

  .page--pricing {
    .pricing-table-wrapper {
      margin-top: 5rem;
      margin-bottom: 0rem;
      background: $primary;
    }
    .pricing-table .pricing-plan .plan-item {
       background: #FFF;
    }
  }
</style>
