<template lang="pug">
  .notification.has-text-left.mb-0(
    :class="[color]"
    v-show="alert"
    )
    button.delete(@click="markAsSeen" v-if="!cantBeRemoved[uid]")
    slot
</template>

<script>

export default {
  name: "Alert",
  props: [
    "uid",
    "color"
  ],
  data() {
    return {
      alert: false,
      cantBeRemoved: {
        free_plan_ends_soon_5: true
      }
    };
  },
  mounted() {
    this.alert = !this.$localStorage.get(`alert.${this.uid}_seen`)
  },
  methods: {
    markAsSeen() {
      this.$localStorage.set(`alert.${this.uid}_seen`, true)
      this.alert = false
    }
  }
};
</script>

<style lang="scss">
  .live-lesson-page {
    .notification.is-info {
      margin: -13px -24px 0 -12px;
      border-radius: 0;
    }
  }
</style>
