<template lang="pug">
  .is-loading.is-fullheight.has-loader.primary-loader.app-container.w-100.is-size-1
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "LessonsAutoCreatePage",
    computed: mapGetters(['user']),
    mounted() {
      this.$store.dispatch('saveLesson',{
        title: 'Lesson title',
        language_to: this.user.teaches_languages[0] || this.user.learns_languages[0] || 'en-US',
        visitors: [],
        user_id: this.user.id,
        user: this.user
      }).then((le) => {
        this.$router.push(`/lessons/${le.uuid}`)
      }, () => {
        this.$toast.error("The lesson could not be created")
        this.$router.push(`/home`)
      })
    },
  };
</script>