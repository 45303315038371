<script>
  import AskToSubscribeModal from '../modals/AskToSubscribeModal';

  export default {
    name: 'AskToSubscribeOnLeave',
    // mounted() {
    //   this.onLeave();
    // },
    methods: {
      // ON LEVE MODAL - Decomment to enable again
      // onLeave() {
      //   this.$buefy.modal.open({
      //     parent: this,
      //     width: 480,
      //     props: {
      //     },
      //     component: AskToSubscribeModal,
      //     hasModalCard: false,
      //     onCancel() {
      //     }
      //   })
      // }
    }
  }
</script>
