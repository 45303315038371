<template lang="pug">
  .container.correction-page
    .box.has-text-left
      div.has-loader.is-primary.is-outlined.is-loading.has-margin-auto.primary-loader(v-if="querying")
      b-table.w-100(
        :paginated="corrections.length > 20"
        :per-page="20"
        v-else
        :hoverable="true"
        :data="corrections"
      )
        b-table-column(v-slot="props" field="id" label="ID" width="40" numeric)
          | {{ props.row.id }}
        b-table-column(v-slot="props" field="language" label="Language" width="40")
          | {{ props.row.language }}
        b-table-column(v-slot="props" field="word" label="Word" width="40")
          | {{ props.row.word }}
        b-table-column(v-slot="props" field="ipa" label="Phonetic" width="40")
          | {{ props.row.ipa }}
        b-table-column(v-slot="props" label="Actions")
          .has-text-right
            button.button.is-primary(
              @click="apply(props.row.id)"
            ) Apply

            button.button.is-primary.is-outlined.ml-2(
              @click="skip(props.row.id)"
            ) Skip
</template>


<script>
import {Correction} from "@/store/models/resources";
import userCard from "@/components/UserCardUI";
import userPicture from "@/components/UserPictureUI";
import findIndex from "lodash/findIndex";

export default {
  name: "CorrectionsPage",
  components: {
    userCard,
    userPicture
  },
  data() {
    return {
      querying: true,
      corrections: [],
    };
  },
  computed: {
  },
  created() {
    Correction.query().then(response => {
      // console.log(response.body)
      this.corrections = response.body.corrections;
      this.querying = false;
    })
  },
  methods: {
    apply(id) {
      const idx = findIndex(this.corrections, { id })
      Correction.use({id:  this.corrections[idx].id}, {}).then(res => {
        this.corrections.splice(idx, 1)
      })
    },
    skip(id) {
      const idx = findIndex(this.corrections, { id })
      Correction.skip({id:  this.corrections[idx].id}, {}).then(res => {
        this.corrections.splice(idx, 1)
      })
    }
  }
};
</script>


<style lang="scss">
@import "colors";

.correction-page {
  td:last-child,
  th:last-child {
    width: 100%;
    .th-wrap {
      text-align: right;
      justify-content: flex-end;
    }
  }
}
</style>
