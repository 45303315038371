`import {Paypal} from '../models/resources'`

actions =

  chargePaypal: (context, charge) ->
    new Promise((resolve, reject) ->
      Paypal.save(charge).then(
        (res) ->
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )

`export default actions`
