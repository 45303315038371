<template lang="pug">
  component.button.is-fullwidth(
    :is="(lesson.id) ? 'router-link' : 'div'"
    :to="link"
    :class="{'has-bg-picture': lesson.picture && lesson.picture.url}"
    :style="styleObject"
    )
    b-tooltip.is-hidden-mobile.avatar-tooltip(
      v-if="lesson.id && user.id != lesson.user_id && lesson.user.picture"
      :label='lesson.user.first_name'
       type='is-light'
      :animated='true'
       position='is-top')
      user-picture(:user="lesson.user")
    b-tooltip.is-hidden-mobile.avatar-tooltip(
        v-if="lesson.id && user.id == lesson.user_id && student.picture"
        v-for="student in lesson.visitors"
        :key="student.id"
        :label='student.first_name'
        type='is-light'
        :animated='true'
        position='is-top')
      user-picture(:user="student")
    span(v-if="lesson.id")
      b-icon(:icon="lesson.icon" v-if="lesson.icon" custom-size="mdi-18px")
      b-icon(icon="public" v-if="!lesson.icon && lesson.is_public" title="Public" custom-size="mdi-18px")
      span
        | {{ lesson.title }}
      span.lesson__sentences-nb(v-if="lesson.sentences && lesson.sentences.length")
        | {{ lesson.sentences.length }}
      span.lesson__created-at
        | {{ lesson.created_at | moment("DD.MM") }}

    b-field.lesson__title.is-fullwidth(v-else)
      b-input.live-edit(
        placeholder="Ex: Lesson with Anna [ PRESS ↵ ]"
        autofocus="true"
        v-model="newLesson.title"
        @blur='save'
        @keyup.native.enter="save")

</template>

<script>
  import {mapGetters} from 'vuex'
  import userPicture from "@/components/UserPictureUI";
  export default {
    name: 'Lesson',
    props: [
      'lesson',
      'index',
    ],
    components: {
      userPicture
    },
    data() {
      return {
        newLesson: {
          title: null,
        },
        savedTitle: null
      }
    },
    computed: {
      ...mapGetters(['user', 'apiUrl']),
      styleObject() {
        if (!this.lesson.picture || !this.lesson.picture.url) {
          return {}
        } else {
          return {'background-image': `url('${this.apiUrl}/${this.lesson.picture.thumb_url}')`}
        }
      },
      link() {
        if (this.lesson && this.lesson.id) {
          if (this.lesson.has_package || this.lesson.has_readonly) {
            return {name: 'ReadOnlyPackageLesson', params: {lesson_uuid: this.lesson.uuid}}
          } else {
            return {name: 'LiveLesson', params: {id: this.lesson.uuid}};
          }
        } else {
          return {}
        }
      }
    },
    mounted() {
      this.newLesson = Vue.util.extend({}, this.lesson)
      this.newLesson.index = this.index
    },
    methods: {
      titleEdited() {
        // console.log(this.newLesson.title)
      },
      save() {
        const ctrl = this
        // console.log('EXECUTING save', this.newLesson);
        if (this.newLesson.title && this.newLesson.title.length && this.savedTitle !== this.newLesson.title) {
          this.savedTitle = this.newLesson.title
          this.$store.dispatch('saveLesson', Vue.util.extend({
            id: this.lesson.id,
          }, this.newLesson)).then(le => {
            ctrl.$router.push(`/lessons/${le.uuid}`)

            if (this.newLesson.student_id) {
              // BRINGS STUDENT TO THE LESSON
              this.$socket.emit('LESSON_CREATED', {student_id: this.newLesson.student_id})
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'colors';

  .button {
    min-height: 56px;
    position: relative;
    &>span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


  .has-bg-picture {
    /*background-image: url(http://localhost:8081/api/v2/uploads/lessons/54/cc2d9_DSC_0098_prev.jpg);*/
    background-size: cover;
    background-position: 0% 50%;
    color: #FFF;
    text-shadow: 0px 0px 5px #333, 0px 0px 17px #333, 0px 0px 29px #dedede;
    background-repeat: no-repeat;
    background-color: #333;
    /*transition: background-position-y .4s ease-in-out;*/
    .lesson__sentences-nb,
    .lesson__created-at {
      text-shadow: initial
    }
    &:hover {
      /*background-position-x: 250px;*/
      background: $primary !important;
      text-shadow: none !important;
    }
  }

  a .card:hover {
    //border: 1px solid $primary;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f1edfb+0,ffffff+92 */
    background: #f1edfb; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #f1edfb 0%, #ffffff 92%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #f1edfb 0%, #ffffff 92%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #f1edfb 0%, #ffffff 92%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1edfb', endColorstr='#ffffff', GradientType=1); /* IE6-9 fallback on horizontal gradient */
  }

  span.icon {
    vertical-align: middle;
    width: 1em !important;
    margin-top: -3px;
    margin-left: 0 !important;
  }
  span.lesson__created-at {
    position: absolute;
    bottom: 5px;
    right: 10px;
    text-align: right;
    color: $lightGray;
    font-weight: bold;
    font-family: 'Montserrat'
  }
  span.lesson__language-to {
    margin-right: 2px;

  }
  span.lesson__sentences-nb {
    color: $lightGray;
    position: absolute;
    top: 5px;
    right: 10px;
    font-weight: bold;
    font-family: 'Montserrat'
  }

</style>
