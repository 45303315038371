<template lang="pug">
  .user-card(:class="[size]" :title="'User: '+user.id")
    p.is-flex.mb-1
      span
        user-picture.has-valign-middle(:user="user")
        span.is-inline-block.has-valign-middle
          | {{ user.first_name || 'U '+(index+1) }}
          //span.is-block.is-size-7
          //  | {{user.email.toLowerCase()}}
      span.ml-auto
        | {{ user.last_sign_in | moment("DD.MM") }}
    p.is-flex
      b.mr-2.is-inline-flex.is-size-7.has-flex-center-items
        | {{user.lessons_count}}
        b-icon(icon="folder-shared")
      b.mr-2.is-inline-flex.is-size-7.has-flex-center-items
        | {{user.visited_lessons_count}}
        b-icon(icon="school")
      b.mr-2.is-inline-flex.is-size-7.has-flex-center-items(v-if="user.sign_in_count")
        | {{user.sign_in_count}}
        b-icon(icon="graphic-eq")
      b.ml-auto.is-inline-flex.is-size-7.has-flex-center-items(v-if="user.city")
        b-icon(icon="map")
        span.p-0
          | {{user.city}}


</template>

<script>
import userPicture from "@/components/UserPictureUI";
import { mapGetters } from "vuex";

export default {
  name: "UserCardUI",
  props: ["user", "index", "size"],
  components: {
    userPicture
  },
  computed: {
    ...mapGetters(["apiUrl"])
  }
};
</script>


<style lang="scss">
@import "colors";

$figureSize: 36px;
$imgSize: 32px;

$figureSizeXl: 50px;
$imgSizeXl: 46px;

.user-card {
  figure.avatar {
    width: $figureSize;
    height: $figureSize;
    vertical-align: middle;
    display: inline-block;
    margin-right: 8px;
    img {
      width: $figureSize;
      height: $figureSize;
      padding: 2px;
    }
  }
  .is-large {
    figure.avatar {
      width: $figureSizeXl;
      height: $figureSizeXl;
      img {
        width: $figureSizeXl;
        height: $figureSizeXl;
      }
    }
    .letter-avatar {
      line-height: $figureSizeXl;
      span {
        line-height: $imgSizeXl;
        height: $imgSizeXl;
        width: $imgSizeXl;
      }
    }
  }
}
</style>
