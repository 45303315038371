<template lang="pug">
  .container
    .columns.is-multiline
      .box.show-package-page.is-three-fifths-desktop.column.has-position-relative.is-offset-one-fifth-desktop.is-12-tablet
        div.has-loader.is-primary.is-outlined.is-loading.has-margin-auto.primary-loader.mt-2.mb-3(v-if="querying")
        div(v-else)
          div.has-text-right
            router-link#link_add_package.button.is-outlined.is-primary(
              v-if="pack.user_id == user.id"
              :to="{name: 'EditPackPage', params: {id: pack.id}}"
              )
              b-icon(icon="edit")
              span Edit

          div(v-if="picture")
            figure.avatar
              img(:src="picture" width="98" height="98")
            h2 {{ name }}
            h3.is-size-7 {{ location }}

          section.section.has-position-relative

            span.price-ribbon.has-text-weight-bold.is-size-4(v-if="!isBought")
              | {{ (currencies[pack.user.currency] || {}).l || '' }}{{ pack.price }}{{ (currencies[pack.user.currency] || {}).r || '' }}

            h2.title.has-text-weight-bold.mb-3
              | {{ pack.name }}
            h3.subtitle.is-size-5.mt-2.has-text-left.mb-5
              | {{ pack.description }}

            .columns.is-centered(v-for="lesson in pack.lessons")
              .column.is-narrow
                figure.book
                  // Front
                  ul.hardcover_front
                    li
                      .coverDesign.red
                        h1 {{ lesson.title }}
                        p Digital Lesson
                    li
                  // Pages
                  ul.page
                    li
                    li
                      | {{ pack.sentencesSummary[lesson.id].wordsCount }} words
                      br
                      | {{ pack.sentencesSummary[lesson.id].audiosCount }} recordings
                      player.is-outlined(
                        v-if="lesson.sentences[0] && lesson.sentences[0].audio && lesson.sentences[0].audio.path"
                        :audio="lesson.sentences[0].audio"
                        )
                        |  SAMPLE
                    li
                    li
                    li
                  // Back
                  ul.hardcover_back
                    li
                    li
                  ul.book_spine
                    li
                    li
              .column.is-narrow.pt-5
                ul.m-auto.is-inline-block.w-auto.pack--details
                  li.has-flex-center-items.mb-2
                    img.mr-2.img-tt(src="@/assets/logo_teachertool_v2_square.png" width="28" height="28")
                    div.has-text-left
                      | Access to
                      router-link.ml-1.is-inline-block.has-text-info(
                        v-if="pack.user_id == user.id || isBought"
                        :to="{name: 'ReadOnlyPackageLesson', params:{ lesson_uuid: lesson.uuid }}"
                        ) "{{lesson.title}}"
                      span.ml-1.is-inline-block(v-else)
                        |  "{{lesson.title}}"
                      p.has-text-grey.is-size-7 {{ lesson.sentences.length }} sentences
                  li.has-flex-center-items.mb-2
                    img.mr-2(src="@/assets/anki.png" width="28" height="28")
                    div.has-text-left
                      a#export_to_anki_default_package.has-text-info(
                      v-if="isBought"
                      :href="apiUrl+'/lessons/'+lesson.uuid+'/anki'"
                      @click="$mixpanel.track('Lesson - Download Anki Deck - Back - Package')"
                      target="_blank")
                        | Flashcards deck
                      span(v-else)
                        | Flashcards deck
                      p.has-text-grey.is-size-7 {{ lesson.sentences.length }} flashcards
                  li.has-flex-center-items
                    img.mr-2(src="@/assets/headset.png" width="28" height="28")
                    <!--span {{ lesson.filename }}.mp3-->
                    a#export_to_audio.has-text-info(
                    v-if="isBought"
                    :href="lesson.podcast_path ? apiUrl+'/'+lesson.podcast_path : apiUrl+'/lessons/'+lesson.uuid+'/audio'"
                    :target="'_blank'"
                    @click="$mixpanel.track('Lesson - Download Podcast - Package')")
                      | Spaced repetition audio
                    span(v-else) Spaced repetition audio
      .column.is-one-fifth-desktop.is-12-tablet.p-lg-0.pl-lg-2(v-if="!querying && pack.user_id != user.id && !isBought")
        .box.show-package-page.buy-section.px-3.py-2

          table.table.is-fullwidth
            thead
              tr
                th.pl-1 Package
                th Price
            tbody
              tr
                td.pl-1
                  | {{ pack.name }}
                td.has-text-right
                  | {{ (currencies[pack.user.currency] || {}).l || '' }}{{ pack.price }}{{ (currencies[pack.user.currency] || {}).r || '' }}

          button.button.is-warning.px-3.mb-3(
            @click="openChargeModal"
          ) Buy now


</template>

<script>
  import { mapGetters } from "vuex";
  import lessonsList from "@/components/LessonsList";
  import player from "@/components/AudioPlayer";
  import chargeModal from "@/components/ChargeModal";
  import find from "lodash/find";

  export default {
    name: "ShowPackPage",
    components: {
      lessonsList,
      player,
    },
    computed: {
      ...mapGetters(["pack", "user", "purchases", "apiUrl"]),
      isBought() {
        return !!find(this.purchases, (purchase) => purchase.item_id == this.pack.id && purchase.item_type == 'Pack')
      },
      name() {
        if (!this.pack || !this.pack.user || !this.pack.user.id) return '';

        return (
          this.pack.user.name ||
          `${this.pack.user.first_name || ""} ${this.pack.user.last_name || ""}`
        );
      },
      location() {
        if (!this.pack || !this.pack.user || !this.pack.user.id) return '';

        if (
          this.pack.user.city &&
          this.pack.user.city.length &&
          this.pack.user.country &&
          this.pack.user.country.length
        ) {
          return `${this.pack.user.city}, ${this.pack.user.country}`;
        }
      },
      picture() {
        if (!this.pack || !this.pack.user || !this.pack.user.id) return '';

        if (
          this.pack.user.picture &&
          (this.pack.user.picture.substring(0, 4) === "data" ||
            this.pack.user.picture.substring(0, 4) === "http")
        )
          return this.pack.user.picture;
        else if (this.pack.user.picture)
          return `${this.apiUrl}/${this.pack.user.picture}`;
        else return null
      },
    },
    data() {
      return {
        querying: true,
        currencies: {
          EUR: { r: "€" },
          USD: { r: "$" },
          GBP: { l: "£" }
        },
      };
    },
    mounted() {
      this.loadScript("https://js.stripe.com/v3/")
    },
    created() {
      const { id } = this.$route.params;
      this.$store
        .dispatch("findPack", { id })
        .then(() => {
          this.querying = false
        }, () => {
          this.querying = false
        });
    },
    methods: {
      loadScript(url, callback) {
        if (!document.querySelector(`script[src="${url}"]`)) {
          const script = document.createElement("script")
          script.type = "text/javascript";

          if (script.readyState){  //IE
            script.onreadystatechange = function(){
              if (script.readyState == "loaded" ||
                script.readyState == "complete"){
                script.onreadystatechange = null;
                if (callback) callback();
              }
            };
          } else {  //Others
            script.onload = function(){
              if (callback) callback();
            };
          }

          script.src = url;
          document.getElementsByTagName("head")[0].appendChild(script);
        }
      },
      openChargeModal() {
        const { pack } = this;

        this.$buefy.modal.open({
          parent: this,
          width: 400,
          props: {
            teacher: pack.user,
            kind: 'package',
            productTh: 'Package',
            product: {
              id: pack.id,
              invoiceName: pack.name,
              invoiceDescription: `Contains ${pack.lessons.length} lessons`,
              price: pack.price
            },
          },
          component: chargeModal,
          hasModalCard: false
        })

      }
    }
  };
</script>


<style lang="scss">
  @import 'colors';

  .show-package-page {
    .lesson .avatar-tooltip {
      display: none !important;
    }

    .img-tt {
      padding: 3px;
    }

    .pack--details {
      width: 300px !important;
      margin-right: -40px !important;
    }

    .play-button {
      position: relative !important;
      top: inherit !important;
      left: inherit !important;
      width: 80% !important;
      margin-left: 10%;
      background-color: transparent;
      margin-top: 9px;
      .icon {
        margin-right: 3px !important;
      }
    }

    h2.title {
      width: calc(100% - 140px);
      text-align: center;
      margin: auto;
    }

    &.buy-section {
      /*position: fixed;*/
      /*right: 20px;*/
      /*z-index: 10;*/
      /*top: 106px;*/
    }

    span.price-ribbon {
      position: absolute;
      padding: 4px 18px;
      font-size: 15px;
      right: -13px;
      top: 42px;
      background-color: #1C7C9C;
      color: #FFF;
  }

    :not(.mdi-edit):not(.mdi-play-arrow)::before,
    :not(.mdi-edit):not(.mdi-play-arrow)::after {
      content: "";
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    a {
      color: #2c3e50;
      text-decoration: none;
    }

    .btn {
      display: inline-block;
      text-transform: uppercase;
      border: 2px solid #2c3e50;
      margin-top: 100px;
      font-size: 0.7em;
      font-weight: 700;
      padding: 0.1em 0.4em;
      text-align: center;
      -webkit-transition: color 0.3s, border-color 0.3s;
      -moz-transition: color 0.3s, border-color 0.3s;
      transition: color 0.3s, border-color 0.3s;
    }

    .btn:hover {
      border-color: #16a085;
      color: #16a085;
    }

    /* basic grid, only for this demo */

    .align {
      clear: both;
      margin: 90px auto 20px;
      width: 100%;
      max-width: 1170px;
      text-align: center;
    }

    .align > li {
      width: 500px;
      min-height: 300px;
      display: inline-block;
      margin: 30px 20px 30px 30px;
      padding: 0 0 0 60px;
      vertical-align: top;
    }

    /* ///////////////////////////////////////////////////

    HARDCOVER
    Table of Contents

    1. container
    2. background & color
    3. opening cover, back cover and pages
    4. position, transform y transition
    5. events
    6. Bonus
      - Cover design
      - Ribbon
      - Figcaption
    7. mini-reset

    /////////////////////////////////////////////////////*/

    /*
      1. container
    */

    .book {
      margin: auto;
      position: relative;
      width: 160px;
      height: 220px;
      transform: scale(0.7);
      -webkit-perspective: 1500px;
      -moz-perspective: 1500px;
      perspective: 1500px;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }

    /*
      2. background & color
    */

    /* HARDCOVER FRONT */
    .hardcover_front li:first-child {
      transform: translateZ(5px);
      z-index: 100;
      background-color: #eee;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    /* reverse */
    .hardcover_front li:last-child {
      background: #fffbec;
    }

    /* HARDCOVER BACK */
    .hardcover_back li:first-child {
      background: #fffbec;
    }

    /* reverse */
    .hardcover_back li:last-child {
      background: #fffbec;
    }

    .book_spine li:first-child {
      background: #eee;
    }
    .book_spine li:last-child {
      background: #333;
    }

    /* thickness of cover */

    .hardcover_front li:first-child:after,
    .hardcover_front li:first-child:before,
    .hardcover_front li:last-child:after,
    .hardcover_front li:last-child:before,
    .hardcover_back li:first-child:after,
    .hardcover_back li:first-child:before,
    .hardcover_back li:last-child:after,
    .hardcover_back li:last-child:before,
    .book_spine li:first-child:after,
    .book_spine li:first-child:before,
    .book_spine li:last-child:after,
    .book_spine li:last-child:before {
      background: #999;
    }

    /* page */

    .page > li {
      background: -webkit-linear-gradient(left, #e1ddd8 0%, #fffbf6 100%);
      background: -moz-linear-gradient(left, #e1ddd8 0%, #fffbf6 100%);
      background: -ms-linear-gradient(left, #e1ddd8 0%, #fffbf6 100%);
      background: linear-gradient(left, #e1ddd8 0%, #fffbf6 100%);
      box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.1), inset -1px 0px 1px rgba(150, 150, 150, 0.2);
      border-radius: 0px 5px 5px 0px;
    }

    /*
      3. opening cover, back cover and pages
    */

    .hardcover_front {
      -webkit-transform: rotateY(-34deg) translateZ(8px);
      -moz-transform: rotateY(-34deg) translateZ(8px);
      transform: rotateY(-34deg) translateZ(8px);
      z-index: 100;
    }

    .hardcover_back {
      -webkit-transform: rotateY(-15deg) translateZ(-8px);
      -moz-transform: rotateY(-15deg) translateZ(-8px);
      transform: rotateY(-15deg) translateZ(-8px);
    }

    .page li:nth-child(1) {
      -webkit-transform: rotateY(-28deg);
      -moz-transform: rotateY(-28deg);
      transform: rotateY(-28deg);
    }

    .page li:nth-child(2) {
      -webkit-transform: rotateY(-30deg);
      -moz-transform: rotateY(-30deg);
      transform: rotateY(-30deg);
    }

    .page li:nth-child(3) {
      -webkit-transform: rotateY(-32deg);
      -moz-transform: rotateY(-32deg);
      transform: rotateY(-32deg);
    }

    .page li:nth-child(4) {
      -webkit-transform: rotateY(-34deg);
      -moz-transform: rotateY(-34deg);
      transform: rotateY(-34deg);
    }

    .page li:nth-child(5) {
      -webkit-transform: rotateY(-36deg);
      -moz-transform: rotateY(-36deg);
      transform: rotateY(-36deg);
    }

    /*
      4. position, transform & transition
    */

    .hardcover_front,
    .hardcover_back,
    .book_spine,
    .hardcover_front li,
    .hardcover_back li,
    .book_spine li {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }

    .hardcover_front,
    .hardcover_back {
      -webkit-transform-origin: 0% 100%;
      -moz-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }

    .hardcover_front {
      -webkit-transition: all 0.8s ease, z-index 0.6s;
      -moz-transition: all 0.8s ease, z-index 0.6s;
      transition: all 0.8s ease, z-index 0.6s;
    }

    /* HARDCOVER front */
    .hardcover_front li:first-child {
      cursor: default;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      -webkit-transform:  translateZ(5px);
      -moz-transform:  translateZ(5px);
      transform:  translateZ(5px);
    }

    .hardcover_front li:last-child {
      -webkit-transform: rotateY(180deg) translateZ(2px);
      -moz-transform: rotateY(180deg) translateZ(2px);
      transform: rotateY(180deg) translateZ(2px);
    }

    /* HARDCOVER back */
    .hardcover_back li:first-child {
      -webkit-transform: translateZ(2px);
      -moz-transform: translateZ(2px);
      transform: translateZ(2px);
    }

    .hardcover_back li:last-child {
      -webkit-transform: translateZ(-2px);
      -moz-transform: translateZ(-2px);
      transform: translateZ(-2px);
    }

    /* thickness of cover */
    .hardcover_front li:first-child:after,
    .hardcover_front li:first-child:before,
    .hardcover_front li:last-child:after,
    .hardcover_front li:last-child:before,
    .hardcover_back li:first-child:after,
    .hardcover_back li:first-child:before,
    .hardcover_back li:last-child:after,
    .hardcover_back li:last-child:before,
    .book_spine li:first-child:after,
    .book_spine li:first-child:before,
    .book_spine li:last-child:after,
    .book_spine li:last-child:before {
      position: absolute;
      top: 0;
      left: 0;
    }

    /* HARDCOVER front */
    .hardcover_front li:first-child:after,
    .hardcover_front li:first-child:before {
      width: 4px;
      height: 100%;
    }

    .hardcover_front li:first-child:after {
      -webkit-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
      -moz-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
      transform: rotateY(90deg) translateZ(-2px) translateX(2px);
    }

    .hardcover_front li:first-child:before {
      -webkit-transform: rotateY(90deg) translateZ(158px) translateX(2px);
      -moz-transform: rotateY(90deg) translateZ(158px) translateX(2px);
      transform: rotateY(90deg) translateZ(158px) translateX(2px);
    }

    .hardcover_front li:last-child:after,
    .hardcover_front li:last-child:before {
      width: 4px;
      height: 160px;
    }

    .hardcover_front li:last-child:after {
      -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(-2px) translateY(-78px);
      -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(-2px) translateY(-78px);
      transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(-2px) translateY(-78px);
    }
    .hardcover_front li:last-child:before {
      box-shadow: 0px 0px 30px 5px #333;
      -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(-2px) translateY(-78px);
      -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(-2px) translateY(-78px);
      transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(-2px) translateY(-78px);
    }

    /* thickness of cover */

    .hardcover_back li:first-child:after,
    .hardcover_back li:first-child:before {
      width: 4px;
      height: 100%;
    }

    .hardcover_back li:first-child:after {
      -webkit-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
      -moz-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
      transform: rotateY(90deg) translateZ(-2px) translateX(2px);
    }
    .hardcover_back li:first-child:before {
      -webkit-transform: rotateY(90deg) translateZ(158px) translateX(2px);
      -moz-transform: rotateY(90deg) translateZ(158px) translateX(2px);
      transform: rotateY(90deg) translateZ(158px) translateX(2px);
    }

    .hardcover_back li:last-child:after,
    .hardcover_back li:last-child:before {
      width: 4px;
      height: 160px;
    }

    .hardcover_back li:last-child:after {
      -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(2px) translateY(-78px);
      -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(2px) translateY(-78px);
      transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(2px) translateY(-78px);
    }

    .hardcover_back li:last-child:before {
      box-shadow: 10px -1px 80px 20px #666;
      -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(2px) translateY(-78px);
      -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(2px) translateY(-78px);
      transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(2px) translateY(-78px);
    }

    /* BOOK SPINE */
    .book_spine {
      -webkit-transform: rotateY(60deg) translateX(-5px) translateZ(-12px);
      -moz-transform: rotateY(60deg) translateX(-5px) translateZ(-12px);
      transform: rotateY(60deg) translateX(-5px) translateZ(-12px);
      width: 16px;
      z-index: 0;
    }

    .book_spine li:first-child {
      -webkit-transform: translateZ(2px);
      -moz-transform: translateZ(2px);
      transform: translateZ(2px);
    }

    .book_spine li:last-child {
      -webkit-transform: translateZ(-2px);
      -moz-transform: translateZ(-2px);
      transform: translateZ(-2px);
    }

    /* thickness of book spine */
    .book_spine li:first-child:after,
    .book_spine li:first-child:before {
      width: 4px;
      height: 100%;
    }

    .book_spine li:first-child:after {
      -webkit-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
      -moz-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
      transform: rotateY(90deg) translateZ(-2px) translateX(2px);
    }

    .book_spine li:first-child:before {
      -webkit-transform: rotateY(-90deg) translateZ(-12px);
      -moz-transform: rotateY(-90deg) translateZ(-12px);
      transform: rotateY(-90deg) translateZ(-12px);
    }

    .book_spine li:last-child:after,
    .book_spine li:last-child:before {
      width: 4px;
      height: 16px;
    }

    .book_spine li:last-child:after {
      -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(8px) translateX(2px) translateY(-6px);
      -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(8px) translateX(2px) translateY(-6px);
      transform: rotateX(90deg) rotateZ(90deg) translateZ(8px) translateX(2px) translateY(-6px);
    }

    .book_spine li:last-child:before {
      box-shadow: 5px -1px 100px 40px rgba(0, 0, 0, 0.2);
      -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(-210px) translateX(2px) translateY(-6px);
      -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(-210px) translateX(2px) translateY(-6px);
      transform: rotateX(90deg) rotateZ(90deg) translateZ(-210px) translateX(2px) translateY(-6px);
    }

    .page,
    .page > li {

      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }

    .page {
      width: 100%;
      height: 98%;
      top: 1%;
      left: 3%;
      z-index: 10;
    }

    .page > li {
      width: 100%;
      height: 100%;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      transform-origin: left center;
      -webkit-transition-property: transform;
      -moz-transition-property: transform;
      transition-property: transform;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      transition-timing-function: ease;
    }

    .page > li:nth-child(1) {
      -webkit-transition-duration: 0.6s;
      -moz-transition-duration: 0.6s;
      transition-duration: 0.6s;
    }

    .page > li:nth-child(2) {
      -webkit-transition-duration: 0.6s;
      -moz-transition-duration: 0.6s;
      transition-duration: 0.6s;
    }

    .page > li:nth-child(3) {
      -webkit-transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      transition-duration: 0.4s;
    }

    .page > li:nth-child(4) {
      -webkit-transition-duration: 0.5s;
      -moz-transition-duration: 0.5s;
      transition-duration: 0.5s;
    }

    .page > li:nth-child(5) {
      -webkit-transition-duration: 0.6s;
      -moz-transition-duration: 0.6s;
      transition-duration: 0.6s;
    }

    /*
      5. events
    */

    .book:hover > .hardcover_front {
      -webkit-transform: rotateY(-145deg) translateZ(0);
      -moz-transform: rotateY(-145deg) translateZ(0);
      transform: rotateY(-145deg) translateZ(0);
      z-index: 0;
    }

    .book:hover > .page li:nth-child(1) {
      -webkit-transform: rotateY(-30deg);
      -moz-transform: rotateY(-30deg);
      transform: rotateY(-30deg);
      -webkit-transition-duration: 1.5s;
      -moz-transition-duration: 1.5s;
      transition-duration: 1.5s;
    }

    .book:hover > .page li:nth-child(2) {
      -webkit-transform: rotateY(-35deg);
      -moz-transform: rotateY(-35deg);
      transform: rotateY(-35deg);
      -webkit-transition-duration: 1.8s;
      -moz-transition-duration: 1.8s;
      transition-duration: 1.8s;
    }

    .book:hover > .page li:nth-child(3) {
      -webkit-transform: rotateY(-118deg);
      -moz-transform: rotateY(-118deg);
      transform: rotateY(-118deg);
      -webkit-transition-duration: 1.6s;
      -moz-transition-duration: 1.6s;
      transition-duration: 1.6s;
    }

    .book:hover > .page li:nth-child(4) {
      -webkit-transform: rotateY(-130deg);
      -moz-transform: rotateY(-130deg);
      transform: rotateY(-130deg);
      -webkit-transition-duration: 1.4s;
      -moz-transition-duration: 1.4s;
      transition-duration: 1.4s;
    }

    .book:hover > .page li:nth-child(5) {
      -webkit-transform: rotateY(-140deg);
      -moz-transform: rotateY(-140deg);
      transform: rotateY(-140deg);
      -webkit-transition-duration: 1.2s;
      -moz-transition-duration: 1.2s;
      transition-duration: 1.2s;
    }

    /*
      6. Bonus
    */

    /* cover CSS */

    .coverDesign {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .coverDesign::after {
      background-image: -webkit-linear-gradient( -135deg, rgba(255, 255, 255, 0.45) 0%, transparent 100%);
      background-image: -moz-linear-gradient( -135deg, rgba(255, 255, 255, 0.45) 0%, transparent 100%);
      background-image: linear-gradient( -135deg, rgba(255, 255, 255, 0.45) 0%, transparent 100%);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .coverDesign h1 {
      font-size: 2em;
      line-height: 1em;
      color: #fff;
      /*font-size: 2.2em;*/
      letter-spacing: 0.05em;
      padding: 0 10px;
      text-align: center;
      /*margin: 54% 0 0 0;*/
      margin: 0;
      text-shadow: -1px -1px 0 rgba(0,0,0,0.1);
    }

    .coverDesign p {
      color: #f8f8f8;
      font-size: 1em;
      text-align: center;
      text-shadow: -1px -1px 0 rgba(0,0,0,0.1);
    }


    .red {
      background-color: $primaryDarker;
      background-image: -webkit-linear-gradient(top, $primaryDarker  58%, $primary 0%);
      background-image: -moz-linear-gradient(top, $primaryDarker 58%, $primary 0%);
      background-image: linear-gradient(top, $primaryDarker 58%, $primary 0%);
    }

    .yellow {
      background-color: #f1c40f;
      background-image: -webkit-linear-gradient(top, #f1c40f 58%, #e7ba07 0%);
      background-image: -moz-linear-gradient(top, #f1c40f 58%, #e7ba07 0%);
      background-image: linear-gradient(top, #f1c40f 58%, #e7ba07 0%);
    }

    .blue {
      background-color: #3498db;
      background-image: -webkit-linear-gradient(top, #3498db 58%, #2a90d4 0%);
      background-image: -moz-linear-gradient(top, #3498db 58%, #2a90d4 0%);
      background-image: linear-gradient(top, #3498db 58%, #2a90d4 0%);
    }

    .grey {
      background-color: #f8e9d1;
      background-image: -webkit-linear-gradient(top, #f8e9d1 58%, #e7d5b7 0%);
      background-image: -moz-linear-gradient(top, #f8e9d1 58%, #e7d5b7 0%);
      background-image: linear-gradient(top, #f8e9d1 58%, #e7d5b7 0%);
    }

    /* Basic ribbon */

    .ribbon {
      background: #c0392b;
      color: #fff;
      display: block;
      font-size: 0.7em;
      position: absolute;
      top: 11px;
      right: 1px;
      width: 40px;
      height: 20px;
      line-height: 20px;
      letter-spacing: 0.15em;
      text-align: center;
      -webkit-transform: rotateZ(45deg) translateZ(1px);
      -moz-transform: rotateZ(45deg) translateZ(1px);
      transform: rotateZ(45deg) translateZ(1px);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      z-index: 10;
    }

    .ribbon::before,
    .ribbon::after{
      position: absolute;
      top: -20px;
      width: 0;
      height: 0;
      border-bottom: 20px solid #c0392b;
      border-top: 20px solid transparent;
    }

    .ribbon::before{
      left: -20px;
      border-left: 20px solid transparent;
    }

    .ribbon::after{
      right: -20px;
      border-right: 20px solid transparent;
    }

    /* figcaption */

    figcaption {
      padding-left: 40px;
      text-align: left;
      position: absolute;
      top: 0%;
      left: 160px;
      width: 310px;
    }

    figcaption h1 {
      margin: 0;
    }

    figcaption span {
      color: #16a085;
      padding: 0.6em 0 1em 0;
      display: block;
    }

    figcaption p {
      color: #63707d;
      line-height: 1.3;
    }


  }


  /* Media Queries */
  @media screen and (max-width: 37.8125em) {
    .show-package-page {
    .align > li {
      width: 100%;
      min-height: 440px;
      height: auto;
      padding: 0;
      margin: 0 0 30px 0;
    }

    .book {
      margin: 0 auto;
    }

    figcaption {
      text-align: center;
      width: 320px;
      top: 250px;
      padding-left: 0;
      left: -80px;
      font-size: 90%;
    }
    }
  }


</style>
