<template lang="pug">
  .modal.is-active.modal-edit-ipa
    .modal-background
    .modal-card

      header.modal-card-head.has-flex-center-items.valign
        h2.title.is-size-5.mb-0.mr-5
          | {{ word.tts }}

        a.button.ml-auto(
          v-if="language.substring(0,2) !== 'zh'"
          :href="'https://'+language.substring(0,2)+'.wiktionary.org/wiki/'+word.tts "
          target="_blank"
          )
          b-icon(icon="search")
          span.pl-0 Search Wiktionary for: <b>{{ word.tts }}</b>

      section.modal-card-body
        b-field.has-flex-wrap
          label.label.mt-2 Edit {{ language.substring(0,2) !== 'zh' ? 'phonetic' : 'pinyin' }}

          b-input#correction_input.is-wide.ml-3(
            expanded
            ref="correction"
            v-model="correction"
            @keyup.native="removeSlashes"
            @keyup.enter.native="apply"
            )

        .ipa-keyboard.mt-4(v-if="ipa[language]")
          button.button.is-small.mr-1.button-letter.is-size-6.mb-1(
            @mousedown.prevent="addLetterToComment(letter)"
            v-for="letter in ipa[language]"
            ) {{letter}}
          button.button.is-small.mr-1.button-letter.is-size-6.mb-1.border-radius.button-expand(
            @click="shortIpaVersion = !shortIpaVersion"
          )
            b-icon(:icon="shortIpaVersion ? 'keyboard-arrow-right' : 'keyboard-arrow-left' ")

      footer.modal-card-foot
        button.button.is-outlined.is-fullwidth(
          :class="{'is-loading': loading}"
          @click="apply"
        ) Apply change

        button.button.is-primary.is-fullwidth(
          @click="$emit('close')"
        ) Cancel

    button.modal-close.is-large(aria-label="close" @click="$emit('close')")


</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import {
  SHORT_FRENCH_IPA,
  SHORT_AMERICAN_IPA,
  SHORT_BRITISH_IPA,
  FRENCH_IPA,
  AMERICAN_IPA,
  BRITISH_IPA
} from "../constants";

export default {
  name: 'EditIpaModal',
  components: {},

  props: [
    'language',
    'sentence',
    'word',
  ],

  // computed: {
  //   ...mapGetters(["user"]),
  // },

  data() {
    return {
      loading: false,
      correction: '',
      ipas: {
        "fr-FR" : FRENCH_IPA,
        "en-US" : AMERICAN_IPA,
        "en-GB" : BRITISH_IPA
      },
      shortIpas: {
        "fr-FR" : SHORT_FRENCH_IPA,
        "en-US" : SHORT_AMERICAN_IPA,
        "en-GB" : SHORT_BRITISH_IPA
      }
    };
  },
  computed: {
    ...mapGetters(['showShortIpaVersion']),
    shortIpaVersion: {
      set(value) {
        this.SET_SHOW_SHORT_IPA_VERSION(value);
      },
      get() {
        return this.showShortIpaVersion;
      }
    },
    ipa() {
      return this.shortIpaVersion ? this.shortIpas : this.ipas
    }
  },
  mounted() {
    setTimeout(() => {
      document.getElementById('correction_input').focus()
    }, 400)
  },
  created() {
     this.correction = this.word.content
  },
  methods: {
    ...mapMutations(['SET_SHOW_SHORT_IPA_VERSION']),
    removeSlashes() {
      // console.log(this.correction)
      this.correction = this.correction.replace(/[\/\\]+ ?/g, '')
    },
    addLetterToComment(letter) {
      const {correction} = this.$refs;
      const input = correction.$el.children[0];
      input.setRangeText(
          letter,
          input.selectionStart,
          input.selectionEnd,
          'end'
      )
    },
    apply() {
      this.loading = true;
      this.$store.dispatch('saveCorrection', {
        language: this.language,
        word: this.word.tts,
        ipa:  this.correction,
      }).then(res => {
        this.$store.commit('editWord',  {
            sentence: this.sentence,
            word: Object.assign({}, this.word, {
              content: this.correction,
              miss: false
            })
          }
        )
        this.$emit('preClose');
        this.$emit('close');
      })
    }
  }
};
</script>

<style lang="scss">
@import "colors";

.modal-edit-ipa {
  .ipa-keyboard {
    width: 418px;
    margin: auto;
  }
  .button-expand {
    height: 30px;
    width: 30px;
    line-height: 30px;
    margin-top: 7px;
    border: 0 none !important;
    color: #cbcbcb !important;
    &:active,&:focus {
      outline: 0 none;
    }
  }
  .button-letter {
    opacity: 1;
    border: 1px solid #e5e5e5;
  }
}

</style>
