<template lang="pug">
  .modal--duplicate
    .modal-card-head
      h2 Duplicate lesson

    .modal-card-body
      p.has-text-left.has-text-grey
        | Select which sentence you want to be part of the duplicated lesson.
        |  Then share the link with another teacher or use it yourself.

      b-table.w-100.mt-2(
        :data="lesson.sentences"
        :checked-rows.sync="checkedRows"
        checkable
        checkbox-position="left"
      )
        b-table-column(

          v-slot="props" field="content" label="Sentence"
          )
          .is-clickable(
            @click="checkRow(props.row)"
            :class="{'has-text-grey-light': !checkedRows.includes(props.row)}"
          )
            | {{ props.row.content }}

      .has-text-right
        b-checkbox.mt-3.mb-2(v-model="form.with_media") Include audio files

      //b-table-column(v-slot="props" field="action" label="")
      //  b-checkbox(v-model="form.sentence_ids")

    .modal-card-foot.has-bg-light

      .w-100
        textarea.textarea.is-size-7.has-bg-light.has-text-grey-dark(
          ref="textarea"
          :key="forceUrl.length"
          @keyup="() => { $refs.textarea.value = forceUrl }"
        )
          | {{ forceUrl }}

        //b-field.is-light.w-100
        //  b-input(
        //    custom-class="is-size-7 py-2"
        //    :disabled='!checkedRows.length'
        //    :value="url" @keyup="url = forceUrl" expanded :class="{'has-text-success': isCopied}")
        //  p.control
        .has-text-right.w-100.mt-2
          button.button.is-info.ml-auto(
            :disabled='!checkedRows.length'
            v-clipboard:copy="forceUrl"
            v-clipboard:success="onCopy"
            v-clipboard:error="() => {}"
            :class="{'is-success': isCopied, 'is-info': !isCopied}"
          ) {{ isCopied ? 'Copied' : 'Copy link to clipboard' }}


</template>

<script>
import { mapGetters } from "vuex";
import {LG_TO_TXT} from "../constants"
import xor from "lodash/xor";

export default {
  name: "DuplicateModalBody",
  components: {
  },
  props: [
    'lesson',
  ],
  computed: {
    ...mapGetters(["apiUrl", "user"]),
    forceUrl() {
      const myUrlWithParams = new URL(`${window.location.origin}${window.location.pathname}/duplicate`);
      console.log({ myUrlWithParams })
      myUrlWithParams.searchParams.append("with_media", this.form.with_media);
      myUrlWithParams.searchParams.append("sentence_ids", this.checkedRows.map(row => row.id));

      return myUrlWithParams.href
    },
    url() {
      return this.forceUrl;
    }
  },
  mounted() {
    this.checkedRows = this.lesson.sentences
    // this.forceUrl = window.location.href;
    console.log(this.form.sentence_ids);
  },
  data() {
    return {
      isCopied: false,
      checkedRows: [],
      checkboxPosition: 'left',
      form: {
        with_media: false
      },
    };
  },
  methods: {
    checkRow(sentence) {
      console.log("hello", sentence)
      this.checkedRows = xor(this.checkedRows, [sentence])
    },
    onCopy() {
      this.isCopied = true
      setTimeout(() => {
        this.isCopied = false
      }, 2000)
    }
  }
};
</script>

<style lang="scss">
  @import "colors";

  .modal--duplicate {
    .modal-content {
      overflow: visible;
    }
    th,
    td {
      padding-left: 0 !important;
    }

    .table-wrapper {
      height: 400px;
      overflow: auto;
    }

    textarea {
      word-break: break-all;
      resize: none;
      font-family: Monospace;
      border: 1px solid rgba(153, 153, 153, 0.26);
    }

    button {
      min-width: 280px;
    }
  }
</style>
