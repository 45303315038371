<template lang="pug">
  .modal.is-active.modal-payment
    .modal-background
    .modal-content
      .box.pb-5
        figure.avatar
          picture
            source(srcset="@/assets/logo_teachertool_v2_square.webp" type="image/webp")
            source(srcset="@/assets/logo_teachertool_v2_square.png" type="image/png")
            img(alt='Teachee'  role='presentation' src='@/assets/logo_teachertool_v2_square.png')

        h2.title.has-text-left Payment
        .stripe-payment
          .message.is-danger(v-if="error")
            .message-body
              | {{ error }}

          transition(name="fade" mode="out-in")
            table.table.is-fullwidth(:key="offer.uid")
              thead
                tr
                  th.pl-0 Offer
                  th Price
              tbody
                tr
                  td
                    | Teachee • {{ offer.invoiceName || offer.name }}
                    p.is-size-7(v-if="offer.invoiceDescription" v-html="offer.invoiceDescription")
                    p.has-text-info.has-text-underlined.is-size-7.is-clickable(@click="switchToMonthly" v-if="offer.uid.indexOf('yearly') !== -1")
                      | Pay monthly instead
                    p.has-text-info.has-text-underlined.is-size-7.is-clickable(@click="switchToYearly" v-if="offer.uid.indexOf('monthly') !== -1")
                      | Pay yearly instead
                  td.has-text-right
                    | {{ priceWithoutVat | currency }}
                    s.is-block.has-text-grey-dark.normal-price(v-if="offer.normalPrice")
                      | {{ priceWithoutVatNormalPrice | currency }}
                tr(v-if="hasVat")
                  td.has-text-right VAT
                  td.has-text-right
                    | {{ vat | currency }}
                    s.is-block.has-text-grey-dark.normal-price(v-if="offer.normalPrice")
                      | {{ vatNormalPrice | currency }}
                tr
                  td.has-text-right
                    b TOTAL
                  td.has-text-right
                    b
                      | {{ offer.price | currency}}
                    s.is-block.has-text-grey-dark.normal-price(v-if="offer.normalPrice")
                      | {{ offer.normalPrice | currency }}

          b-field.has-text-left(label="Country" v-if="!countryWasSet")
            country-select(v-model="country" v-if="!country.code")
            span.input(v-else)
              | {{ country.name }}
              button.button.is-rounded.is-light.pull-right.btn-edit-country.ml-auto(@click="country.code = null")
                b-icon(
                  icon="close"
                  custom-size="mdi-16px"
                )

          b-field.has-text-left.has-flex-wrap(:class="{'is-disabled': !country.code}")
            label.label.w-100 Coupon
            b-input.is-wide(v-model="couponCode" :disabled="isCouponApplied" @keyup.native.enter="applyCoupon")
            p.control
              button.button.mw-110px(
              @click="applyCoupon"
              :class="{'is-loading': loadingCoupon, 'is-success': isCouponSuccess}"
              :disabled="loadingCoupon || isCouponApplied"
              )
                b-icon(v-if="isCouponSuccess" icon="check")
                span(v-else="")
                  span(v-if="isCouponApplied")
                    b-icon.mr-2.has-valign-middle(icon="check")
                    | Applied
                  span(v-else)
                    | Apply

          b-field.has-text-left(label="Credit card" :class="{'is-disabled': !country.code}")
            #card_element

          button.button.is-primary.is-fullwidth(
            :class="{'is-loading is-disabled': isPaying}"
            :disabled="!complete"
            @click="pay")
              | Pay

    button.modal-close.is-large(aria-label="close" @click="$emit('input', null);$emit('close')")


</template>

<script>
import { stripeKey } from "@/config";
import countrySelect from "@/components/CountrySelect";
import { Coupon } from "@/store/models/resources";
import VATRates from 'vatrates';
import { mapGetters } from "vuex";
import offers from "@/support/offers";
import couponOffers from "@/support/couponOffers";
import countries from '@/support/countries';
const vatRates = new VATRates();
import find from "lodash/find";

// https://github.com/mazipan/vue-currency-filter

export default {
  components: {
    countrySelect
  },

  props: [
    'offerUid',
  ],

  computed: {
    ...mapGetters(["user"]),
    hasVat() {
      if (this.offer && this.offer.kind == 'credit') {
        return false
      }
      if (this.isCouponApplied && !this.coupon.user.charges_vat) {
        return false
      }

      return this.country.code && vatRates.isVATCountry(this.country.code)
    },
    vat() {
      if (this.hasVat) {
        const vat = vatRates.getStandardRate(this.country.code)
        return this.offer.price - this.offer.price/((100+vat)/100)
      } else {
        return null
      }
    },
    priceWithoutVat() {
      return this.offer.price - this.vat
    },
    vatNormalPrice() {
      if (!this.offer.normalPrice)
        return null

      if (this.hasVat) {
        const vat = vatRates.getStandardRate(this.country.code)
        return this.offer.normalPrice - this.offer.normalPrice/((100+vat)/100)
      } else {
        return null
      }
    },
    priceWithoutVatNormalPrice() {
      if (!this.offer.normalPrice)
        return null

      return this.offer.normalPrice - this.vat
    }
  },

  data() {
    return {
      offer: {},
      stripe: {},
      card: null,
      countryWasSet: false,
      vatRates: vatRates,
      loadingCoupon: false,
      couponCode: null,
      coupon: {},
      isCouponSuccess: false,
      isCouponApplied: false,
      country: {
        code: null,
        name: null
      },
      isPaying: null,
      error: null,
      stripeKey: stripeKey,
      complete: false,
    };
    // see https://stripe.com/docs/stripe.js#element-options for details
  },

  created() {
    this.offer = find(offers, {uid: this.offerUid});
    const country = find(countries, {name: this.user.country})
    if (country) {
      this.countryWasSet = true;
      this.country = country
    }

    try {
      // console.log(stripeKey)
      this.stripe = Stripe(stripeKey)
    } catch (err) {
    }

    setTimeout( () => {
      var elements = this.stripe.elements();
      const style = {
        base: {
          color: "#32325d",
          lineHeight: "18px",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };
      this.card = elements.create("card", { hidePostalCode: true, style: style });
      // this.card.hidePostalCode = true;
      this.card.mount("#card_element");

      this.card.addEventListener("change", event => {
        if (event.error) {
          // this.error = event.error;

          this.$toast.open({
            duration: 2000,
            message: event.error.message,
            type: "error"
          });
        } else {
          this.error = null;
        }
        this.complete = event.complete;
        // console.log(event);
      });
    }, 700)
  },
  methods: {
    applyCoupon() {
      this.loadingCoupon = true;
      Coupon.get({id: this.couponCode}).then((res) => {
        // console.log(res.body);
        this.offer = find(couponOffers, {uid: res.body.offer_uid});
        this.coupon = res.body;
        this.offer.price = res.body.offer_price;
        this.offer.invoiceDescription = this.offer.invoiceDescription.replace(/__COUPON__/, this.couponCode)
        this.loadingCoupon = false;
        this.isCouponSuccess = true;
        this.isCouponApplied = true;
        setTimeout(() => {
          this.isCouponSuccess = false;
        }, 2500)
      })
    },
    switchToMonthly() {
      this.offer = find(offers, {uid: this.offer.uid.replace(/yearly/, 'monthly')});
    },
    switchToYearly() {
      this.offer = find(offers, {uid: this.offer.uid.replace(/monthly/, 'yearly')});
    },
    pay() {
      // SAVE COUNTRY
      if (!this.countryWasSet) {
        this.$store.dispatch("saveUser", Object.assign(this.user, {country: this.country.name})).then(data => {
          this.$store.dispatch("setUser", data.user);
        })
      }

      this.isPaying = true;
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      this.stripe.createToken(this.card).then(data => {
        if (data.error) {
          this.error = data.error;
          this.isPaying = false;
          this.$toast.open({
            duration: 5000,
            message: this.error,
            type: "error"
          });
        } else {
          // console.log(data.token);
          if (this.offer.kind === 'plan') {
            this.stripeTokenHandlerForPlan(data.token);
          } else if (this.offer.kind === 'coupon_pass') {
            this.stripeTokenHandlerForCouponPass(data.token);
          } else if (this.offer.kind === 'credit') {
            this.stripeTokenHandlerForCredit(data.token);
          } else {
            this.stripeTokenHandlerForOrder(data.token);
          }
        }
      });
    },

    stripeTokenHandlerForCouponPass(token) {
      this.$store
        .dispatch("charge", {
          amount: this.offer.price,
          kind: 'coupon_offer',
          teacher_id: this.coupon.user.id,
          coupon: this.couponCode,
          stripe_token: token.id
        })
        .then(
          data => {
            this.$auth.user(data.user);
            this.$store.dispatch("setUser", data.user);
            this.isPaying = false;
            this.$toast.open({
              duration: 5000,
              message: `Perfect! You can now enjoy your "${this.offer.name}" pass`,
              type: "success"
            });
            this.$emit('close');
            this.$router.push({ name: "Home" });
            // this.bookingSuccess()
          },
          err => {
            this.isPaying = false;
            this.$toast.open({
              duration: 5000,
              message: err.message,
              type: "error"
            });
          }
        );

    },

    stripeTokenHandlerForCredit(token) {
      this.$store
        .dispatch("credit", {
          code: this.country.code,
          uid: this.offer.uid,
          stripe_token: token.id
        })
        .then(data => {
          this.$auth.user(data.user);
          this.$store.dispatch("setUser", data.user);
          this.$toast.open({
            duration: 5000,
            message: `Congrats! Enjoy your new credits!`,
            type: "success"
          });
          this.isPaying = false;
          this.$mixpanel.people.set({ "Credit": this.offer.uid });
          this.$mixpanel.identify(data.user.id+'');
          this.$router.push({ name: "Home" });
        }, err => {
          this.paymentError(err)
        });
    },

    stripeTokenHandlerForOrder(token) {
      this.$store
        .dispatch("order", {
          code: this.country.code,
          uid: this.offer.uid,
          stripe_token: token.id
        })
        .then(data => {
          this.$auth.user(data.user);
          this.$store.dispatch("setUser", data.user);
          this.$toast.open({
            duration: 5000,
            message: `Congrats! Enjoy your ${this.offer.duration.replace(' ', '-')} pass!`,
            type: "success"
          });
          this.isPaying = false;
          this.$mixpanel.people.set({ "Plan": this.offer.uid });
          this.$mixpanel.identify(data.user.id+'');
          this.$router.push({ name: "Home" });
        });
    },

    stripeTokenHandlerForPlan(token) {
      this.$store
        .dispatch("subscribe", {
          plan: this.offer.uid,
          stripe_token: token.id
        })
        .then(data => {
          this.$auth.user(data.user);
          this.$store.dispatch("setUser", data.user);
          this.$toast.open({
            duration: 5000,
            message: `Congrats! You are now a ${this.offer.name} member`,
            type: "success"
          });
          this.isPaying = false;
          this.$mixpanel.people.set({ "Plan": this.offer.uid });
          this.$mixpanel.identify(data.user.id+'');
          this.$router.push({ name: "Home" });
        });
    },

    paymentError(err) {
      this.isPaying = false;
      this.$toast.open({
        duration: 7000,
        message: `Something went wrong :/. ${err.message}`,
        type: "error"
      });
    }
  }
};
</script>

<style lang="scss">
@import "colors";

.modal-payment .modal-content {
  max-width: 400px;
  min-width: 380px;
  overflow: visible;
}
.modal-payment {
  .avatar {
    margin-top: -50px;
    padding-bottom: 20px;
  }

  .avatar img {
    padding: 0.8rem;
    background: #fff;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }
}

.normal-price {
  font-size: 0.9rem !important;
  letter-spacing: 1px;
}

.btn-edit-country {
  padding: 0 !important;
  width: 24px;
  display: inline-flex;
  /* text-align: center; */
  border-radius: 50%;
  height: 25px;
}

.stripe-card {
  width: 300px;
  border: 1px solid grey;
}

.stripe-card.complete {
  border-color: green;
}

.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
