`import {Order} from '../models/resources'`

actions =

  order: (context, order) ->
    new Promise((resolve, reject) ->
      Order.save(order).then(
        (res) ->
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )

`export default actions`
