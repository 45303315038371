<template lang="pug">
  div#time_zone_select
    i.fa.fa-spinner.fa-spin(v-if="loading")
    template(v-else="")
      i.fa.fa-search(v-show="isEmpty")
      i.fa.fa-times(v-show="isDirty" @click="reset")
    // the input field
    b-field
      b-input#time_zone_select_btn(
      icon="search"
      type="search"
      :placeholder="placeholder || 'Select'"
      autocomplete="off"
      v-model="query"
      @keydown.native.prevent.down="down"
      @keydown.native.prevent.up="up"
      @keydown.native.enter="hit"
      @keydown.native.esc="reset"
      @input="update")
    <!--@blur="reset"-->
    // the list
    ul.box(v-show="hasItems")
      // for vue@1.0 use: ($item, item)
      li(v-for="(option, $item) in items" :class="activeClass($item)" @mousedown="hit" @mousemove="setActive($item)")
        span
          | {{ option }}

</template>

<script>
import VueTypeahead from "vue-typeahead";
import toLower from "lodash/toLower";
import filter from "lodash/filter";
import includes from "lodash/includes";


export default {
  name: "TimeZoneSelect",
  extends: VueTypeahead,
  props: ['value', 'placeholder'],
  data() {
    return {
      time_zone: null,
      timeZones: [],
      query: this.value,
      limit: 10,
      minChars: 2,
      selectFirst: true
    };
  },
  created() {
    this.options = this.$moment.tz.names()
  },
  methods: {
    redirectWhenSelect(selection) {
      if (selection) {
        this.$router.push(selection.link);
      }
    },
    onHit(selection) {
      this.$emit('input', selection)
      this.query = selection
      this.items = []
      this.loading = false
    },
    fetch() {
      return new Promise((resolve, reject) => {
        const query = toLower(this.query);
        resolve({
          data: filter(this.options, opt =>
            includes(toLower(opt || ""), query)
          )
        });
        reject();
      });
    },
    search() {
      document.getElementById("time_zone_select_btn").focus();
    }
  }
};
</script>

<style lang="scss">
@import "colors";

#time_zone_select {
  position: relative;
  img {
    max-height: initial;
  }
  ul {
    padding: 0;
    position: absolute;
    width: 100%;
    top: 40px;
    z-index: 60;
    li {
      padding: 10px 10px;
      &.active,
      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
    li span {
      vertical-align: middle;
    }
  }
}
@media (max-width: 768px) {
  #time_zone_select #time_zone_select_btn {
    width: 100%;
  }
}

/*
::-webkit-input-placeholder::first-letter {
border-bottom: 1px solid red;
}
*/
</style>
