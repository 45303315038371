<template lang="pug">
  .teacher-mini-card(:class="size")
    user-picture(:user="teacher")
    | {{ teacher.first_name || 'Teacher '+(index+1) }}


</template>

<script>
import userPicture from "@/components/UserPictureUI";
import { mapGetters } from "vuex";

export default {
  name: "teacher-mini-card",
  props: ["teacher", "index", "size"],
  components: {
    userPicture
  },
  computed: {
    ...mapGetters(["apiUrl"])
  }
};
</script>


<style lang="scss">
@import "colors";

$figureSize: 36px;
$imgSize: 32px;

$figureSizeXl: 50px;
$imgSizeXl: 46px;

.teacher-mini-card {
  figure.avatar {
    width: $figureSize;
    height: $figureSize;
    vertical-align: middle;
    display: inline-block;
    margin-right: 8px;
    img {
      width: $figureSize;
      height: $figureSize;
      padding: 2px;
    }
  }
  .is-large {
    figure.avatar {
      width: $figureSizeXl;
      height: $figureSizeXl;
      img {
        width: $figureSizeXl;
        height: $figureSizeXl;
      }
    }
    .letter-avatar {
      line-height: $figureSizeXl;
      span {
        line-height: $imgSizeXl;
        height: $imgSizeXl;
        width: $imgSizeXl;
      }
    }
  }
}
</style>
