const settings = {}
const isStaging = window.location.href.includes("staging")

if (process.env.NODE_ENV !== 'production' && !isStaging) {
  settings.nodeApi = 'http://localhost:1337/v2'
  settings.railsApi = 'http://localhost:8082/api/v1'
  settings.stripeKey = 'pk_test_SpeusTERlVj75FfNdOg4nyW1'
  settings.stripeConnectKey = 'ca_DBYbA0PbYJbVaE1UAB6IH4Sglt41NFyB'
  settings.mollieConnectKey = 'app_3WQ9MH9ABeHKJSGuBdhT63Ru'
} else if (isStaging) {
  settings.nodeApi = 'https://staging.teachee.io/v2'
  settings.railsApi = 'https://staging.teachee.io/v1'
  settings.stripeKey = 'pk_test_SpeusTERlVj75FfNdOg4nyW1'
  settings.stripeConnectKey = 'ca_DBYbA0PbYJbVaE1UAB6IH4Sglt41NFyB'
  settings.mollieConnectKey = 'app_3WQ9MH9ABeHKJSGuBdhT63Ru'
} else {
  settings.nodeApi = 'https://teachee.io/v2'
  settings.railsApi = 'https://teachee.io/v1'
  settings.stripeKey = 'pk_live_GFmySqtBUSQrEJIIl1xddEpQ'
  settings.stripeConnectKey = 'ca_DBYb7yCyPWaGu8wYJJwqWU6mdp0FnlAP'
  settings.mollieConnectKey = 'app_3WQ9MH9ABeHKJSGuBdhT63Ru'
}

export const nodeApi = settings.nodeApi
export const railsApi = settings.railsApi
export const stripeKey = settings.stripeKey
export const stripeConnectKey = settings.stripeConnectKey
export const mollieConnectKey = settings.mollieConnectKey
export const freeStudentLimit = 10;
