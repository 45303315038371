<template lang="pug">
  .box
    section.section.has-text-left.pt0.is-size-7.content.px-2
      p
        strong Data collected
      p
        | Teachee stores those personal data :
        ul
          li first name
          li last name
          li city
          li country
          li email
          li google identifier
          li picture
        |  Data collected is used only to make the app work and will
        |  never be shared with 3rd parties other than Google (analytics), Mixpanel (analytics) and Paddle (payments) without your explicit consentment.
        br
        br
        | When you delete your account,
        |  everything you ever did in the app get irrevocably destroyed immediately.
        br
        br
        | All server logs (data automatically generated by the softwares) have
        |  been anonymized (no IP addresses).
        br
        br
        | Your actions on the app are sent to Google Analytics and Mixpanel to improve the
        |  service. All data sent to Google Analytics is anonymized (no IP sent)
        br
        | We also use Facebook Pixel and Reddit Pixel to avoid to display ads to paid users.

      br
      label#btn_consent.button.is-light.is-fullwidth.b-checkbox.checkbox(aria-label="Accept GPDR")
        input(type="checkbox" true-value="true" value="false" @change="$emit('input', $event.target.checked)" @click="accepted")
        span.check
        span.control-label
          | I have read and agree to the <a href="/privacy" target="blank">privacy &amp; cookies notice</a>
        <!--b-checkbox(v-model="value" aria-label="Accept GPDR" @change="$emit('input', $event.target.value)")-->
          <!--| I have read and agree to the <a href="/privacy" target="blank">privacy & cookies notice</a>-->


</template>

<script>
export default {
  name: "GdprConsent",
  props: [
    'value'
  ],
  data() {
    return {
      acceptGpdr: Vue.cookie.get('gdprConsented')
    };
  },
  methods: {
    accepted() {
      this.$cookie.set('gdprConsented', true, 365)
    }
  }
};
</script>


<style scoped>
  .pt0 {
    padding-top: 0 !important;
  }
  .box {
    margin-top: 98px !important;
  }

  #btn_consent {
    font-size: 13.5px;
  }

  .button a  {
    text-decoration: underline !important;
  }
</style>
