<template lang="pug">
  .app-container.page--front.text-main
    front-menu
    main.app-content

      //- Hero
      hero-section(
        title="On the fly conversational training"
        subtitle="One click to record, translate, and help your student practice"
        scroll-anchor="for_teachers"
      )
        lottie.mb-md-5(
          path="lottie/hero.json"
          :loop="false"
          :height="203"
        )

      //- Benefits
      benefits-section(ref="nextSection")

      //- How it works
      how-it-works-teacher-section(
        topic="LEARN TO USE TEACHEE"
      )

      //- Community
      our-community-section

      //- Testimonials
      testimonials-section

      //- Try it out
      try-it-out-section( v-if="browser.name === 'chrome'" )

      //- Teach pronunciation like magic
      cta-section(
        tagline="Teach pronunciation<br>like magic"
        link-text="Create your first lesson"
        link-href="/sign_up"
      )
    footer-section
</template>

<script>
  import footerSection from "@/components/sections/FooterSection";
  import askToSubscribeOnLeave from '@/components/behaviors/AskToSubscribeOnLeave';
  import BenefitsSection from "@/components/sections/Benefits";
  const { detect } = require('detect-browser');
  const browser = detect();
  import HowItWorksTeacherSection
    from "@/components/sections/HowItWorksTeacher";
  import CtaSection from "@/components/sections/Cta";
  import {ssrFinishedRendering, noLayoutAndStorePartnerInfo} from "@/utils";
  import HeroSection from "@/components/sections/Hero";
  import OurCommunitySection from "../sections/OurCommunity";
  import TestimonialsSection from "../sections/Testimonials";
  import TryItOutSection from "../sections/TryItOut";
  import PronunciationTrySection from "../sections/PronuncationTry";

  export default {
    name: 'FrontPage',
    mixins: [
      askToSubscribeOnLeave,
    ],
    components: {
      PronunciationTrySection,
      TryItOutSection,
      TestimonialsSection,
      OurCommunitySection,
      HeroSection,
      CtaSection,
      HowItWorksTeacherSection,
      BenefitsSection,
      footerSection
    },
    data() {
      return {
        browser,
      }
    },
    mounted() {
      noLayoutAndStorePartnerInfo(this.$store, this.$route, this.$cookie)
      ssrFinishedRendering();

      if (this.$analytics && this.$analytics.fbq && this.$analytics.fbq.event) {
        this.$analytics.fbq.event("ViewContent", {
          content_name: 'Home Page'
        })
      }
    },
  }
</script>


<style lang="scss">
  @import 'colors';

  .page--front {
    .subtitle {
      font-weight: 500;
    }

    .hero-body {
      .button.is-dark {
        margin-top: 1rem;
      }

      @media (max-width: 767px) {
        .h1 {
          margin-top: -2rem !important;
          position: relative;
          margin-bottom: 2.25rem;
        }
      }
    }

    .section--for-teachers {
      min-height: 700px;
    }

    .section--how-it-works-teacher {
      background: $primaryDarker url("~@/assets/v3/home-howitworks-bg.svg") no-repeat;
      background-position: center top;
      background-size: cover;
      padding-bottom: 6rem;
      @media (max-width: 767px) {
        background-size: cover;
        background-position: 25% top;
      }
      .head {
        margin-top: 5rem;
      }
    }

    .section--cta {
      background: url("~@/assets/v3/bottomCTA-bg.svg") no-repeat;
      background-size: 50%;
      background-position: center 36%;
      @media screen and (max-width: 767px) {
        background-size: 100%;
      }
    }
  }

  @media (max-width: 1200px) {
    .section--try-it-out {
      display: none
    }
  }
</style>
