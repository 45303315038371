<template lang="pug">
  .columns.sentence(
    :class="[{'has-picture': hasPicture || uploadingPicture, 'is-compact': compactMode, 'is-answer': isAnswer, 'has-dragging': dragging, 'has-no-ipa': (!isTeacher && !displayIpa)}, sentenceTagsClasses]" :id="'sentence_'+model.id"
    @mouseleave="dragging=false"
    @dragleave="dragging=false"
    @dragover="$event.preventDefault();dragging=true"
    @drop="onDrop"
  )
    .column.is-narrow.has-text-left.pt-22px.px-2.column-recorder.d-none-on-print.sentence--audio
      // RECORDER
      recorder.recorder.mb-1(
        v-if="model.id && index != null && user && user.id"
        :label="compactMode ? '' : 'Record'"
        :user="user"
        model-type="Sentence"
        :shortcut="index"
        :model-id="model.id"
        :language="language"
        speech-recognition="true"
        @audio-created="onAudioCreated"
        @voice-recognition-result="voiceRecognitionResultHandler"
        :text="sentence.content"
        :audio="(model.audio || [])[0]")
      transition(name="bounce")
        div(v-if="model.audio && model.audio.length")
          player.is-inverted.is-primary.mb-1(
            :class=`{
            'is-primary': audio.user_id == lesson.user_id,
            'is-info': audio.user_id != lesson.user_id
            }`
            :can-delete="user.id && (user.id == lesson.user_id || user.id == audio.user_id)"
            v-for="audio in model.audio"
            :key="audio.id"
            :label="compactMode ? '' : (audio.user_id == user.id ? user.first_name : (usersById[audio.user_id-0] || {}).first_name)"
            :audio="audio"
            :shortcut="index"
            )
    .column.is-wide.has-position-relative.pl-2.ml-2(
      :class="{'is-flex is-justify-content-space-between': compactMode}"
    )
      // INPUTS
      //-b-tooltip.lock-status(
        v-if="isTeacher && !compactMode"
        :label="sentence.locked ? 'Speech to text will not override the existing text' : 'Speech to text will override the existing text'"
        type='is-light'
        :animated='true'
        position='is-top'
        )
        span.is-clickable(@click="toggleLock()")
          b-icon(
            :icon="sentence.locked ? 'mic-off' : 'mic-none'"
            :type="sentence.locked ? '' : 'is-info'"
            custom-size="is-size-5"



            )
      span.sentence__number {{ sentence.index + 1 }}

      figure.sentence__picture-uploading.mb-1( v-if="uploadingPicture" )
        .has-loader.is-primary.is-outlined.is-loading.has-margin-auto.primary-loader

      //- Content
      .w-100(
        v-if="compactMode"
      )
        //- Picture
        transition(name="bounce")
          figure.sentence__picture.mb-1.is-clickable.is-relative(
            v-if="!uploadingPicture && picture"
            @click="openImageModal(picture)"
          )
            img( :src="picture" )
            button.delete-picture-button.button.is-small.is-secondary.has-justified-start.is-flex(
              @click.stop="onPictureDeleteClick"
            )
              b-icon(icon="delete")

        //- Inputs
        .sentence--inputs.has-text-left.is-flex
          span.field.sentence__content.is-fullwidth.is-narrow
            input.input.live-edit.w-auto.has-no-border.has-no-outline(
              :size="sentence.content.length"
              :placeholder="(lgToTxt[language] || 'English')+' sentence'"
              v-model="sentence.content"
              :expanded='true'
              @blur="save"
              @paste.native="handlePaste($event)"
              @keyup.enter.prevent="addSentence"
              @keyup.shift.exact.enter.prevent="translate(true)"
              @keyup="propagateChange('content', $event)"
            )

          span.field.sentence__translation.is-fullwidth.is-wide
            input.input.live-edit.has-no-border.has-no-outline.pl-0.has-text-right.w-100(
              placeholder="Translation"
              v-model="sentence.translation"
              @blur="save"
              @keyup.exact.enter="addSentence"
              @keyup="propagateChange('translation', $event)"
              :expanded='true'
              )

        p.sentence__ipa.has-text-left(v-if="isTeacher || displayIpa")
          b-tooltip(
            :label="word.known && !pronouncedWords[word.tts.toLowerCase()] ? `You learnt \"${word.tts}\" in a previous lesson` : ''"
            type='is-info'
            :animated='true'
            position='is-top'
            v-for="word in model.words"
          )
            ipa(
              :class="{disabled: !word.tts, miss: word.miss, known: word.known, pronounced: (word.tts && pronouncedWords[word.tts.toLowerCase()])}"
              :user="user || {}"
              :key="word.idx"
              :lang="language"
              :tts="word.tts"
              :title="word.variations ? word.variations.join(' / ') : ''"
              :content="word.content"
              @click.native="correctIpa(word, $event)"
              @mouseenter="highlightIndex = word.idx")

        .field.has-flex-start-items
          b-tag.mr-1(
            v-for="tag in sentence.tags"
            :key="tag"
            closable
            rounded
            @close="removeTag(tag)"
          )
            | {{tag}}
          button.button.is-small.is-rounded.is-tag-like(
            tabindex="-1"
            @click="addTag(lastTag)"
            v-if="lastTag && sentence.tags.indexOf(lastTag) === -1"
          )
            | Add "{{lastTag}}"


      div(
        v-else
      )

        //- Picture
        transition(name="bounce")
          figure.sentence__picture.mb-1.is-clickable.is-relative(
            v-if="!uploadingPicture && picture"
            @click="openImageModal(picture)"
          )
            img( :src="picture" )
            button.delete-picture-button.button.is-small.is-secondary.has-justified-start.is-flex(
              @click.stop="onPictureDeleteClick"
            )
              b-icon(icon="delete")

        //- Content
        b-field.sentence__content.is-fullwidth
          autocomplete-sentence.live-edit(
            v-if="(user && user.preferences && user.preferences.use_autocomplete) && (!sentence.content || !sentence.content.length)"
            @input="applyAutocomplete"
            @translate="translate(true)"
            @paste="handlePaste"
            @set-content="setContent"
          )

          p.is-size-6.is-bold.has-text-left.leading-tight.pb-2(
            v-else-if="isMobile"
          ) {{ sentence.content }}

          //-textarea.live-edit.w-100.is-borderless(
          b-input.live-edit(
            v-else
            :placeholder="(lgToTxt[language] || 'English')+' sentence'"
            v-model="sentence.content"
            :expanded='true'
            @blur="save"
            @paste.native="handlePaste($event)"
            @keyup.native.enter.exact.prevent.stop="addSentence"
            @keyup.native.shift.enter.exact.native.prevent.stop="translate(true)"
            @keyup.native="propagateChange('content', $event)")

        //- IPA
        p.sentence__ipa.has-text-left(v-if="isTeacher || displayIpa")
          b-tooltip(
            :label="word.known && !pronouncedWords[word.tts.toLowerCase()] ? `You learnt \"${word.tts}\" in a previous lesson` : ''"
            type='is-info'
            :animated='true'
            position='is-top'
            v-for="word in model.words"
          )
            ipa(
              :class="{disabled: !word.tts, miss: word.miss, known: word.known, pronounced: (word.tts && pronouncedWords[word.tts.toLowerCase()])}"
              :key="word.idx"
              :lang="language"
              :user="user"
              :tts="word.tts"
              :title="word.variations ? word.variations.join(' / ') : ''"
              :content="word.content"
              @click.native="correctIpa(word, $event)"
              @mouseenter="highlightIndex = word.idx"
              )

        //- Literal translation
        b-field.mb0.sentence__literal-translation.is-fullwidth(
          v-if="displayLit"
          grouped
        )
          b-input.live-edit(
            placeholder="Literal translation"
            v-model="sentence.literal_translation"
            :expanded='true'
            @keyup.exact.native.enter="addSentence"
            @keyup.native="propagateChange('literal_translation', $event)"
            @blur="literalTranslationEdited")

        //- Translation
        b-field.sentence__translation.is-fullwidth(
          grouped
        )
          b-input.live-edit(
            v-if="!isMobile"
            placeholder="Translation"
            v-model="sentence.translation"
            @keyup.exact.native.enter="addSentence"
            @keyup.native="propagateChange('translation', $event)"
            :expanded='true')
          p.is-bold.leading-tight(
            v-else
          ) {{ sentence.translation }}
        .field.has-flex-start-items
          b-tag.mr-1(
            v-for="tag in sentence.tags"
            :key="tag"
            closable
            rounded
            @close="removeTag(tag)"
          )
            | {{tag}}
          button.button.is-small.is-rounded.is-tag-like(
            tabindex="-1"
            @click="addTag(lastTag)"
            v-if="lastTag && sentence.tags.indexOf(lastTag) === -1"
          )
            | Add "{{lastTag}}"

    .column.is-narrow.pr-0.sentence--actions(
      :class="{'is-flex': compactMode}"
    )
      transition(name="bounce")
        tts-button.sentence--listen.mb-1.is-flex(
          :disabled="!(sentence.content && sentence.content.length)"
          :tts="sentence.content"
          :lang="language"
        )
      b-tooltip.sentence--delete(
        v-if="!isGuest"
        label="Double-click to delete"
        type='is-light'
        :animated='true'
        position='is-left')
        button.button.is-small.is-outlined.button-delete.is-flex(
          tabindex="-1"
          @dblclick="remove"
          v-ga="'deleteSentence'")
          b-icon(icon="delete")

      b-tooltip.button-delete.is-flex(
        :label="'Click & drag to move the sentence'"
        type='is-light'
        :animated='true'
        position='is-top'
      )
        button.button.is-small.is-outlined(
          v-handle="true"
          tabindex="-1"
          )
          b-icon(icon="drag-handle")

      button.button.is-small.is-outlined(
        v-if="lesson.user_id == (user || {}).id"
        :class="{'has-loader is-loading': isAdding}"
        @click="addAfter"
        tabindex="-1"
      )
        b-icon(icon="add")

    .column.is-narrow.column-comment(
      v-if="!compactMode"
    )
      resizable-textarea
        b-input.light-border.sentence__comment(
        ref="comment"
        tabindex="-1"
        placeholder="Comment"
        maxlength="800"
        :has-counter="false"
        @keydown.enter.native="transformHashtagToTagForComment($event)"
        @keyup.native="reformatComment();propagateChange('comment', $event)"
        v-ga.blur="'addComment'"
        type="textarea"
        v-model="sentence.comment")

      //- SPECIAL LETTERS

      .mt-2(v-if="language == 'fr-FR'")
        button.button.is-small.mr-1.button-letter(
          @mousedown.prevent="addLetterToComment(letter)"
          v-for="letter in ['é', 'è', 'ê', 'à', 'ù', 'ô', 'ç', 'É', 'È', 'Ê', 'À', 'Ç']"
        ) {{letter}}




</template>

<script>
import Ipa from "@/components/Ipa";
import Recorder from "@/components/Recorder";
import AudioPlayer from "@/components/AudioPlayer";
import TtsButton from "@/components/TtsButton";
import EditIpaModal from "@/components/EditIpaModal";
import ResizableTextarea from "@/components/ResizableTextarea";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import uniq from "lodash/uniq";
import filter from "lodash/filter";
import some from "lodash/some";
import forEach from "lodash/forEach";
import { ElementMixin, HandleDirective } from 'vue-slicksort';
import {LG_TO_TXT, LATIN_LANGUAGES} from "../constants"
import AutocompleteSentence from "./AutocompleteSentence";
import { Sentence } from "@/store/models/resources";
import PasteModal from "./modals/PasteModal";

const FRENCH_QUESTION = /^(est-?ce qu|qu'|quand|où|pourquoi|comment|qui|quel)/i;
const FR = 'fr-FR';

export default {
  name: "Sentence",
  mixins: [ElementMixin],
  directives: {
    handle: HandleDirective
  },
  components: {
    PasteModal,
    AutocompleteSentence,
    ipa: Ipa,
    player: AudioPlayer,
    Recorder,
    TtsButton,
    ResizableTextarea
  },
  props: [
    "lastTag",
    "isLast",
    "language",
    "model",
    "index",
    "isTeacher",
    "isGuest",
    "lessonUuid",
    "version",
    "features",
    "languageKnown",
    "displayIpa",
    "displayLit",
  ],
  data() {
    return {
      lgToTxt: LG_TO_TXT,
      isSaving: false,
      pronouncedWords: {},
      sentence: {
        id: null,
        locked: false,
        lesson_id: null,
        content: "",
        translation: "",
        literal_translation: "",
        ipa: "",
        comment: "",
        tags: [],
        audio: [],
      },
      highlightIndex: null,
      comment: "",
      uploadingPicture: false,
      dragging: false,
      isAdding: false,
      delay: 400,
      clicks: 0,
      timer: null
    };
  },
  computed: {
    ...mapGetters(["uuid", "apiUrl", "compactMode", "user", "usersById", "lesson"]),

    isMobile() {
      return window.innerWidth < 700
    },

    isAnswer() {
      return this.sentence.tags && this.sentence.tags.indexOf('answer') !== -1
    },
    sentenceTagsClasses() {
      // return this.sentence.tags.map(tag => `tag-${tag}`).join(" ");
      return ""
    },
    picture() {
      if ( this.hasPicture ) {
        if (this.sentence.picture.url.match(/^https?:/)) {
          return this.sentence.picture.url;
        } else {
          return `${this.apiUrl}/${this.sentence.picture.url}`;
        }
      } else {
        return null;
      }
    },

    hasPicture() {
      if (
        this.sentence && this.sentence.picture && this.sentence.picture.url
      ) {
        return true
      } else {
        return false
      }
    },
    isLocked() {
      let isLocked = true;
      const {paid} = this.lesson;
      if (this.user && this.user.id) {
        isLocked = this.user.is_locked
      }
      if (paid) {
        isLocked = false
      }
      return isLocked;
    }
  },
  watch: {
    version(val) {
      // if (val >= (this.sentence.version || 0)) {
      // console.log({realLast: this.$store.getters.lesson.sentences[this.sentence.index].lastChange});
      Object.assign(this.sentence, this.model.lastChange);
      // this.sentence.tags = this.model.lastChange.tags
      // }
    }
  },
  mounted() {
    this.sentence = Vue.util.extend(
        {
          lesson_id: this.lessonUuid,
        },
        this.model
    );
    this.sentence.index = this.index;
    if (this.sentence.content && this.sentence.content.length) {
      this.$set(this.sentence, 'locked', true);
    }
  },
  methods: {
    setContent({content, focus}) {
      this.sentence.content = content
      if (focus) {
        this.$nextTick(() => {
          setTimeout(() => {
            const dom = document.querySelector(`#sentence_${this.sentence.id} input`)
            if (dom) {
              dom.focus()
            }
          }, 100)
        })
        this.$forceUpdate();
      }
      this.propagateChange('content', null)
    },
    applyAutocomplete(id) {
      Sentence.replace( {
        id: this.sentence.id,
        sentence_id: id,
        lesson_uuid: this.lessonUuid,
      }, {id: this.sentence.id}).then(res => {
        console.log({ res })

        this.$socket.emit('LESSON_ASK_REFRESH', {
          room: `LESSON_${this.lessonUuid}`,
          uuid: this.uuid
          // uuid: '0'
        });
        this.$store.commit("editSentenceAndForceRefresh", res.body);
        if (!this.lesson.sentences[this.sentence.index+1] && (this.lesson.sentences.length < 50 || (this.user || {}).role === "premium")) {
          this.addSentence()
        }
      })
    },
    explainRemove() {
      this.$toast.info("Double-click to remove the picture.")
    },
    removePicture() {
      this.$store.dispatch("removePictureFromSentence", {
        sentence: this.sentence,
        uuid: this.lessonUuid
      }).then(() => {
        this.sentence.picture = null
      })
    },
    onPictureDeleteClick() {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout( () => {
          this.explainRemove()
          this.clicks = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.removePicture();
        this.clicks = 0;
      }
    },

    voiceRecognitionResultHandler(words) {
      console.log({ words });
      this.isTeacher ? this.fillContentBasedOnSpeech(words) : this.markWordsAsWellPronounced(words)
    },
    addLetterToComment(letter) {
      const {comment} = this.$refs;
      const textarea = comment.$el.children[0];
      textarea.setRangeText(
        letter,
        textarea.selectionStart,
        textarea.selectionEnd,
        'end'
      )
    },
    correctIpa(word, ev) {
      // console.log('correctIpa...');
      if (this.isTeacher) {
        ev.stopPropagation();
        ev.preventDefault();
        this.$buefy.modal.open({
          parent: this,
          animation: "none",
          width: 480,
          props: {
            sentence: this.sentence,
            language: this.language,
            word,
          },
          component: EditIpaModal,
          hasModalCard: false,
          events: {
            preClose: () => {
              this.delayedSave();
            }
          },
          onCancel() {
            // console.log('has finisehd ipa edit')
          }
        })
      }
    },
    openImageModal(src) {
      this.$buefy.modal.open(
        `<p class="image">
                        <img src="${src}">
                    </p>`
      )
    },
    toggleLock() {
      // this.sentence.locked = !this.sentence.locked;
      this.$set(this.sentence, 'locked', !this.sentence.locked)
    },
    literalTranslationEdited() {
      if (
        this.sentence.literal_translation &&
        this.sentence.literal_translation.length
      ) {
        this.sentence.literal_translation = this.sentence.literal_translation
          .replace(/ • /g, " ")
          .split(/\s+/)
          .join(" • ");
        this.$ga.commands.editLiteral();
      }
    },
    delayedSave: debounce(function() {
      this.save();
    }, 600),
    save() {
      if (this.sentence.content && this.sentence.content.length && this.lessonUuid) {
        this.transformHashtagToTag();
        this.isSaving = true;
        if (this.sentence.lastChange) {
          delete this.sentence.lastChange;
        }
        this.$store
          .dispatch(
            "saveSentence",
            Object.assign(this.sentence, {
              id: this.model.id,
              index: this.index,
              lesson_id: this.lessonUuid,
              ipa: this.ipa
            })
          )
          .then(() => {
            this.isSaving = false;
          });
      }
    },
    transformHashtagToTag() {
      if (this.sentence.content && this.sentence.content.length) {
        if (this.sentence.content.match(/#[\w_]+/)) {
          this.sentence.content.match(/#[\w_]+/g).forEach(tag => {
            this.sentence.tags.push(tag.replace(/#/, ""));
            this.$emit('tag', tag.replace(/#/, ""));
          });
          this.sentence.tags = uniq(this.sentence.tags);
          this.sentence.content = this.sentence.content.replace(
            /\s?#[\w_\s]+/g,
            ""
          );
          this.$mixpanel.track("Lesson - Sentence - Add tag");
          return true;
        }
      }
      return false;
    },
    transformHashtagToTagForComment(event) {
      if (this.sentence.comment && this.sentence.comment.length) {
        if (this.sentence.comment.match(/#[\w_]+/)) {
          event.stopPropagation();
          event.preventDefault();
          this.sentence.comment.match(/#[\w_]+/g).forEach(tag => {
            tag = tag.replace(/#/, "").toLowerCase()
            this.sentence.tags.push(tag);
            this.$emit('tag', tag);
          });
          this.sentence.tags = uniq(this.sentence.tags);
          this.sentence.comment = this.sentence.comment.replace(
            /\s?#[\w_]+/g,
            ""
          );
          this.save();
          this.$mixpanel.track("Lesson - Sentence - Add tag in comment");
          return false
        }
      }
      return true;
    },
    removeTag(tag) {
      this.sentence.tags = filter(this.sentence.tags, t => t !== tag);
      this.$emit('tag', null);
      this.save();
    },
    addTag(tag) {
      tag = tag.replace(/#/, "").toLowerCase();
      this.sentence.tags.push(tag);
      this.sentence.tags = uniq(this.sentence.tags);
      this.save();
    },
    addAfter() {
      const {uuid} = this;
      this.isAdding = true;
      this.$store.dispatch("addSentence", {
        lesson_id: this.lessonUuid,
        index: this.index+1,
        addAfter: true
      }).then(
          sentence => {
            this.isAdding = false;
            const {id} = this.$route.params;
            this.$socket.emit('LESSON_ASK_REFRESH', {
              room: `LESSON_${id}`,
              uuid
            });
          },
          err => {
            this.isAdding = false;
          }
      );
    },
    remove() {
      this.$store.dispatch("deleteSentence", {
        id: this.model.id,
        index: this.index
      });

      this.$emit("propagate-change", {
        sentence: {
          id: this.model.id,
          uuid: this.uuid,
          index: this.index
        },
        action: "deleteSentence"
      });
      this.$mixpanel.track("Sentence - Delete");
    },
    addSentence(event) {
      console.log({ event, addSentence: true })

      // console.log('ADDING SENTENCE');
      if (!this.transformHashtagToTag()) {
        this.save();
        this.$emit("add-sentence");
      } else {
        this.save();
      }
    },
    onAudioCreated() {
      this.propagateChange('audio', null, true)
    },
    onAudioRemoved() {
      console.log("onAudioRemoved");
      this.propagateChange('audio', null, true)
    },
    propagateChange(field, event, preventLock = false) {
      console.log({ event, propagateChange: true  })
      this.$store.commit('updateActivity');
      //////////
      //
      // SAVE
      //


      this.delayedSave();

      if (field == 'content' && !preventLock) {
        this.sentence.locked = true
      }

      // PROPAGATE CHANGES

      var msg = {
        sentence: {
          id: this.model.id,
          index: this.index,
          uuid: this.uuid,
          ipa_locked: this.model.ipa_locked
        },
      };
      msg.sentence[field] = this.sentence[field] || this.model[field];

      this.$emit("propagate-change", msg);
    },

    handlePaste(e) {
      if (this.isLocked) {
        this.$toast.open({
          duration: 7000,
          message: 'Adding audio and pictures are Premium features. Please subscribe to a paid subscription or pay credits',
          type: "error"
        });
        return
      }

      this.$mixpanel.track("Sentence - Paste");
      const file = e.clipboardData.files[0];
      var text = e.clipboardData.getData("text/plain");

      if (file && !text.match(/\t/)) {
        this.updatePicture([file]);
      } else {
        var lines = text.split("\n");

        if (lines.length == 1 && lines[0].split("\t").length == 1) {
          return;
        } else {
          e.preventDefault();

          this.$buefy.modal.open({
            parent: this,
            component: PasteModal,
            hasModalCard: false,
            props: {
              content: text,
              initialIndex: this.sentence.index,
              lessonUuid: this.lessonUuid
            },
          })
        }
      }
    },

    trim(str) {
      if (!str) {
        return null;
      }
      return str.replace(/^[\/\-_\s]+/, "").replace(/[\/\-_\s]+$/, "");
    },

    onDrop(ev) {
      ev.stopPropagation();
      ev.preventDefault();
      const { dataTransfer } = ev;
      const html = dataTransfer.getData('text/html')
      if (html.length) {
        const matches = html.match(/https?:\/\/[^\s"']+/gi);
        if (matches) {
        const url = matches[0];
        if (url) {
          this.sentence.picture = {
            url
          }
          this.save()
        }
        }
      } else {
        this.updatePicture(dataTransfer.files)
      }

      this.dragging = false;
    },

    updatePicture(pictArr) {
      // PREVENT SORTABLE TO RUN THIS METHOD
      if (this.isLocked && !pictArr.length) {
        return true
      } else if (this.isLocked && !pictArr.length) {
        this.$toast.open({
          duration: 7000,
          message: 'Adding audio and pictures are Premium features. Please subscribe to a paid subscription.',
          type: "error"
        });
        return
      }
      // console.log({ pictArr });
      this.uploadingPicture = true;
      this.$store.dispatch("addPictureToSentence", {
        sentence: Object.assign({}, this.sentence, {lesson_id: this.$route.params.id}),
        file: pictArr[0] || pictArr }).then(
        res => {
          // this.sentence.picture = Object.assign({}, res.picture);
          this.$set(this.sentence, 'picture', {});
          this.$set(this.sentence.picture, 'url', res.picture.url);
          this.uploadingPicture = false;
        }, err => {
          this.$toast.open({
            duration: 7000,
            message: 'The file sent couldn\'t be processed. Please make sure you send an image.',
            type: "error"
          });
          this.uploadingPicture = false;
        })
    },

    fillContentBasedOnSpeech(words) {
      console.log("fillContentBasedOnSpeech")
      const byPass = !this.sentence.content
      if (!this.sentence.locked || !this.sentence.content) {
        this.sentence.content = words.join(' ');
        const {content} = this.sentence;
        if (content.length && this.user.capitalize_tts && LATIN_LANGUAGES.includes(this.language)) {
          this.sentence.content = content[0].toLocaleUpperCase(this.language)+content.substring(1)
        }

        if (
            this.language === FR &&
            this.sentence.content &&
            this.sentence.content.match(FRENCH_QUESTION) &&
            !this.sentence.content.match(/\?$/)
        ) {
          this.sentence.content = `${this.sentence.content} ?`
        }

        // this.suggestTranslation();
        this.translate(byPass).then( (sentenceFromServer) => {
          if (sentenceFromServer) {
            if (!sentenceFromServer.translation || !sentenceFromServer.translation.length) {
              document
                .querySelector(`#sentence_${this.model.id} .sentence__literal-translation input`)
                .focus();
            }
            this.propagateChange('content', null, true);
            this.propagateChange('translation');
            // this.propagateChange('ipa');
            this.$mixpanel.track("Sentence - Fill with speech");
          }
        })
      }
    },

    translate(byPassLock = false) {
      if (!byPassLock && this.sentence.locked) {
        return Promise.resolve(false);
      }

      return this.$store.dispatch('translateSentence', {
        sentence: Object.assign({}, this.sentence, {lesson_id: this.$route.params.id}),
        lesson: {
          language_from: this.languageKnown,
          language_to: this.language || 'en-US',
        }
      })
    },

    markWordsAsWellPronounced(words) {
      if (!this.sentence.content || !this.sentence.content.length) {
        this.fillContentBasedOnSpeech(words)
      } else {
        this.$ga.event("sentences", "pronunciation", "button", 1);

        this.pronouncedWords = {}
        forEach(words, word => {
          this.$set(this.pronouncedWords, word.toLowerCase(), true);
        });

      }
    },

    // async suggestTranslation() {
    //   if (
    //     this.sentence.content &&
    //     this.sentence.content.length &&
    //     (!this.sentence.translation || !this.sentence.translation.length) &&
    //     this.languageKnown
    //   ) {
    //     const response = await Yandex.translate(this.sentence.content, this.language.substring(0,2), this.languageKnown.substring(0,2));
    //     this.sentence.translation = response.data.text[0];
    //     this.propagateChange('translation')
    //   }
    // }
    reformatComment() {
      if (!this.sentence.comment || !this.sentence.comment.length) {
        return
      }
      this.sentence.comment = this.sentence.comment.replace(/\- /g, '• ').replace(/=> /g, '▶ ').replace(/:list:/, "🞐 \n🞐 \n🞐 ")
    },
  }
};
</script>

<style lang="scss">
@import "colors";


.column.column-actions {

}

.has-invisible-inputs {
  .sentence__ipa {
    opacity: 0;
    transition: all .2s ease-in-out;
  }
  .sentence__content input {
    opacity: 0;
    transition: all .2s ease-in-out;
  }

  .sentence .column.is-wide:hover {
    .sentence__ipa,
    .sentence__content input {
      opacity: 1;
    }
  }
}

.sentence {
  position: relative;
  /*transition: transform .2s ease-in-out;*/
  background-color: #FFF;

  .textarea:not([rows]) {
    min-height: 9.55em;
  }

  &.has-no-ipa {
    .sentence__content {
      margin-bottom: .5rem;
    }
  }

  &.has-dragging {
    -webkit-box-shadow: 0 2px 24px #cecdcd;
    box-shadow: 0 2px 24px #cecdcd;
    position: relative;
    z-index: 10;
    transform: scale(1.005);
  }

  &.is-answer {

    /*.column:nth-child(1) { order: 1 }*/
    /*.column:nth-child(2) { order: 4 }*/
    /*.column:nth-child(3) { order: 3 }*/
    /*.column:nth-child(4) { order: 2 }*/
    .sentence__ipa,
    input {
      text-align: right !important;
    }
    figure {
      float: right;
      margin-right: 0;
    }
    .field.has-flex-start-items {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .delete-picture-button {
    display: none !important;
    border-radius: 50%;
    position: absolute;
    right: -0.6rem;
    bottom: -0.6rem;
    transform: scale(0.75);
    transition: transform .3s cubic-bezier(.2, .8, .2, .8);
    color: #8e8e8e;
    background-color: #ededed;
    z-index: 100;
  }
  figure:hover {
    .delete-picture-button {
      display: block !important;
      &:hover {
        transform: scale(0.95);
        //&:after {
        //  position: absolute;
        //  content: "Double-click";
        //  left: 110%;
        //  top: 40%;
        //  z-index:100;
        //}
      }
    }
  }


span.sentence__number {
    position: absolute;
    z-index: 5;
    left: -8px;
    top: 20px;
    color: #dfdcdc;
    font-weight: 600;
    width: 25px;
    text-align: center;
  }

  .sentence__picture-uploading {
    display: flex;
    font-size: 2rem;
    background-color: #fbfbfb;
  }

  figure {
    float: left;
    margin-right: 1rem;
    margin-left: 1rem;
    height: 150px;
    width: 150px;
  }
  img {
    max-width: 150px;
    border-radius: 3px;
  }
  &.has-picture {
    .sentence__ipa {
    }
  }

  .sentence__content {
    margin-left: 9px;
    .control {
      margin-left: -9px;
    }
  }


  &.is-compact {
    height: 90px;
    textarea {
      height: 70px !important;
      min-height: 70px;
    }
    .sentence__ipa {
      margin-left: 0;
      margin-top: -21px;
    }
    .sentence__content {
      margin-left: 0;
      input {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    .sentence--actions {
      .button {
        margin-right: 0.25rem;
      }
    }
  }
}

@media (max-width: 768px) and (orientation: portrait) {
  .sentence.is-compact {
    height: 260px;
  }

  .sentence--actions {
    display: none !important
  }
}

.live-lesson-page {
  .sentence {
    transition: transform .3s ease-in-out;
  }
  .sentence.has-dragging {
    -webkit-box-shadow: 0 2px 12px #cecdcd;
    box-shadow: 0 2px 12px #cecdcd;
    position: relative;
    z-index: 10;
    transform: scale(1);
  }
}

.sentence__content input {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  transition: color 0.5s ease;
}

.tag-informal .sentence__content input {
  font-family: "Caveat Brush", cursive;
  font-weight: 400;
  font-size: 24px;
  padding-left: 9px;
  padding-top: 0px;
  line-height: 1;
  height: 2em;
}

.column-recorder {
  background-color: #F8F7FD;
  position: relative;
  .student-recorder {
  }
}

span {
  &.masc,
  &.masculin {
    color: #3272dc;
  }
  &.fem,
  &.feminin {
    color: #ff3860;
  }
  &.sing,
  &.singular {
  }
  &.plu,
  &.plural {
    color: #363636;
  }
  &.for,
  &.formal {
    color: #209cee;
  }
  &.inf,
  &.informal {
    color: #01d1b2;
  }
}

/*
  .sentence__content {
    &.masc,
    &.masculin { input {color: #3272DC;} }
    &.fem,
    &.feminin { input {color: #FF3860;} }
    &.sing,
    &.singular { }
    &.plu,
    &.plural { input {color: #363636;} }
    &.for,
    &.formal { input {color: #209CEE;} }
    &.inf,
    &.informal { input {color: #01D1B2;} }
  }
  */
.is-saving input {
  color: $primary;
}
.is-saved input {
  color: $green;
}

.lock-status {
  position: absolute;
  z-index: 5;
  left: -6px;
  top: 19px;
  color: $lightGray;
}

.sentence__ipa {
  font-family: SF Pro, Avenir, Helvetica, sans-serif;
  position: relative;
  margin-left: 9px;
  margin-top: -17px;
  font-size: 17.5px;
  color: #777;
  letter-spacing: 1px;
  margin-bottom: 8px;
  .miss {
    color: #b3b2b2;
  }
  .known {
    color: lighten(#209cee, 10%);
  }
  .pronounced {
    -webkit-animation: wellPronounced 0.5s ease;
    -webkit-animation-fill-mode: forwards;
    animation: wellPronounced 0.5s ease;
    animation-fill-mode: forwards;
    & + .pronounced {
      animation-delay: 0.1s;
      & + .pronounced {
        animation-delay: 0.2s;
        & + .pronounced {
          animation-delay: 0.3s;
          & + .pronounced {
            animation-delay: 0.4s;
            & + .pronounced {
              animation-delay: 0.5s;
              & + .pronounced {
                animation-delay: 0.6s;
              }
            }
          }
        }
      }
    }
  }
}

@-webkit-keyframes wellPronounced {
  0% {
    color: #777;
    transform: scale(1);
  }
  25% {
    color: $darkGreen;
    transform: scale(1.2);
  }
  100% {
    color: $green;
    transform: scale(1);
  }
}
@keyframes wellPronounced {
  0% {
    color: #777;
    transform: scale(1);
  }
  25% {
    color: $darkGreen;
    transform: scale(1.2);
  }
  100% {
    color: $green;
    transform: scale(1);
  }
}

.sentence__literal-translation {
  margin-top: -10px;
  input {
    font-size: 0.95rem;
    height: 2em;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.sentence__literal-translation-formatted {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding-left: 8px;
}

.sentence__literal-translation input,
.sentence__translation input {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.sentence__translation p,
.sentence__translation input {
  color: $blue;
}
.sentence__translation {
  p {
    margin-left: 9px;
    font-weight: 400;
  }
}

.button-delete {
  /*width: 79px;*/
}

.light-border textarea {
  border: 1px solid #f4f4f4;
  box-shadow: none;
  font-size: 0.8rem;
  resize: none;
}


.column {
  position: relative;
}

.is-tag-like{
  height: 2em;
  padding-top: 0;
  padding-bottom: 0;
}

.tags-indicator {
  position: absolute;
  width: 8px;
  top: 25px;
  left: 3px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  line-height: 12px;
}

.recorder {
  height: 30px;
}
.download-button {
  position: absolute;
  top: 62px;
  left: -50px;
  transition: all 0.3s ease;
  width: 40px;
  height: 40px;
}

.sentence__tags {
  height: 12px;
  font-size: 12px;
  padding-left: 9px;
  margin-top: 13px;
  margin-bottom: 26px !important;
  &:empty {
    display: none;
  }
}

.button-letter {
  transition: opacity .3s ease;
  opacity: 0;
  width: 11px;
  color: $midGray;
  border: 1px solid #f4f4f4;
}
.sentence .column-comment:hover {
  .button-letter {
    opacity: 1;
  }
}

/*
  .tags {
    .tag {
      text-transform: uppercase;
    }

    &.masc,
    &.masculin {
      .tag {
        background-color: #3272DC;
        color: #FFF;
        &:hover {
          background-color: #1959C3;
          color: #FFF;
        }
      }
    }
    &.fem,
    &.feminin {
      .tag {
        background-color: #FF3860;
        color: #FFF;
        &:hover {
          background-color: #CC052D;
          color: #FFF;
        }
      }
    }
    &.sing,
    &.singular {

    }
    &.plu,
    &.plural {
      .tag {
        background-color: #363636;
        color: #FFF;
        &:hover {
          background-color: #1D1D1D;
          color: #FFF;
        }
      }
    }
    &.for,
    &.formal {
      .tag {
        background-color: #209CEE;
        color: #FFF;
        &:hover {
          background-color: #0069BB;
          color: #FFF;
        }
      }
    }
    &.inf,
    &.informal {
      .tag {
        background-color: #01D1B2;
        color: #FFF;
        &:hover {
          background-color: #00B899;
          color: #FFF;
        }
      }
    }
  }
  */



@media (min-width: 769px) and (max-width: 1366px) {
  .sentence .column-comment {
    max-width: 15rem;
    textarea {
      font-size: 11px;
    }
  }
}
@media (max-width: 768px) {
  .recorder {
    position: relative;
    top: initial;
    left: initial;
    z-index: 42;
    text-align: left;
    margin-bottom: 20px;
  }
  .sentence {
    //border: 1px solid rgba(140, 94, 255, 0.5);
    //border-radius: 6px;
    overflow: hidden;
    margin-bottom: 1.5rem !important;
    .play-button,
    .button.is-white {
      box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px -1px rgba(0,0,0,0.1);
    }
  }
  .column-recorder {
    padding-top: 1rem;
    height: 57px !important;
  }

  .sentence .column-comment {
    display: none !important
  }
}
@media (max-width: 768px) and (orientation: landscape) {
  .sentence {
    .column-comment {
      display: block !important
    }
  }
}
</style>
