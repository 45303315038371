<template lang="pug">
  .box.mt-5.box.has-text-left.is-10.column.is-offset-1
    .content
      section.section
        h2.title Privacy Policy
        p
          | <a href="http://www.guillaumevoisin.eu/" target="_blank">Guillaume Voisin</a> built the Teachee app as a paid Software as a Service.
        p
          | This page is used to inform website visitors regarding my policies with
          | the collection, use, and
          |  disclosure of Personal Information if anyone decided to use my
          |  Service.
        p
          | If you choose to use my Service, then you agree to the collection and
          |  use of information in
          |  relation with this policy. The Personal Information that I collect are
          |  used for providing and
          |  improving the Service. I will not use or share your information with
          |  anyone except as described
          |  in this Privacy Policy.
        p
          | The terms used in this Privacy Policy have the same meanings as in our
          |  Terms and Conditions,
          |  which is accessible at teachee.io, unless otherwise defined in
          |  this Privacy Policy.
      section.section
        p
          strong Data collected
        p
          | Teachee collects minimum amount of user data needed for it to work
          |  properly. Data collected is used only to make the app work and will
          |  never be shared with 3rd parties other than Google and Stripe without your explicit consentment.
          br
          |         Teachee stores those personal data : first name, last name, city, country, email, google identifier, picture). We
          |         collect the aforementioned personal data through Google Connect to ensure you can log
          |         in effortlessly.
          br
          |         When you delete your account by sending an email to the <a href="mailto:support@teachee.io">support</a>,
          |         Everything you ever did in the app get irrevocably destroyed after 7 days.
          |         We leave some time to your students to get a backup of the lessons.
          br
          |         All server logs (data automatically generated by the softwares) have
          |         been anonymized (no IP addresses).
          br
          |         Your actions on the app are sent to Google Analytics to improve the
          |         service. All data sent to Google Analytics is anonymized (no IP sent)
      section.section
        p
          strong Data retention
        p
          | Server logs are destroyed after 14 days.
      section.section
        p#cookies
          strong Cookies
        p
          | Cookies are small text files which are downloaded to your computer,
          |  tablet or mobile phone when you visit a website or application. The
          |  website or application may retrieve these cookies from your web browser
          |  (eg Internet Explorer, Mozilla Firefox or Google Chrome) each time you
          |  visit, so they can recognise you, remember your preferences and provide
          |  you with a more secure online experience.
          br
          |
          br
          |         Generally, cookies are very useful and are a common method used by
          |         almost every website you visit because they help to make your online
          |         experience as smooth as possible. For security reasons, many websites
          |         will not function at all without the use of cookies (or other similar
          |         technologies, such as &quot;web beacons&quot; or &quot;tags&quot;).
          br
          |
          br
          |         Cookies generally do not hold any information to identify an individual
          |         person, but are instead used to identify a browser on an individual
          |         machine.
          br
          |
          br
          |         If you prefer, you can restrict, block or delete cookies by changing
          |         your browser settings but that may mean that the website won&apos;t work
          |         properly
          br
          |
          br
          |         For more information about cookies and their impact on you and your
          |         browsing visit www.aboutcookies.org.
          br
      section.section
        p
          strong Service Providers
        p
          | We may employ third-party companies and individuals due to the
          |  following reasons:
        |
        ul
          li To facilitate our Service;
          |
          li To provide the Service on our behalf;
          |
          li To perform Service-related services; or
          |
          li To assist us in analyzing how our Service is used.
        p
          | Currently we use Google connect, Google Analytics, Mixpanel and Stripe
      section.section
        p
          strong Security
        p
          | I value your trust in providing us your Personal Information, thus we
          |  are striving to use
          |  commercially acceptable means of protecting it. But remember that no
          |  method of transmission over
          |  the internet, or method of electronic storage is 100% secure and
          |  reliable, and I cannot
          |  guarantee its absolute security.
      section.section
        p
          strong Links to Other Sites
        p
          | This Service may contain links to other sites. If you click on a
          |  third-party link, you will be
          |  directed to that site. Note that these external sites are not operated
          |  by me. Therefore, I
          |  strongly advise you to review the Privacy Policy of these websites. I
          |  have no control over, and
          |  assume no responsibility for the content, privacy policies, or practices
          |  of any third-party
          |  sites or services.
      section.section
        p
          strong Changes to This Privacy Policy
        p
          | I may update our Privacy Policy from time to time. Thus, you are
          |  advised to review this page
          |  periodically for any changes. I will notify you of any changes by
          |  posting the new Privacy Policy
          |  on this page. These changes are effective immediately, after they are
          |  posted on this page.
      section.section
        p
          strong Contact Us
        p
          | If you have any questions or suggestions about my Privacy Policy, do
          |  not hesitate to contact
          |  <a href="mailto:support@teachee.io">me</a>.
      section.section
        p
          | This Privacy Policy page was created at
          a(href='https://privacypolicytemplate.net', target='_blank')  privacypolicytemplate.net
          | .


</template>

<script>
import pricingTable from "@/components/PricingTableV2";

export default {
  name: "PrivacyPage",
  components: {
    pricingTable
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("setLayout", "front-layout");
  },
  methods: {}
};
</script>


<style scoped>

  .section {
    padding: 2rem 1.5rem;
  }
</style>
