`import {Purchase} from '../models/resources'`

actions =
  getPurchases: (context) ->
    Purchase.query().then (response) ->
      context.commit('purchases', response.body.purchases)

  findPurchase: (context, purchase) ->
    new Promise((resolve, reject) ->
      Purchase.get({id: purchase.id}).then (response) ->
        context.commit('purchase', response.body)
        resolve(response.body)
      , (err) ->
        reject(err)
    )

`export default actions`
