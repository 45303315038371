`import {Credit} from '../models/resources'`

actions =

  claimCredits: (context) ->
    new Promise((resolve, reject) ->
      Credit.claim().then(
        (res) ->
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )

  credit: (context, credit) ->
    new Promise((resolve, reject) ->
      Credit.save(credit).then(
        (res) ->
          resolve(res.body)
      , (res) ->
        reject(res.body)
      )
    )

`export default actions`
