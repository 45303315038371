<template lang="pug">
  span
    figure.avatar(v-if="picture")
      img(:src="picture" width="50" height="50")
    figure.avatar.letter-avatar(v-else)
      span
        | {{ (user.first_name || '')[0] || 'S' }}

</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'UserPictureUi',
    props: [
      'user',
    ],
    computed: {
      ...mapGetters(['apiUrl']),
      picture() {
        if (
          this.user.picture &&
          (this.user.picture.substring(0, 4) === "data" ||
            this.user.picture.substring(0, 4) === "http")
        )
          return this.user.picture;
        else if (this.user.picture) return `${this.apiUrl}/${this.user.picture}`;
        else return null;
      }
    },
  }
</script>


<style lang="scss">
  @import 'colors';

  $figureSize: 36px;
  $imgSize: 32px;

  $figureSizeXl: 50px;
  $imgSizeXl: 46px;


  .avatar-tooltip {
    position: absolute;
    top: 8px;
    left: 8px;

    img {
      width: 30px;
      height: 30px;
      padding: 3px;
    }
    &+.avatar-tooltip {
      left: 25px;
      &+.avatar-tooltip {
        left: 42px;
        &+.avatar-tooltip {
          left: 59px;
        }
      }
    }
  }

  .letter-avatar {
    line-height: $figureSize;
    border-radius: 50%;
    text-align: center;
    color: #FFF;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    padding: 2px;
    background-color: #FFF;
    span {
      display: block;
      font-size: 19px;
      border-radius: 50%;
      line-height: $imgSize;
      background-color: $darkGray;
      margin-top: 0px;
      height: $imgSize;
      width: $imgSize;
    }
  }

  .has-size-30px {
    .avatar {
      height: 30px;
    }
    img {
      padding: 2px;
      width: 30px;
      height: 30px;
    }
    .letter-avatar {
      line-height: 30px;
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      span {
        line-height: 27px;
        height: 26px;
        width: 26px;
      }
    }
  }

</style>
