<template lang="pug">
  section.section.section--cta.is-medium
    .container
      .head
        h2.size-h1.has-text-primary.has-text-weight-bold.mb-6(
          v-scroll-reveal.reset="{ delay: 200 }"
          v-html="tagline"
          )

      .body.mt-6.has-text-centered(
        v-scroll-reveal.reset="{ delay: 400 }"
      )
        router-link.button.is-dark(
        :to="linkHref"
        ) {{ linkText }}
</template>

<script>
export default {
  name: "CtaSection",
  props: [
    'tagline',
    'linkText',
    'linkHref'
  ]
}
</script>

<style lang="scss">
.section--cta {
  background: url("~@/assets/v3/bottomCTA-bg.svg") no-repeat;
  background-size: 50%;
  background-position: center 36%;
  @media screen and (max-width: 767px) {
    background-size: 100%;
  }

  h2 {
    line-height: 1.3;
  }

  @media screen and (max-width: 767px) {
    h2 {
      line-height: 1.2;
    }
  }
}
</style>