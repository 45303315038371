<template lang="pug">
  .app-container.page--front.page--lost-password.text-main(v-farewell="onLeave")
    front-menu
    hero-section(
      title="Lost password"
      :hide-cta="true"
    )
    main.app-content.mt-5
      section
        .container
          .box.mx-auto
            form
              .message.is-danger(v-if="error")
                .message-body
                  span(v-html="error")
              b-field
                b-input#email(v-model="form.email" type="text" required="" autofocus="" size="is-medium" placeholder="Email")
              button#btn_sign_in.button.is-block.is-primary.ml-auto.is-flex(:class="{'is-loading': isLoading, 'is-success': success, 'is-disabled': isLoading || !validEmail}" @click.prevent="validEmail ? onSubmit($event) : ''")
                b-icon(icon="check" v-if="success")
                b-icon(icon="send" v-else)
                span(v-if="!success")
                  | Reset my password
                span(v-else)
                  | Check your emails
    footer-section.mt-auto
  </template>


<script>
import { User } from "@/store/models/resources";
import HeroSection from "@/components/sections/Hero";
import FooterSection from "@/components/sections/FooterSection";

export default {
  name: "LostPasswordPage",
  components: {FooterSection, HeroSection},
  data() {
    return {
      error: null,
      success: false,
      redirect: null,
      form: {
        email: ""
      },
      isLoading: false
    };
  },
  computed: {
    validEmail() {
      return this.form.email && this.form.email.match(/@/);
    }
  },
  created() {
    this.$store.dispatch("setLayout", "no-layout");
  },
  methods: {
    onSubmit(evt) {
      this.isLoading = true;

      User.resetPasswordEmail(this.form).then(
        () => {
          this.isLoading = false;
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 6000);
        },
        err => {
          // console.log(err);
          this.isLoading = false;
        }
      );
    }
  }
};
</script>


<style lang="scss">
@import 'colors';

.page--lost-password {
  background: linear-gradient(180deg, #FFF 51%, $primary 51%);

  .box {
    width: 400px;
  }

  input {
    font-weight: 300;
  }

  p {
    font-weight: 700;
  }

  p.subtitle {
    padding-top: 1rem;
  }

  a {
    text-decoration: none;
  }

}
</style>
