var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui--pronunciation-recorder"},[(_vm.browser.name === 'chrome')?_c('button',{staticClass:"button has-justified-start button-recorder is-small",class:( _obj = {
      'is-primary': !_vm.dataUrl.length,
      'has-loader is-loading': _vm.loading
      }, _obj[_vm.success] = _vm.dataUrl.length, _obj ),on:{"mousedown":function($event){$event.preventDefault();return _vm.startRecording($event)},"mouseup":function($event){$event.preventDefault();$event.stopPropagation();return _vm.endRecording($event)},"mouseout":function($event){$event.preventDefault();$event.stopPropagation();return _vm.endRecording($event)},"touchstart":function($event){$event.preventDefault();return _vm.startRecording($event)},"touchend":function($event){$event.preventDefault();return _vm.endRecording($event)}}},[_c('b-icon',{attrs:{"icon":"mic","size":"is-small"}}),_c('span',{staticClass:"p-0"},[_vm._v("Record")])],1):_c('b-tooltip',{attrs:{"label":"To record yourself, please use Chrome","type":"is-light","animated":true,"position":"is-top"}},[_c('button',{staticClass:"button is-light is-disabled is-small has-justified-start"},[_c('b-icon',{attrs:{"icon":"mic","size":"is-small"}}),_c('span',{staticClass:"p-0"},[_vm._v("Record")])],1)]),_c('div',{staticClass:"pronunciation-assessment-results-wrapper is-flex is-justify-content-center"},[(_vm.words.length)?_c('table',{staticClass:"is-size-4 pronunciation-assessment-results mt-4 mb-6"},[_c('tbody',[_c('tr',[_vm._l((_vm.words),function(word,idx){return _c('td',{key:idx,staticClass:"word pr-2"},[_c('span',{staticClass:"fullword is-clickable",class:{
                'pronunciation-assessment-color-1': word.PronunciationAssessment.AccuracyScore >= 95,
                'pronunciation-assessment-color-2': word.PronunciationAssessment.AccuracyScore < 95 && word.PronunciationAssessment.AccuracyScore >= 90,
                'pronunciation-assessment-color-3': word.PronunciationAssessment.AccuracyScore < 90 && word.PronunciationAssessment.AccuracyScore >= 75,
                'pronunciation-assessment-color-4': word.PronunciationAssessment.AccuracyScore < 75 && word.PronunciationAssessment.AccuracyScore >= 50,
                'pronunciation-assessment-color-5': word.PronunciationAssessment.AccuracyScore < 50
              },attrs:{"data-db-ipa":_vm.language === 'en-US' ? (_vm.ipa[idx] || {}).content : ''},on:{"click":function($event){return _vm.speak(word.Word, _vm.language, $event)}}},[_vm._v(_vm._s(word.Word))])])}),_c('td',[_c('div',{staticClass:"is-flex valign"},[_c('transition',{attrs:{"name":"bounce"}},[(_vm.sentenceStr && _vm.words.length)?_c('div',{staticClass:"button-sentence--listen"},[_c('tts-button',{staticClass:"sentence--listen is-flex",attrs:{"tts":_vm.sentenceStr,"tooltip":false,"icon":"hearing","lang":_vm.language}})],1):_vm._e()]),_c('transition',{attrs:{"name":"bounce"}},[(_vm.dataUrl)?_c('audio-player',{staticClass:"is-inverted is-primary ml-1",attrs:{"icon":"record-voice-over","can-delete":false,"blob":_vm.dataUrl,"label":""}}):_vm._e()],1)],1)])],2),(_vm.hasPhonemes)?_c('tr',[_vm._l((_vm.words),function(word,idx){return _c('td',{key:idx,staticClass:"word phoneme pr-2"},_vm._l((word.Phonemes),function(phoneme,i){return _c('div',{key:i,staticClass:"is-inline"},[(phoneme.PronunciationAssessment.AccuracyScore < 90 && phoneme.PronunciationAssessment.NBestPhonemes[0] && phoneme.PronunciationAssessment.NBestPhonemes[0].Phoneme != phoneme.Phoneme)?_c('b-dropdown',{attrs:{"triggers":['hover'],"position":"is-bottom-right","paddingless":""}},[_c('span',{class:{
                    'pronunciation-assessment-color-1': phoneme.PronunciationAssessment.AccuracyScore >= 95,
                    'pronunciation-assessment-color-2': phoneme.PronunciationAssessment.AccuracyScore < 95 && phoneme.PronunciationAssessment.AccuracyScore >= 90,
                    'pronunciation-assessment-color-3': phoneme.PronunciationAssessment.AccuracyScore < 90 && phoneme.PronunciationAssessment.AccuracyScore >= 75,
                    'pronunciation-assessment-color-4': phoneme.PronunciationAssessment.AccuracyScore < 75 && phoneme.PronunciationAssessment.AccuracyScore >= 50,
                    'pronunciation-assessment-color-5': phoneme.PronunciationAssessment.AccuracyScore < 50
                  },attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(_vm._s(phoneme.Phoneme))]),_c('b-dropdown-item',{attrs:{"custom":""}},[_c('div',{staticClass:"modal-card is-flex is-flex-direction-column"},[_c('p',{staticClass:"mb-0 has-text-grey-light"},[_vm._v("You said")]),_c('div',{staticClass:"is-flex mx-auto valign"},[_c('b',{staticClass:"is-size-4 pronunciation-assessment-color-5"},[_vm._v(_vm._s(phoneme.PronunciationAssessment.NBestPhonemes[0].Phoneme))]),_c('span',{staticClass:"px-2 pt-1"},[_vm._v("like in")]),_c('span',{staticClass:"is-size-4 is-clickable pronunciation-assessment-color-5 pronunciation-hint",domProps:{"innerHTML":_vm._s((_vm.hints[_vm.language] || {})[phoneme.PronunciationAssessment.NBestPhonemes[0].Phoneme])},on:{"click":function($event){return _vm.speak($event.target.innerText, _vm.language, $event)}}})]),_c('p',{staticClass:"mb-0 mt-4 has-text-grey-light"},[_vm._v("instead of")]),_c('div',{staticClass:"is-flex mx-auto valign"},[_c('b',{staticClass:"is-size-4 pronunciation-assessment-color-1"},[_vm._v(_vm._s(phoneme.Phoneme))]),_c('span',{staticClass:"px-2 pt-1"},[_vm._v("like in")]),_c('span',{staticClass:"is-clickable pronunciation-assessment-color-1 is-size-4 pronunciation-hint",domProps:{"innerHTML":_vm._s((_vm.hints[_vm.language] || {})[phoneme.Phoneme])},on:{"click":function($event){return _vm.speak($event.target.innerText, _vm.language, $event)}}})])])])],1):_c('span',{class:{
                    'pronunciation-assessment-color-1': phoneme.PronunciationAssessment.AccuracyScore >= 95,
                    'pronunciation-assessment-color-2': phoneme.PronunciationAssessment.AccuracyScore < 95 && phoneme.PronunciationAssessment.AccuracyScore >= 90,
                    'pronunciation-assessment-color-3': phoneme.PronunciationAssessment.AccuracyScore < 90 && phoneme.PronunciationAssessment.AccuracyScore >= 75,
                    'pronunciation-assessment-color-4': phoneme.PronunciationAssessment.AccuracyScore < 75 && phoneme.PronunciationAssessment.AccuracyScore >= 50,
                    'pronunciation-assessment-color-5': phoneme.PronunciationAssessment.AccuracyScore < 50
                  }},[_vm._v(_vm._s(phoneme.Phoneme))])],1)}),0)}),_c('td')],2):_c('tr',_vm._l((_vm.words),function(word,idx){return _c('td',{key:idx,staticClass:"word phoneme pr-2"},_vm._l((word.Phonemes),function(phoneme,i){return _c('span',{key:i,class:{
                'pronunciation-assessment-color-1': phoneme.PronunciationAssessment.AccuracyScore >= 95,
                'pronunciation-assessment-color-2': phoneme.PronunciationAssessment.AccuracyScore < 95 && phoneme.PronunciationAssessment.AccuracyScore >= 90,
                'pronunciation-assessment-color-3': phoneme.PronunciationAssessment.AccuracyScore < 90 && phoneme.PronunciationAssessment.AccuracyScore >= 75,
                'pronunciation-assessment-color-4': phoneme.PronunciationAssessment.AccuracyScore < 75 && phoneme.PronunciationAssessment.AccuracyScore >= 50,
                'pronunciation-assessment-color-5': phoneme.PronunciationAssessment.AccuracyScore < 50
              }},[_vm._v(_vm._s(_vm._f("splitByPhoneme")(((_vm.ipa[idx] || {}).content || ""),i))),(i == (word.Phonemes.length - 1))?_c('span',[_vm._v(_vm._s(_vm._f("remainingSplitByPhoneme")(((_vm.ipa[idx] || {}).content || ""),i)))]):_vm._e()])}),0)}),0)])]):_vm._e()]),_c('transition',{attrs:{"name":"bounce"}},[(_vm.pronunciationScore || _vm.pronunciationScore === 0)?_c('b-tooltip',{staticClass:"mt-4 mx-auto score",class:{
              'bigger-number': _vm.pronunciationScore < 100,
              'pronunciation-assessment-color-1': _vm.pronunciationScore >= 95,
              'pronunciation-assessment-color-2': _vm.pronunciationScore < 95 && _vm.pronunciationScore >= 90,
              'pronunciation-assessment-color-3': _vm.pronunciationScore < 90 && _vm.pronunciationScore >= 75,
              'pronunciation-assessment-color-4': _vm.pronunciationScore < 75 && _vm.pronunciationScore >= 50,
              'pronunciation-assessment-color-5': _vm.pronunciationScore < 50
            },attrs:{"label":_vm.scoreLabel,"type":"is-dark","position":"is-bottom"}},[_vm._v(_vm._s(_vm.pronunciationScore))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }