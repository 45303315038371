<template lang="pug">
  .app-container.page-for-teachers.text-main(v-farewell="onLeave")
    front-menu
    main.app-content

      //- Hero
      hero-section(
        topic="FOR STUDENTS"
        title="Teachee helps you<br>learn in a smarter way"
        scroll-anchor="how_it_works_student"
      )

      how-it-works-student-section(
        topic="USE TEACHEE AS A STUDENT"
      )

      features-section(
        topic="TEACHEE HELPS YOU LEARN LANGUAGES"
        content="student"
        mode="light"
      )

      cta-section(
        tagline="Learn pronunciation<br>like magic"
        link-text="Create your first lesson"
        link-href="/sign_up"
      )

    footer-section
</template>

<script>
import footerSection from "@/components/sections/FooterSection";
import askToSubscribeOnLeave
  from '@/components/behaviors/AskToSubscribeOnLeave';

import CtaSection from "@/components/sections/Cta";
import {
  noLayoutAndStorePartnerInfo,
  ssrFinishedRendering
} from "@/utils";
import HowItWorksStudentSection
  from "@/components/sections/HowItWorksStudent";
import FeaturesSection from "@/components/sections/Features";
import HeroSection from "@/components/sections/Hero";

export default {
  name: 'ForTeachersPage',
  mixins: [
    askToSubscribeOnLeave,
  ],
  components: {
    HeroSection,
    FeaturesSection,
    HowItWorksStudentSection,
    CtaSection,
    footerSection
  },
  mounted() {
    noLayoutAndStorePartnerInfo(this.$store, this.$route, this.$cookie);
    ssrFinishedRendering();

    if (this.$analytics && this.$analytics.fbq && this.$analytics.fbq.event) {
      this.$analytics.fbq.event("ViewContent", {
        content_name: 'Students Page'
      })
    }
  },
}
</script>


<style lang="scss">
@import 'colors';

.page-for-teachers {
  .hero-body {
    background: url("~@/assets/v3/bottomCTA-bg.svg") no-repeat;
    background-size: 50%;
    background-position: center 51%;
  }

  .section--how-it-works-teacher {
    p,
    h3,
    h2 {
      color: $primary !important;
    }

    .tab-selector {
      span {
        color: $primary;

        &.selected {
          background-color: $primary;
          color: #FFF
        }
      }
    }
  }
}


</style>
