<template lang="pug">
  .box.has-shadow.pb-5(v-if="user.counter && user.counter.data && !isEmpty(user.counter.data)")
    .my-progress
      h3.title.has-text-left
        | Progress
      .charts-wrapper
        .columns.is-multiline
          .column.is-4.p-0(:class="{'shows-gt-2': languageNb > 2}")
            user-picture.user-picture(:user="user" v-if="user.picture")
            word-counts-doughnut.ml-3(
              :user="user"
              frequency="week"
              :height="200"
              :width="200"
              )
          .column.is-8.pr-3
            b-tooltip(
            label="Total of unique words per week"
            type='is-light'
            :animated='true'
            position='is-bottom'
            )
              word-counts-chart(
              :user="user"
              frequency="week"
              :height="200"
              )

</template>

<script>
  import {mapGetters} from 'vuex'
  import wordCountsChart from "@/charts/WordCountsChart";
  import wordCountsDoughnut from "@/charts/WordCountsDoughnut";
  import userPicture from "@/components/UserPictureUI";
  import isEmpty from "lodash/isEmpty";
  import keys from 'lodash/keys';

  export default {
    name: "Progress",
    components: {
      wordCountsChart,
      wordCountsDoughnut,
      userPicture,
      alert
    },
    computed: {
      ...mapGetters(['user']),
      languageNb() {
        if (!this.user.counter.data) {
          return 0;
        }
        return keys(this.user.counter.data).length
      }
      ,
    },
    data() {
      return {
        isEmpty
      };
    },
    mounted() {},
    methods: {

    }
  };
</script>

<style lang="scss" scoped>
  .charts-wrapper {
    min-height: 323px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
  }

  .user-picture {
    display: none
  }
  @media (min-width: 1281px) {
    .user-picture {
      transform: scale(1);
      margin: 50px auto;
      display: block;
      & + div {
        z-index: 2;
        position: relative;
        margin: -206.5px auto;
        display: block;
      }
    }
    .shows-gt-2 {
      .user-picture {

      }
    }
  }
</style>
