<template lang="pug">
  #modal_quick_setup.modal.is-active.modal-payment
    .modal-background
    .modal-card

      header.modal-card-head.has-flex-center-items.valign
        h2.title.is-size-5.mb-0.mr-5
          | Welcome to Teachee!


      section.modal-card-body

        .is-flex.w-100.has-justified-center.mt-2(v-if="!role.teacher && !role.student")
          button.button.mx-2.is-primary(@click="role.teacher = true") I'm a teacher
          button.button.mx-2.is-info(@click="role.student = true") I'm a student
          button.button.mx-2.is-warning(@click="role.teacher = true;role.student = true") I'm both


        .field.has-addons.valign(v-if="role.teacher")
          label.label.mr-2.mb-0 I teach:
          language-selector(
          :showChoose="!lastTeaches"
          @select="addTeaches"
          :language="lastTeaches"
          )

        button.button.mr-2(@click="removeFromTeaches(lang)" v-for="lang in teaches_languages")
          i.flag.mr-2(:class="'flag-'+lang")
          b-icon.has-text-grey(icon="remove-circle-outline")

        .field.has-addons.valign(v-if="role.student" :class="{'mt-4': role.teacher}")
          label.label.mr-2.mb-0 I learn:
          language-selector(
          :showChoose="!lastLearns"
          @select="addLearns"
          :language="lastLearns"
          )

        button.button.mr-2(@click="removeFromLearns(lang)" v-for="lang in learns_languages")
          i.flag.mr-2(:class="'flag-'+lang")
          b-icon.has-text-grey(icon="remove-circle-outline")


      footer.modal-card-foot
        button.button.is-primary.is-inline-block.ml-auto(
          :class="{'is-loading': loading}"
          :disabled="loading"
          @click="save"
        ) Save

    button.modal-close.is-large(aria-label="close" @click="$emit('close')")

</template>

<script>
import last from 'lodash/last';
import uniq from 'lodash/uniq';
import languageSelector from "@/components/LanguageSelector";
import { mapGetters } from "vuex";

export default {
  name: 'QuickSetupModal',
  components: {
    languageSelector
  },

  props: [
  ],

  computed: {
    ...mapGetters(["user"]),
    lastTeaches() {
      return last(this.teaches_languages)
    },
    lastLearns() {
      return last(this.learns_languages)
    }
  },

  data() {
    return {
      teaches_languages: [],
      learns_languages: [],
      learn: '',
      loading: false,
      correction: '',
      role: {
        teacher: false,
        student: false,
      }
    };
  },
  mounted() {
    // setTimeout(() => {
    //   document.getElementById('correction_input').focus()
    // }, 400)
  },
  created() {
  },
  methods: {
    addTeaches(lang) {
      this.teaches_languages.push(lang)
      this.teaches_languages = uniq(this.teaches_languages)
    },
    addLearns(lang) {
      this.learns_languages.push(lang)
      this.learns_languages = uniq(this.learns_languages)
    },
    removeFromTeaches(lang) {
      const idx = this.teaches_languages.indexOf(lang);
      this.teaches_languages.splice(idx, 1);
    },
    removeFromLearns(lang) {
      const idx = this.learns_languages.indexOf(lang);
      this.learns_languages.splice(idx, 1);
    },
    save() {
      this.loading = true;
      const {teaches_languages, learns_languages} = this;
      const user = this.$auth.user();

      this.$store.dispatch("saveUser", Object.assign({}, user, {
        teaches_languages,
        learns_languages,
        is_teacher: !!teaches_languages[0]
      })).then(data => {
        this.$auth.user(data.user);
        this.loading = false;
        this.$emit('close')
      })
    }
  }
};
</script>

<style lang="scss">
@import "colors";

.modal-background {
  background-color: rgba(10, 10, 10, 0.36);
}

.modal-card,
.modal-card-body {
  overflow: visible;
}

#modal_quick_setup .dropdown-menu {
  max-height: 300px;
  overflow: auto;
}

</style>
