<template lang="pug">
  section#pronunciation_try.section.section--pronunciation-try.is-small
    .container

      .head.mb-6.mt-5.has-text-centered
        p.text-main.is-size-6.mb-0.opacity-8 EXPERIENCE THE MAGIC
        h2.size-h2.mb-6 Test your pronunciation

      .body.mt-6.pt-6
        p.subtitle.is-size-6.w-70.has-text-light.m-auto.mb-5
          | Click on record & keep the click pressed while you speak.
          br
          | Release the click when you're done speaking.

        .has-bg-white.demo-wrapper.has-text-centered.px-6.py-4.pb-5.mb-5
          div.mt-4.m-auto.is-flex.is-flex-direction-column
            b-dropdown#pr_legend.ml-auto(
              v-if="result"
              position="is-bottom-left"
              paddingless
            )
              a#pr_legend_info.button.is-white.card-header-icon.has-text-grey(slot="trigger")
                b-icon(icon="info" custom-size="is-size-4")
              b-dropdown-item(
                custom
                paddingless
                )
                .modal-card(style="width:500px;")
                  .content.p-5
                    //- h3.h3.has-text-left Legend
                    //- Colors

                    h3.has-text-left Color code
                    dl
                      dt
                        span.color-square.pronunciation-assessment-color-1
                      dd
                        | Perfect
                        small.has-text-grey.ml-2 Pronunciation Score superior to 95

                      dt
                        span.color-square.pronunciation-assessment-color-2
                      dd
                        | Great
                        small.has-text-grey.ml-2 Pronunciation Score between 90 and 95

                      dt
                        span.color-square.pronunciation-assessment-color-3
                      dd
                        | Rather good
                        small.has-text-grey.ml-2 Pronunciation Score between 75 and 90

                      dt
                        span.color-square.pronunciation-assessment-color-4
                      dd
                        | Average
                        small.has-text-grey.ml-2 Pronunciation Score between 50 and 75

                      dt
                        span.color-square.pronunciation-assessment-color-5
                      dd
                        | Mispronounced
                        small.has-text-grey.ml-2 Pronunciation Score below 50


                    h3.has-text-left Interactions
                    p.has-text-left.mb-0
                      | Click on a word to listen to it
                    p.has-text-left.mb-0
                      | Shift+Click on a word to listen to it slowly
                    p.has-text-left
                      | Hover mispronounced sounds to see more detail

                    h3.has-text-left Phonetic
                    p.has-text-left
                      | We use the International Phonetic Alphabet to show you speech sounds in written form
                      br
                      | /ˈ/ indicates that the primary stressed syllable follows and /ˌ/ indicates the secondary stressed syllable follows.

            .is-flex.mb-3.valign.mx-auto.pronunciation-input-wrapper
              language-selector(
                @select="setPrLessonLanguageTo"
                :language="pronunciationLanguageTo"
              )
              input.input(v-model="pronunciationText")
            pronunciation-recorder(
              v-model="result"
              :language="pronunciationLanguageTo"
              :sentence-str="pronunciationText"
              success-class="is-dark is-outlined"
            )
              //-@input="showInfoIfNeeded"
              //-template(v-slot:default="{blob}")
                .is-flex.mb-3.valign
                  audio-player.is-inverted.is-primary.mb-1.mt-1.mx-auto( :can-delete="false" :blob="blob" label="Play" )

</template>

<script>

import {mapGetters} from "vuex";
import Ipa from "@/components/Ipa";
import LanguageSelector from "../LanguageSelector";
import Recorder from "../Recorder";
import AudioPlayer from "../AudioPlayer";
import PronunciationRecorder from "../PronunciationRecorder";
import {DEFAULT_PR_SENTENCES} from "../../constants";

export default {
  name: "PronunciationTrySection",
  components: {
    PronunciationRecorder,
    AudioPlayer, Recorder, LanguageSelector, Ipa
  },
  computed: {
    ...mapGetters(["apiUrl"]),
  },
  data() {
    return {
      pronunciationText: "I say the word communication",
      pronunciationLanguageTo: "en-US",
      translating: false,
      DEFAULT_PR_SENTENCES,
      hideLanguages: [''],
      result: null
    }
  },
  created() {

  },
  mounted() {
    // this.useBrowserLanguage();
  },
  methods: {
    // useBrowserLanguage() {
    //   const language = window.navigator.userLanguage || window.navigator.language;
    //   const defaultLg = Object.keys(DEFAULT_PR_SENTENCES).find(key => key.includes(language));
    //   if (defaultLg) {
    //     this.setPrLessonLanguageTo(defaultLg);
    //   }
    // },
    setPrLessonLanguageTo(value) {
      this.pronunciationLanguageTo = value;
      this.pronunciationText = DEFAULT_PR_SENTENCES[value];
    },
    showInfoIfNeeded() {
      setTimeout(() => {
        if (!this.$localStorage.get('pronunciationInfoShow')) {
          this.$localStorage.set('pronunciationInfoShow', 1);
          document.getElementById("pr_legend_info").click();
        }
      }, 400)
    }
  }
}
</script>

<style lang="scss">
@import "colors";

.section--pronunciation-try {
  background-color: $primaryDarker;
  color: $primary;
  .body {
  }

  dl {
    display: flex;
    flex-wrap: wrap;
  }

  .pronunciation-input-wrapper {
    width: 100%;
    max-width: 700px;
  }


  dd {
    width: 96%;
    text-align: left;
    margin-left: 0.5rem;
  }

  .color-square {
    display: inline-block;
    width: 9px;
    height: 9px;

    &.pronunciation-assessment-color-1 { background-color: $resultScore5}
    &.pronunciation-assessment-color-2 { background-color: $resultScore4}
    &.pronunciation-assessment-color-3 { background-color: $resultScore3}
    &.pronunciation-assessment-color-4 { background-color: $resultScore2}
    &.pronunciation-assessment-color-5 { background-color: $resultScore1}

  }
}
</style>

