<template lang="pug">
  .modal.is-active.modal--edit-lesson-access
    .modal-background
    .modal-card

      header.modal-card-head.has-flex-center-items.valign
        h2.title.is-size-5.mb-0.mr-5
          | Edit access

      section.modal-card-body
        b-table.w-100(
          :pagination-simple="true"
          :loading="loading"
          :data="visitedLessons"
        )
          template(#empty="scope")
            span.has-text-grey.mt-3.is-block
              | No contact is associated to the lesson

          b-table-column(v-slot="props" field="first_name" label="User")
            .is-flex.valign
              span.mr-2 {{ props.row.user.first_name }}
              user-picture-ui(:user='props.row.user')
          b-table-column(v-slot="props" field="action" label="")
            button.button.is-danger.is-small(
              v-confirm="`Are you sure you want to <b>remove the association</b>?<br><small>The lesson won't appear anymore in the student's lessons but he/she will be able to open it again if he/she saved the link somewhere</small>`"
              @click="remove(props.row.id)"
            ) Remove association for this user

      footer.modal-card-foot
        search-student.is-fullwidth(
          :key="newAssociation.student_id"
          placeholder="Add a student"
          @input="add"
          v-model="newAssociation.student_id"
        )

        button.button.is-primary.is-outlined.ml-auto(
          @click="$emit('close')"
        ) Close

    button.modal-close.is-large(aria-label="close" @click="$emit('close')")
</template>

<script>
// import { mapGetters } from "vuex";

import {VisitedLesson} from "@/store/models/resources";
import UserPictureUi from "@/components/UserPictureUI";
import SearchStudent from "@/components/SearchStudent";

export default {
  name: 'EditLessonAccessModal',
  components: {SearchStudent, UserPictureUi},

  props: [
    'lesson',
  ],

  data() {
    return {
      loading: false,
      visitedLessons: [],
      newAssociation: {
        student_id: null
      }
    };
  },
  created() {
    this.fetchAssociations();
    this.$store.dispatch('getStudents');
  },
  methods: {
    fetchAssociations() {
      VisitedLesson.index({id: this.lesson.id}, {}).then((res) => {
        this.visitedLessons = res.body.visitedLessons
      })
    },
    add(studentId) {
      console.log({ studentId })

      VisitedLesson.save({visited_lesson: {
          user_id: studentId,
          lesson_id: this.lesson.id
        }}).then(() => {
        this.fetchAssociations();
        this.newAssociation.student_id = null
      })
    },
    remove(id) {
      VisitedLesson.delete({id}).then(() => {
        this.visitedLessons = this.visitedLessons.filter(vl => vl.id != id)
      })
    }
  }
};
</script>

<style lang="scss">
@import "colors";

.modal--edit-lesson-access {
  .modal-card {
    width: 500px
  }
  .table-wrapper {
    max-height: 320px;
    overflow: auto;
  }
  ul.box {
    position: absolute;
    max-width: 313px;
    z-index: 10;
    li.active {
      color: $primary;
    }
  }
}
</style>
