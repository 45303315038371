<template lang="pug">
  .container
    .nps-card.column.m-auto
      .box
        h2.title.has-text-left Share your thoughts
        nps-box(:in-page="true")


</template>

<script>
  import { mapGetters } from "vuex";
  import npsBox from "@/components/NpsBox";

  export default {
    name: "NpsPage",
    components: {
      npsBox
    },
    data() {
      return {
      };
    },
    created() {
      this.$store.dispatch("setLayout", "logged-in-layout");
    },
    methods: {
    }
  };
</script>


<style lang="scss" scoped>
.nps-card {
  width: 770px;
}
</style>
