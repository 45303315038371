<template lang="pug">
  section#try_it_out.section.section--try-it-out.is-small
    .container

      .head.mb-6.mt-5.has-text-centered
        p.text-main.is-size-6.mb-0.opacity-8 EXPERIENCE THE MAGIC
        h2.size-h2.mb-6 Try it out

      .body.mt-6.pt-6
        p.subtitle.is-size-6.w-70.m-auto.has-text-light.mb-5
          | Click on record & keep the click pressed while you speak.
          br
          |Release the click when you're done speaking.

        .has-bg-white.demo-wrapper.has-text-centered.px-6.py-4.pb-5(
          :class=`{
              'is-unused': !sentence.content,
              'show-explanations': animate,
            }`
        )
          .is-inline-flex.has-flex-center-items.m-auto
            language-selector(
              @select="setLessonLanguageTo"
              :language="languageTo"
            )
            span.mr-1.has-text-dark.is-clickable(@click="switchLanguages") ➡︎
            language-selector(
              @select="setLessonLanguageFrom"
              :language="languageFrom"
            )

          .flex.has-flex-center-items.m-auto.is-justify-content-center.mt-2.is-relative
            recorder.recorder.mb-1(
              :label="'Record'"
              size="is-small"
              :user="{}"
              :language="languageTo"
              success-class="is-dark is-outlined"
              speech-recognition="true"
              @voice-recognition-result="fillContentBasedOnSpeech"
              :text="sentence.content"
              :audio="sentence.audio[0]")
              template(v-slot:default="{blob}")
                audio-player.is-inverted.is-primary.mb-1.mt-1(
                  :can-delete="false"
                  :blob="blob"
                  label="Play"
                )

            transition(name="bounce")
              .sentence.has-text-left.ml-5(v-if="sentence.content.length")
                div.sentence__content
                  | {{ sentence.content }}
                  .sentence__content_explanations(
                  )
                    img(src="@/assets/extra/arrow.svg")
                    span.has-text-primary
                      | Speech-to-Text
                div.sentence__ipa
                  ipa(
                    v-for="word in sentence.words"
                    :user="{}"
                    :key="word.idx"
                    :lang="languageTo"
                    :tts="word.tts"
                    :title="word.variations ? word.variations.join(' / ') : ''"
                    :content="word.content"
                    )
                  .sentence__ipa_explanations(
                    )
                    img(src="@/assets/extra/arrow.svg")
                    span.has-text-primary
                      | Phonetic. Click to listen to the word
                div.sentence__translation
                  | {{ sentence.translation }}
                  .sentence__translation_explanations(
                  )
                    img(src="@/assets/extra/arrow.svg")
                    span.has-text-primary
                      | Automatic translation

            //-button.button.is-primary( :class="{loading: translating}" @click="translate") Translate

        //-router-link.button.is-primary.mt-6( to="/sign_up" ) Join for free
        //-pronunciation-recorder(
          language="en-US"
          sentence-str="How well do you speak?"
          success-class="is-dark is-outlined")

</template>

<script>

import {mapGetters} from "vuex";
import Ipa from "@/components/Ipa";
import LanguageSelector from "../LanguageSelector";
import {Sentence} from '@/store/models/resources';
import Recorder from "../Recorder";
import AudioPlayer from "../AudioPlayer";
import {LATIN_LANGUAGES} from "../../constants";
import PronunciationRecorder from "../PronunciationRecorder";

export default {
  name: "TryItOutSection",
  components: {
    PronunciationRecorder,
    AudioPlayer, Recorder, LanguageSelector, Ipa},
  computed: {
    ...mapGetters(["apiUrl"]),
  },
  data() {
    return {
      languageTo: "en-GB",
      languageFrom: "fr-FR",
      sentence: {
        content: "",
        literal_translation: "",
        translation: "",
        audio: [],
        words: []
      },
      translating: false,
      model: {
        id: 1
      },
      animate: false
    }
  },
  created() {

  },
  methods: {
    setLessonLanguageTo(value) {
      if (this.languageFrom === value) {
        this.switchLanguages()
      } else {
        this.languageTo = value
      }
    },
    setLessonLanguageFrom(value) {
      if (this.languageTo === value) {
        this.switchLanguages()
      } else {
        this.languageFrom = value
      }
    },
    translate() {
      this.translating = true

      Sentence.demoTranslate({}, {
        sentence: this.sentence,
        language_from: this.languageFrom,
        language_to: this.languageTo
      }).then(res => {
        this.sentence = Object.assign({}, res.body.sentence)
        this.translating = false
      })
    },
    onAudioCreated() {
    },
    voiceRecognitionResultHandler() {
    },
    fillContentBasedOnSpeech(words) {
      this.sentence.content = words.join(' ');
      const {content} = this.sentence;
      if (content.length && LATIN_LANGUAGES.includes(this.languageTo)) {
        this.sentence.content = content[0].toLocaleUpperCase(this.languageTo) + content.substring(1)
      }
      setTimeout(() => {
        this.animate = true
      }, 1500);
      this.translate();
    },
    switchLanguages() {
      const lgTo = this.languageTo;
      this.languageTo = this.languageFrom;
      this.languageFrom = lgTo;
    }
  }
}
</script>

<style lang="scss">
@import "colors";

.section--try-it-out {
  background-color: $primaryDarker;
  color: $primary;

  .w-70 {
    width: 70% !important;
  }

  .ui--recorder .button {
    width: 139px;
    .is-small i {
      font-size: 20px;
    }
  }

  .sentence {
    color: $primaryDarker;
    margin-top: -7px;
    .sentence__translation,
    .sentence__content {
      position: relative;
      font-family: "Montserrat", "SF Pro Text", "Helvetica Neue", "Helvetica", sans-serif;
      font-weight: 700;
      transition: color 0.5s ease;
    }

    .sentence__content {
      font-size: 20px;
    }
    .sentence__ipa {
      position: relative;
      font-size: 18px;
    }

    .sentence__translation {
      font-weight: 300;
    }
  }

  .is-unused .button {
    font-size: 1.25rem;
  }

  .sentence__ipa_explanations,
  .sentence__translation_explanations,
  .sentence__content_explanations {
    transition: all 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    transform: translateX(10%);
    opacity: 0;
    position: absolute;
    left: 100%;
    width: 650px;
    bottom: 102%;
    img {
      vertical-align: top;
      width: 140px;
      margin-top: 26px;
      transform: rotate(-20deg);
    }
    span {
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 40px;
      display: inline-block;
      vertical-align: top;
      margin-left: 0.5rem;
      background-color: #1b1a45;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }
  .sentence__ipa_explanations {
    transition-delay: .3s;
    bottom: initial;
    top: -12px;
    left: 105%;
    img {
      margin-top: -3px;
      transform: rotate(-1deg);
      width: 160px;
    }
  }

  .show-explanations {
    .sentence__ipa_explanations,
    .sentence__translation_explanations,
    .sentence__content_explanations {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  .sentence__translation_explanations {
    transition-delay: .6s;
    bottom: initial;
    top: 29px;

    img {
      margin-top: -15px;
      transform: rotate(12deg);
    }
  }

  .demo-wrapper {
    width: 100%;
    max-width: 600px;
    margin: auto;
  }
}
</style>

