<template lang="pug">
  .box.has-shadow.pb-4.h-100
    h3.title.has-text-left.mb-3
      | Bookings

    .has-position-relative.w-100.h-100
      .has-loader.is-primary.is-outlined.is-loading.has-margin-auto.primary-loader(v-if="querying")
      calendar.is-in-box.h-100(
      :limit="5"
      v-model="querying"
      )

</template>

<script>
  import {mapGetters} from 'vuex'
  import userPicture from "@/components/UserPictureUI";
  import { Event } from "@/store/models/resources";
  import each from "lodash/each";
  import sortBy from "lodash/sortBy";
  import calendar from "@/components/Calendar";

  export default {
    name: "BookingsBox",
    components: {
      userPicture,
      calendar
    },
    computed: {
      ...mapGetters(["user"]),
    },
    data() {
      return {
        querying: true,
      };
    },
    mounted() {
    },
    methods: {

    }
  };
</script>

<style lang="scss" scoped>

  .has-loader {
    opacity: 0.8 !important;
    position: absolute !important;
    left: 0;
    top: -3%;
    width: 100%;
    height: 96%;
    font-size: 2rem;
    background-color: rgba(255, 255, 255, 0.5);
    display: block;
    z-index: 2;
  }
</style>
