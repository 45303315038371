<template lang="pug">
  .container
    .box.new-package-page.has-shado
      h3.title.has-text-left
        | {{ (pack.id) ? 'Edit package' : 'New package' }}
      .columns
        .column
          .columns.has-text-left
            .column
              b-field.has-text-left.mb-4(label="Name")
                b-input#name(v-model="pack.name" icon="short-text")
            .column.is-narrow
              b-field.has-text-left.mb-4(label="Price")
                b-input#price(
                  v-model="pack.price"
                  :icon="currencyIcon"
                  )
            .column.pack-language-selectors.is-narrow
              b-field.has-text-left.mb-4(label="Languages")
                .has-flex-center-items
                  language-selector.is-fullwidth(
                    @select="setLanguageFrom"
                    :language="pack.language_from"
                  )
                  b-icon(icon="arrow-forward")
                  language-selector.is-fullwidth(
                    @select="setLanguageTo"
                    :language="pack.language_to"
                  )
          b-field.has-text-left.is-fullwidth(label="Description")
            b-input#description(
            v-model="pack.description"
            type="textarea"
            )
      p.label.has-text-left
        | Content
      .columns
        transition(name="slide-out-margin")
          .column.is-one-third(v-if="step == 0")
            aside.menu.has-text-left
              p.menu-label Pick lessons
              b-input.mb-3(type="search" icon="search" v-model="search")
              transition-group(
              name="slide-right"
              tag="ul"
              class="menu-list")
                li.mb-1(v-for="lesson in sortedLessons" :key="lesson.id")
                  span.is-flex.has-flex-center-items
                    button.is-white.button.has-flex-grow-1.has-flex-start-items.has-justify-start(@click="pick(lesson)")
                      i.flag.mr-2(:class="'flag-'+lesson.language_to")
                      | {{ lesson.title }}
                    span.actions.ml-auto.has-flex-center-items
                      a.button.is-small.is-inline-block.ml-2.mr-1.is-white(:href="'/lessons/'+lesson.uuid" target="_blank")
                        b-icon(icon="remove-red-eye" custom-size="is-size-6")
                      button.button.is-small.ml-auto.is-primary.is-outlined(@click="pick(lesson)")
                        b-icon(icon="keyboard-arrow-right" custom-size="is-size-6")
        .column.is-one-third
          aside.menu.has-text-left
            p.menu-label Picked lessons
            draggable(v-model="pack.lessons")
              transition-group(
              name="slide-left"
              tag="ul"
              class="menu-list")
                li.mb-1(
                v-for="lesson in pack.lessons"
                :key="lesson.id"
                )
                  span.is-flex.has-flex-center-items
                    button.is-white.button.has-flex-grow-1.has-flex-start-items.has-justify-start(@click="(step == 0) ? unpick(lesson) : ''")
                      i.flag.mr-2(:class="'flag-'+lesson.language_to")
                      | {{ lesson.title }}
                    span.actions.ml-auto
                  p.has-loader.is-primary.is-loading.ml-4.primary-loader.my-3.has-text-left.is-inline-block(v-if="lesson.loading")
                  ul.menu-list(
                  v-if="lesson.sentences && lesson.sentences.length && !lesson.loading"
                  )
                    draggable(
                    @end="rerender"
                    v-model="lesson.sentences")
                      li(
                      v-for="sentence in lesson.sentences"
                      :key="sentence.id"
                      :class="{discarded: sentence.discarded}")
                        span.is-flex.has-flex-center-items
                          span.has-flex-grow-1.has-flex-start-items.has-justify-start.sentence--content
                            | {{ sentence.content }}
                          span.actions.ml-auto(v-if="!pack.id")
                            button.button.is-small.is-white.has-smooth-animation(
                            @click="toggleDiscard(sentence)"
                            :class="{'btn-add': sentence.discarded}"
                            )
                              b-icon(
                              :icon="sentence.discarded ? 'close' : 'close'"
                              custom-size="is-size-6"
                              :type="sentence.discarded ? 'is-grey' : 'is-danger'"
                              )
              div.is-flex(
              slot="footer"
              )
                button.is-narrow.mt-3.mr-1.button.is-small.is-fullwidth.is-outlined(
                v-if="step == 1"
                @click="step = 0"
                :disabled="!pack.lessons.length"
                )
                  b-icon(icon="keyboard-arrow-left")
                button.mt-3.button.is-primary.is-small.is-fullwidth.is-outlined.has-flex-grow-1(
                @click="finalize"
                v-if="step == 0"
                :disabled="!pack.lessons.length"
                ) Next
                button.mt-3.button.is-primary.is-small.is-fullwidth.is-outlined.has-flex-grow-1(
                :class="{'is-loading': loading}"
                @click="save"
                v-if="step == 1"
                :disabled="!pack.lessons.length || loading"
                ) Save


</template>


<script>
  import draggable from 'vuedraggable'
  import userPicture from "@/components/UserPictureUI";
  import {freeStudentLimit} from "@/config";
  import {mapGetters} from "vuex";
  import alert from "@/components/Alert";
  import {Lesson} from "@/store/models/resources";
  import lessonsList from "@/components/LessonsList";
  import languageSelector from "@/components/LanguageSelector";
  import isEmpty from "lodash/isEmpty";
  import filter from "lodash/filter";
  import reject from "lodash/reject";
  import includes from "lodash/includes";
  import toLower from "lodash/toLower";
  import findIndex from "lodash/findIndex";
  import sortBy from "lodash/sortBy";

  export default {
    name: "NewPackPage",
    components: {
      lessonsList,
      userPicture,
      languageSelector,
      draggable,
      alert
    },
    data() {
      return {
        isEmpty,
        step: 0,
        alert: true,
        loading: false,
        search: "",
        pack: {
          language_from: null,
          language_to: null,
          lessons: []
        },
      };
    },

    computed: {
      ...mapGetters(["lessons", "user"]),
      currencyIcon() {
        switch (this.user.currency) {
          case 'USD':
            return 'attach-money';
            break;
          case 'GDP':
            return '';
            break;
          default:
            return 'euro-symbol';
        }
      },
      sortedLessons() {
        const ids = this.pack.lessons.map((less) => less.id);
        return filter(reject(sortBy(this.lessons, 'language_to'), (less) => ids.indexOf(less.id) !== -1), (lessFilter) => {
          return includes(toLower(lessFilter.title || ""), this.search)
        });
      }
    },
    created() {
      const { id } = this.$route.params;

      this.$store.dispatch("getLessons", this.$auth.user());

      if (id) {

        this.$store
          .dispatch("findPack", { id })
          .then((pack) => {
            this.pack = pack;
            this.step = 1;
            this.querying = false
          }, () => {
            this.querying = false
          });

      }

    },
    methods: {
      setLanguageFrom(newValue) {
        this.pack.language_from = newValue;
      },
      setLanguageTo(newValue) {
        this.pack.language_to = newValue;
      },
      rerender() {
        this.$forceUpdate();
      },
      toggleDiscard(sentence) {
        this.$set(sentence, 'discarded', !sentence.discarded);
        this.rerender();
      },
      unpick(lesson) {
        const idx = findIndex(this.pack.lessons, {id: lesson.id});
        this.pack.lessons.splice(idx, 1);
      },
      pick(lesson) {
        this.pack.lessons.push(lesson);
      },
      finalize() {
        this.pack.lessons.forEach((less, i) => {
          // console.log("INDEX IS ", i);
          this.$set(less, 'loading', true);
          Lesson.get({id: less.uuid}).then((response) => {
            const {sentences} = response.body;
            less.sentences = sentences.map((sent) => Object.assign(sent, {discarded: false}));
            // this.$set( this.pack.lessons[i], 'sentences', []);
            // for (let sentence of sentences) {
            //   this.pack.lessons[i].sentences.push(Object.assign({}, sentence));
            // }
            // this.$set(
            //   this.pack.lessons[i],
            //   'sentences',
            //   response.body.sentences.map((sent) => Object.assign(sent, {discarded: false}))
            // );
            this.$set(less, 'loading', false);
          });
        });
        this.step = 1;
      },
      save() {
        this.loading = true;
        this.$store.dispatch("savePack", this.pack).then((pack) => {
          this.$router.push(`/packages/${pack.id}`)
        });
      }
    }
  };
</script>


<style lang="scss">
  @import 'colors';

  .new-package-page {
    min-height: 130vh;
    .slide-right-enter-active, .slide-right-leave-active {
      transition: transform .4s ease, opacity .4s ease;
    }
    .slide-right-enter {
      transform: translateX(-30px);
      opacity: 0;
    }
    .slide-right-leave-to {
      transform: translateX(30px);
      opacity: 0;
    }

    .slide-left-enter-active, .slide-left-leave-active {
      transition: transform .4s ease, opacity .4s ease;
    }
    .slide-left-enter {
      transform: translateX(-30px);
      opacity: 0;
    }
    .slide-left-leave-to {
      transform: translateX(-30px);
      opacity: 0;
    }

    .slide-out-margin-leave-active {
      transition: margin-left .4s ease, opacity .4s ease;
    }
    .slide-out-margin-leave-to {
      margin-left: -33.33333%;
      opacity: 0;
    }

    .discarded {
      .sentence--content {
        text-decoration: line-through;
        color: $subMidGray;
      }
    }

    .pack-language-selectors {
      .button {
        padding-top: 5px;
      }
    }

    .button.btn-add {
      span.icon {
        transform: rotate(45deg);
        color: $subMidGray;
      }
    }
  }
</style>
