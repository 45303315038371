<template lang="pug">
  div.mautic-subscribe

    b-field.is-position-relative.mt-4
      b-input(v-model="mauticform.first_name" placeholder="First name" expanded :disabled="isSuccess" required)
    b-field.is-position-relative.mb-1
      b-input(v-model="mauticform.email" type="email" placeholder="Email" expanded :disabled="isSuccess" required)

    .has-text-right.mt-5.is-flex.valign
      b-checkbox.is-size-6.has-text-grey(v-model="consented") I consent to receive Teachee's newsletter

      button#btn_subscribe_mautic.button.is-primary.mw-110px.ml-auto(
        :disabled="isLoading || !consented || isSuccess || !mauticform.first_name.length || !mauticform.email.length"
        :class=`{
            'is-loading':  isLoading,
            'is-success':  isSuccess
          }`
        @click.stop.prevent="subscribe")
          b-icon(v-if="isSuccess" icon="check")
          span(v-else="")
            | Subscribe


</template>

<script>
  import {MailingSubscription} from '@/store/models/resources';
  export default {
    name: 'MauticSubscribe',
    data () {
      return {
        url: '',
        consented: false,
        isLoading: false,
        isSuccess: false,
        mauticform: {
          formName: 'newsletterform',
          formId: '1',
          return: '',
          email: '',
          first_name: '',
        }
      }
    },
    mounted () {
    },
    methods: {
      onDone() {
        this.isSuccess = true;
        this.isLoading = false;
        if (this.$analytics && this.$analytics.fbq && this.$analytics.fbq.event) {
          this.$analytics.fbq.event("Lead")
        }
      },

      subscribe() {
        const {mauticform} = this;

        // headers: {
        //   'X-Requested-With': 'XMLHttpRequest'
        // }

        this.isLoading = true;
        this.$http.post('https://mautic.teachee.io/form/submit?formId=1', { mauticform }, {
        }).then((res) => {
          this.onDone()
        }, (res) => {
          this.onDone()
        })
        // var formData = new FormData();
        // formData.append('email', this.email);

        // MailingSubscription.save({mailing_subscription: { email }}).then(() => {
        //   this.isLoading = false;
        //   this.isSuccess = true;
        //
        // })

      }
    }
  }
</script>

<style lang="scss">
.mautic-subscribe {
  .control-label {
    text-align: left;
  }

  .b-checkbox.checkbox input[type=checkbox] + .check {
  border: 1px solid rgba(122, 122, 122, 0.46);
  }
}
</style>
