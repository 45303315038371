<template>
<!--  <transition name="fade" mode="out-in">-->
    <router-view/>
<!--  </transition>-->
</template>

<script>
export default {
  name: 'no-layout',
};
</script>

<style>
  .fade-leave-active {
    transition-duration: .1s
  }
  .fade-enter-active {
    transition-duration: .1s
  }
</style>
