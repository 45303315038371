import debounce from "lodash/debounce";

VoiceRecognition =
  name: 'VoiceRecognition'

  listening_activated:  false
  can_listen:  true
  recognizedWord:  ''
  finalRecognizedWord: ''


  onstart: ->
    null

  onresult: (event) ->
    console.log({onresult: event})
    for result in event.results
      if result.isFinal
        VoiceRecognition.recognizedWord = result[0].transcript
#        VoiceRecognitionfinalRecognizedWord = result[0].transcript
        if VoiceRecognition.callback
          VoiceRecognition.callback(VoiceRecognition.recognizedWord.split(/[ \?!,\.]/))
          return
      else
        VoiceRecognition.recognizedWord = result[0].transcript

#    console.log VoiceRecognition.recognizedWord
#    console.log VoiceRecognition.finalRecognizedWord

    # CHECK IF WORDS DISPLAYED MATCH WORDS RECOGNIZED

    return VoiceRecognition.recognizedWord.split(/[ \?!,\.]/)

  onend: ->
    null

  # public

  listen: (language, callback) ->
    VoiceRecognition.callback = callback
    @sr.lang = language
    console.log(@sr)
    try
      @sr.stop()
    catch e
      console.log e

    try
      @sr.start()
    catch e
      console.log e

  stop: () ->
    try
      @sr.stop()
    catch e
      console.log e

  init: (language) ->
    wsr = window.SpeechRecognition || window.webkitSpeechRecognition
    if wsr
      @sr = new wsr()
      @sr.continuous =  false
#      @sr.lang = language
      @sr.interimResults = false
      @sr.onresult = @onresult
      @
    else
      console.info('webkitSpeechRecognition not found')
      {
        listen: ->
          null
      }

`export default VoiceRecognition`
