<template lang="pug">
  section#for_teachers.section.section--for-teachers.is-small
    .container

      .head.mb-6.mt-5.has-text-centered
        p.text-main.is-size-6.mb-0.opacity-8 WHAT YOU'LL GET FROM TEACHEE
        .is-inline-flex.mx-auto.has-justify-center.has-flex-end-items
          h2.size-h2.is-clickable.mr-4(
            :class="{ selected: selected == TEACHERS_TAB }"
            @click="selected = TEACHERS_TAB"
          ) For teachers
          h2.size-h2.is-clickable.wiggles(
            :class="{ selected: selected == STUDENTS_TAB }"
            @click="selected = STUDENTS_TAB"
          ) For students

      .body

        .columns(v-if="selected === TEACHERS_TAB")

          .column(
            v-for="(item, i) in contentTeachers"
            :key="i"
            v-scroll-reveal.reset="{ delay: 200*i }"
            )
            .picture
              img(:src="require(`../../assets/v3/benefits-teachers-${i+1}.svg`)" :width="item.pictWidth")
            h3.size-h3 {{ item.title }}
            p.mt-4 {{ item.subtitle }}

        .columns(v-if="selected === STUDENTS_TAB")

          .column(
            v-for="(item, i) in contentStudents"
            :key="i"
            v-scroll-reveal.reset="{ delay: 200*i }"
          )
            .picture
              img(:src="require(`../../assets/v3/benefits-students-${i+1}.svg`)" :width="item.pictWidth")
            h3.size-h3 {{ item.title }}
            p.mt-4 {{ item.subtitle }}

</template>

<script>
const TEACHERS_TAB = 'teachers'
const STUDENTS_TAB = 'students'

export default {
  name: "BenefitsSection",
  data() {
    return {
      TEACHERS_TAB,
      STUDENTS_TAB,
      selected: TEACHERS_TAB,
      contentTeachers: [
        {
          pictWidth: "145",
          title: "Save 20 hours a month",
          subtitle: "Save that 15 minutes prep work before and after each lesson. You can now record practice material for your student during the lesson and assign the homework with one click."
        },
        {
          pictWidth: "116",
          title: "Stand out from competitors",
          subtitle: "Be more than an online teacher. Offer your students audio recordings, flashcards, and a voice recognition system for their practice."
        },
        {
          pictWidth: "145",
          title: "Speed up your students’ progress",
          subtitle: "During your lessons you are generating content your student can use to practice between lessons. Assign homework to them and see how they perform so you can tailor your next lesson around that."
        },
      ],
      contentStudents: [
        {
          pictWidth: "120",
          title: "Learn pronunciation faster",
          subtitle: "Listen to your teacher's pronunciation as many times as you want, record your own audio to practice and compare."
        },
        {
          pictWidth: "120",
          title: "Don't miss anything from your lesson",
          subtitle: "Get more from your lesson with your teacher. Instead of writing down just a few bits of the lesson, now you’ll have a complete set of sentences with automatic translations, audio & visuals"
        },
        {
          pictWidth: "145",
          title: "Export and practice in between lessons",
          subtitle: "Anki doesn’t need any more introduction. It is the most-used free flashcard app among polyglots. Teachee allows you to export any lesson to Anki in one click, with visuals, your teacher’s audio recordings and phonetic."
        },
      ]
    }
  },

}
</script>

<style lang="scss">
@import "colors";

.section--for-teachers {
  padding-bottom: 5rem;
  background-color: $primaryDarker;
  color: $primary;
  h2:not(.selected) {
    font-size: 1.25rem !important;
    margin-bottom: 0.5rem;
  }
  .picture {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
  h3, p {
    width: 70%;
    margin: auto;
  }
  h3 {
    line-height: 1.3
  }

  .wiggles {
    animation-delay: 4s;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
  }
}
</style>

