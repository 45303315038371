<template lang="pug">
  div
    b-tooltip.w-100(
      :animated="true"
      :label="`${storageUsedHumanReadable} used on ${limit}`"
      :type="storageUsed < 60 ? 'is-success' : (storageUsed < 80 ? 'is-warning' : 'is-danger')"
      position="is-right")
      progress.progress.mt-2(
      :class=`{
            'is-success': storageUsed < 60,
            'is-warning': storageUsed >= 60 && storageUsed < 80,
            'is-danger': storageUsed >= 80,
          }`
      :value="storageUsed"
      max="100"
      )
</template>

<script>
  export default {
    name: "StorageProgressBarGeneric",
    props: [
      "user", "storageStats"
    ],
    computed: {
      storageUsedHumanReadable() {
        return this.getReadableFileSizeString(this.storageStats);
      },
      storageUsed() {
        if (this.user.role === 'free') {
          return parseInt(this.storageStats / 50000000 * 100);
        } else {
          return Math.round(parseFloat(this.storageStats / 2000000000 * 100 * 10), 1) / 10;
        }
      },
      limit() {
        if (this.user.role === 'free') {
          return '50 MB';
        } else {
          return '2 GB'
        }
      }
    },
    methods: {
      getReadableFileSizeString(fileSizeInBytes) {
        var i = -1;
        var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
