import {Lesson} from "./models/resources";
import categories      from './actions/categories.coffee'
import paypals      from './actions/paypals.coffee'
import sentences       from './actions/sentences.coffee'
import lessons         from './actions/lessons.coffee'
import packs           from './actions/packs.coffee'
import purchases       from './actions/purchases.coffee'
import students        from './actions/students.coffee'
import users           from './actions/users.coffee'
import layouts         from './actions/layouts.coffee'
import audios         from './actions/audios.coffee'
import corrections  from './actions/corrections.coffee'
import subscriptions  from './actions/subscriptions.coffee'
import charges  from './actions/charges.coffee'
import freeLessonTokens  from './actions/freeLessonTokens.coffee'
import events  from './actions/events.coffee'
import orders  from './actions/orders.coffee'
import credits  from './actions/credits.coffee'
import uuids         from './actions/uuids.coffee'

const es6Actions = {
  saveLesson: (context, lesson) => new Promise((resolve, reject) => {
      if (lesson.id) {
        Lesson.update({id: lesson.id}, {lesson}).then(
            (res) => {
              context.commit('editLesson', lesson)
              resolve(res.body)
            },
            reject
        )
      } else {
        Lesson.save({lesson}).then(
            (res) => {
              context.commit('editLesson', Object.assign({}, lesson, res.body))
              resolve(res.body)
            },
            (res) => reject(res.body)
        )
      }
    })
}

export default {
  ...corrections,
  ...subscriptions,
  ...charges,
  ...categories,
  ...packs,
  ...purchases,
  ...students,
  ...lessons,
  ...sentences,
  ...events,
  ...orders,
  ...credits,
  ...freeLessonTokens,
  ...users,
  ...layouts,
  ...audios,
  ...paypals,
  ...uuids,
  ...es6Actions
}
