<template lang="pug">
  #modal_collect_email.modal.is-active.modal-subscribe
    .modal-background
    .modal-card

      header.modal-card-head.has-flex-center-items.valign.no-border
        img.w-100(src="@/assets/v3/newsletter.svg")

      section.modal-card-body
        h2.title.is-size-5.mb-1.has-text-dark.has-text-centered
          | Don't miss out our newsletter!
        p.is-size-6.mt-3.mb-3.has-text-primary.has-text-centered
          | Sign up now to our weekly(ish) newsletter to stay in the loop on special offers, new features, teaching tips, survey results and more.

        mautic-subscribe

    button.modal-close.is-large(aria-label="close" @click="$emit('close')")
</template>

<script>
  import MauticSubscribe from "../ui/MauticSubscribe";
  export default {
    name: "AskToSubscribeModal",
    components: {MauticSubscribe}
  }
</script>

<style lang="scss" scoped>
  #modal_collect_email {
    .modal-background {
      background-color: rgba(10, 10, 10, 0.005);
    }
    .modal-card {
      width: 470px;
      max-width: 90%;
      box-shadow: 0 20px 40px rgba(51, 51, 51, 0.15);
    }
    .modal-card-head {
      padding-top: 1.75rem;
      border-bottom: 0 none;
    }
    .modal-card-body {
      padding: 0 2.5rem 2.5rem;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .modal-background {
    /*background-color: rgba(10, 10, 10, 0.36);*/
  }

  .modal-card,
  .modal-card-body {
    overflow: visible;
  }

</style>
