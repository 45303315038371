<template lang="pug">
  div
    .card.has-shadow(v-if="!lessons[0]")
      header.card-header.has-text-center
        div.card-header-title.is-hidden-mobile
          p.has-loader.is-primary.is-outlined.is-loading.has-margin-auto.mt12.mb12.primary-loader
    div(v-for="lesson in lessons" :key="lesson.id" v-else)
      transition(name="bounce")
        figure.lesson-picture.mb-1.mt-2(v-if="lesson.picture")
          img(:src="apiUrl+'/'+lesson.picture.url")
      .card.has-shadow.mb12
        header.card-header.has-text-right-mobile
          p.card-header-title.is-hidden-mobile
            b-icon(:icon="lesson.icon" v-if="lesson.icon")
            | {{ lesson.title }}

          transition(name="bounce")
            div.is-flex(
            v-if="lesson.is_public && lesson.user_id != user.id"
            )
              button#copy.button.is-primary.is-outlined.is-small.mr-2(
                :class="{'is-loading': copying}"
                @click="copy(lesson)"
              )
                b-icon(icon="open-in-new")
                span.pl-0 Copy

              span.separator
                |  |

          b-tooltip(label="Choose the language you're learning", type='is-light', :animated='true', position='is-top')
            language-selector(@select="setLessonLanguageTo" :language="lesson.language_to")

          b-tooltip(label="Choose the voice", type='is-light', :animated='true', position='is-top')
            voice-selector(:language="lesson.language_to")

          b-tooltip(label="Activate pinyin (experimental)", type='is-light', :animated='true', position='is-top', v-if="lesson.language_to === 'zh-CN' || lesson.language_to === 'zh-TW'")
            b-switch.mt-4.ml-2(v-model="features.pinyin")
              | Auto pinyin

          span.separator
            |  |

          b-tooltip.is-hidden-mobile.valign(:label='lesson.user.first_name', type='is-light', :animated='true', position='is-top', v-if="lesson.user && lesson.user.id != user.id && teacherPicture")
            router-link(:to="{name: 'TeacherPage', params: {id: lesson.user_id}}")
              figure.avatar
                img(:src="teacherPicture" width="50" height="50")
          b-tooltip.is-hidden-mobile.valign(:label='user.first_name', type='is-light', :animated='true', position='is-top', v-if="picture")
            figure.avatar
              img(:src="picture" width="50" height="50")

        .card-content
          div(v-if="lesson.sentences && lesson.sentences.length && (lesson.sentences[0] || {}).id")
            sentence.mh100(
            v-for="(sentence, i) in lesson.sentences"
            :key="(sentence || {}).id+'-'+i"
            :model="sentence"
            :features="features"
            :language="lesson.language_to"
            :version="(sentence || {}).version"
            :index="i"
            :lesson-uuid="requestedUuid"
            :is-teacher="forceTeacher")

        footer.card-footer
          a#export_to_anki.has-loader.card-footer-item.is-primary(
            href="#"
            @click.prevent='ankiLesson = lesson;exportToAnkiModal = true')
            b-icon(icon="save")
            span Export to Anki
          download-excel#export_to_excel.has-loader.card-footer-item.is-primary(
            v-if="lesson.sentences && lesson.sentences.length"
            :data= "lesson.sentences"
            :meta="excelMeta"
            :fields= "excelFields"
            :name= "filename+'.xls'")
            b-icon(icon="view-list")
            span Export to Excel
          a#export_to_audio.has-loader.card-footer-item.is-primary(:href="cannotDownloadAudio(lesson) ? '#' : apiUrl+'/lessons/'+lesson.uuid+'/audio'" :target="cannotDownloadAudio(lesson) ? '_self' : '_blank'" :class="{'is-disabled':cannotDownloadAudio(lesson)}"  v-ga="'exportToAudio'")
            b-icon(icon="headset")
            span Export to audio
          router-link#export_to_audio.has-loader.card-footer-item.is-primary(
            v-if="!lesson_uuid || lesson.user_id == user.id"
            :to="{name: 'LiveLesson', params: {id: lesson.uuid}}"
            )
            b-icon(icon="edit")
            span Edit
          router-link.card-footer-item.is-primary(
            :to="{name: 'TeacherPage', params: {id: lesson.user_id}}"
            v-if="!lesson_uuid && lesson.user && lesson.user.stripe_connect_uid && !forceTeacher"
            )
            b-icon(icon="book")
            span Book more lessons

    b-modal#export_to_anki_modal(:active.sync="exportToAnkiModal" :width="620")
      .box
        .content.has-text-centered
          anki-modal-body(
            :lesson="lesson"
            @close="duplicateModal = false"
          )
</template>

<script>
import downloadExcel from "vue-json-excel";
import sentence from "@/components/ReadOnlySentence";
import languageSelector from "@/components/LanguageSelector";
import voiceSelector from "@/components/VoiceSelector";
import { Lesson } from "@/store/models/resources";
import { mapGetters } from "vuex";
import some from "lodash/some";
import {LG_TO_TXT} from "../constants"
import AnkiModalBody from "./AnkiModalBody";

export default {
  name: "ReadOnlyLessons",
  components: {
    AnkiModalBody,
    downloadExcel,
    voiceSelector,
    languageSelector,
    sentence
  },
  data() {
    return {
      copying: false,
      ankiLesson: {},
      lesson: {},
      lessons: [],
      requestedUuid: null,
      exportToAnkiModal: false,
      togglePictureFixed: false,
      isRefreshing: false,
      isLoadingIpa: false,
      forceTeacher: false,
      url: "",
      forceUrl: "",
      excelMeta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      excelFields: {
        Content: "content",
        Phonetic: "ipa",
        "Literal translation": {
          field: "literal_translation",
          callback: value => {
            return `${value || ""}`;
          }
        },
        Translation: {
          field: "translation",
          callback: value => {
            return `${value || ""}`;
          }
        }
      },
      features: {
        pinyin: false
      },
      isLoading: false,
      isAdding: false,
      lesson_uuid: null,
      title: "",
      description: "",
      lgToTxt: LG_TO_TXT
    };
  },
  computed: {
    ...mapGetters(["uuid", "lastAudio", "isConnected", "apiUrl", "user"]),
    filename() {
      return (this.lesson.title || "")
        .toLowerCase()
        .replace(/[\s\/\\?!'"\(\)]+/g, "_");
    },
    picture() {
      if (
        this.user.picture &&
        (this.user.picture.substring(0, 4) === "data" ||
          this.user.picture.substring(0, 4) === "http")
      )
        return this.user.picture;
      else if (this.user.picture) return `${this.apiUrl}/${this.user.picture}`;
      else return null;
    },
    teacherPicture() {
      if (
        this.lesson.user.picture &&
        (this.lesson.user.picture.substring(0, 4) === "data" ||
          this.lesson.user.picture.substring(0, 4) === "http")
      )
        return this.lesson.user.picture;
      else if (this.lesson.user.picture)
        return `${this.apiUrl}/${this.lesson.user.picture}`;
      else return null;
    },

  },
  methods: {
    copy(lesson) {
      this.copying = true
      Lesson.copy({id: lesson.uuid}, {}).then(res => {
        this.$store.commit('lesson', {});
        this.$router.push(`/lessons/${res.body.lesson.uuid}`);
        this.copying = false
      }, err => {
        this.copying = false
      })
    },
    cannotDownloadAudio(lesson) {
      return !some(
        lesson.sentences,
        sent => sent && sent.audio && sent.audio.path
      );
    },
    print() {
      window.print();
    },
    exportToAnki() {
      this.isLoading = true;

      // })
    },
    setLessonLanguageTo(newValue) {
      this.lesson.language_to = newValue;
    },
  },
  created() {
    const { teacher_id, student_id, lesson_uuid } = this.$route.params
    this.lesson_uuid = lesson_uuid; // WHEN LESSON FROM PACKAGE - READ ONLY LESSON

    if (teacher_id) {
      this.forceTeacher = false;
      Lesson.allForTeacher({ id: teacher_id }).then(res => {
        this.lessons = res.body.lessons;
        this.lesson = Object.assign({}, this.lessons[0])
      });
    } else if (student_id) {
      this.forceTeacher = true;
      Lesson.allForStudent({ id: student_id }).then(res => {
        this.lessons = res.body.lessons;
        this.lesson = Object.assign({}, this.lessons[0])
      });
    } else if (lesson_uuid) {
      this.$store
        .dispatch("findLesson", { id: lesson_uuid }).then(res => {
        this.lessons = [res];
        this.lesson = Object.assign({}, this.lessons[0])
      })
    }
  },
  watch: {
    lastAudio() {
      // console.log("LAST AUDIO CHANGED");
      if (this.lastAudio && this.lastAudio.id) {
        // console.log(this.lastAudio);
        const msg = {
          sentence: {
            id: this.lastAudio.model,
            uuid: this.uuid,
            index: this.lastAudio.index,
            audio: this.lastAudio
          }
        };
        this.$socket.emit(
          "SENTENCE_EDITED",
          Vue.util.extend({ room: `LESSON_${this.$route.params.id}` }, msg)
        );
      }
    }
  },
  mounted() {
    this.url = window.location.href;
    this.forceUrl = this.url;

    // this.$store.dispatch('getSentences')
    this.$store.dispatch("setLayout", "logged-in-layout");
  },
  beforeDestroy() {
    document.title = `Teachee`;
  },
};
</script>
<style lang="scss" scoped>
@import "colors";

.lesson-picture {
  margin: auto;
  img {
    transition: all .2s ease-in-out;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    border-radius: 7px;
    width: auto;
    max-height: 250px;
  }
  &:hover:not(.togglePictureFixed) img {
    transform: scale(1.01);
    position: relative;
    z-index: 10000;
  }
}

.card-footer-item {
  color: $primary;
}

#export_to_excel {
  cursor: pointer;
}

.mode-switcher {
  line-height: 55px;
  margin: 0 9px;
}

button#copy {
  height: 30px !important;
  align-self: center;
}

span.separator {
  color: $lightGray;
  line-height: 55px;
  margin: 0 15px;
}

.mh100 {
  min-height: 100px;
}

.avatar img {
  width: 30px;
  height: 30px;
  padding: 3px;
}

.card-header a.options,
.card-header .avatar,
.button.card-header-icon {
  min-width: 50px;
}
</style>
