<script>
  export default {
    methods: {
      resizeTextarea (event) {
        event.target.style.height = 'auto'
        event.target.style.height = (event.target.scrollHeight) + 'px'
      },
    },
    mounted () {
      this.$nextTick(() => {
        this.$el.firstElementChild.setAttribute('style', 'height:' + (this.$el.scrollHeight) + 'px;overflow-y:hidden;')
      })

      this.$el.firstElementChild.addEventListener('input', this.resizeTextarea)
    },
    beforeDestroy () {
      this.$el.firstElementChild.removeEventListener('input', this.resizeTextarea)
    },
    render () {
      return this.$slots.default[0]
    },
  }
</script>
