<template lang="pug">
  .is-flex.is-flex-direction-column
    button#export_to_anki_default.button.is-primary(
      :title="!user.id ? 'You need an account to receive the deck by email' : ''"
      @click="downloadAnki('normal', '/lessons/anki')"
      :class="{'is-loading': loading.back, 'is-disabled': loading.back || !user.id}"
      v-ga="'exportToAnki'")
      b-icon(icon="save")
      span Target language on the back

    button#export_to_anki_alternate.button.is-dark.my-3(
      :title="!user.id ? 'You need an account to receive the deck by email' : ''"
      @click="downloadAnki('normal', '/lessons/anki?alternate=1')"
      :class="{'is-loading': loading.front, 'is-disabled': loading.front || !user.id}"
      v-ga="'exportToAnki'")
      b-icon(icon="save")
      span Target language on the front

    button#export_to_anki_separeted_fields.button.is-dark(
      :title="!user.id ? 'You need an account to receive the deck by email' : ''"
      @click="downloadAnki('custom', '/lessons/anki')"
      :class="{'is-loading': loading.custom, 'is-disabled': loading.custom || !user.id}"
      v-ga="'exportToAnki'")
      b-icon(icon="save")
      span Comprehension deck

</template>

<script>
import { mapGetters } from "vuex";
import {LG_TO_TXT} from "../constants"

const keyToWord = {
  back: "Back",
  front: "Front",
  custom: "Custom"
}

export default {
  name: "BatchAnkiModalBody",
  components: {
  },
  props: [
    'lessonUuids',
  ],
  computed: {
    ...mapGetters(["apiUrl", "user"]),
  },
  data() {
    return {
      isLoading: false,
      loading: {
        back: false,
        front: false,
        custom: false,
      },
      lgToTxt: LG_TO_TXT
    };
  },
  methods: {
    downloadAnki(key, path) {
      this.$set(this.loading, key, true)
      this.$http.post(this.apiUrl + path, {uuids: this.lessonUuids, kind: key}).then(res => {
        const {email} = this.user;
        let msg = "Export in progress...<br />You will receive your Anki deck in an email within 2 mins at <b>"+email+"</b>."
        if (email.includes("@gmail.com")) {
          msg += "<br><br><a style='color: #FFF;text-decoration: underline;' href='https://mail.google.com/mail/u/0/#inbox' target='_blank'>Open Gmail</a>"
        } else if (email.includes("@live") || email.includes("@hotmail") || email.includes("@yahoo")) {
          const domain = email.split("@")[1];
          msg += "<br><br><a style='color: #FFF;text-decoration: underline;' href='https://"+domain+"' target='_blank'>Open Inbox</a>"
        }

        this.$toast.success(msg, { duration: 10000})
        this.$emit('close');
      }).catch(({body}) => {
        this.$toast.error(body, { duration: 10000});
      }).finally(() => {
        this.$set(this.loading, key, false)
      })
    }
  }
};
</script>

<style lang="scss">
  @import "colors";

  #export_to_anki_modal {
    b {
      font-weight: 400;
    }
  }
</style>
