<template lang="pug">
  .columns.is-multiline.page-my-account
    .column.is-4
      .box.has-shadow
        b-tooltip(
          :active="showTooltip" :animated="true" label="Drag and drop a square picture of you here" type="is-dark" always="" position="is-right")
          figure.avatar
            img(:src="picture" width="98" height="98")
        vue-dropzone#picture_dropzone(ref="dropzone" :options="dropOptions" :include-styling="false" @vdropzone-success="pictureUploaded" @vdropzone-error="pictureUploadError")
        .message.is-danger(v-if="error")
          .message-body
            | {{ error }}
        h2 {{ name }}
        h3.is-size-7 {{ location }}
        b-tooltip.is-block(
          v-if="user.role === 'free'"
          :animated="true"
          label="1 credit gives you 1 lesson supporting up to 50 sentences with media (audio & picture)"
          type="is-dark"
          position="is-bottom"
        )
          h4.credit.pt-1(:class="{'has-text-success': user.credits > 1}")
            | {{ user.credits }} credit{{ user.credits > 1 ? 's' : '' }} 💰
        router-link.is-size-7.is-inline-flex.valign.m-auto(
          v-if="user.is_teacher"
          :to="{name: 'TeacherPage', params: {id: user.id}}"
          )
          b-icon(icon="remove-red-eye" custom-size="mdi-12px")
          | See public profile

      //- Languages
      .box.has-shadow(v-if="user.is_teacher")
        label.label.has-text-left Languages
        .field.has-addons.valign
          label.label.mr-2.mb-0 I teach:
          language-selector(
            :showChoose="!lastTeaches"
            @select="addTeaches"
            :language="lastTeaches"
          )

        button.button.mr-2(@click="removeFromTeaches(lang)" v-for="lang in user.teaches_languages")
          i.flag.mr-2(:class="'flag-'+lang")
          b-icon.has-text-grey(icon="remove-circle-outline")



      //- Lesson preferences
      .box.has-shadow
        label.label.has-text-left Preferences

        b-tooltip(
          v-if="!user.is_teacher && $route.query && $route.query.advanced"
          :animated="true"
          label="Check this if you don't create lessons. You're always joining your teacher's lesson"
          type="is-dark"
          position="is-right"
          )
          b-field.has-text-left.mb-2
            b-checkbox(v-model="user.force_student")
              | Set edition mode to student on all lessons

        //-b-field.has-text-left
          b-checkbox(v-model="user.preferences.capitalize_tts")
            | Automatic transcriptions should starts with a capital letter

        b-field.has-text-left.mb-2
          b-checkbox(v-model="user.preferences.tts_on_click_on_ipa")
            | Read word out loud when clicking on phonetic

        b-field.has-text-left
          b-checkbox(v-model="user.preferences.repeat_after_recording")
            | Repeat audio recording after recording

        b-field.has-text-left
          b-checkbox(v-model="user.preferences.use_autocomplete")
            | Enable autocomplete

      //-.box.has-shadow(v-if="user.is_teacher")
        .equal-height
          h2 Payment
          h3.is-size-7 Paypal
        hr
        //-a.mb-4(
          v-if="!isConnecting && !user.stripe_connect_uid"
          :href="'https://connect.stripe.com/oauth/authorize?response_type=code&client_id='+stripeConnectKey+'&scope=read_write'"
          class="connect-button")
          img(
          src="@/assets/blue-on-light@3x.png"
          )
        //-button.button.is-info.is-loading.is-disabled.mb-4(v-if="isConnecting")
          | Connecting to Stripe Connect
        b-field.has-text-left(label="Paypal Email")
          b-input#email(
            @keyup.native="delayedSave"
            v-model="user.paypal_email" type="email" placeholder=""
          )
        div(v-if="user.stripe_connect_uid || user.paypal_email")
          <!--.columns-->
          <!--.column-->
          <!--b-field.has-text-left(label="Stripe Connect ID")-->
          <!--span.input.is-disabled-->
          <!--| {{ user.stripe_connect_uid }}-->
          .columns.has-text-left
            .column
              b-field(label="Hourly rate")
                b-input#rate(
                v-model="user.rate"
                @keyup.native="delayedSave"
                type="number"
                step="0.01"
                )
            .column.is-narrow
              b-field(label="Currency")
                b-select(
                placeholder="Choose"
                v-model="user.currency"
                @input="delayedSave"
                )
                  option(
                  v-for="option in currencies"
                  :value="option"
                  :key="option")
                    | {{ option }}

          b-field.has-text-left
            b-checkbox#charges_vat(
            v-model="user.charges_vat"
            @click.native="delayedSave"
            )
              span(v-if="user.charges_vat") I charge VAT
              span(v-else) I don't charge VAT

          //-h3.is-size-5.has-text-left Discounts
          //-div.has-text-left.is-size-7.discounts.mt12(
            v-for="discount in sortedDiscounts"
            :key="discount.quantity")
            .field.is-grouped.is-grouped-multiline
              span.control Apply a
              b-input.discount_percent(
              v-model.number="discount.percent"
              size="is-small"
              @input="delayedSave"
              type="number"
              step="1"
              )
              span.control % discount when student books
              b-select(
              placeholder="-"
              v-if="!discount.quantity"
              v-model="discount.quantity"
              @input="delayedSave"
              )
                option(
                v-for="option in remainingQuantities"
                :value="option"
                :key="option")
                  | {{ option }}
              b.control(
              v-if="discount.quantity")
                | {{ discount.quantity }}
              span.control sessions
              .has-text-right.is-wide(
              v-if="discount.quantity")
                button.button.is-outlined.is-small.btn-remove-discount(
                @click="removeDiscount(discount.quantity)")
                  b-icon(icon="remove")

          //-.has-text-right.mt12
            button#btn_add_lesson_head.button.is-outlined.is-small(
            @click="addDiscount()"
            v-if="remainingQuantities.length > 0")
              b-icon(icon="add")
              span Add a discount
    .column.is-8
      .columns
        .column.is-5
          .box.has-shadow.h-100
            .columns
              .column
                b-field.has-text-left(label="First name")
                  b-input#first_name(v-model="user.first_name" @keyup.native="delayedSave")
              .column
                b-field.has-text-left(label="Last name")
                  b-input#last_name(v-model="user.last_name" @keyup.native="delayedSave")
            .columns
              .column
                b-field.has-text-left(label="City")
                  b-input#city(v-model="user.city" @keyup.native="delayedSave")
              .column
                b-field.has-text-left(label="Country")
                  b-input#country(v-model="user.country" @keyup.native="delayedSave")

            b-field.has-text-left(label="Settings")
              b-checkbox(v-model="user.is_teacher")
                | I'm a {{user.is_teacher ? 'teacher' : 'student'}}


            b-field.has-text-left(label="Notifications")
              b-checkbox(v-model="user.email_notifications_enabled")
                | Occasional emails

            b-field.has-text-left(label="Time zone")
              time-zone-select(v-model="user.time_zone")

            b-field.has-text-left(label="Email")
              b-input#email(v-model="user.email" type="text" required="" placeholder="Email")

            b-field.has-text-left(label="New password")
              b-input#password(v-model="user.newPassword" type="password" )
            .columns
              .column
                .field.has-addons.has-flex-wrap.has-text-left
                  label.label.w-100 Subscription
                  p.control.is-expanded
                    span.input.is-disabled
                      | {{ roleDisplay[user.role] || user.role }}
                  p.control(v-if="user.role == 'free'")
                    router-link.button.is-warning(:to="{name: 'SubscribePage'}") Upgrade
                p.control.has-text-left.has-text-grey.is-size-7.pl-2(v-if="user.role != 'free'  && user.role_valid_until")
                  | Valid until {{ user.role_valid_until | moment("YYYY.MM.DD") }}

                .valign.has-flex-start-items(v-if="user.subscription_info && user.subscription_info.cancel_url")
                  a#btn_cancel_subscription.button.is-danger.is-outlined.is-small(:href="user.subscription_info.cancel_url" target="_blank")
                    | Cancel
                  a#btn_update_subscription.button.is-info.is-outlined.is-small.ml-1(:href="user.subscription_info.update_url" target="_blank")
                    | Update payment info
                div(v-if="user.subscription_info && user.subscription_info.canceled")
                  button#btn_canceled_subscription.button.is-light.is-medium(disabled="disabled")
                    | Canceled
        .column.is-7
          .box.has-shadow
            .field.has-text-left
              label.label.is-flex
                span
                  | Description
                editor-menu-bar.ml-auto(:editor="editor")
                  div(slot-scope="{ commands, isActive }")
                    .buttons.has-addons
                      button.button.is-small(:class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="commands.heading({ level: 1 })")
                        | H1
                      button.button.is-small(:class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })")
                        | H2
                      button.button.is-small(:class="{ 'is-active': isActive.bold() }" @click="commands.bold")
                        b-icon(icon="format-bold")

              .content
                editor-content(
                    :editor="editor"
                    )
          .is-flex.flex-between
            button#btn_delete_profile.button.is-inline-block.is-danger.is-outlined.mw-110px(
              :class="{'is-loading': isDeleting, 'is-disabled': isDeleting, 'is-success': isSuccess}"
              v-confirm="'Are you sure you want to <b>delete your account</b>? All data will be lost forever.'"
              @click.stop.prevent="remove"
              )
              | Delete my account
            button#btn_save_profile.ml-auto.button.is-inline-block.is-primary.mw-110px(:class="{'is-loading': isLoading, 'is-disabled': isLoading, 'is-success': isSuccess}" @click.stop.prevent="save")
              b-icon(v-if="isSuccess" icon="check")
              span(v-else="")
                | Save

</template>


<script>
import { nodeApi, stripeConnectKey } from "@/config";
import { mapGetters, mapActions } from "vuex";
import { User } from "@/store/models/resources";
import vue2Dropzone from "vue2-dropzone";
import timeZoneSelect from "@/components/TimeZoneSelect";
import map from "lodash/map";
import difference from "lodash/difference";
import sortBy from "lodash/sortBy";
import findIndex from "lodash/findIndex";
import debounce from "lodash/debounce";
import shareGauge from "@/components/ShareGauge";
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  History,
} from 'tiptap-extensions'
import last from 'lodash/last';
import uniq from 'lodash/uniq';
import LanguageSelector from "@/components/LanguageSelector";


const getTemplate = () => `
<div class="dz-preview dz-file-preview">
  <div class="dz-image">
    <div data-dz-thumbnail-bg></div>
  </div>
  </div>
  `;

export default {
  name: "MyAccount",
  components: {
    LanguageSelector,
    vueDropzone: vue2Dropzone,
    // storageProgressBar,
    timeZoneSelect,
    EditorContent,
    EditorMenuBar,
    shareGauge
  },
  data() {
    return {
      dropOptions: {
        url: `${nodeApi}/users/${this.$auth.user().id}`,
        headers: { Authorization: `Bearer ${this.$auth.token()}` },
        method: "put",
        maxFilesize: 1,
        previewTemplate: getTemplate()
      },
      stripeConnectKey: stripeConnectKey,
      isConnecting: false,
      currencies: ['EUR', 'USD', 'GBP'],
      error: null,
      isLoading: false,
      isDeleting: false,
      isSuccess: false,
      showTooltip: false,
      user: {},
      editor: null,
      defaultHtml: '<h1>🎓 Introduce yourself</h1><h2>Keep it short and interesting 🙂</h2><p>For some inspiration, you can use the method SUCCESs from the book <strong>Made to Stick</strong> by Dan Heath and Chip Heath.<br />Craft your message in a way that it is:</p><ul><li><p><strong>S</strong>imple</p></li><li><p><strong>U</strong>nexpected</p></li><li><p><strong>C</strong>oncrete</p></li><li><p><strong>C</strong>redible</p></li><li><p><strong>E</strong>motional</p></li><li><p><strong>S</strong>tory</p></li></ul>',
      defaultHash: '',
      roleDisplay: {
        free: "Free",
        premium: "Pro",
        proplus: "Pro+"
      }
    };
  },
  computed: {
    ...mapGetters(["apiUrl"]),
    name() {
      return (
        this.user.name ||
        `${this.user.first_name || ""} ${this.user.last_name || ""}`
      );
    },
    location() {
      if (
        this.user.city &&
        this.user.city.length &&
        this.user.country &&
        this.user.country.length
      ) {
        return `${this.user.city}, ${this.user.country}`;
      }
    },
    picture() {
      if (
        this.user.picture &&
        (this.user.picture.substring(0, 4) === "data" ||
          this.user.picture.substring(0, 4) === "http")
      )
        return this.user.picture;
      else if (this.user.picture) return `${this.apiUrl}/${this.user.picture}`;
      else return "/logo_teachertool_v2_square.png";
    },
    remainingQuantities() {
      const available = [3,4,5,8,10]
      const used = map(this.user.discounts, 'quantity')
      return difference(available, used);
    },
    sortedDiscounts() {
      if (!this.user.discounts || !this.user.discounts.length) {
        return []
      }
      return sortBy(this.user.discounts, ['quantity'])
    },
    lastTeaches() {
      return last(this.user.teaches_languages)
    },
  },
  created() {
    // console.log('user: ', this.$auth.check())
    // console.log('user: ', this.$auth.user())
    // this.user = Object.assign({}, this.$auth.user())
    this.user = this.$auth.user();
    if (!this.user.picture) {
      var cpnt = this;
      setTimeout(() => {
        cpnt.showTooltip = true;
      }, 400);
      setTimeout(() => {
        cpnt.showTooltip = false;
      }, 4000);
    }

    this.$auth.fetch({
      success: (data) => {
        this.$store.dispatch("setUser", data.body.data);
        this.user = data.body.data
      },
    });

  },
  mounted() {
    // console.log(this.$route)
    if (this.$route.query && this.$route.query.code) {
      this.isConnecting = true
      User.stripeConnect(this.$route.query).then((data) => {
        // console.log(data)
        if (data.body.user) {
          this.$store.dispatch("setUser", data.body.user);
          this.$auth.user(data.body.user);
          this.user = data.body.user
        }
        this.isConnecting = false
      })
    }

    console.log("MOUNTED once")
    this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Bold(),
        new Code(),
        new Italic(),
        new Link(),
        new History(),
      ],
      content: (!this.user.description_html || this.user.description_html == 'null') ? this.defaultHtml : this.user.description_html,
    })


    setTimeout(() => {
      this.defaultHash = this.hashCode(this.editor.view.dom.innerHTML)
    })
  },
  methods: {
    ...mapActions(['getStorageStats']),
    delayedSave: debounce(function() {
      // console.log("delaying");
      this.save();
    }, 700),
    remove() {
      this.isDeleting = true
      this.$store.dispatch("deleteUser", this.user).then(() => {
        this.$toast.success("Account deleted successfully");
        this.$store.dispatch("setUser", {});
        this.$auth.logout({
          redirect: {name: 'SignIn'}
        });
      }).catch(() => {
        this.$toast.error("Something wrong happened. Please contact guillaume@teachee.io if the problem persists.");
        this.isDeleting = true
      })
    },
    save() {
      // console.log({ed: this.editor});
      this.isLoading = true;
      // console.log('hash is ', this.hashCode(this.editor.view.dom.innerHTML))
      if ( this.defaultHash != this.hashCode(this.editor.view.dom.innerHTML) ) {
        this.user.description_html = this.editor.view.dom.innerHTML;
      }
      this.$store.dispatch("saveUser", this.user).then(data => {
        this.$store.dispatch("setUser", data.user);
        this.$auth.user(data.user);
        this.isLoading = false;
        this.isSuccess = true;
        setTimeout(() => {
          this.isSuccess = false;
        }, 2500);
      });
    },
    pictureUploaded(file) {
      // console.log(file);
      this.user.picture = file.dataURL;
    },
    pictureUploadError(file, message, xhr) {
      // console.log(file, message, xhr);
      this.$toast.open({
        duration: 5000,
        message: message,
        type: "error"
      });
    },
    noop() {},
    cancelSubscription() {
      this.$store.dispatch("unsubscribe").then(data => {
        this.isLoading = false;
        this.$store.dispatch("setUser", data.user);
        this.$auth.user(data.user);
        this.user = data.user;

        this.$toast.open({
          duration: 3000,
          message: `Subscription successfully canceled`,
          type: "success"
        });
      });
    },
    addDiscount() {
      this.user.discounts = this.user.discounts || []
      this.user.discounts.push({
        quantity: null,
        percent: 20
      })
    },
    removeDiscount(quantity) {
      const idx = findIndex(this.user.discounts, {quantity: quantity});
      this.user.discounts.splice(idx, 1);
      this.delayedSave();
    },
    hashCode(str) {
      var hash = 0, i, chr;
      if (str.length === 0) return hash;
      for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    getReadableFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    },
    addTeaches(lang) {
      this.user.teaches_languages.push(lang)
      this.user.teaches_languages = uniq(this.user.teaches_languages)
    },
    removeFromTeaches(lang) {
      const idx = this.user.teaches_languages.indexOf(lang);
      this.user.teaches_languages.splice(idx, 1);
    },
  },
  destroyed() {
    this.editor.destroy()
  },
};
</script>


<style lang="scss" scoped>
@import "colors";


.avatar {
  padding-bottom: 30px;
}

input {
  font-weight: 300;
}

p {
  font-weight: 700;
}

p.subtitle {
  padding-top: 1rem;
}



span.coupon {
  text-align: center;
  background: url("~@/assets/coupon.png");
  background-size: contain;
  width: 150px;
  height: 70px;
  font-weight: 900;
  color: $white;
  display: inline-block;
  line-height: 72px;
  font-size: 0.8rem;
  span {
    background-color: $blue;
  }
}

h4.credit {
  color: $midGray;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.8rem;
}


a {
  text-decoration: none;
}
.equal-height {
  padding: 15px 0;
}

#btn_canceled_subscription,
#btn_cancel_subscription {
}

.container {
  width: 400px;
  margin-top: 100px;
}

.dz-drag-hover {
  border: 2px dashed #dedede;
  background-color: rgba(240, 248, 255, 0.34);
}

.tooltip.is-right:before,
.tooltip.is-right:after {
  top: 35%;
}

#picture_dropzone {
  position: relative;
  z-index: 10;
  border-radius: 100%;
  height: 100px;
  width: 100px;
  margin: -126px auto 0px;
}

.connect-button {
  display: inline-block;
  margin-bottom: 1px;
  background-image: -webkit-gradient(linear,left top,left bottom,from(#28a0e5),to(#015e94));
  background-image: linear-gradient(#28a0e5,#015e94);
  -webkit-font-smoothing: antialiased;
  border: 0;
  padding: 1px;
  height: 32px;
  text-decoration: none;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 0 rgba(0,0,0,.2);
  box-shadow: 0 1px 0 rgba(0,0,0,.2);
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none!important;
  width: 200px;
  height: 32px;
  img {
    width: 100%;
    height: 32px;
  }
}
.discount_quantity,
.discount_percent {
  width: 43px;
}

.discounts input,
.discounts .control {
  font-size: 10px;
  line-height: 26px;
  margin-right: 3px !important;
  margin-left: 2px !important;
}

</style>
